import { render, staticRenderFns } from "./FinancialPerformance.vue?vue&type=template&id=05048b36&scoped=true&"
import script from "./FinancialPerformance.vue?vue&type=script&lang=js&"
export * from "./FinancialPerformance.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05048b36",
  null
  
)

export default component.exports