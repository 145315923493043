const state = () => ({
    forceRefreshPage: false,
});

const getters = {
    getForceRefreshPage(state) {
        return state.forceRefreshPage;
    }
}

const mutations = {
    setForceRefreshPage(state, data) {
        state.forceRefreshPage = data;
    }
}

const actions = {
    changeForceRefreshPage(vuexContext, data) {
        vuexContext.commit('setForceRefreshPage', data);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
