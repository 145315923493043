<template>
 <v-container>
    <v-row class="mt-5">
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-hover   v-for="(item,index) in items" :key="index" v-slot="{ hover }">
       <v-card @click="goToReport(item.url)" :style="`width: ${$vuetify.breakpoint.lgAndUp ? '30' : '100'}%`" class="mr-2 mb-2 pa-2" :elevation="hover ? 16 : 2">
        <v-card-text><h2>{{item.display_name}}</h2></v-card-text>
         <p class="pa-5">{{item.description}}</p>
       </v-card>
      </v-hover>

  </v-row>
  </v-container>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "Reports",
  data() {
    return {
      items:[],
      loading:true,
    }
  },
  methods:{
    goToReport(url){
      this.$router.push(url)
    }
  },
  async created() {
    //Fixing the spinner not going away
    // setTimeout(() => {
    //   this.loading = false;
    // }, 500);
    await ClientsService.getReportsPages()
        .then(res=>{
          this.items = res
          this.loading=false
        }).catch(err=>this.loading=false)
    // Testing data until backend is done
    // this.items=[
    //   {
    //     "report_id": 1,
    //     "display_name": "Required documents progress report",
    //     "description": "Shows all the clinets in process, and how close they are to complete submission of their documents",
    //     "url": "/required-documents"
    //   },
    //   {
    //     "report_id": 2,
    //     "display_name": "Ready to sanitize report",
    //     "description": "Shows all the clients who have submitted all required documents, and are ready to begin the sanitization process.",
    //     "url": "/ready-to-sanitize"
    //   }
    // ]
  }
}
</script>

<style scoped>

</style>
