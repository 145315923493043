import $axios from "@/plugins/axios";

class Announcement {

    getAnnouncements() {
        return $axios.get("/announcements").then((res) => {
            return res.data;
        });
    }

    deleteAnnouncement(id) {
        return $axios.delete(`/announcements/${id}`).then((res) => {
            return res.data;
        });
    }

    patchAnnouncement(id, data) {
        return $axios.patch(`/announcements/${id}`, data).then((res) => {
            return res.data;
        });
    }

    postAnnouncement(data) {
        return $axios.post("/announcements", data).then((res) => {
            return res.data;
        });
    }
}

export default new Announcement();
