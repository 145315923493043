import $axios from '../plugins/axios'

class TasksService {
  getClientTasks(id) {
    return $axios.get(`/clients/${id}/tasks`).then(res => res.data)
  }

  postClientTask(id, body) {
    return $axios.post(`/clients/${id}/tasks`, body).then(res => res.data)
  }

  postClientSubtask(body) {
    return $axios.post(`/subtask`, body).then(res => res.data)
  }

  getClientSubtaskDocTypes() {
    return $axios.get(`/subtask/documents/types`).then(res => res.data)
  }

  patchClientSubtask(id, body) {
    return $axios.patch(`/tasks/subtasks/${id}`,body).then(res => res.data)
  }
  patchClientTask(id, body,filter) {
    return $axios.patch(`/tasks/${id}/${filter}`,body).then(res => res.data)
  }

  getTaskDetails(id) {
    return $axios.get(`/tasks/${id}`).then(res => res.data)
  }

  postSubtaskNote(taskId, payload) {
    return $axios.post(`/sub_task/${taskId}/notes`, payload).then(res => res.data)
  }
  getTasksReport() {
    return $axios.get(`/reports/clients/tasks`).then(res => res.data)
  }
}

export default new TasksService();
