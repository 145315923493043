<template>
  <div>
    <v-row class="d-flex justify-center">
      <v-col
          cols="12"
          sm="12"
          md="12"
      >
        <v-card
            @drop.prevent="drop($event)"
            @dragover.prevent="dragoverData = true"
            @dragenter.prevent="dragoverData = true"
            @dragleave.prevent="dragoverData = false"
            height="20vh"
            class="d-flex  align-center justify-center"
            style="border: 5px dashed lightgray; box-shadow: none">
          <v-card-text>
            <v-row class="d-flex justify-center align-center">
              <div
                  class="p-12"
              >
                <input type="file" multiple name="fields[assetsFieldHandleUploads][]"
                       id="assetsFieldHandleUploads"
                       class="d-none absolute" @input="onChange" ref="file"
                />

                <label for="assetsFieldHandleUploads" class="block cursor-pointer">
                  <span class="underline"> CHOOSE  A FILE </span> OR DRAG IT <span style="color: blue;"> HERE </span>
                </label>
                <div v-if="$refs.file" cols="12" class="displayFileName">
          <span
                style="font-size: 12px"
                >{{ file.original_file_name }}
          </span>
                </div>
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col><v-btn color="primary" class="px-16" @click="saveUpload">Save</v-btn></v-col>
      <v-col cols="12">
        <v-list class="mt-4" v-if="this.items.length" dense>
          <v-list-item
              v-for="(uploadFile, index) in items"

              class="pa-3"
              :class="{'activeItemClass': index % 2 !== 0}"
              style="background-color: #f0f0f0"
          >
            <div style="width: 100%" class="d-flex pa-2">
              <div class="d-flex flex-column">
                <h3 style="font-size: 0.8rem;max-width: 430px" class="text-left" :maxlength="4">{{ uploadFile.original_file_name }}</h3>

                <h4 style="font-weight: 500;font-size: 12px;opacity: 70%" class="text-left">Uploaded by :
                  <template v-if="uploadFile.uploaded_by == user.id">You</template>
                  <template v-else>{{ uploadFile.uploaded_by_name }}</template>
                  <span class="ml-5">{{ uploadFile.created_on }}</span>
                </h4>
              </div>
              <v-spacer></v-spacer>
              <div class="d-flex flex-row justify-center align-center">
                <a :download="uploadFile.original_file_name"
                   :href="uploadFile.url"
                   v-show="uploadFile.id"
                   target="_blank"
                   class="ml-2"
                   type="button"
                   title="Download file"
                >

                  <v-icon>
                    mdi-download
                  </v-icon>
                </a>
<!--                <template v-if="file.uploaded_by === currentUser.id">-->
<!--                  <button class="ml-2" type="button"-->
<!--                          @click="$emit('deleteUpload',file)"-->
<!--                          title="Remove file"-->
<!--                  >-->

<!--                    <v-icon>-->
<!--                      mdi-trash-can-outline-->
<!--                    </v-icon>-->
<!--                  </button>-->
<!--                </template>-->
                <template >
                  <div style="margin-left: 32px"></div>
                </template>
              </div>
            </div>
          </v-list-item>
        </v-list>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClientService from "@/services/clients.service";

export default {
  name: "PeoUploads",
  props: {
    clientId: {
      type: Number,
      default: 0
    },
    clientProp: {
      type: Object
    },
    user: {
      type: Object
    }
  },
  data:()=>({
    file:'',
    dragoverData:false,
    items:[]
  }),
  methods:{
    getData(){
      ClientService.getPeoUploads(this.clientId).then(res=>this.items = res)
    },
    saveUpload(){
      let formData = new FormData();
      formData.append('uploaded_file', this.file.uploadedFile);
      formData.append('upload_type', 'peo');
      ClientService.uploadClientCSVFileWithReturningData(this.clientId, formData)
          .then((res) => {
            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: 'File uploaded successfully',
              duration: 4000,
              mode: 'success'
            });
            // this.awaiting_review[index] = res.data;
            // this.awaiting_review = [...this.awaiting_review]
            this.getData()
          }).catch(error => {
        this.$func.popMessage(`${error.response.data.error}`, 'fail')
      })

    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
    },
    onChange() {
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        if (this.$refs.file.files[i].size < 25000000) {
          this.isActiveButton = true;
          let uploadedFile = this.$refs.file.files[i]
          const newFile = {
            original_file_name: uploadedFile.name,
            url: uploadedFile,
            created_on: this.forDate,
            mime_type: uploadedFile.type,
            uploaded_by: this.user.id,
            uploadedFile: uploadedFile,
            uploadingFiles: false,
          }
          if (uploadedFile) {
            this.$emit('fileDropedNotSaved', true)
            this.file=newFile
          }
        } else {
          const removeMessageAfter = 2500
          this.errorMessage = true;
          this.startBuffer()
          setTimeout(() => {
            this.errorMessage = false
            clearInterval(this.interval);
            this.errProgressBarValue = 0;
            this.bufferValue = 0;
          }, removeMessageAfter)
        }
      }
    },
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
