<template>
  <section :id="item.id" :class="{editable:edit}" data-fields="title=txt&amp;body=rte&amp;image=img&amp;vimeo_link=vid">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h2>{{item.title}}</h2>
          <p>{{item.body}}</p>
        </div>
        <div class="col-md-6">
<!--          {{item}}-->
          <template v-if="item.image">
            <!--            <img :src="test" class="img-fluid" />-->
            <img v-if="item.storage_path" :src="'data:image/jpeg;base64,'+item.image" class="img-fluid" />
            <img v-else :src="item.image" class="img-fluid" />
            <canvas id="canvas"></canvas>
          </template>
          <template v-else-if="item.vimeo_link">
            <div class="responsive-container">
              <iframe
                  title="video"
                  id="video"
                  class="responsive-iframe"
                  width="560"
                  height="315"
                  :src="item.vimeo_link"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen />

            </div>
          </template>
          <template v-else>
            <img src="https://vue-pagebuilder.vercel.app/editor/img/placeholder.jpg" class="img-fluid" />
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import helpers from './helpers.js'

export default {
  name: "News",
  props: ['item','edit'],
  data:()=>({
    video:''
  }),

  created() {
    this.youtube_parser = helpers.youtube_parser;
    this.parseUrl = helpers.parseUrl
  },

}
</script>

<style scoped>
@import "~@/components/PageBuilderComponents/editor/editor.css";

</style>
