<template>
  <div>
    <ConfirmCardDialog
        cancel-btn-text="Cancel"
        confirm-btn-text="Confirm"
        :show.sync="deletePopup"
        card-desc="Are you sure you want to remove this client from the Queue?"
        card-title="Remove Client"
        @confirmDialog="removeTranscript"
        :loading="deleteLoading"
    />
    <ClientHeader :client="client" />
    <v-row v-if="user && user.role_id !== 1" justify="space-between">
      <v-col cols="6" md="auto" class="d-flex flex-column flex-md-row">
        <v-btn :small="isMobile" @click="requestNewTask(1)" color="primary" rounded>
          <v-icon left>mdi-plus</v-icon>
          Request New Task
        </v-btn>
        &nbsp;
        <v-btn :style="{fontSize: isMobile ? '11px' : null}" :small="isMobile" @click="requestNewTask(2)" color="primary" rounded>
          <v-icon left>mdi-plus</v-icon>
          Request New Document
        </v-btn>
      </v-col>
      <v-col cols="6" md="auto">
        <v-btn :small="isMobile" @click="deletePopup = true" color="error">REMOVE FROM QUEUE</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12" md="4"><v-skeleton-loader type="card" /></v-col>
      <v-col cols="12" md="8"><v-skeleton-loader type="card"/></v-col>
    </v-row>
    <v-row v-else-if="!loading && transcriptQueue.tasks.length" class="fill-height">
      <v-col v-if="selectedTaskIdx === null || !isMobile" cols="12" md="4" class="d-flex flex-column" style="gap: 10px">
        <div @click="selectTask(i)" v-for="(task, i) in transcriptQueue.tasks" :key="task.id">
          <TranscriptQueueTask
            :class="{ selected: selectedTaskIdx === i }"
            :task="task"
            :index="i"
            :loading="loadingTask[i]"
            @removeUnsavedTask="(j) => transcriptQueue.tasks.splice(j, 1)"
          >
            <template #actions>
              <v-list dense>
                <div v-for="status in statuses" :key="status.id">
                  <v-list-item @click="changeStatus(task.id, status.id, i)">{{status.name}}</v-list-item>
                  <v-divider />
                </div>
              </v-list>
            </template>
          </TranscriptQueueTask>
        </div>
      </v-col>
      <v-col class="d-flex align-center d-md-none" v-if="transcriptQueue.tasks[selectedTaskIdx]">
        <v-btn @click="selectedTaskIdx = null" icon>
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
        <span class="font-weight-bold">Request a {{transcriptQueue.tasks[selectedTaskIdx].type_id === 1 ? 'task' : 'document'}}</span>
      </v-col>
      <v-col cols="12" md="8" v-if="transcriptQueue.tasks[selectedTaskIdx]">
        <v-card elevation="3" v-if="transcriptQueue.tasks[selectedTaskIdx].task_decription === null">
          <v-card-title>
            <v-textarea v-model="newTaskDescription" filled label="Describe the task needed" />
          </v-card-title>
          <v-card-actions class="d-flex justify-end">
            <v-btn :loading="newTaskLoading" @click="submitNewTask" color="primary">
              Request {{transcriptQueue.tasks[selectedTaskIdx].type_id === 1 ? 'task' : 'document'}}
            </v-btn>
          </v-card-actions>
        </v-card>
        <TranscriptQueueTaskDetails v-else :task="transcriptQueue.tasks[selectedTaskIdx]">
          <template #new-note>
            <v-row>
              <v-col cols="auto">
                <v-avatar color="#E7E7E7">{{getInitials(`${user.first} ${user.last}`) }}</v-avatar>
              </v-col>
              <v-col>
                <v-card outlined>
                  <v-card-text>
                    <v-textarea v-model="noteDescription" filled label="New Note" />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="auto">
                <v-btn color="primary" @click="postNote" :disabled="submitNoteLoading">Answer</v-btn>
              </v-col>
            </v-row>
          </template>
        </TranscriptQueueTaskDetails>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="4">
        <v-alert
           colored-border
           elevation="2"
           type="info"
        >
          There are no tasks currently!
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClientHeader from "@/components/Client/ClientHeader.vue";
import ClientService from "@/services/clients.service";
import TranscriptQueueTask from "@/components/TranscriptQueue/TranscriptQueueTask.vue";
import TranscriptQueueTaskNote from "@/components/TranscriptQueue/TranscriptQueueTaskNote.vue";
import TranscriptService from "@/services/transcript.service";
import ConfirmCardDialog from "@/components/ConfirmCardDialog.vue";
import AuthService from "@/services/auth.service";
import {mapGetters} from "vuex";
import TranscriptQueueTaskDetails from "@/components/TranscriptQueue/TranscriptQueueTaskDetails.vue";
export default {
  name: "SingleTranscriptQueueMTA",
  components: {TranscriptQueueTaskDetails, TranscriptQueueTaskNote, TranscriptQueueTask, ClientHeader, ConfirmCardDialog },
  data: () => ({
    transcriptQueue: {},
    client: null,
    selectedTaskIdx: null,
    newTaskDescription: "",
    newTaskLoading: false,
    deletePopup: false,
    deleteLoading: false,
    noteDescription: "",
    submitNoteLoading: false,
    statuses: [],
    loading: false,
    user: null,
    loadingTask: [],
  }),
  methods: {
    getClient() {
      return ClientService.getOneClient(this.transcriptQueue.client_id).then((res) => {
        this.client = res;
      });
    },
    getStatuses(){
      return TranscriptService.getTranscriptStatuses().then(response => {
        this.statuses = response
      })
    },
    postNote(){
      this.submitNoteLoading = true;

      const noteModel = {
        body: this.noteDescription,
        author: this.user.id
      }

      return TranscriptService.postTranscriptNote(this.transcriptQueue.tasks[this.selectedTaskIdx].id,noteModel).then((data) => {
        this.$func.popMessage("Submit Successfully.", "success")
        this.submitNoteLoading = false;
        this.noteDescription = "";
        let task = this.transcriptQueue.tasks[this.selectedTaskIdx]
        let date = new Date();
        date.setHours(date.getHours() - 7)
        let newNote = {...noteModel, author_name: `${this.user.first} ${this.user.last}`, created_on: date}
        if (task?.notes)
          task.notes.push(newNote)
        else
          task.notes = [newNote]

      }).catch(err => {
        this.$func.popMessage("Submit Failed.", "error")
      }).finally(() => {
        this.submitNoteLoading = false;
      })
    },
    getTranscript() {
      return TranscriptService.getTranscriptQueueById(this.$route.params.id).then((res) => {
        this.transcriptQueue = res;
      })
    },
    requestNewTask(type) {
      this.transcriptQueue.tasks.unshift({
        type_id: type,
        task_decription: null,
        status_id: 1,
        status: 'Needs Action',
        new: true
      })
      this.selectedTaskIdx = this.isMobile ? null : 0;
    },
    submitNewTask() {
      const payload = {
        description: this.newTaskDescription,
        task_type: this.transcriptQueue.tasks[this.selectedTaskIdx].type_id
      }
      this.newTaskLoading = true;

      return TranscriptService.createTranscriptQueueTask(this.$route.params.id, payload).then((res) => {
        this.transcriptQueue.tasks.splice(this.selectedTaskIdx, 1)
        this.transcriptQueue.tasks.push(res)
        this.newTaskDescription = ""

        if (this.isMobile)
          this.selectedTaskIdx = null
      }).finally(() => this.newTaskLoading = false)
    },
    changeStatus(taskId, statusId, index){
      const statusModel = {
        status: statusId
      }
      this.loadingTask[index] = true
      this.loadingTask = [...this.loadingTask]
      return TranscriptService.patchTranscriptTaskStatus(taskId, statusModel).then((data) => {
        this.$func.popMessage("Status Changed Successfully.", "success")
        const { notes } = this.transcriptQueue.tasks[index]
        this.transcriptQueue.tasks[index] = {...data, notes }
      }).catch(err => {
        this.$func.popMessage("Changing Status Failed.", "error")
      }).finally(() => {
        this.loadingTask[index] = false
        this.loadingTask = [...this.loadingTask]
      })
    },
    removeTranscript() {
      this.deleteLoading = true;

      return TranscriptService.removeTranscriptFromQueue(this.$route.params.id).then(() => {
        this.deletePopup = false
        this.$router.push(`/queues/transcript`)
      }).finally(() => this.deleteLoading = false,)
    },
    getInitials(user) {
      return user.split(' ').map((name) => name[0]).join("");
    },
    selectTask(i) {
      this.selectedTaskIdx = i

      if (this.isMobile)
        window.scroll(0, 0)
    },
    async getUser(){
      await AuthService.getCurrentUser(this.currentUser.current_user_id)
          .then(res=>{
            this.user = res.data
          })
    },
  },
  computed: {
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser'
        },
    ),
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },
  async created() {
    this.loading = true
    await Promise.all([
        this.getTranscript(),
        this.getStatuses(),
        this.getUser()
    ]).finally(() => this.loading = false)
    await this.getClient();
  },
};
</script>
