const state = () => ({
    overPayment: null
});

const getters = {
    getOverPayment(state) {
        return state.overPayment
    }
}

const mutations = {
    setOverPayment(state, payload) {
        state.overPayment = payload.number;
    },
}

const actions = {
    changeOverpayment({ commit }, payload) {
        commit("setOverPayment", payload);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
