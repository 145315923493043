<template>
  <DialogRightAlign
    :showDialog.sync="showRightDialog"
    :titleProp="`View Entity`"
    :closeOutside="false"
    :clickedOutside="false"
  >

      <CreateEntitiesDialog
 
	    v-if="showEditEntity"
			:key="dialogKeyNewEntity"
			:showRightDialog.sync="showEditEntity"
      :entityObject="entityObject"
      v-on:entity-update="entitiesEmitEvent"
      @updatedFirmType="updatedFirmType"
			/>

      <v-card-text class="mt-5">

        <v-container>

    <v-card-text class="mt-5">
      <v-container>
        <v-row class="d-flex justify-center">
          <v-col cols="12" sm="12" md="12">
<!--            <h3>{{ name }}</h3>-->

            <h3>{{name}}</h3>

              <v-col cols="12" sm="12" md="12">
                <p>EIN: {{ein}}</p>
              </v-col>

               <v-col cols="12" sm="12" md="12">
                <p>Firm type: {{firmType}}</p>
              </v-col>

               <v-col cols="12" sm="12" md="12">
                <p>First name: {{first}}</p>
              </v-col>

<!--               <v-col cols="12" sm="12" md="12">-->
<!--                <p>Last name: {{last}}</p>-->
<!--              </v-col>-->

            <v-col cols="12" sm="12" md="12">
              <p>Last name: {{ last }}</p>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <p>Email: {{ email }}</p>
            </v-col>
          </v-col>
        </v-row>
        </v-container>

    </v-card-text>
        </v-container>
      </v-card-text>

    <v-spacer></v-spacer>

    <v-card-actions>
      <v-btn color="red" text @click.native="deleteEntity(entityID)">
        DELETE
      </v-btn>

      <v-spacer></v-spacer>

      <v-btn color="#263B4F" text @click.native="closeDialog"> CANCEL </v-btn>
      <v-btn color="#263B4F" text @click.native="editEntity()"> EDIT ENTITY </v-btn>
    </v-card-actions>
  </DialogRightAlign>
</template>

<script>
import ClientsService from "@/services/clients.service";

import DialogRightAlign from "../Base/DialogRightAlign";

import CreateEntitiesDialog from "../../components/Entites/CreateEntitiesDialog.vue";


export default {
  name: "EntitesDialog",
  components: { DialogRightAlign, CreateEntitiesDialog },

  props: {
    showRightDialog: {
      type: Boolean,
      default: false,
    },
    entityObject: {
      type: Object,
      default: {}
    }  
  },

  data: () => ({
    showEditEntity: false,
    dialogKeyNewEntity: 15,
    entityID: 0,
    name: "",
    ein: "",
    firmType: "",
    first: "",
    last: "",
    email: ""
  }),

  methods: {
    updatedFirmType(id){
      this.firmType = id
    },
    closeDialog() {
      this.$emit("update:showRightDialog", false);
    },
    async deleteEntity(id){
      await ClientsService.deleteEntities(id)
          .then(res=>  
            this.$func.popMessage("Entity was deleted.")
          )

      this.$emit('entity-update', 'entity-update');
      this.closeDialog()
    },
    editEntity(id, name){
      this.showEditEntity = true;
      this.dialogKeyNewEntity++
      //this.entityID = id
      //console.log(this.entityID)
      // this.entityName = name
      //console.log('id: ', id)
      //console.log('name: ', index.name)
      //console.log('ein: ', index.ein)
      //console.log('firm type: ', index.firm_type_name)
    },
    getCurrentEntity(){
        ClientsService.getEntity(this.entityObject.id)
          .then(res=>  {
              this.name = res.name
              this.ein = res.ein
              this.firmType = res.firm_type.name
              this.first = res.primary_user.first
              this.last = res.primary_user.last
              this.email = res.primary_user.email
            }
          )
    },
    entitiesEmitEvent(){
      this.getCurrentEntity()
      this.$emit('entity-update', 'entity-update');
      //this.closeDialog()
      //console.log('entity updated')
    }
  },
  mounted(){
    //console.log("THE ENTITY OBJECT IS ", this.entityObject)
    this.entityID = this.entityObject.id
    this.name = this.entityObject.name
    this.ein = this.entityObject.ein
    this.firmType = this.entityObject.firm_type.type
    this.first = this.entityObject.primary_user.first
    this.last = this.entityObject.primary_user.last
    this.email = this.entityObject.primary_user.email
  },
};
</script>

<style scoped></style>
