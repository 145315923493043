<template>
  <div>
    <v-dialog v-model="deleteDialog" width="600">
      <v-card>
        <v-card-title
          class="text-h5 font-weight-bold"
          style="background-color: #263b4f; color: white"
        >
          Are you sure you want to delete the document
        </v-card-title>

        <v-card-actions>
          <div class="d-flex justify-space-between" style="width: 100%;">
            <v-btn color="blue darken-1" text @click="deleteDialog = false"> Cancel </v-btn>
            <v-btn color="red darken-1" text @click="deleteDocument()"> Confirm </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <!--  <v-data-table-->
    <!--      disable-pagination-->
    <!--      mobile-breakpoint="0"-->
    <!--      @click:row="openClientDialog"-->
    <!--      hide-default-footer-->
    <!--      :items="items"-->
    <!--      :headers="headers"-->

    <!--  ></v-data-table>-->
    <RadioGroupComponent @radio="radioFilter"></RadioGroupComponent>
    <v-data-table
        :single-expand="false"
        :expanded.sync="expanded"
        :headers="headers"
        :items="items"
        class="elevation-5 mt-6"
        disable-pagination
        hide-default-footer
        show-expand
        item-key="id"
    >
      <template v-slot:item.document_score="{ item }">
        <v-progress-linear
            :value="item.document_progress"
            height="20"
        >
          <!--        <strong>{{ Math.ceil(item.document_score) }}%</strong>-->
          <strong>{{ item.document_score }} /{{ item.document_max }}</strong>
        </v-progress-linear>
      </template>
      <template v-slot:item.trade_name="{ item }">
        <div style="cursor: pointer" @click="goToClient(item.id)">
          {{ item.trade_name }}
        </div>
      </template>
      <template v-slot:item.uses_peo="{ item }">
        <div>
          {{ peoField(item.uses_peo) }}
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-data-table
              disable-pagination
              hide-default-footer
              id="subTableReQdocs"
              :headers="headers2"
              :items="item.documents">
            <template v-slot:item.actions="{ item }">
              <v-btn
                v-show="item.document_status === 0"
                color="error"
                @click="showDeleteDialog(item)"
              >
                Remove
              </v-btn>
            </template>
            <template v-slot:item.document_status="{ item }">
              <span v-if="item.document_status == 0" style="font-weight: bold;color: #AF0808">OUTSTANDING</span>
              <span v-if="item.document_status == 1" style="color: #4CAF50;">OK</span>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
    <ProfileClientDialog
        v-if="showRightDialogChosenClient"
        :key="dialogKeyChosenClient"
        :showRightDialogChosenClient.sync="showRightDialogChosenClient"
        :clientProp="clientProp"
        @openClientProfileDialog="openClientProfileDialog"

    />
  </div>
</template>

<script>
import ProfileClientDialog from "@/components/Client/ProfileClientDialog.vue";
import ClientsService from "@/services/clients.service";
import RadioGroupComponent from "./RadioGroupComponent.vue"

export default {
  name: "Documents",
  components: {ProfileClientDialog, RadioGroupComponent},
  data() {
    return {
      items: [],
      loading: true,
      expanded: [],
      showRightDialogChosenClient: false,
      dialogKeyChosenClient: 13,
      clientProp: {},
      headers: [
        {text: 'Days in system', value: 'days_in_system'},
        {text: 'Client', value: 'trade_name'},
        {text: 'EIN', value: 'ein'},
        {text: 'Document Score', value: 'document_score'},
        {text: 'PEO', value: 'uses_peo'},
      ],
      headers2: [
        {text: 'Client', value: 'client'},
        {text: 'Status', value: 'document_status'},
        {text: 'Document ', value: 'document_name'},
        {text: 'Actions', value: 'actions'},
      ],
      radioRow: 'radio-0',
      deleteDialog: false,
      itemToDelete: undefined
    }
  },
  methods: {
    showDeleteDialog(item) {
      this.itemToDelete = item
      this.deleteDialog = true
    },
    deleteDocument() {
      const {id, client} = this.itemToDelete
      this.loading = true
      this.itemToDelete = undefined
      this.deleteDialog = false
      ClientsService.deleteReqDocs(client,{requirement_id: id})
          .then(async res  => {
            await this.getRequiredDocuments()
            this.loading = false
          }).finally(() => {
        this.loading = false
      })
    },
    async getRequiredDocuments(payload) {
      await ClientsService.getClientsRequiredDocuments(payload)
          .then(res => {
            this.items = res
            this.loading = false
          })
    },
    goToClient(id) {
      this.$router.push(`/clients/${id}/profile`)
    },

    openClientProfileDialog() {
      this.showRightDialogChosenClient = true;
      this.dialogKeyChosenClient++;
      this.loading = false
      this.showRightDialog = false;
    },
    peoField(field) {
      return field == 1 ? 'Yes' : 'No'
    },
    radioFilter(value) {
      this.radioRow = value
      this.switchBusinessType(value)
    },
    switchBusinessType(value) {
      const payload = {
        business_type: '941'
      }
      if (value == 'radio-1') {
        payload.business_type = '943'
      } else if (value == 'radio-2') {
        payload.business_type = '944'
      }
      this.getRequiredDocuments(payload)
    },
  },
  async created() {
    await ClientsService.getClientsRequiredDocuments()
        .then(res => {
          this.items = res
          this.loading = false
        })
  },
}
</script>

<style scoped>

#subTableReQdocs {
  background-color: #ededed;
}

</style>
