<template>
  <div id="draft-container" class="pa-md-16 d-flex justify-center">
    <v-overlay :value="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="12">
        <h1 style="font-weight: 300; font-size: 4rem">Final Report</h1>
      </v-col>
      <v-col cols="12">
        Your final report was generated on 4/20/2023 @ 12:07pm, and is available
        for download below after you submit the missing documents.
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn
          color="primary"
          style="font-size: 0.7rem"
          :disabled="outstanding.length"
          >Download Final</v-btn
        >
      </v-col>
      <v-col cols="12" v-if="outstanding.length">
        <h1 style="font-weight: 400; color: red">You are missing documents</h1>
      </v-col>
      <v-col cols="12" v-if="outstanding.length">
        <p>
          Our records indicate that you are missing the following documents,
          which are required for you to download your final report as well as
          submitting the report to the IRS to receive your tax credits.
        </p>
        <p>
          Please ensure that the following documents are submitted. Once you
          have submitted these documents, your final report will be downloadable
          at this page.
        </p>
      </v-col>
      <v-col cols="12" v-if="outstanding.length">
        <h1 style="font-weight: 400; color: red">
          Please send the following documents:
        </h1>
        <div class="d-flex align-center">
          <v-checkbox></v-checkbox>
          <h1 style="font-size: 0.9rem; font-weight: 300">Client Service</h1>
        </div>
      </v-col>
      <!-- <v-col cols="12" class="d-flex justify-space-between">
                <v-btn color="red" style="font-size: 0.7rem;" @click="openModal">Reject Draft</v-btn>
                <v-btn color="success"><span style="font-size: 0.7rem;" @click="acceptDraft">Accept Draft</span></v-btn>
            </v-col> -->
    </v-row>
  </div>
</template>

<script>
import clientsService from "../../services/clients.service";

export default {
  name: "ClientFinal",

  data() {
    return {
      loading: true,
      clientId: null,
      outstanding: [],
    };
  },

  mounted() {
    this.clientId = this.$route.params.id;
    this.getDocs();
  },

  methods: {
    getDocs() {
      clientsService.getOutstandingUploads(this.clientId).then((res) => {
        this.outstanding = res;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
