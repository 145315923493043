<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <v-card style="width: 500px" class="d-flex flex-column  align-center justify-center">
          <v-card-title style="font-size: 1.5rem">Current component versions</v-card-title>
          <v-card-subtitle class="d-flex flex-column  align-center justify-center">
            <h4>Front End: {{uxVersion}}</h4>
            <h4>API: {{apiVersion}}</h4>
            <h4>Engine: {{engineVersion}}</h4>
          </v-card-subtitle>
        </v-card>
<!--        <h3>Current component versions</h3>-->
<!--        <li>Front End: {{uxVersion}}</li>-->
<!--        <li>API: {{apiVersion}}</li>-->
<!--        <li>Engine: {{engineVersion}}</li>-->
      </v-col>
      <v-col v-for="item in items"  cols="12" md="6" lg="3">
        <v-card @click="()=>$router.push(`/new/releases/${item.release_id}`)" style="cursor:pointer;" class="pa-8 d-flex flex-column align-center justify-center" elevation="5">
          <h2>{{item.release_date}}</h2>
          <h3>User Interface: <span style="font-weight: 350">{{item.components.ux}}</span> </h3>
          <h3>API: <span style="font-weight: 350">{{item.components.api}}</span> </h3>
          <h3>Database: <span style="font-weight: 350">{{item.components.database}}</span> </h3>

        </v-card>
      </v-col>
    </v-row>
  </div>

</template>

<script>
import ReleasesService from "@/services/releases.service";
import authService from "@/services/auth.service";
export default {
  name: "Releases",
  data(){
    return{
      items:[],
      loading:false,
      apiVersion:"v0.0",
      uxVersion:"v0.0",
      engineVersion:"v0.0",
    }
  },
  created() {
    this.loading=true
    ReleasesService.getReleases().then(res => {
      this.items = res
    }).finally(()=> this.loading = false)
    this.uxVersion = process.env.VUE_APP_UX_VERSION
    authService.getMaintenanceStatus().then(res=>{
      this.apiVersion = res.api_version
      this.engineVersion = res.engine_version
    })
    // this.items=
    //  [
    //     {
    //       "release_id": 12,
    //       "release_date": "8/24/2023",
    //       "components": {
    //         "ux": "v2.1.9",
    //         "api": "v3.1",
    //         "database": "v2.2",
    //         "erc_engine": "v4.0"
    //       }
    //     },
    //     {
    //       "release_id": 11,
    //       "release_date": "8/23/2023",
    //       "components": {
    //         "ux": "v2.0.1",
    //         "api": "v2.9",
    //         "database": "v1.9",
    //         "erc_engine": "v3.9"
    //       }
    //     },
    //     {
    //       "release_id": 10,
    //       "release_date": "7/29/2023",
    //       "components": {
    //         "ux": "v1.0.9",
    //         "api": "v1.9",
    //         "database": "v1.0",
    //         "erc_engine": "v2.1"
    //       }
    //     }
    //   ]

  }
}
</script>


<style scoped>

</style>
