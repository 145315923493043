<template>
 <div>
   <v-overlay :value="loading">
     <v-progress-circular
         :size="70"
         :width="7"
         color="white"
         indeterminate
     ></v-progress-circular>
   </v-overlay>
    <RadioGroupComponent @radio="radioFilter"></RadioGroupComponent>
   <v-data-table
       :single-expand="false"
       :headers="headers"
       :items="items"
       class="elevation-5 mt-6"
       disable-pagination
       hide-default-footer
       item-key="id"
   > <template v-slot:item.trade_name="{ item }">
     <div style="cursor: pointer" @click="goToClient(item.client)">
       {{item.trade_name}}
     </div>
   </template>
   <template v-slot:item.uses_peo="{ item }">
      <div>
        {{peoField(item.uses_peo)}}
      </div>
    </template> 
    </v-data-table>
   <ProfileClientDialog
       v-if="showRightDialogChosenClient"
       :key="dialogKeyChosenClient"
       :showRightDialogChosenClient.sync="showRightDialogChosenClient"
       :clientProp="clientProp"
       @openClientProfileDialog="openClientProfileDialog"

   />
 </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import ClientService from "@/services/clients.service";
import ProfileClientDialog from "@/components/Client/ProfileClientDialog.vue";
import RadioGroupComponent from  "./RadioGroupComponent.vue"
import {mapGetters} from "vuex";
export default {
  name: "UnreviwedUploads",
  components: {
    ProfileClientDialog,
    RadioGroupComponent
  },
  data() {
    return {
      items:[],
      loading:true,
      showRightDialogChosenClient:false,
      dialogKeyChosenClient:13,
      clientId:'',
      clientProp:{},
      headers:[
        { text: 'Client', value: 'trade_name' },
        { text: 'Attorney of record', value: 'aor_name' },
        { text: 'Legal name', value: 'legal_name' },
        { text: 'Uploads Awaiting Review', value: 'uploads_awaiting_review' },
        { text: 'Most Recent Upload Date', value: 'most_recent_upload_formatted' },
        { text: 'PEO', value: 'uses_peo' }
      ],
      radioRow:'radio-0' 
    }
  },
  methods:{
    getData(payload){
      this.loading=true
      ClientsService.getUnreviwedDocs(payload).then(res=>{
        this.items=res
        this.loading=false
      }).catch(()=>this.loading=false)
    },
    goToClient(id){
      this.loading = true
      this.$router.push(`/clients/${id}/profile`)
    },
    openClientProfileDialog() {
      this.showRightDialogChosenClient = true;
      this.dialogKeyChosenClient++;
      this.loading = false
      this.showRightDialog = false;
    },
    peoField(field) {
      return field==1? 'Yes':'No'
    },
    radioFilter(value){
      this.radioRow=value
      this.switchBusinessType(value)
    },
    switchBusinessType(value){
        const payload= {
        business_type:'941'
      }
      if (value=='radio-1'){
        payload.business_type='943'
      } else if (value=='radio-2') {
        payload.business_type='944'
      }
        this.getData(payload)
      },
  },
  watch:{
    refreshPage(val){
     if (val === true) {
       setTimeout(()=>{
         this.getData()
         this.$store.dispatch('reports/changeRefreshUploadReports', false)
       },300)
     }
    },
  },
  computed:{
    ...mapGetters(
        {
          refreshPage: 'reports/getRefreshUploadReports'
        },
    ),
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
