<template>
  <div class="pa-8 text-center">
    <h4 class="text-start">
      8. Payroll Spreadsheet
    </h4>
    <h5 class="ml-6 mt-6 text-start">
      In order to calculate the ERC you must provide an EXCEL SPREADHSEET (not a PDF) detailing every check written to <strong>every employee from
      March 15, 2020 through September 30, 2021.</strong> A properly formatted spreadsheet will contain a column for each of the following pieces of
      information: Date of Check, Name of Employee, Gross Wages.
    </h5>

    <h5 class="ml-6 mt-6 text-start">
      Your ability to provide a properly formatted payroll spreadhseet is the single most imporatant item in determining the speed at which your ERC
      analysis will be conducted. This spreadsheet can easily be generated out of Quickbooks or any reputible payroll provider.
    </h5>


    <v-row class="my-6">
      <v-col cols="12" class="text-start ml-6">
        <h4 style="font-weight: 350">(BELOW: Example of a properly formatted payroll report from Quickbooks)</h4>
      </v-col>
      <v-col cols="12" class="my-5 mx-4">
          <img style="object-fit: contain;width: 100%;height: 100%;right: 0" src="../../assets/img/Check4.png">
      </v-col>
    </v-row>

    <v-btn color="#263B4F" class="mt-4">
      <span style="color: #FFFFFF" @click="()=>this.$emit('goToNextStep', 9)">Save and Continue</span>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Step8",
  data () {
    return{

    }
  }
}
</script>

<style scoped>

</style>