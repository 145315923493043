<template>
  <div>
    <LeadCreateEditDialog
      v-if="showLead"
      :key="showLeadKey"
      :showRightDialog.sync="showLead"
      :clientProp="leadProp"
      :isEdit="editLead"
      @forceUpdate="forceUpdate"
      @getLeads="getLeads"
    />
    <v-data-table
      class="row-pointer"
      :items="leads"
      disable-pagination
      mobile-breakpoint="0"
      @click:row="openCreateLeadDialog"
      hide-default-footer
      :headers="leadHeaders"
      :loading="loading"
    >
    </v-data-table>

    <!--    Contractor-->
    <v-row class="d-flex justify-center align-center mt-10">
      <v-col md="2" sm="4" class="btn-client">
        <v-btn
          @click="openCreateLeadDialog(false)"
          color="primary"
          block
          rounded
          x-large
        >
          Create New Lead
        </v-btn>
      </v-col>

      <v-fab-transition>
        <v-btn
          @click="openCreateLeadDialog(false)"
          style="position: fixed; right: 0; bottom: 0"
          fab
          large
          dark
          bottom
          right
          class="v-btn--example ma-5"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-row>
  </div>
</template>

<script>
import LeadService from "@/services/lead.service";
import LeadNotes from "@/components/Lead/LeadNotes";
import LeadCreateEditDialog from "@/components/Lead/LeadCreateEditDialog";
import { mapGetters } from "vuex";
import { addHashToLocation } from "@/globalFunctions";
export default {
  name: "Leads",
  components: {
    LeadNotes,
    LeadCreateEditDialog,
  },
  data: () => ({
    leadProp: {},
    leadHeaders: [
      { text: "Trade name", value: "trade_name", sortable: false },
      { text: "First name", value: "contact_first", sortable: false },
      { text: "Last name", value: "contact_last", sortable: false },
      { text: "Email", value: "contact_email", sortable: false },
      { text: "Phone number", value: "contact_phone", sortable: false },
      { text: "Status", value: "status.name", sortable: false },
    ],
    loading: true,
    leads: [],
    showLead: false,
    showLeadKey: 13,
    editLead: false,
  }),
  computed: {
    ...mapGetters({
      refreshPage: "leads/getForceRefreshPage",
    }),
  },
  watch: {
    refreshPage(val) {
      if (val === true) {
        this.forceUpdate();
        this.$store.dispatch("leads/changeForceRefreshPage", false);
      }
    },
  },
  methods: {
    getLeads() {
      this.loading = true
      LeadService.getLeads().then((res) => {
        this.loading = false
        this.leads = res;
      }).catch(() => this.loading = false);
    },
    getCurrentLead(id) {
      LeadService.getSingleLead(id).then((res) => {
        this.openCreateLeadDialog(res);
      });
    },
    addHashToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path + "#" + encodeURIComponent(params)
      );
    },
    async openCreateLeadDialog(lead) {
      this.addHashToLocation(lead.id);
      this.leadProp = {
        status: {
          id: 1,
          name: "New lead",
          workflow_order: 0,
        },
      };
      if (lead) {
        this.leadProp = lead;
        this.editLead = true;
        this.showLead = true;
        this.showLeadKey++;
      } else {
        this.editLead = false;
        this.showLead = true;
        this.showLeadKey++;
      }
      // Open Lead creation dialog
    },
    forceUpdate() {
      this.leads = [];
      this.getLeads();
    },
  },
  async created() {
    // await this.getUser()
    if (this.$route.hash) {
      await this.getCurrentLead(this.$route.hash.substring(1));
    }
    if (window.screen.availHeight < 920) {
      this.leadHeaders = [
        { text: "Trade name", value: "trade_name", sortable: false },
        { text: "Status", value: "status.name", sortable: false },
      ];
    }
    await this.getLeads();
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
