<template>

  <div>
    <div class="d-flex justify-center">
      <v-btn color="#263B4F" class="white--text" :disabled="!selected.length" @click="moveToFiled" >Move to Ready to File</v-btn>
    </div>
    <div class="d-flex justify-center">
      <RadioGroupComponent @radio="radioFilter"></RadioGroupComponent>
  </div>
    <v-data-table
        :headers="headers"
        :items="items"
        item-key="client_id"
        class="elevation-1 mt-5"
        hide-default-footer
        :loading="loadingTable"
        loading-text="Loading... Please wait"
        show-select
        v-model="selected"
    >
      <template v-slot:item.uses_peo="{ item }">
        <div>
          {{peoField(item.uses_peo)}}
        </div>
      </template>
    </v-data-table>
    <h3 v-if="items.length" class="mt-4 ml-2">Total client count: {{items.length}}</h3>

  </div>

</template>

<script>
import ClientsService from "@/services/clients.service";
import RadioGroupComponent from  "./RadioGroupComponent.vue"
export default {
  name: "ReadyToFinal",
  components: {
    RadioGroupComponent
  },
  data(){
    return{
      singleSelect: false,
      selected: [],
      headers: [
        { text: 'Client', value: 'client_name' },
        { text: 'Days Ready', value: 'days_in_ready' },
        { text: 'Attorney', value: 'attorney' },
        { text: 'Patriot Staff', value: 'patriot_stuff' },
        { text: 'PEO', value: 'uses_peo' }
      ],
      items:[],
      loadingTable:false,
      radioRow:'radio-0'
    }
  },
  methods:{
    moveToFiled(){
      const model = this.selected.map(e=>e.client_id)
      ClientsService.moveToFiled(model).then(()=>this.getReadyTofinal())
    },
    getReadyTofinal(x) {
      this.loadingTable=true
      ClientsService.getReadyToFinal(x)
          .then(res => {
            this.loadingTable=false
            this.items = res
          }).catch(() => {})
    },
    peoField(field) {
        return field==1? 'Yes':'No'
    },
    radioFilter(value){
      this.radioRow=value
      this.switchBusinessType(value)
    },
    switchBusinessType(value){
        const payload= {
        business_type:'941'
      }
      if (value=='radio-1'){
        payload.business_type='943'
      } else if (value=='radio-2') {
        payload.business_type='944'
      }
        this.getReadyTofinal(payload)
      },
  },
  created() {
    this.getReadyTofinal()
  }
}
</script>

<style scoped>

</style>
