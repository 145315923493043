import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#263B4F',
				secondary:'#FFE9E9'
			},
		},
	},
})

export default vuetify;
