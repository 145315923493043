<template>
  <div>
    <v-row>
      <v-col>
        <div>
          <v-text-field
            outlined
            label="Search Donor"
            prepend-inner-icon="mdi-magnify"
            v-model="donorSearchValue"
          ></v-text-field>
        </div>
        <div style="background-color: #D4DAEF" class="pa-4 font-weight-bold">Donor</div>
        <div
          v-for="firm in donors"
          class="pa-4"
          :class="{'selected': selectedDonorId === firm.id, 'invalid': selectedReceiverId === firm.id}"
          style="border-bottom: 1px solid #E5E7EB; cursor: pointer"
          @click="selectDonor(firm.id)"
        >
          {{ firm.name }}
        </div>
      </v-col>
      <v-col>
        <div>
          <v-text-field
            outlined
            label="Search Receiver"
            prepend-inner-icon="mdi-magnify"
            v-model="receiverSearchValue"
          ></v-text-field>
        </div>
        <div style="background-color: #D4DAEF" class="pa-4 font-weight-bold">Receiver</div>
        <div
          v-for="firm in receivers"
          class="pa-4"
          style="border-bottom: 1px solid #E5E7EB; cursor: pointer"
          :class="{'selected': selectedReceiverId === firm.id, 'invalid': selectedDonorId === firm.id}"
          @click="selectReceiver(firm.id)"
        >
          {{ firm.name }}
        </div>
      </v-col>
      <v-col style="position: sticky; top: 72px" class="fill-height">
        <div style="background-color: #D4DAEF" class="pa-4 font-weight-bold mb-7">Percentage Share</div>
        <div>
          <v-text-field
            @blur="adjustShare"
            outlined
            suffix="%"
            type="number"
            max="100"
            min="0.01"
            v-model="share"
          ></v-text-field>
        </div>
        <v-btn :disabled="!isConfirmActive()" :loading="createLoading" @click="createAdoption" color="primary" block>Confirm</v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LawService from "@/services/law.service";
import AdoptionsService from "@/services/adoptions.service";

export default {
  name: "AdoptionTool",
  data: () => ({
    firms: [],
    donors: [],
    receivers: [],
    donorSearchValue: '',
    receiverSearchValue: '',
    firmsLoading: false,
    selectedDonorId: undefined,
    selectedReceiverId: undefined,
    share: 0,
    createLoading: false
  }),
  methods: {
    async getAllLaws() {
      this.firmsLoading = true;

      try {
        this.firms = await LawService.getLaws();
        this.donors = [...this.firms]
        this.receivers = [...this.firms]
      } finally {
        this.firmsLoading = false;
      }
    },
    selectDonor(id) {
      if (this.selectedReceiverId !== id)
        this.selectedDonorId = id
    },
    selectReceiver(id) {
      if (this.selectedDonorId !== id)
        this.selectedReceiverId = id
    },
    searchDonor(value) {
      this.donors = this.firms.filter((firm) => {
        return firm.name.toLowerCase().startsWith(value.toLowerCase())
      })
    },
    searchReceiver(value) {
      this.receivers = this.firms.filter((firm) => {
        return firm.name.toLowerCase().startsWith(value.toLowerCase())
      })
    },
    adjustShare(e) {
      if (e.target.value > 100)
        this.share = 100
      else if (e.target.value < 0.01)
        this.share = 0.01
    },
    isConfirmActive() {
      let donorSelected = this.selectedDonorId
      let receiverSelected = this.selectedReceiverId
      let shareIsValid = this.share > 0 && this.share <= 100

      return donorSelected && receiverSelected && shareIsValid
    },
    async createAdoption() {
      this.createLoading = true;

      const payload = {
        master_entity: this.selectedReceiverId,
        slave_entity: this.selectedDonorId,
        contingency_fees: this.share
      }

      try {
        await AdoptionsService.createAdoption(payload)
        this.$store.dispatch("snackbar/showSnackbarMessage", {
          message: "Adopted successfully!",
          duration: 3000,
        });
      } finally {
        this.createLoading = false;
      }
    }
  },
  created() {
    this.getAllLaws();
  },
  watch: {
    donorSearchValue(newValue) {
      if (newValue)
        this.searchDonor(newValue)
      else
        this.donors = [...this.firms]
    },
    receiverSearchValue(newValue) {
      if (newValue)
        this.searchReceiver(newValue)
      else
        this.receivers = [...this.firms]
    },
  }
};
</script>

<style scoped>
.selected {
  background-color: #F2F5FF;
}
.invalid {
  cursor: not-allowed !important;
  opacity: 0.4;
}
</style>