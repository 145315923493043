import $axios from "@/plugins/axios";

class AdoptionsService {
  createAdoption(payload) {
    return $axios.post('/adoptions', payload).then(res => res.data)
  }

  getAdoptions(entityId) {
    return $axios.get(`/entity/${entityId}/adoptions`).then(res => res.data)
  }

  getAdoptedClientsOfEntity(entityId) {
    return $axios.get(`/entities/${entityId}/adopted_clients`).then(res => res.data)
  }

  deleteAdoption(id) {
    return $axios.delete(`/adoptions/${id}`)
  }

  getAllAdoptions() {
    return $axios.get('/entities/adoptions').then(res => res.data)
  }

  getAdoptionStats(entityId) {
    return $axios.get(`/adoptions/${entityId}/queue/stats`).then(res => res.data)
  }

  getAdoptionStatus(entityId, statusId, filter = 0) {
    const params = {
      filter
    }
    return $axios.get(`/adoption/queue/${entityId}/${statusId}`, {params}).then(res => res.data)
  }

  getAdoptionsQuarter(entityId, quarter, status){
    const params = {
      quarter,
      status
    }
    return $axios.get(`/adoptions/${entityId}/report/quarters`, {params}).then(res => res.data)
  }

  patchAdoption(adoptionId, payload) {
    return $axios.patch(`/adoptions/${adoptionId}`, payload).then(res => res.data)
  }
}

export default new AdoptionsService()