<template>
    <div>
        <TheLayoutWrap>
            <transition name="fade" mode="out-in">
                <router-view></router-view>
            </transition>
        </TheLayoutWrap>
        <v-tour name="myTour" :steps="steps" :callbacks="{
            onPreviousStep: myCustomPreviousStepCallback,
            onNextStep: myCustomNextStepCallback,
            onSkip: myCustomSkipCallback
        }">
            <template slot-scope="tour">
                <transition name="fade">
                    <v-step v-if="tour.steps[tour.currentStep]" :key="tour.currentStep"
                        :step="tour.steps[tour.currentStep]" :previous-step="tour.previousStep"
                        :next-step="tour.nextStep" :stop="tour.stop" :skip="tour.skip" :is-first="tour.isFirst"
                        :is-last="tour.isLast" :labels="tour.labels" :highlight="tour.highlight"
                        :enable-scrolling="tour.enableScrolling">

                        <template v-if="tour.currentStep === 0">
                            <div slot="actions">
                                <button @click="tour.skip();applyLightTheme()" class="v-step__button v-step__button-skip">Skip tour</button>
                                <button @click="tour.nextStep" class="v-step__button v-step__button-next">Next
                                    step</button>
                            </div>
                        </template>


                        <template v-if="tour.currentStep === 1">
                            <div slot="actions">
                                <button @click="tour.skip();applyLightTheme()" class="v-step__button v-step__button-skip">Skip tour</button>
                                <button @click="tour.nextStep" class="v-step__button v-step__button-next">Next step
                                </button>
                            </div>
                        </template>

                        <template v-if="tour.currentStep === 2">
                            <div slot="actions">
                                <button @click="tour.skip();applyLightTheme()" class="v-step__button v-step__button-skip">Skip tour</button>

                                <button @click="tour.nextStep(); openAdminTab()"
                                    class="v-step__button v-step__button-next">Next step
                                </button>
                            </div>
                        </template>

                        <template v-if="tour.currentStep === 3">
                            <div slot="actions">
                                <button @click="tour.skip(); removePayrollActiveClass();applyLightTheme()"
                                    class="v-step__button v-step__button-skip">Skip tour</button>
                                <button @click="tour.nextStep(); changeActiveClass()"
                                    class="v-step__button v-step__button-next">Next step
                                </button>
                            </div>
                        </template>

                        <template v-if="tour.currentStep === 4">
                            <div slot="actions">
                                <button @click="tour.skip();applyLightTheme()" class="v-step__button v-step__button-skip">Skip tour</button>
                                <button @click="myCustomFinish" class="v-step__button v-step__button-next">Finish
                                </button>
                            </div>
                        </template>
                    </v-step>
                </transition>
            </template>
        </v-tour>
    </div>
</template>

<script>
import TheLayoutWrap from "./layouts/TheLayoutWrap";
import { mapGetters } from "vuex";

export default {
    name: 'AppContent',
    components: {

        TheLayoutWrap
    },
    data: () => ({
        steps: [
            {
                target: '#pppRound1Tour',
                content: `Add PPP Round 1`,
                params: {
                    placement: 'left',
                    highlight: true,
                    enableScrolling: false
                }
            },
            {
                target: '#pppRound2Tour',
                content: 'Add PPP Round 2',
                params: {
                    placement: 'left',
                    highlight: true,
                    enableScrolling: false
                }
            },
            {
                target: '#admin-tab',
                content: 'Go to ADMIN to input Owners and Payroll',
                params: {
                    placement: 'right',
                    highlight: true,
                    enableScrolling: false
                }
            },
            {
                target: '#payroll-csv',
                content: 'Upload Payroll CSV',
                params: {
                    placement: 'right',
                    highlight: true,
                    enableScrolling: false
                }
            },
            {
                target: '#owners-list',
                content: 'Add Owners',
                params: {
                    placement: 'right',
                    highlight: true,
                    enableScrolling: false
                }
            },
            {
                target: '#generatePackageBtn',
                content: 'You can generate your package',
                params: {
                    placement: 'right',
                    highlight: true,
                    enableScrolling: false
                }
            }
        ],
    }),
    computed: {
        ...mapGetters(
            {
                closeTour: 'tour/getcloseTour'
            },
        ),
    },
    watch: {
        closeTour(val) {
            if (val) {
                this.myCustomFinish()
            }
        }
    },
    methods: {
        startTourGuide() {
            // this.$store.dispatch('tour/changeResetThemeLight',false)
            this.$store.dispatch('tour/changeShowTourGuideRibbon', false);
            this.$store.dispatch('tour/changeIsTourGuideActive', true);
            this.$tours['myTour'].start();

        },
        myCustomPreviousStepCallback(currentStep) {

            document.body.classList.remove('v-tour--active');
        },

        myCustomNextStepCallback(currentStep) {
        },

        myCustomSkipCallback() {

            this.tourGuideFinish();
        },

        myCustomFinish() {
            this.tourGuideFinish();
            this.$tours['myTour'].finish();
            const el = document.getElementById("payroll-csv");
            const ownersEl = document.getElementById("owners-list");
            el.className -= "  v-tour__target--highlighted";
            ownersEl.className -= "  v-tour__target--highlighted";
            this.removeActiveClass()
            this.$store.dispatch('tour/changeCloseTour', false)
            this.applyLightTheme()
        },
      applyLightTheme(){
          this.$store.dispatch('tour/changeResetThemeLight',true)
      },
        openAdminTab() {
            this.$store.dispatch('tour/changeOpenAdminTab', true)
        },
        changeActiveClass() {
            const el = document.getElementById("payroll-csv");
            const ownersEl = document.getElementById("owners-list");
            el.className -= "  v-tour__target--highlighted";
            ownersEl.className += "  v-tour__target--highlighted";
        },
        removePayrollActiveClass() {
            const payrollEl = document.getElementById("payroll-csv");
            payrollEl.classList.remove("v-tour__target--highlighted")
            // payrollEl.className -= "  v-tour__target--highlighted";

        },
        removeActiveClass() {
            const ownersEl = document.getElementById("owners-list");
            ownersEl.className -= "  v-tour__target--highlighted";
            this.$store.dispatch('tour/changeOpenProfileTab', true)
        },
        tourGuideFinish() {
            this.$store.dispatch('tour/changeIsTourGuideActive', false);
            this.$store.dispatch('tour/changeShowTourGuideRibbon', true);
            this.$store.dispatch('tour/changeOpenAdminTab', false);
            this.$store.dispatch('tour/changeOpenProfileTab', false);
            // UserService.setActiveUserTourGuide();
        },
    }
};
</script>

<style lang="scss" >
@media only screen and (min-width: 1278px) {

    .v-tour {

        &--active {
            pointer-events: auto !important;
        }

        .v-step {

            &__header {
                background-color: transparent;
            }

            position: absolute;
            max-width: 250px;
            left: 120px !important;
            top: 105px !important;

            background: #FFFFFF !important;
            color: #000000;
            font-size: 14px;
            padding: 15px;
            font-family: 'Roboto';
            font-weight: 500;
            border-radius: 10px;

            &__button {
                padding: 2px;
                margin-left: 3px;
                font-size: 1rem !important;
                color: #000000 !important;
                ;
                border: .05rem solid #263b4f !important;

                &:hover {
                    background-color: #263b4f;
                    color: white !important;
                }
            }

            //&__button {
            //   font-size: 1rem !important;
            //  margin-left: 3px;
            //   color: #000000 !important;;
            // //border: .05rem solid rgb(38, 59, 79) !important;
            //
            //&:hover {
            //   background-color: rgb(38, 59, 79);
            //   color: white !important;
            // }
            //}

            &__content {
                min-width: 200px;
                margin: 0 !important;
            }
        }
    }
}

::v-deep body.v-tour--active {
    pointer-events: auto !important;
}

.v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, .7) !important;
}

.v-step__arrow {

    background: url(../public/arrow-guide.png) no-repeat !important;
    border-width: 0 !important;
    border-left-color: transparent !important;
    border-top-color: transparent;
    border-bottom-color: transparent;
    left: -9rem !important;
    top: calc(-13rem) !important;
    margin-left: 0;
    margin-right: 0;
    width: 200px !important;
    height: 150px !important;
    z-index: 203;
}

::v-deep .v-step__arrow.v-step__arrow--dark {

    background: url(../public/arrow-guide.png) no-repeat !important;
    border-width: 0 !important;
    border-left-color: transparent !important;
    border-top-color: transparent;
    border-bottom-color: transparent;
    left: -14.5rem !important;
    top: calc(-143%) !important;
    margin-left: 0;
    margin-right: 0;
    width: 200px !important;
    height: 150px !important;
    z-index: 2030;
}
#v-step-04a3ae1e{
  inset: auto !important;
  right: -680px !important;
  top: 200px !important;
  position: relative;
  z-index: 203;

  ::v-deep .v-step__arrow {
    left: 60px !important;
    position: relative;
    z-index: 999;
    transform: rotate(180deg) !important;
    transform: rotateY(180deg) !important;
  }
}

#v-step-3e087127 {
    inset: auto !important;
    right: -570px !important;
    top: 205px !important;
    position: relative;
    z-index: 203;

    ::v-deep .v-step__arrow {
        left: 60px !important;
        position: relative;
        z-index: 999;
        transform: rotate(180deg) !important;
        transform: rotateY(180deg) !important;
    }
}

#v-step-db1cdab4 {
    inset: auto !important;
    right: -570px !important;
    top: 215px !important;
    position: absolute;
    z-index: 203;

    ::v-deep .v-step__arrow {
        left: 60px !important;
        position: relative;
        z-index: 999;
        transform: rotate(180deg) !important;
        transform: rotateY(180deg) !important;
    }
}

#v-step-db1cdab2 {
    inset: auto !important;

    //left: 1050px !important;
    right: -20px !important;
    top: 245px !important;
    z-index: 203;

    .v-step__arrow {
        left: 60px !important;
        position: relative;
        transform: rotate(180deg) !important;
        transform: rotateY(180deg) !important;
    }
}

#v-step-e782006c {
    inset: auto !important;
    right: -570px !important;
    top: 310px !important;
    z-index: 203;

    .v-step__arrow {
        left: 160px !important;
        position: relative;
        //transform: rotate(180deg) !important;
        transform: rotateX(180deg) !important;
        transform: rotateZ(80deg) !important;
        //transform: rotateY(180deg) !important;
    }
}

#v-step-32c0f7a8 {
    inset: auto !important;
    right: -80px !important;
    top: 365px !important;
    z-index: 203;

    .v-step__arrow {
        left: 60px !important;
        position: relative;
        transform: rotate(180deg) !important;
        transform: rotateY(180deg) !important;
    }
}


#v-step-e1f13f5e {
    inset: auto !important;

    right: 790px !important;
    top: 465px !important;
    max-height: 120px;
    z-index: 203;

    .v-step__arrow {
        left: 60px !important;
        position: relative;
        transform: rotate(180deg) !important;
        transform: rotateY(180deg) !important;
    }
}

 #v-step-04b1c59f {
   inset: auto !important;
   right: 15px !important;
   top: 115px !important;
   z-index: 203;

   .v-step__arrow {
     left: 61px !important;
     position: relative;
     transform: rotate(180deg) !important;
     transform: rotateY(180deg) !important;
   }
 }


#v-step-0b482dc4 {
    inset: auto !important;
    right: -469px !important;
    top: 240px !important;
    z-index: 203;

    .v-step__arrow {
        left: -81px !important;

    }
}

//final
#v-step-29110d90 {
    inset: auto !important;
    right: 40px !important;
    top: 20px !important;
    z-index: 999;

    .v-step__arrow {
        left: 220px !important;
        top: -210px !important;
        position: relative;
        transform: rotateY(180deg) !important;
        transform: rotate(270deg) !important;

    }
}

//
//#v-step-269e7dec, #v-step-d9234c78, #v-step-0b410070,
//#v-step-269e7dea, #v-step-d9234c76, #v-step-30f40964,
//#v-step-298d054d, #v-step-f447c9fc, #v-step-f81bcea8,
//#v-step-dc444bc2, #v-step-6b062d07, #v-step-ac44431e,
//#v-step-0277efa8, #v-step-b67ef1de, #v-step-dc98bd7e,
//#v-step-1058ac67, #v-step-3ac8c432, #v-step-f05fa5fe,
//#v-step-a74fa238 {
//  left: 290px !important;
//  top: 120px !important;
//}





//final step
#v-step-1d434433 {
    left: 115px !important;
    top: -170px !important;

    ::v-deep .v-step__arrow {
        //transform: rotate(260deg);
        transform-origin: 103% 105% !important;
    }
}

::v-deep .theme--light.v-card {
    background-color: transparent !important;
}

::v-deep .theme--light.v-sheet {
    background-color: transparent !important;
}
</style>
