<template>

  <div>

    <h1>Supplement Report</h1>

    <v-data-table
        :headers="headers"
        :items="items"
        item-key="client_id"
        class="elevation-1 mt-5"
        style="cursor:pointer;"
        hide-default-footer
        :loading="loadingTable"
        loading-text="Loading... Please wait"
        @click:row="handleRowClick"
    >
    </v-data-table>


  </div>

</template>

<script>
import ClientService from "@/services/clients.service";

export default {
  name: "SupplementReport",
  data(){
    return{
      headers: [
        { text: 'Client ID', value: 'id' },
        { text: 'Client', value: 'client' },
        { text: 'Legal Name', value: 'legal_name' },
        { text: 'Trade Name', value: 'trade_name' },
      ],
      items:[],
      loadingTable:false,
    }
  },
  methods:{
    getSupplementReport(){
      this.loadingTable = true;
       ClientService.getSupplementReport().then(res=>{
        this.items = res
       }).finally(()=>{this.loadingTable = false;})
    },
    handleRowClick(row) {
      this.$router.push(`/clients/${row.client}/packages`)
    },
  },
  created() {
    this.getSupplementReport()
  }
}
</script>

<style scoped>

</style>
