<template>
  <v-row>
    <v-col cols="12" class="mt-5 mb-5">
      <v-card  elevation="3" class="d-flex flex-column some-style" :class="{activeClass:!isFollowUpDate }" style="position: relative">
       <div class="d-flex pa-0 ma-0">
         <v-switch
             v-model="isFollowUpDate"
             class="my-checkbox pl-2 pa-0 ma-0 mt-3"
         ></v-switch>
         <v-text-field class="pa-0 ma-0 mt-2 pl-2 " v-model="noteInput"  label="Add note"></v-text-field>
         <v-icon @click="save" class="pr-5 pa-0 ma-0 mb-3" style="cursor: pointer">
           mdi-send
         </v-icon>

       </div>
        <div class="pl-12 pr-12 " v-show="isFollowUpDate">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                label="Date"
                persistent-hint
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                @blur="followUpDate = parseDate(dateFormatted)"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="followUpDate"
              no-title
              @input="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </div>
      </v-card>
    </v-col>

    <v-col cols="12" class="pt-0 pb-0">

    </v-col>
    <v-progress-linear
        v-if="loading"
        class="mx-4 mb-4"
        indeterminate
        color="cyan"
    ></v-progress-linear>
    <v-col cols="12" v-if="notes.length">
      <v-card class="mb-8" v-for="(note, index) in notes" :key="`${index}-note`">
        <v-row>
          <v-col cols="10" class="text-start d-flex align-center">
            <img v-if="note.author_avatar" class="defaultImage" :src="note.author_avatar" >
            <img  v-else class="defaultImage" :src="defaultAvatar" >
            <span v-if="note.author === user.id" class="pl-2" style="font-size: 1rem;font-weight: 460">
              You
            </span>
            <span v-else class="pl-2" style="font-size: 1rem;font-weight: 460">
              {{ note.author_name }}
            </span>
            <span class="pl-5" style="opacity: 75%;font-size: 0.8rem" v-if="note.follow_up_datetime">Follow-up date: {{ note.follow_up_datetime.split(' ')[0] }}</span>
          </v-col>
          <v-col cols="2">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title @click="editNote(index)" style="cursor: pointer">Edit</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title @click="deleteNote(note.id,note.lead,index)" style="cursor: pointer">Delete</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col v-show="!note.isEdit" cols="12" class="pb-12 px-12">
            <p class="text-left" style="opacity: 75%;font-weight: 500;font-size: 1rem">{{ note.body }}</p>
          </v-col>
          <v-col v-show="note.isEdit" cols="12" class="mt-5 mb-5">
            <v-card height="48px" elevation="0" class="d-flex some-style" style="position: relative">
              <v-text-field outlined autofocus class="pl-4 mr-3"
                            v-model="note.body" label="Edit note"></v-text-field>
              <v-icon @click="patchNote(note.lead,note.id,note.body, index)" class="pr-5" style="cursor: pointer">
                mdi-send
              </v-icon>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <!--    Empty State Note-->
    <v-col cols="12" v-if="!notes.length & !loading">
      <v-card v-if="notes" class="mb-8">
        <v-row>
          <v-col cols="10" class="text-start">
            <span class="pl-5" style="font-size: 1rem;font-weight: bold">Patriot</span>
            <span class="pl-5" style="opacity: 75%;font-size: 0.8rem"></span>
          </v-col>
          <v-col cols="2">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon

                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list></v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" class="pb-12 px-12">
            <p class="text-left" style="opacity: 75%;font-weight: 500;font-size: 1rem">To add a note type your message
              and click the send icon</p>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ClientsService from "@/services/clients.service";
import LeadService from "@/services/lead.service";
import defaultAvatar from '@/assets/img/Asset-1.png'
export default {
  name: "LeadNotes",
  data: vm => ({
      followUpDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      menu1: false,
      noteInput: '',
      defaultAvatar: defaultAvatar,
      notes: [],
      isFollowUpDate:false,
      loading: false,
      today: new Date().toISOString()
  }),
  methods: {
    editNote(index) {
      this.notes[index].isEdit = true
      this.notes = [...this.notes]
    },
    getNotes() {
      this.loading = true
      LeadService.getLeadNotes(this.clientId)
          .then(res => {
            this.notes = res
            this.loading = false
          }).catch(err => {
        this.loading = false
        this.$func.popMessage(`${err.response.data.error}`, 'fail')
      })
    },
    deleteNote(id,lead,index) {
      LeadService.deleteLeadNote(lead,id)
          .then(() => {
            this.notes.splice(index, 1)
            this.$func.popMessage(`Successfully deleted your note`, 'success')
          })
          .catch(err => {
            this.$func.popMessage(`${err.response.data.error}`, 'fail')
          })
    },
    patchNote(lead,id,note, index) {
      const model = {'body':note,'id':id}
      LeadService.patchLeadNote(lead, model)
          .then(res => {
            this.$func.popMessage(`Successfully edit your note`, 'success')
            this.notes[index].isEdit = false
            this.notes = [...this.notes]
          }).catch(err => {
        this.$func.popMessage(`${err.response.data.error}`, 'fail')
      })
    },
    getDateFormat(){
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      let day = dateObj.getUTCDate();
      let year = dateObj.getUTCFullYear();
      let time = dateObj.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
      let newdate = month + "/" + day + "/" + year + ' @ ' + time;
      this.today = newdate
    },
    save() {
      if (!this.noteInput.length){
        this.$func.popMessage('Please input a message before submitting!','danger')
        return
      }
      //const date = this.followUpDate.replace('T',' ') + ':00'
      const date = this.followUpDate.replace('/', '-') + " " + '00:00:00'

      const model = {
        body:this.noteInput,
        follow_up_datetime: this.isFollowUpDate ? date : null
      }
      this.loading = true
      LeadService.postLeadNote(this.clientId, model)
          .then(res => {
            this.notes.splice(0,0, res)
            this.loading = false
            this.noteInput = ""
            this.$func.popMessage(`Successfully saved your note`, 'success')

          }).catch(err => {
        this.loading = false
        this.$func.popMessage(`${err.response.data.error}`, 'fail')
      })
    },
    formatDate (followUpDate) {
        if (!followUpDate) return null

        const [year, month, day] = followUpDate.split('-')
        return `${month}/${day}/${year}`
      },
      parseDate (followUpDate) {
        if (!followUpDate) return null

        const [month, day, year] = followUpDate.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
  },
  watch: {
    followUpDate (val) {
        this.dateFormatted = this.formatDate(this.followUpDate)
      },
    },
    computed: {
      computedDateFormatted () {
        return this.formatDate(this.followUpDate)
      },
  },
  props: {
    clientId: {
      type: Number,
      default: 0
    },
    clientProp: {
      type: Object
    },
    user: {
      type: Object
    }
  },
  mounted() {
    this.getNotes()
    this.getDateFormat()
  }
}
</script>

<style scoped>
.some-style >>> .v-input__slot::before {
  border-style: none !important;
}
.defaultImage{
  margin-left: 10px;
}
::v-deep .my-checkbox .v-label {
  font-size: 12px;
}
::v-deep .datePickr input{
  padding: 0;
}
.activeClass{
  height: 48px;
}
</style>
