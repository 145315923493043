<template>
	<v-row justify="center">
		<v-dialog v-model="showDialog" max-width="600px">
			<v-card>
				<v-card-title>
					<h2 class="text-center" style="word-break: normal;"> Request password reset link </h2>
				</v-card-title>

				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12">
								<p>
									Enter your email and press submit button. We will send you reset link shortly.
									Be aware that this link will be active for the next 24 hours after you receive it.
								</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12">
								<v-text-field
									style="height: 50px;"
									label="Email*"
									required
									v-model="$v.resetForm.emailForResetLink.$model"
									:error="$v.resetForm.emailForResetLink.$invalid && $v.resetForm.emailForResetLink.$dirty"
									errorText="Valid email is required"
								/>
								<small>*indicates required field</small>	
							</v-col>
						</v-row>
					</v-container>

				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn
						color="blue darken-1"
						text
						@click="closeDialog"
					>
						Close
					</v-btn>

					<v-btn
						color="blue darken-1"
						text
						:disabled="$v.resetForm.$invalid"
						@click="submitEmail"
					>
						SUBMIT
					</v-btn>
				</v-card-actions>
			</v-card>

		</v-dialog>
	</v-row>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "AuthResetPasswordDialog",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    resetForm: {
      emailForResetLink: "",
    },
  }),

  validations: {
    resetForm: {
      emailForResetLink: { required, email },
    },
  },

  methods: {
    closeDialog() {
      this.resetForm.emailForResetLink = "";
      this.$emit("update:showDialog", false);
    },

    submitEmail() {
      let resetEmail = this.resetForm.emailForResetLink;

      this.$axios
        .post("auth/users/reset", { email: resetEmail })
        .then(() => {
          this.closeDialog();
          this.$func.popMessage(
            "Reset link was sent! Check your email!",
            "success"
          );
        })
        .catch((error) => {
          this.$func.popMessage(`${error.response.data.error}`, "fail");
        });
    },
  },
  watch: {
    showDialog: function (newValue, old) {
      if (!newValue) {
        this.$emit("update:showDialog", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
