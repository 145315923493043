<template>
  <div class="mt-2">
    <LeadCreateEditDialog
        v-if="showLead"
        :key="showLeadKey"
        :showRightDialog.sync="showLead"
        :clientProp="leadProp"
        :isEdit="editLead"
        @forceUpdate="forceUpdate"
        @getLeads="getReports"
    />
    <AnnouncementsAlert />
    <div id="warningMsgContainer" v-if="unassignedClients.length" @click="()=>this.$router.push('/queues/clients/unassigned')" style="background-color: #D21919;border-radius: 20px;cursor: pointer" class="mx-5 mb-3 pa-2 d-flex text-center justify-center align-center">
      <h2 style="color: whitesmoke" id="warningMsg">Your firm has new clients who have not been assigned a team member. Click here to assign them.</h2>
    </div>
    <div class="text-center">
      <v-row class="px-3 flex-column flex-lg-row">
          <v-card
            elevation="2"
            class="mt-3 mr-3 d-flex align-center"
            :style="`width: ${$vuetify.breakpoint.lgAndUp ? '32.5' : '100'}%`"
          >
            <v-col>
              <h1 class="statsText">CSA Signed</h1>
              <h1 id="clientCountNumber" class="headerNumbers">
                {{ dataForCards.clients.count }}
              </h1>
              <h3>Clients</h3>
            </v-col>
          </v-card>

          <v-card
            elevation="2"
            class="mt-3 mr-3"
            :style="`width: ${$vuetify.breakpoint.lgAndUp ? '32.5' : '100'}%`"
          >
            <v-col>
              <h1 class="statsText">Processed</h1>
              <h1 id="clientProcessedNumber" class="headerNumbers">
                {{ dataForCards.processed.processed }}
              </h1>
              <h3>Clients</h3>
              
              <h3>
                Credits -
                <span id="clientProcessedCreditsNumber">{{
                  dataForCards.processed.total_credits_formatted
                }}</span>
              </h3>
            </v-col>
          </v-card>

          <v-card
            elevation="2"
            class="mt-3"
            :style="`width: ${$vuetify.breakpoint.lgAndUp ? '32.5' : '100'}%`"
          >
            <v-col>
              <h1 class="statsText">Filed with IRS</h1>
              <h1 id="clientFiledNumber" class="headerNumbers">
                {{ dataForCards.filed.filed }}
              </h1>
              <h3>Clients</h3>
             
              <h3>
                Credits -
                <span id="clientFiledCreditsNumber">{{
                  dataForCards.filed.total_credits_formatted
                }}</span>
              </h3>
            </v-col>
          </v-card>
        </v-row>
    </div>
    <v-data-table
        style="padding-top: 56px;"
        :items="items"
        :headers="headers"
        @click:row="openLead"
        disable-pagination
        class="row-pointer"
        hide-default-footer
        mobile-breakpoint="0"
        :loading="loading"
    >
      <template v-slot:item.contact_email="{ item }">
        <a :href="`mailto:${item.contact_email}`">
          {{ item.contact_email }}
        </a>
      </template>

      <template v-slot:item.contact_phone="{ item }">
        <a :href="`tel:${item.contact_phone}`">
          {{ item.contact_phone }}
        </a>
      </template>

      <template v-slot:item.originator="{ item }">
        <a :href="`mailto:${item.originator_email}`">
          {{ item.originator }}
        </a>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import LeadCreateEditDialog from "@/components/Lead/LeadCreateEditDialog";
import AnnouncementsAlert from "@/components/Home/AnnouncementsAlert.vue";

export default {
  name: "HomeManagingPartner",
  components: {AnnouncementsAlert, LeadCreateEditDialog},
  data: () => ({
    items: [],
    headers: [
      {text: 'Age', value: 'age'},
      {text: 'Status', value: 'lead_status'},
      {text: 'Trade name', value: 'trade_name'},
      {text: 'Point of contact', value: 'point_of_contact'},
      {text: 'Email', value: 'contact_email'},
      {text: 'Phone', value: 'contact_phone'},
      {text: 'Originator', value: 'originator'}
    ],
    loading: true,
    editLead: false,
    leadProp: {},
    showLead: false,
    showLeadKey: 76,
    unassignedClients:[],
    dataForCards: {
      clients: {
        count: "0"
      },
      filed: {
        filed: "0",
        total_credits: "0",
        total_credits_formatted: "0"
      },
      processed: {
        processed: "0",
        total_credits: "0",
        total_credits_formatted: "0"
      }
    },
  }),
  methods: {
    forceUpdate() {
      this.leads = [];
      this.getReports()
    },
    getSingleLead(item) {
      ClientsService.getSingleLead(item.lead_id)
          .then(res => {
            this.leadProp = res
            this.editLead = true
            this.showLead = true
            this.showLeadKey++
          })
    },
    async openLead(lead) {
      await this.getSingleLead(lead)

    },
    getUnassigned(){
      ClientsService.getUnassignedClients().then(res=>{
        this.items=res
      })
    },
    getReports() {
      this.loading = true
      ClientsService.getReportsForManagingParter()
          .then(res => {
            this.items = res
            this.loading = false
          }).catch(() => this.loading = false)
    },
    getCardsData() {
      ClientsService.getClientReports("filed")
        .then((e) => {
          this.dataForCards.filed = e;
        })
      ClientsService.getClientReports("processed")
        .then((e) => {
          this.dataForCards.processed = e;
        })
      ClientsService.getClientReports("clients")
        .then((e) => {
          this.dataForCards.clients = e;
        })
    },
  },
  created() {
    this.getReports()
    this.getUnassigned()
    this.getCardsData()
  }
}
</script>

<style scoped>
#warningMsg{
  font-size: 1.2rem;
}

.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.headerNumbers {
  font-size: 3rem;
}

@media only screen and (max-width: 900px) {
  .statsText {
    font-size: 1rem;
  }

  .headerNumbers {
    font-size: 1.5rem;
  }
}
</style>
