<template>
  <div>
    <v-overlay :value="loadingScreen">
      <v-progress-circular
          :size="70"
          :width="7"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <component :is="tabs[usersRole]" />
  </div>
</template>

<script>
import SingleTranscriptQueueMTA from "@/views/SingleTranscriptQueue/SingleTranscriptQueueMTA.vue"
import SingleTranscriptQueueAOR from "@/views/SingleTranscriptQueue/SingleTranscriptQueueAOR.vue"
import AuthService from "@/services/auth.service";
import {mapGetters} from "vuex";
export default {
  name: 'SingleTranscriptQueue',
  components: {SingleTranscriptQueueMTA, SingleTranscriptQueueAOR},
  data: () => ({
    tabs: [],
    usersRole: null,
    loadingScreen: true,
  }),
  methods: {
    getUser() {
      return AuthService.getCurrentUser(this.currentUser.current_user_id).then((res) => {
        this.usersRole = res.data.role_id;
      }).finally(() => this.loadingScreen = false)
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  async created() {
    this.tabs[1] = 'SingleTranscriptQueueMTA'
    this.tabs[4] = 'SingleTranscriptQueueAOR'
    this.tabs[7] = 'SingleTranscriptQueueAOR'
    this.tabs[9] = 'SingleTranscriptQueueMTA'
    this.tabs[13] = 'SingleTranscriptQueueMTA'
    await this.getUser()
  }

}
</script>