<template>
	<DialogRightAlign :showDialog.sync="showRightDialog" :titleProp="`Manage Users`">
		<template #header-button>
			<v-btn
				color="white"
				block
			>
				add new user
			</v-btn>
		</template>

		<v-card-text class="mt-10">
			<v-row class="d-flex justify-center">
				<v-col
					cols="12"
					sm="12"
					md="12"
				>
					<v-list>

						<v-list-item
							v-for="chat in users"
							:key="chat.title"
						>
							<v-list-item-avatar>
								<v-icon
									size="48"
								>
									mdi-account-circle
								</v-icon>
							</v-list-item-avatar>

							<v-list-item-content>
								<v-list-item-title v-text="chat.title"></v-list-item-title>
							</v-list-item-content>

							<v-spacer></v-spacer>
							<v-list-item-content>
								Admin
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-col>
			</v-row>
		</v-card-text>

		<v-spacer></v-spacer>

		<v-card-actions>
			<v-spacer></v-spacer>

			<v-btn
				color="#263B4F"
				text
				@click.native="closeDialog"
			>
				done
			</v-btn>

		</v-card-actions>

	</DialogRightAlign>

</template>

<script>

	import DialogRightAlign from "../Base/DialogRightAlign";

	export default {
		name: "UserManageDialog",
		components: {DialogRightAlign},

		props: {
			showRightDialog: {
				type: Boolean,
				default: false
			},
		},

		data: () => ({
			users: [
				{
					title: 'Jason Oner',
				},
				{
					title: 'Mike Carlson',
				},
				{
					title: 'Jason Oner',
				},
				{
					title: 'Mike Carlson',
				}
			]
		}),

		methods: {

			closeDialog() {

				this.$emit('update:showRightDialog', false);
			},

		},

	};
</script>

<style scoped>

</style>