<template>
 <div>
   <v-overlay :value="loading">
     <v-progress-circular
         :size="70"
         :width="7"
         color="white"
         indeterminate
     ></v-progress-circular>
   </v-overlay>
   <transition name="slide-left">
     <div class="slidein-left" id="adder" v-if="add" style="z-index: 9999 !important;" >

       <div class="editor-header">
         <h4 class="float-left">Add Content</h4>
         <div class="close" @click="add = false">&times;</div>
       </div>
       <div class="editor-content mt-4">
         <img @click="addLayout" v-for="layout in layouts" :data-layout="layout" class="box img-fluid" :src="require(`../img/${layout}-preview.png`)" />

       </div>
     </div>
   </transition>


   <transition name="slide-left">
     <div class="slidein-left" id="saver" v-if="save" style="z-index: 9999 !important;">

       <div class="editor-header">
         <h4 class="float-left">Save</h4>
         <div class="close" @click="save = false">&times;</div>
       </div>
       <div class="editor-content mt-1">

         <div class="label"><span>Save as new page</span>  </div>


         <v-btn style="width: 100%" class="mt-4 text-white"  @click="postPage = true;" color="#263B4F">Save</v-btn>

       </div>
     </div>
   </transition>

   <div id="dock">
     <img src="../img/add.png" v-if="editing" class="grow" @click="add = true; designer = false; save= false;" />
<!--     <img src="../img/settings.png" class="grow" @click="designer = true; add = false; save = false;" />-->
     <img src="../img/save.png" v-if="editing" class="grow" @click="save = true; designer = false; add = false;" />
   </div>
 </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import {mapGetters} from "vuex";
import ResourceService from "@/services/resource.service";
export default {
  name: "AddContent",
  props: ['layouts', 'entries','editing'],

  data() {
    return {
      loading:false,
      postPage:false,
      add: false,
      addKey: 13,
      designer: false,
      choose: false,
      save: false,
      color: '#F7FAFC',
      font: 'Rubik',
      spacing: '-0.04em',
      colors: ['#1CA085', '#27AF60', '#1FBC9C', '#2ECC70', '#3398DB', '#2980B9', '#575fcf', '#3D556E', '#222F3D', '#ffdd59', '#F2C511', '#F39C19', '#E84B3C', '#C0382B', '#BDC3C8', '#DDE6E8', '#F7FAFC', '#FFFFFF']
    }
  },

  watch:{
    postPage(val){
      if (val){
        this.savePageData()
      }
      // if (val) {
      //   const formData = new FormData();
      //   formData.append("page_name", this.pageData.pageName);
      //   formData.append('page_name', this.entries);
      //   ClientsService.postResourcePage(this.pageData.pageOption, formData)
      // }
    }
  },
  computed:{
    ...mapGetters(
        {
          pageData: 'pagebuilder/getPageData'
        },
    ),
  },
  methods: {
    postAllPageData(){
      ClientsService.postResourcePage(this.pageData.pageOption,{
        page_name:this.pageData.pageName,
        page_data:this.entries})
          .then(()=>{
            this.$router.push('/resources')
            this.loading = false
          })
    },
    async savePageData(){
      this.loading = true
      for (let i = 0; i < this.entries.length; i++) {
        const e = this.entries[i]
        if (e.image){
          const file = e.original_image
          const csvFile = [file];
          const formData = new FormData();
          formData.append("uploaded_file", csvFile[0]);
          formData.append('upload_type', 'page_builder');
          await ResourceService.postUploads(formData)
              .then(res=>{
                this.entries[i].image = res.id
              })
        }
      }
      this.postAllPageData()
    },
    addLayout: function(event) {
      let layout = event.target.getAttribute('data-layout');
      let newItem = {};
      newItem.id = "item-" + Date.now();
      newItem.layout = layout;
      newItem.title = 'Lorem Ipsum';
      newItem.body = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris eleifend ligula ut augue scelerisque venenatis.';
      this.entries.unshift(newItem);
      this.add = false;
    },
    setColor: function(hex) {
      this.color = hex;
      let hex2 = colorContrast(hex);
      let root = document.documentElement;
      root.style.setProperty('--bg-color', hex);
      root.style.setProperty('--fg-color', hex2);
      localStorage.setItem('bg-color', hex);
      localStorage.setItem('fg-color', hex2);
    },
    setFont: function(font) {
      this.font = font;
      let spacing = '0';
      if (font == 'Rubik') {
        spacing = '-0.04em';
      }
      let root = document.documentElement;
      root.style.setProperty('--font', font);
      root.style.setProperty('--spacing', spacing);
      localStorage.setItem('font', font);
      localStorage.setItem('spacing', spacing);
    },
    swapStyleSheet(font) {
      let sheet = 'css/' + font;
      document.getElementById("fonts").setAttribute("href", sheet);
    }
  },
  mounted() {
    //console.log('Adder component mounted.')
  }
}

function colorContrast(hex) {

  var threshold = 150; /* about half of 256. Lower threshold equals more dark text on dark background  */
  var hRed = hexToR(hex);
  var hGreen = hexToG(hex);
  var hBlue = hexToB(hex);

  function hexToR(h) {
    return parseInt((cutHex(h)).substring(0, 2), 16)
  }

  function hexToG(h) {
    return parseInt((cutHex(h)).substring(2, 4), 16)
  }

  function hexToB(h) {
    return parseInt((cutHex(h)).substring(4, 6), 16)
  }

  function cutHex(h) {
    return (h.charAt(0) == "#") ? h.substring(1, 7) : h
  }

  var cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
  if (cBrightness > threshold) {
    return "#000000";
  } else {
    return "#ffffff";
  }
}


function dl(filename, data) {
  var blob = new Blob([data], {
    type: 'text/html'
  });
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.download = filename;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
  }
}
</script>

<style scoped>

</style>
