<template>
  <v-hover v-slot="{ hover }">
    <v-card style="cursor: pointer" :elevation="hover ? 8 : 3" :loading="loading" rounded outlined>
      <v-card-title class="d-flex justify-space-between">
        <slot name="title">Request a {{taskType}}</slot>
        <v-chip small color="warning" v-if="task.new">UNSAVED</v-chip>
        <v-btn @click="removeUnsavedTask(index)" v-if="task.new" icon><v-icon color="black">mdi-delete</v-icon></v-btn>
        <v-menu v-else transition="scroll-y-transition" offset-y left>
          <template #activator="{on, attrs}">
            <v-btn
                :loading="loading"
                color="black"
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <slot name="actions"/>
        </v-menu>
      </v-card-title>
      <v-card-text>
      <v-alert v-if="isAOR" color="#FB8C0033">
        <span style="color: #FB8C00">
          {{notificationName}}
        </span>
      </v-alert>
        <span class="text-primary">
          Status:&nbsp;<v-chip small class="white--text" :color="chipColor">{{task.status}}</v-chip>
        </span>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'TranscriptQueueTask',
  props: {
    task: {
      type: Object,
      required: true
    },
    index: {
      required: true
    },
    isAOR: {
      required: false,
      default: false
    },
    loading: {
      required: true,
      default: false
    }
  },
  methods: {
    removeUnsavedTask(index) {
      this.$emit('removeUnsavedTask', index)
    },
  },
  computed: {
    chipColor() {
      switch (this.task.status_id) {
        case 1:
          return 'orange darken-1'
        case 2:
          return '#E3C218'
        case 3:
          return 'success'
      }
    },
    taskType() {
      switch (this.task.type_id) {
        case 1:
          return 'task'
        case 2:
          return 'document'
      }
    },
    notificationName() {
      switch (this.task.type_id) {
        case 1:
          return 'Note from MTA'
        case 2:
          return 'Request for a Document from MTA'
      }
    }
  }
}
</script>

<style scoped>
.selected {
  background-color: #E7F4FF
}
</style>
