export default {
    def: function(key, val) {
        if (key) {
            return key;
        } else {
            return val;
        }
    },
    youtube_parser: function(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
        var match = url.match(regExp)
        return match && match[7].length == 11 ? match[7] : false
    },
   parseUrl(){
    let val = document.getElementById('video').value;
    let vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
    let parsed = val.match(vimeoRegex);

    return "//player.vimeo.com/video/" + parsed[1];
  }
}
