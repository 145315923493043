<template>
  <v-card elevation="3" rounded outlined>
    <v-card-text class="font-weight-regular">
      <v-container>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              outlined
              v-model="selectedType"
              label="Type"
              :menu-props="{ auto: true }"
              :items="types"
            >
              <template #selection="props">
                <v-alert class="mb-0" style="width: 100%" dense outlined :type="props.item">{{
                  props.item
                }}</v-alert>
              </template>
              <template #item="props">
                <v-alert class="mb-0" style="width: 100%" dense outlined :type="props.item">{{
                  props.item
                }}</v-alert>
              </template>
            </v-select>
          </v-col>
<!--          <v-col cols="12" md="6" class="d-flex justify-space-between">-->
<!--            <v-checkbox v-model="allDayValue" label="All day" />-->
<!--            <v-checkbox v-model="noTimeLimitValue" label="No time limit" />-->
<!--          </v-col>-->
        </v-row>
        <v-row>
          <v-col cols="12" md="6" v-for="key in Object.keys(dates)" :key="key">
            <v-menu
              v-model="dates[key].picker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dates[key].value"
                  :label="key"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker :min="dates[key].min" color="primary" v-model="dates[key].value" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dates[key].picker = false"> OK </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" v-for="key in Object.keys(times)" :key="key">
            <v-menu
              v-model="times[key].picker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="times[key].value"
                  :label="key"
                  prepend-inner-icon="mdi-clock-time-four-outline"
                  outlined
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="times[key].picker"
                v-model="times[key].value"
                full-width
                format="24hr"
                required
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="times[key].picker = false"> OK </v-btn>
              </v-time-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea v-model="descValue" outlined label="Describe the announcement" />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn :disabled="!descValue" style="width: 160px" color="primary" @click="saveAnn">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import AnnouncementService from "@/services/announcement.service";

export default {
  name: "AnnouncementDetails",
  props: {
    ann: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    types: ["success", "info", "warning", "error"],
    selectedType: "",
    dates: {
      from: {
        value: "",
        picker: false,
        min: new Date().toISOString().substring(0, 10),
      },
      until: {
        value: "",
        picker: false,
        min: ""
      },
    },
    times: {
      start: {
        value: "",
        picker: false,
      },
      end: {
        value: "",
        picker: false,
      },
    },
    descValue: "",
  }),
  methods: {
    reloadAnn(announcement) {
      const dateTimeFrom = announcement.valid_from.split(" ");
      // const dateParts = dateTimeFrom[0].trim().split("/");
      // const formattedDateFrom = `${dateParts[2]}-${dateParts[0].padStart(2, '0')}-${dateParts[1].padStart(2, '0')}`;
      const timeStart = dateTimeFrom[1]

      const dateTimeTo = announcement.valid_until.split(" ");
      // const dateParts2 = dateTimeTo[0].trim().split("/");
      // const formattedDateTo = `${dateParts2[2]}-${dateParts[0].padStart(2, '0')}-${dateParts2[1].padStart(2, '0')}`;
      const timeEnd = dateTimeTo[1]

      this.dates.from.value = dateTimeFrom[0];
      this.dates.until.value = dateTimeTo[0];

      this.times.start.value = timeStart;
      this.times.end.value = timeEnd;

      this.selectedType = announcement.type;
      this.descValue = announcement.message;
    },
    saveAnn() {
      // Check if type is selected
      if (!this.selectedType) {
        // Display an error message or prevent form submission
        this.$func.popMessage("Type field is required", "warning");

        return;
      }

      // Check if date fields are filled
      if (!this.dates.from.value || !this.dates.until.value) {
        this.$func.popMessage("Date fields is required", "warning");
        return;
      }

      // Check if time fields are filled
      if (!this.times.start.value || !this.times.end.value) {
        // Display an error message or prevent form submission
        this.$func.popMessage("Time fields are required", "warning");
        return;
      }

      this.$emit('editAnn', this.ann);
      const isNewAnnouncement = this.ann.new;

      const editedData = {}; // Object to store edited data

      // Compare and populate editedData with modified properties
      if (this.descValue !== this.ann.message) {
        editedData.message = this.descValue;
      }
      if (this.selectedType !== this.ann.type) {
        editedData.type = this.selectedType;
      }
      // Check if time values are changed
      const newValidFrom = this.dates.from.value + " " + this.times.start.value;
      const newValidUntil = this.dates.until.value + " " + this.times.end.value;
      if (newValidFrom !== this.ann.valid_from) {
        editedData.valid_from = newValidFrom;

      }
      if (newValidUntil !== this.ann.valid_until) {
        editedData.valid_until = newValidUntil;
      }

      if (Object.keys(editedData).length === 0) {
        // No changes detected, do nothing or show a message but still it will
        // change until we fix the stupid date format which is detected as a change
        return;
      }

      const model = {
        message: this.descValue,
        type: this.selectedType,
        valid_from: this.dates.from.value + " " + this.times.start.value,
        valid_until: this.dates.until.value + " " + this.times.end.value,
      };

      this.$emit('postAnn', isNewAnnouncement, model, this.ann.id)
    }
  },
  watch: {
    ann(newVal) {
      this.reloadAnn(newVal);
    },
    'dates.from.value'(val) {
      this.dates.until.min = val
    }
  },
  created() {
    this.reloadAnn(this.ann)
  },
};
</script>
