<template>
  <div class="loginCont">
    <v-row
      class="d-flex justify-center align-center auth-container fill-height ma-0"
      style="width: 100vw"
    >

      <v-col
        cols="10"
        sm="10"
        md="8"
        lg="5"
        xl="4"
        class="pa-0"
      >

        <v-card
          class="py-10 card-login d-flex justify-center flex-column justify-space-around"
          style="border-radius: 14px"
          elevation="13"
          v-on:keyup.enter="login"
        >
          <v-img
            contain
            max-height="100"
            src="@/assets/img/changed-logo.png"
          ></v-img>

          <v-card-title
            class="d-flex justify-center align-content-space-around"
          >
            <h3 class="text-bold-h5 title-sign-in">Sign in to Your Account</h3>
            <br />
          </v-card-title>

          <v-card-text class="pb-0">
            <v-container>
              <v-row class="d-flex justify-center">
                <v-col cols="12" sm="12" md="10">
                  <v-text-field
                      id="email"
                    v-model="loginForm.email"
                    :error-messages="emailErrors"
                    @keydown.space.prevent
                    label="Email"
                    required
                    @blur="$v.loginForm.email.$touch()"
                    style="height: 50px;"
                  />
                </v-col>
              </v-row>

              <v-row class="d-flex justify-center">
                <v-col cols="12" sm="12" md="10">
                  <v-text-field
                      id="password"
                    v-model="loginForm.password"
                    @keydown.space.prevent
                    type="password"
                    :error-messages="passwordErrors"
                    label="Password"
                    required
                    @blur="$v.loginForm.password.$touch()"
                    style="height: 50px;"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container>
              <v-row class="d-flex justify-center">
                <v-col cols="12" sm="12" md="10">
                  <v-btn
                    block
                    class="mr-4 px-10 py-7"
                    id="login-button"
                    @click.native="login"
                    :loading="buttonLoadingSpinner"
                  >
                    LOGIN
                  </v-btn>

                  <div class="mt-4 d-flex justify-center">
                    <a
                      style="color: black"
                      @click.stop="openResetPasswordDialog()"
                      id="forgot-password"
                    >
                      FORGOT YOUR PASSWORD?
                    </a>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <AuthResetPasswordDialog
      :showDialog.sync="showResetPassRequestDialog"
    ></AuthResetPasswordDialog>
  </div>
</template>

<script>
	import AuthResetPasswordDialog from '@/components/Auth/AuthResetPasswordDialog';
	import {validationMixin} from 'vuelidate'
	import {required, email} from 'vuelidate/lib/validators'
  import {mapGetters} from "vuex";

	export default {
		name: 'AuthLogin',

		mixins: [validationMixin],

		components: {
			AuthResetPasswordDialog,
		},

		validations: {
			loginForm: {
				email: {required, email},
				password: {required},
			}
		},

		data() {

			return {
				loginForm: {
					email: '',
					password: '',
				},
        loginPath: null,
				dialogKey: 0,
				showResetPassRequestDialog: false,
				buttonLoadingSpinner: false,
			}
		},
  methods: {
    login() {
      let _this = this;
      this.$v.$touch();
      this.buttonLoadingSpinner = true;

      this.$store
        .dispatch("auth/login", this.loginForm)
        .then(async (res) => {
          //Set axios Authorization header

          await this.addBearerToken(res);
          // this.$router.push('/dashboard');
          if(this.loginPath === null)
            window.location = "/dashboard";
          else
            window.location = this.loginPath;

          this.buttonLoadingSpinner = false;
        })
        .catch((error) => {
          // console.log('error length',Object.keys(error).length);
          // _this.$store.dispatch("snackbar/showSnackbarMessage", {
          //   message: "Wrong username or password",
          //   duration: 6000,
          //   mode: "fail",
          // });
          this.buttonLoadingSpinner = false;
        });
    },

    addBearerToken(res) {
      this.$axios.defaults.headers.common["Authorization"] = `Bearer ${res}`;
    },
    openResetPasswordDialog() {
      this.dialogKey++;
      this.showResetPassRequestDialog = true;
    },

    goToRegister() {
      this.$router.replace("/register");
    },
  },
  created(){
    this.loginPath=this.getCurrentPath
  },
  computed: {
    ...mapGetters(
          { getCurrentPath: 'auth/getCurrentPath' },
      ),
   
    emailErrors() {
      const errors = [];
      if (!this.$v.loginForm.email.$dirty) return errors;
      !this.$v.loginForm.email.email && errors.push("Must be valid e-mail");
      !this.$v.loginForm.email.required && errors.push("E-mail is required");
      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.loginForm.password.$dirty) return errors;
      !this.$v.loginForm.password.required &&
        errors.push("Password is required");
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped>
.loginCont {
  background-image: url("../../assets/img/Patriot-Homepage2.png");
  background-size: cover;
  // background: #263B4F;
  height: 100%;
}

.guest-login {
  text-align: right;
}

.forgot-pass {
  text-align: left;
}

.auth-form__link {
  color: white;
  cursor: pointer;
}

.auth-form__link--forgot-pass-link:hover,
.auth-form__link--log-as-guest:hover {
  color: #a156a0;
}

.title-sign-in {
  font-weight: 600;
  line-height: 22px;
  color: #263b4f;
}

.card-login {
  background-color: #ffffff;
}

#login-button {
  font-size: 18px;
  border-radius: 12px;
  background-color: #263b4f !important;
  color: #ffffff;
}
</style>
