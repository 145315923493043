<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" persistent max-width="600px">
      <v-card>
        <v-card-title
          class="text-h5 font-weight-bold"
          style="background-color: #263b4f; color: white"
        >
          {{ noteTitle }}
        </v-card-title>

        <v-card-text class="mt-3" style="font-size: 1.2rem">{{ noteDescription }}</v-card-text>

        <v-textarea
          class="px-3"
          label="Reason"
          variant="underlined"
          v-model="reasonValue"
        ></v-textarea>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeDialog"> {{ cancelBtn }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="acceptedDelete"> {{ confirmBtn }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DeleteConfirmation",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    noteTitle: {
      type: String,
      default: "Are you sure you want to delete this?",
    },
    noteDescription: {
      type: String,
      default: "This proccess is irreversible.",
    },
    cancelBtn: {
      type: String,
      default: "Cancel",
    },
    confirmBtn: {
      type: String,
      default: "Confirm",
    },
  },

  data: () => ({
    reasonValue: "",
  }),
  methods: {
    acceptedDelete(event) {
      this.$emit("update:reasonValue", this.reasonValue);
      this.$emit("clicked", {
        acceptedDelete: true,
        closeWindow: false,
      });
    },
    closeDialog(event) {
      this.$emit("clicked", {
        acceptedDelete: false,
        closeWindow: false,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-message {
  margin-bottom: 1.6rem;
}
</style>