<template>
  <div class="MaintenanceCont">
    <v-row
      class="d-flex justify-center align-center auth-container fill-height"
    >

      <v-col
        cols="10"
        sm="10"
        md="8"
        lg="6"
        xl="4"
      >

        <v-card
          class="py-10 card d-flex justify-center flex-column justify-space-around"
          style="border-radius: 14px;"
          elevation="13"
          v-on:keyup.enter="login"
        >
          <v-img
            contain
            max-height="100"
            src="@/assets/img/changed-logo.png"
          ></v-img>

          <div class="textCont">
            <h3 class="title-in">Our system is currently undergoing scheduled maintenance.</h3>
            <h3 class="title-in">This page will refresh once maintenance is completed.</h3> 
          </div>

        </v-card>

      </v-col>
    </v-row>

  </div>
</template>

<script>
import AuthService from "@/services/auth.service";
import router from "@/router";
import {mapGetters} from "vuex";
import store from '../store';
export default {
   data() {
   return{
     pathPreMaintanance: ''
   }
},
    methods:{
        CheckStatus() {
          const previousPath = (this.routePath === null ? '/dashboard' : this.routePath)
          let checker = setInterval(function () {
                AuthService.getMaintenanceStatus()
                .then((data) => {
                    if(data.status === "online"){
                      clearInterval(checker)
                      router.push(previousPath)
                      store.dispatch('snackbar/showSnackbarMessage', {
                        message: "Patriot is back online",
                        duration: 6000,
                        mode: 'success',
                      });
                    }else if(data.status === "maintenance"){
                      
                    }
                })
                .catch((error) => {
                  
                })
            }, 10000);
        }
    },
    mounted() {
        this.CheckStatus()
        this.pathPreMaintanance = this.$store.state["auth/preMaintenancePath"]
        // this.pathPreMaintanance = (this.$store.state["auth/preMaintenancePath"] === null ? '/dashboard' : this.$store.state["auth/preMaintenancePath"]);
    },
  computed:{
      ...mapGetters(
          {
            routePath:'auth/getPreMaintenancePath'
          }
      )
  }

}
</script>

<style lang="scss" scoped>

.MaintenanceCont{
  background-image: url("../assets/img/Patriot-Homepage2.png");
  background-size: cover;
  // background: #263B4F;
  height: 100%;
}

.textCont{
  margin: auto;
}


.title-in {
    line-height: 22px;
    color: #263B4F;
    text-align: left;
    font-size: 1.3rem;
}

.card {
    background-color: #FFFFFF;
}

@media only screen and (max-width: 800px) {

.title-in {
    text-align: center;
    font-size: 1.1rem;
}

}

</style>