<template>
  <div class="mt-2">
    <AnnouncementsAlert />
    <v-overlay :value="loadinOverlay">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div class="text-center">
      <v-row class="px-3 flex-column flex-lg-row">
          <v-card
            elevation="2"
            class="mt-3 mr-3 d-flex align-center"
            :style="`width: ${$vuetify.breakpoint.lgAndUp ? '32.5' : '100'}%`"
          >
            <v-col>
              <h1 class="statsText">CSA Signed</h1>
              <h1 id="clientCountNumber" class="headerNumbers">
                {{ dataForCards.clients.count }}
              </h1>
              <h3>Clients</h3>
            </v-col>
          </v-card>

          <v-card
            elevation="2"
            class="mt-3 mr-3"
            :style="`width: ${$vuetify.breakpoint.lgAndUp ? '32.5' : '100'}%`"
          >
            <v-col>
              <h1 class="statsText">Processed</h1>
              <h1 id="clientProcessedNumber" class="headerNumbers">
                {{ dataForCards.processed.processed }}
              </h1>
              <h3>Clients</h3>
              
              <h3>
                Credits -
                <span id="clientProcessedCreditsNumber">{{
                  dataForCards.processed.total_credits_formatted
                }}</span>
              </h3>
            </v-col>
          </v-card>

          <v-card
            elevation="2"
            class="mt-3"
            :style="`width: ${$vuetify.breakpoint.lgAndUp ? '32.5' : '100'}%`"
          >
            <v-col>
              <h1 class="statsText">Filed with IRS</h1>
              <h1 id="clientFiledNumber" class="headerNumbers">
                {{ dataForCards.filed.filed }}
              </h1>
              <h3>Clients</h3>
             
              <h3>
                Credits -
                <span id="clientFiledCreditsNumber">{{
                  dataForCards.filed.total_credits_formatted
                }}</span>
              </h3>
            </v-col>
          </v-card>
        </v-row>
    </div>
    <v-row style="padding-top: 56px;" class="d-flex flex-column flex-md-row  justify-space-between">

      <v-col cols="12" md="7">

        <v-card>
          <div style="height: 400px !important;" class="mt-2 mb-2">
            <canvas id="myChart"></canvas>
          </div>
        </v-card>
      </v-col>


      <v-col  cols="12" md="5" class="d-flex mt-1 flex-column">
        <v-card elevation="2" class="pa-10 mb-4 d-flex justify-center align-center flex-column ">
          <h3>Total Checks Received</h3>
          <h1 style="font-size: 2rem">{{ totalFees }}</h1>
        </v-card>

        <v-card elevation="2" class="pa-10 d-flex justify-center align-center flex-column">
          <h3>Average Cases per Week</h3>
          <h1 style="font-size: 2rem">{{ avgCases }}</h1>
        </v-card>
      </v-col>

    </v-row>
    <v-data-table
        v-if="feeItems.length"
        :headers="headers"
        :items="feeItems"
        class="elevation-2 mt-6"
        disable-pagination
        hide-default-footer

    >
      <template v-slot:item="row">


        <tr>
          <td class="text-xs-right">{{ row.item.adjusted_fees_payable_formatted }}</td>
          <td class="text-xs-right  text-end">{{ row.item.pay_to_the_order_of }}</td>
        </tr>


      </template>
    </v-data-table>
    <div v-if="!feeItems.length" class="mt-10 px-10 text-center">
      <h3 style="color: #555555">
        No checks have been received for the current month. Once checks are entered for this month, amounts collected
        for those entities will display here.
      </h3>
    </div>
  </div>
</template>

<script>

import ClientsService from "@/services/clients.service";
import Chart from "chart.js/auto";
import AnnouncementsAlert from "@/components/Home/AnnouncementsAlert.vue";

export default {
  name: "HomeMasterTaxAttorney",
  components: {AnnouncementsAlert},
  data() {
    return {
      loadinOverlay: false,
      menu: false,
      date: '',
      feeItems: [],
      totalFees: 0,
      avgCases: 0,
      headers: [
        {text: 'Fees Payable', value: 'adjusted_fees_payable_formatted'},
        {text: 'Pay to the order of', value: 'pay_to_the_order_of', align: 'end'},
      ],
      datasets: [{
        label: 'Submissions per week',
        data: [],
        fill: true,
        backgroundColor: ['rgba(229,227,229,0.47)'],
        borderColor: ['#263B4F'],
        borderWidth: 1
      }
      ],
      chartLabels: [],
      dataForCards: {
      clients: {
        count: "0"
      },
      filed: {
        filed: "0",
        total_credits: "0",
        total_credits_formatted: "0"
      },
      processed: {
        processed: "0",
        total_credits: "0",
        total_credits_formatted: "0"
      }
    },
    }

  },
  watch: {
    date(val) {
      this.getReport()
    },
  },
  methods: {
    getReport() {
      this.loadinOverlay = true
      ClientsService.getReports(this.date)
          .then(data => {
            this.feeItems = data.register
            this.totalFees = data.adjusted_fees_payable_formatted != null ? data.adjusted_fees_payable_formatted : 0;
            this.avgCases = data.avg_cases_per_week;
            // Chart code

            const ctx = document.getElementById('myChart').getContext("2d");
            this.datasets[0].data = data.case_history.data
            this.chartLabels = data.case_history.labels
            this.maxChartValue = Math.max(...this.datasets[0].data);


            const myChart = new Chart(ctx, {
              type: 'line',
              data: {
                labels: this.chartLabels,
                datasets: this.datasets
              },
              options: {
                maintainAspectRatio: false,
                responsive: true,
                // scales: {
                //   y: {
                //     beginAtZero: true
                //   }
                // }
                scales: {
                  x: {
                    display: true,
                    title: {
                      display: true
                    }
                  },
                  y: {
                    display: true,
                    title: {
                      display: true,
                      text: 'Value'
                    },
                    suggestedMin: 0,
                    suggestedMax: this.maxChartValue
                  }
                }
              }
            });


            this.loadinOverlay = false
          }).catch(() => {
        this.loadinOverlay = false
      })

    },
    getCurrMonth() {
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      const year = dateObj.getUTCFullYear();
      if (month < 10) {
        month = '0' + month
      }

      return year + "-" + month;
    },
    getCardsData() {
      ClientsService.getClientReports("filed")
        .then((e) => {
          this.dataForCards.filed = e;
        })
      ClientsService.getClientReports("processed")
        .then((e) => {
          this.dataForCards.processed = e;
        })
      ClientsService.getClientReports("clients")
        .then((e) => {
          this.dataForCards.clients = e;
        })
    },
  },
  created() {
    this.date = this.getCurrMonth()
    this.getCardsData()
  }
}
</script>


<style scoped>
.headerNumbers {
  font-size: 3rem;
}

@media only screen and (max-width: 900px) {
  .statsText {
    font-size: 1rem;
  }

  .headerNumbers {
    font-size: 1.5rem;
  }
}
</style>
