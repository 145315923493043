<template>
  <div class="pa-8 text-center">
    <v-overlay :value="loading">
      <v-progress-circular
          :size="90"
          :width="7"
          color="black"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <h4 class="text-start">
      3. Employee Count
    </h4>
    <h6 class="ml-6 mt-6 text-start">
      Please look at your 941 filings for each of the applicable quarters and enter the number of employees from Line 1.
    </h6>

    <v-row>
    <v-col class="d-flex flex-column pl-5" cols="4">
      <v-text-field v-restrict.number.decimal
                    v-for="(q,index) in employees"
                    :key="index" v-model="q.count"
                    size="xs" :label="q.year + ' Quarter ' +  q.quarter" ></v-text-field>
    </v-col>
      <v-col sm="12" md="6" >
        <img style="object-fit: contain;width: 100%;height: 100%;right: 0"
             class="ml-16"
             src="../../assets/img/CheckExplanations.png">
      </v-col>
    </v-row>
    <v-btn color="#263B4F" class="mt-6">
      <span style="color: #FFFFFF" @click="saveEmpl">Save and Continue</span>
    </v-btn>
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "Step3",
  data(){
    return{
      loading:false,
      employees:[]
    }
  },
  methods:{
    emitData(){
      this.$emit('returnStep2Data',this.employees,3)
      this.$emit('goToNextStep', 4)
    },
    getEmpl(){
      ClientsService.getEmployeesAmounts(this.clientId)
          .then(res=>{
            this.employees=res
          })
    },
    saveEmpl(){
      this.loading = true;

      ClientsService.deleteEmployeesAmounts(this.clientId).then(()=>{
      ClientsService.saveEmployeesAmounts(this.clientId, this.employees)
          .then(res=>{
              this.emitData()
              this.loading = false
            this.$func.popMessage("Client data saved successfully!")}
          ).catch(this.loading = false)
      })
    }
  },
  props:{
    clientId:{
      type: Number
    }
  },
  watch:{
    clientId(val){
      if (val){
        this.getEmpl()
      }
    }
  },
}
</script>

<style scoped>

</style>