<template>
  <div class="d-flex align-center justify-center mt-16">
    <h2 style="color: #AF0808">Not allowed!</h2>
<!--    <v-overlay :value="loadinOverlay">-->
<!--      <v-progress-circular-->
<!--          :size="70"-->
<!--          :width="7"-->
<!--          color="white"-->
<!--          indeterminate-->
<!--      ></v-progress-circular>-->
<!--    </v-overlay>-->
<!--    <v-radio-group-->
<!--        row-->
<!--    >-->
<!--      <v-radio-->
<!--          label="All"-->
<!--          @click="getReport"-->
<!--          value="radio1"-->
<!--      ></v-radio>-->
<!--      <v-radio-->
<!--          label="Outstanding"-->
<!--          value="radio2"-->
<!--          @click="getFilteredReports('outstanding')"-->
<!--      ></v-radio>-->
<!--      <v-radio-->
<!--          label="Remitted"-->
<!--          value="radio3"-->
<!--          @click="getFilteredReports('remitted')"-->
<!--      ></v-radio>-->
<!--    </v-radio-group>-->

<!--    <v-data-table-->
<!--        :headers="headers"-->
<!--        :items="feeItems"-->
<!--        class="elevation-5 mt-6"-->
<!--        disable-pagination-->
<!--        hide-default-footer-->
<!--    >-->
<!--      <template v-slot:item.remitted="{item}">-->
<!--        <span v-if="item.remitted === 0" style="color: #AF0808">Outstanding</span>-->
<!--        <span v-if="item.remitted === 1" style="color: #4CAF50">Remitted</span>-->
<!--      </template>-->

<!--      <template v-slot:item.fee_expected="{item}">-->
<!--        <span>{{formatAmount(item.fee_expected)}}</span>-->
<!--      </template>-->
<!--    </v-data-table>-->
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "NonAdmin",
  data () {
    return {
      headers: [
        {
          text: 'Client Name',
          align: 'start',
          sortable: false,
          value: 'client_name',
        },
        { text: 'Quarter', value: 'quarter' },
        { text: 'Fee Expected', value: 'fee_expected' },
        { text: 'Due Before', value: 'due_before' },
        { text: 'Late After', value: 'late_after' },
        { text: 'Status', value: 'remitted' },
        { text: 'Remitted Amount', value: 'remitted_amount' },
        { text: 'Remitted on', value: 'remitted_on' },
        // { value: 'remittedSwitch' }
      ],
      feeItems: [],
      loadinOverlay: false,
      radio1:null,
      radio2:null,
      radio3:null
    }
  },
  methods: {
    getReport(){
      this.loadinOverlay = true
      ClientsService.getReports()
          .then(data => {
            this.feeItems = data
            this.loadinOverlay = false
          }).catch(()=> {this.loadinOverlay = false})
    },
    getFilteredReports(filter){
      this.loadinOverlay = true
      ClientsService.getFilteredReports(filter)
          .then(data => {
            this.feeItems = data
            this.loadinOverlay = false
          }).catch(()=> {this.loadinOverlay = false})
    }
  },
  created(){
    // this.getReport()
  }
}
</script>

<style scoped>

</style>
