<template>
  <v-row>
    <v-col cols="12" class="mt-6 d-flex flex-column flex-md-row">
      <v-text-field
        @change="getMandates"
        v-model="searchStr"
        label="restaurants houston tx"
        dense
      ></v-text-field>
      <v-btn @click="getMandates" class="ml-md-8" color="primary">Search</v-btn>
    </v-col>
    <v-col cols="12" class="text-center" v-if="!mandates.length && !loading">
      <h3 style="color: #6c757d">
        Mandate search supports searching by keywords, NAICS codes or descriptions, states,
        counties, cities, and other relevant terms.
      </h3>
    </v-col>
    <v-col cols="12" v-if="loading">
      <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
    </v-col>
    <v-col cols="12" v-if="!loading && mandates.length">
      <v-hover v-for="mandate in mandates" v-slot="{ hover }">
        <v-card @click="goToMandate(mandate.id)" :elevation="hover ? 12 : 4" class="mb-2 py-6 px-4">
          <v-card-text style="color: black" class="text-center pa-0 font-weight-medium">
            <v-row dense>
              <v-col cols="12" md="3">
                <div
                  :style="
                    isMobile
                      ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
                      : 'border-right: 1px solid rgba(0, 0, 0, 0.1)'
                  "
                  class="d-flex flex-column px-2 py-5 fill-height"
                >
                  {{ mandate.state_code ?? "N/A" }}
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <div
                  class="d-flex justify-center flex-column fill-height pa-2"
                  :style="
                    isMobile
                      ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
                      : 'border-right: 1px solid rgba(0, 0, 0, 0.1)'
                  "
                >
                  <p
                    class="text-caption"
                    style="color: rgb(128, 128, 128)"
                    v-if="!mandate.publication_date"
                  >
                    No data for publication date
                  </p>
                  <div v-else>
                    {{ mandate.publication_date }}
                  </div>
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <div
                  class="d-flex justify-center flex-column fill-height pa-2"
                  :style="
                    isMobile
                      ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
                      : 'border-right: 1px solid rgba(0, 0, 0, 0.1)'
                  "
                >
                  <p class="text-caption" style="color: rgb(128, 128, 128)" v-if="!mandate.author">
                    No data for author
                  </p>
                  <h5 v-else>
                    {{ mandate.author }}
                  </h5>
                </div>
              </v-col>
              <v-col cols="12" md="3">
                <div class="d-flex justify-center flex-column fill-height pa-2">
                  <p class="text-caption" style="color: rgb(128, 128, 128)" v-if="!mandate.title">
                    No data for title
                  </p>
                  <h5 v-else>
                    {{ mandate.title }}
                  </h5>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
import MandateService from "@/services/mandate.service";

export default {
  name: "MandatesSearch",
  data: () => ({
    mandates: [],
    loading: false,
    searchStr: "",
  }),
  methods: {
    goToMandate(id) {
      this.$router.push(`/mandate/${id}`);
    },
    getMandates() {
      if (!this.searchStr) return;
      this.loading = true;
      MandateService.searchMandates(this.searchStr)
        .then((res) => {
          this.mandates = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
      }
    },
  },
};
</script>

<style scoped>
.rightSideBorder {
  border-right: #b9b9bb 1px solid;
}
</style>
