<template>
  <div class="bg d-flex flex-column">
    <div style="padding: 0">
      <v-col>
        <router-link to="/">
          <v-img
            max-height="64px"
            max-width="252px"
            id="home-logo-button"
            src="@/assets/img/logo-top-menu.png"
          ></v-img>
        </router-link>
      </v-col>
    </div>
    <div class="flex-grow-1">
      <v-container fill-height>
        <v-row style="color: white" justify="center">
          <v-col align="center" cols="12" class="text-h1 font-weight-bold"> 404</v-col>
          <v-col align="center" cols="12" class="text-h6 font-weight-regular">
            Sorry, we were unable to find that page
          </v-col>
          <v-col align="center" cols="12" class="text-caption font-weight-regular">
            Start from <a href="/">home page</a>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style scoped>
.bg {
  background-image: url("../assets/img/Patriot-Homepage2.png");
  background-size: cover;
  height: 100%;
}

a:any-link {
  color: white;
}
</style>
