<template>
  <EditUserCard v-if="showViewUser" :user="selectedUser" :availableRoles="availableRoles" @exitEditingUser="exitEditingUser" />
  <CreateUserCard v-else-if="showCreateNewUser" @exitCreatingUser="exitCreatingUser" />
  <v-container v-else>
    <v-row justify="center" justify-md="start" align="center">
      <v-col cols="12" md="10">
        <v-text-field
          v-model="searchValue"
          @keyup.enter.native="searchUser"
          hide-details
          label="Search user"
        />
      </v-col>
      <v-col cols="auto" md="2">
        <v-btn :loading="btnLoading" block @click="searchUser" color="primary">Search</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list three-line>
          <v-col class="text-subtitle-1 text-center" cols="12" v-if="loading"> Loading...</v-col>
          <v-col cols="12">
            <v-progress-linear
              :active="loading"
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
          <v-list-item
            cols="12"
            class="d-flex mt-3"
            style="position: relative; height: 80px; cursor: pointer"
            v-ripple
            v-for="(user, index) in users"
            :key="index"
            @click="openEditUserDialog(user.id, index, user)"
          >
            <v-row align="center">
              <v-col cols="auto">
                <v-avatar color="blue">
                  <v-icon color="indigo darken-3" dark>mdi-account</v-icon>
                </v-avatar>
              </v-col>
              <v-col :style="userStyle(user)" class="d-flex flex-column flex-md-row">
                <span>{{ user.first }}&nbsp;{{ user.last }}</span>
                <span v-if="!user.activation_status">&nbsp;(Pending)</span>
              </v-col>
              <v-col cols="auto">
                <span>{{ user.role }}</span>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
        <v-btn v-if="validUser" @click="showCreateNewUser = true" dark color="primary" elevation="2" fab fixed bottom right class="ma-5">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import AuthService from "@/services/auth.service";
import ClientsService from "@/services/clients.service";
import EditUserCard from "@/components/User/EditUserCard.vue";
import CreateUserCard from "@/components/User/CreateUserCard.vue";

export default {
  name: "Users",
  components: {
    EditUserCard,
    CreateUserCard,
  },

  data: () => ({
    usersRole: null,
    users: [],
    availableRoles: [],
    selectedUser: {},
    btnLoading: false,
    showViewUser: false,
    showCreateNewUser: false,
    loading: true,
    searchValue: "",
  }),
  methods: {
    async searchUser() {
      this.btnLoading = true;
      this.loading = true;

      //Checks if input is email and if so, runs searchUserByMail() instead
      if (this.searchValue.indexOf("@") > -1) {
        this.searchUserByMail();
        return;
      }
      await ClientsService.searchUser(this.searchValue)
        .then((res) => {
          this.users = res;
        })
        .catch(() => (this.loading = false));
      this.btnLoading = false;
      this.loading = false;
    },
    async searchUserByMail() {
      this.btnLoading = true;
      this.loading = true;
      await ClientsService.getUserByMail(this.searchValue)
        .then((res) => {
          this.users = res;
        })
        .catch(() => (this.loading = false));

      this.btnLoading = false;
      this.loading = false;
    },
    openEditUserDialog(id, index, user) {
      this.selectedUser = user;
      this.showViewUser = true;
    },
    exitEditingUser(refresh) {
      this.selectedUser = {};
      this.showViewUser = false;

      if(refresh)
        this.getUsers()
    },
    exitCreatingUser(refresh) {
      this.showCreateNewUser = false;

      if(refresh)
        this.getUsers()
    },
    closeDialog() {
      this.$emit("update:showRightDialogUsers", false);
    },
    getUsers() {
      this.loading = true;
      ClientsService.getUsers()
        .then((res) => {
          this.availableRoles = res.available_roles;
          this.users = res.users;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    getEntityRoles() {
      ClientsService.getEntity(23).then((res) => {
        //console.log('entities available roles: ', res)
      });
    },
    userStyle(user) {
      if (!user.activation_status)
        return "opacity: 0.8";
    }
  },
  computed: {
    validUser() {
      switch (this.usersRole) {
        case 1:
        case 4:
        case 3:
        case 9:
        case 13:
          return true;
        default:
          return false;
      }
    },
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  props: {
    showRightDialogUsers: {
      type: Boolean,
      default: false,
    },
  },
  async created() {
    // this.getEntityRoles()
    AuthService.getCurrentUser(this.currentUser.current_user_id).then((res) => {
      this.usersRole = res.data.role_id;
      this.user = res.data;
    });
  },
  mounted() {
    this.getUsers();
    // this.showRightDialogUsers = true
  },
};
</script>