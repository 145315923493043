<template>
  <div>
    <v-navigation-drawer
      :value="drawer"
      fixed
      mini-variant
      mobile-breakpoint="0"
      mini-variant-width="80"
      style="padding: 44px 0px 56px 0px"
    >
      <v-list-item-group v-model="selectedTab" active-class="active-tab">
        <v-list style="padding: 44px 0px 56px 0px">
          <v-list-item
            v-if="drawer"
            v-for="(item, i) in items"
            :key="i"
            @click="handleTabClick(item)"
            dense
            class="d-flex d-column"
            style="padding: 0"
          >
            <v-list-item-content @click="ifTabDisabled(i) ? $emit('toggleDrawer', false) : null">
              <v-icon class="mb-2" :color="selectedTab === i ? 'white' : 'black'"
                >{{ item.icon }}
              </v-icon>
              <v-list-item-title
                style="text-align: center; font-size: 11px; white-space: normal"
                v-text="item.title"
              />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-list-item-group>
    </v-navigation-drawer>
    <v-list-item-group
      :mandatory="mandatoryNav"
      v-model="selectedDrawer"
      active-class="active-drawer-tab"
    >
      <v-list style="padding: 96px 0px 56px 80px" v-if="showSecondaryMenu()">
        <v-list-item
          v-for="(tab, i) in items[selectedTab].drawer"
          :key="tab.id"
          @click="handleDrawerClick(tab)"
          style="font-size: 14px"
        >
          <v-list-item-icon v-if="tab.link === '/clients'">
            <v-icon color="black">mdi-arrow-left</v-icon>
          </v-list-item-icon>
          <v-list-item-icon v-else-if="tab.icon">
            <v-icon color="black">{{ tab.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="white-space: normal" v-text="tab.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-list-item-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import authService from "@/services/auth.service";

export default {
  name: "TheLayoutWrapMenuList",
  props: ["drawer"],
  data: () => ({
    selectedTab: 0,
    mandatoryNav: false,
    selectedDrawer: 0,
    items: [],
    showRightDialogUsers: false,
    dialogKeyUsers: 100,
    showRightDialog: false,
    showSubMenu: false,
    clientTabIndex: undefined,
  }),
  watch: {
    currentUser() {
      this.updateNav();
    },
    $route() {
      this.adjustSelectedTab();
    },
    drawer(val) {
      this.adjustSelectedTab();
    },
  },
  methods: {
    handleTabClick(item) {
      this.mandatoryNav = false;
      if (this.handlingSpecificNavCases(item)) return;

      if (this.selectedTab === undefined && this.$route.params.clientId) return;
      if (this.selectedTab !== this.clientTabIndex) this.$router.push(item.link).catch(() => {});
      if (this.selectedTab === this.clientTabIndex && item.title !== "Clients")
        this.$router.push(item.link).catch(() => {});

      for (let i = 0; i < item.drawer.length; i++) {
        if (item.drawer[i].link.replace(":id", this.$route.params.clientId) === this.$route.path) {
          this.mandatoryNav = true;
          break;
        }
      }
    },
    handlingSpecificNavCases(item) {
      switch (item.link) {
        case "/resources":
          window.open("https://patriotdataprocessing.zendesk.com/hc/en-us", "_blank");
          return true;
        default:
          return false;
      }
    },
    handleDrawerClick(item) {
      if (this.handlingSpecificNavCases(item)) return;
      if (item.link.replace(":id", this.$route.params.clientId) === this.$route.path) return;
      const clientId = this.$route.params.clientId;
      if (this.selectedTab === this.clientTabIndex && clientId) {
        this.$router.push(item.link.replace(":id", clientId));
        return;
      }

      this.$router.push(item.link);
    },
    openUsersDialog() {
      this.showRightDialogUsers = true;
      this.dialogKeyUsers++;
    },
    showSecondaryMenu() {
      if (this.selectedTab !== undefined && this.items.length) {
        const hasDrawer = this.items[this.selectedTab].drawer.length;

        if (hasDrawer) {
          if (this.selectedTab === this.clientTabIndex && this.$route.name === "clients") {
            this.$emit("toggleShow", false);
            return false;
          }
          this.$emit("toggleShow", true);
          return true;
        }
      }
      this.$emit("toggleShow", false);
      return false;
    },
    adjustSelectedTab() {
      let currentRoute = null;
      this.selectedTab = 0;
      this.selectedDrawer = undefined;
      const path = this.$route.path;
      let tabFound = false;
      let drawerFound = false;
      for (let i = 0; i < this.items.length; i++) {
        let item = this.items[i];
        let hasDrawer = !!item.drawer.length;
        if (item.title === "Clients") this.clientTabIndex = i;
        if (path === item.link) {
          tabFound = true;
          this.selectedTab = i;
          if (path === "/clients") return;
        }
        if (hasDrawer) {
          for (let j = 0; j < item.drawer.length; j++) {
            let drawerItem = item.drawer[j];
            if (this.$route.params.clientId) {
              currentRoute = this.$route.fullPath.replace(`${this.$route.params.clientId}`, ":id");
              if (currentRoute === drawerItem.link) {
                drawerFound = true;
                this.selectedTab = i;
                this.selectedDrawer = j;
                break;
              }
            }
            if (path === drawerItem.link && drawerItem.link !== "/clients") {
              drawerFound = true;
              this.selectedTab = i;
              this.selectedDrawer = j;
              break;
            }
          }
        }

        if (!drawerFound) this.selectedDrawer = undefined;
      }
    },
    ifTabDisabled(i) {
      if (this.selectedTab === i && !this.showSecondaryMenu()) {
        return true;
      }
      return false;
    },
    async updateNav() {
      this.items = await authService.getUserNav(this.currentUser.current_user_id);
      this.adjustSelectedTab();
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  async created() {
    await this.updateNav();
  },
};
</script>

<style lang="scss" scoped>
.active-tab {
  background-color: #263b4f !important;
  color: white;
}

.active-drawer-tab {
  background-color: #263b4f !important;
  font-weight: 600;
  color: white;
}

@media only screen and (min-width: 1264px) {
  .v-list {
    margin: 0 !important;
  }
}
</style>
