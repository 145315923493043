<template>
  <EditEntityCard :allEntities="entities" v-if="showViewEntity" :entity="entityObject" @exitEditingEntity="exitEditingEntity" />
  <CreateEntityCard v-else-if="showCreateNewEntity" @exitCreatingEntity="exitCreatingEntity" />
  <v-container v-else>
    <v-row justify="center" justify-md="start" align="center">
      <v-col cols="12" md="10">
        <v-text-field
          v-model="searchValue"
          @keyup.enter.native="searchEntity"
          hide-details
          label="Search entity"
        />
      </v-col>
      <v-col cols="auto" md="2">
        <v-btn :loading="btnLoading" block @click="searchEntity" color="primary">Search</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-list three-line>
          <v-col class="text-subtitle-1 text-center" cols="12" v-if="loading"> Loading... </v-col>
          <v-col cols="12">
            <v-progress-linear
              :active="loading"
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>

          <template v-for="(item, index) in entities">
            <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader>

            <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider>

            <v-list-item
              v-else
              :key="item.title"
              @click="openViewEntityDialog(item)"
              v-ripple
            >
              <v-avatar :color="avatarColor(item.firm_type.id)" class="mr-3">
                <v-icon dark> mdi-account-circle</v-icon>
              </v-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="item.name"></v-list-item-title>
                <v-list-item-subtitle>{{ item.firm_type.type }} </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
    </v-row>
    <v-btn @click="showCreateNewEntity = true" dark color="primary" elevation="2" fab fixed bottom right class="ma-5">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import ClientsService from "@/services/clients.service";
import AuthService from "@/services/auth.service";
import { mapGetters } from "vuex";
import EditEntityCard from "@/components/Entites/EditEntityCard.vue";
import CreateEntityCard from "@/components/Entites/CreateEntityCard.vue";

export default {
  name: "EntitiesPage",
  components: {CreateEntityCard, EditEntityCard},
  data: () => ({
    entities: [],
    btnLoading: false,
    searchValue: "",
    loading: false,
    usersRole: null,
    user: null,
    showCreateNewEntity: false,
    showViewEntity: false,
    entityObject: {}
  }),
  methods: {
    async searchEntity() {
      this.entities = [];
      this.btnLoading = true;
      this.loading = true;

      await ClientsService.searchEntity(this.searchValue).then((res) => {
        // ,
        this.entities = res;
      });
      this.btnLoading = false;
      this.loading = false;
    },
    async getEntities() {
      this.loading = true;

      await ClientsService.getEntities().then((res) => {
        // ,
        this.entities = res;
      });

      this.loading = false;
    },
    openViewEntityDialog(entity) {
      this.showViewEntity = true;
      this.entityObject = entity
    },
    exitEditingEntity(refresh){
      this.showViewEntity = false;
      if (refresh)
        this.getEntities();
      this.entityObject = {}
    },
    exitCreatingEntity(refresh){
      this.showCreateNewEntity = false;

      if(refresh)
        this.getEntities();
    },
    avatarColor(id) {
      switch (id) {
        case 1:
          return "green";
        case 2:
          return "indigo";
        case 3:
          return "blue";
        case 4:
          return "red";
      }
    },
  },
  created() {
    this.getEntities();
    AuthService.getCurrentUser(this.currentUser.current_user_id).then((res) => {
      this.usersRole = res.data.role_id;
      this.user = res.data;
    });
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
};
</script>
