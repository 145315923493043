<template>
  <div class="pa-8 text-center">
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <h2 class="mb-5 text-start">Employee Retention Tax Credit Checklist</h2>
    <h4 class="text-start">
      1. Company Information
    </h4>
    <h6 class="ml-6 mt-6 text-start">
      This information should match your most recent 941 payroll filing
    </h6>

    <div class="d-flex flex-column pl-5" style="width: 33rem">
      <v-text-field size="xs" v-model="data1.ein" placeholder="Employer Identification Number (EIN)"></v-text-field>
      <v-text-field v-model="data1.legal_name" placeholder="Legal Business Name"></v-text-field>
      <v-text-field v-model="data1.trade_name" placeholder="DBA/Trade Name (if applicable)"></v-text-field>
      <v-text-field v-model="data1.street" placeholder="Mailing Address"></v-text-field>
      <div class="d-flex flex-row">
        <v-text-field placeholder="City" v-model="data1.city" class="pr-5"></v-text-field>
        <v-text-field placeholder="State" v-model="data1.state" class="pr-5"></v-text-field>
        <v-text-field placeholder="ZIP" v-model="data1.zip" class="pr-5"></v-text-field>

      </div>
    </div>
    <v-btn color="#263B4F" class="mt-4">
      <span style="color: #FFFFFF" @click="emitData">Save and Continue</span>
    </v-btn>
  </div>

</template>

<script>
import {mapGetters} from "vuex";
import ClientsService from "@/services/clients.service";

export default {
  name: "Step1",
  data(){
    return{
     clientId:null,
      loading:false,
     data1:{
       ein:"",
       legal_name:"",
       trade_name:"",
       street:"",
       city:"",
       state:"",
       zip:"",
     }
    }
  },
  methods:{
    emitData(){
      this.$emit('returnClientId', this.clientId)
      this.$emit('returnStep1Data',this.data1,1)
      // this.$emit('goToNextStep', 2)
    },
    getUser(){
      ClientsService.getUsersRole(this.currentUser.current_user_id)
          .then(res=>
              {
                this.getClient(res.parent_entity)
                this.$emit('returnClientId', this.clientId)
              })
    },
    getClient(client_id){
      ClientsService.getOneClient(client_id)
          .then(res=>{
            this.$emit('returnClientProp', res)
            this.clientId = res.id;
            this.data1.ein=res.ein
            this.data1.legal_name=res.legal_name;
            this.data1.trade_name=res.trade_name;
            this.data1.street=res.street;
            this.data1.city=res.city;
            this.data1.state=res.state;
            this.data1.zip=res.zip;
          })
    },

  },
  computed: {
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
        },
    ),
  },
  created() {
    this.getUser()
  }

}
</script>

<style scoped>

</style>