<template>
  <div>
    <v-progress-circular v-if="loading" :size="70" :width="7" indeterminate></v-progress-circular>
    <div v-else class="d-flex justify-lg-space-around">
      <v-btn class="mr-3" color="primary" @click="onPrevPage" id="prev">Previous</v-btn>
      <div>
        <v-select
          style="width: 100px"
          dense
          label="Zoom in/out"
          v-model="scale"
          @change="queueRenderPage(pageNum)"
          :items="canvasSize"
        >
        </v-select>
        <span class="mr-16"
          ><strong>Page</strong>: <span id="page_num"></span> / <span id="page_count"></span
        ></span>
      </div>
      <v-btn class="mr-3" color="primary" @click.prevent="onNextPage" id="next">Next</v-btn>
    </div>
    <canvas @click="handleClick" class="text-center mt-2" id="the-canvas"></canvas>
  </div>
</template>

<script>
export default {
  name: "MandatePDF",
  props: {
    pdf: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      canvasSize: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
      pageNum: 1,
      pdfDoc: null,
      loading: false,
      pageRendering: false,
      pageNumPending: null,
      scale: 1,
    };
  },
  watch: {
    pdf() {
      this.renderNewPdf();
    },
  },
  created() {
    this.renderNewPdf();
  },
  methods: {
    renderNewPdf() {
      this.loading = true;
      let pdfjsLib = window["pdfjs-dist/build/pdf"];
      pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js`;
      setTimeout(() => {
        this.canvasEl = document.getElementById("the-canvas");
        this.ctx = this.canvasEl.getContext("2d");
        pdfjsLib.getDocument(this.pdf).promise.then((pdfDoc_) => {
          this.pdfDoc = pdfDoc_;
          document.getElementById("page_count").textContent = this.pdfDoc.numPages;
          this.pageNum = 1;
          // Initial/first page rendering
          this.renderPage(this.pageNum, 1, this.canvasEl, this.ctx);
        });
        this.loading = false;
      }, 1000);
    },
    renderPage(num, scale, canvasEl, ctx) {
      this.pageRendering = true;
      // Using promise to fetch the page
      this.pdfDoc.getPage(num).then(function (page) {
        let viewport = page.getViewport({ scale: scale });
        canvasEl.height = viewport.height;
        canvasEl.width = viewport.width;

        // Render PDF page into canvas context
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        page.render(renderContext);
      });
      setTimeout(() => {
        this.pageRendering = false;
        if (this.pageNumPending !== null) {
          this.pageNumPending = null;
          this.renderPage(this.pageNumPending, scale, canvasEl, ctx);
        }
        document.getElementById("page_num").textContent = num;
      }, 50);
      // Update page counters
    },
    queueRenderPage(num) {
      if (this.pageRendering) {
        this.pageNumPending = num;
      } else {
        this.renderPage(num, this.scale, this.canvasEl, this.ctx);
      }
    },
    onPrevPage() {
      if (this.pageNum <= 1) {
        return;
      }
      this.pageNum--;
      this.queueRenderPage(this.pageNum);
    },
    onNextPage() {
      if (this.pageNum >= this.pdfDoc.numPages) {
        return;
      }
      this.pageNum++;
      this.queueRenderPage(this.pageNum);
    },
    handleClick() {
      if (!this.clickTimeout) {
        this.clickTimeout = setTimeout(() => {
          clearTimeout(this.clickTimeout);
          this.clickTimeout = null;
        }, 230);
      } else {
        clearTimeout(this.clickTimeout);
        this.clickTimeout = null;
        if (this.scale >= 2) return;
        this.scale += 0.25;
        this.queueRenderPage(this.pageNum);
      }
    },
  },
};
</script>

<style scoped></style>
