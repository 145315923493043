<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-1 mt-5"
        hide-default-footer
        disable-pagination
        style="cursor:pointer;"
        @click:row="rowClicked"
    >
      <template v-slot:item.claim_task="{ item }">
        <v-btn
            class="primary"
            @click="claimTask(item)"
        >Claim
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import TasksService from "@/services/tasks.service";
import AuthService from "@/services/auth.service";
import {mapGetters} from "vuex";

export default {
  name: "OutstandingTasks",
  data: () => ({
    loading: false,
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {text: 'Days in system', value: 'days_in_queue'},
      {text: 'Attorney of record', value: 'attorney_of_record'},
      {text: 'Trade name', value: 'trade_name'},
      {text: 'Legal name', value: 'legal_name'},
      {text: '', value: 'claim_task'}
    ],
    items: [],
    roleId: null
  }),
  methods: {
    rowClicked(row) {
      this.$router.push({name: 'client-tasks', params: {clientId: row.client}})
    },
    async getCurrentUserRole() {
      const {data} = await AuthService.getCurrentUser(this.currentUser.current_user_id)
      this.roleId = data.role_id
      if (this.roleId !== 1) this.headers.pop()
    },
    getData() {
      TasksService.getTasksReport()
          .then(res => {
            this.items = res
          })
    },
    claimTask(item) {
      // this.loading = true
      // TasksService.patchClientSubtask(item.id,{assignee: this.currentUser.current_user_id})
      //     .then(() => {
            this.$router.push({name: 'client-tasks', params: {clientId: item.client}})
          // })
          // .finally(() => this.loading = false)
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  created() {
    this.getData()
    this.getCurrentUserRole()
  }
}
</script>

<style scoped>

</style>
