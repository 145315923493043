<template>
  <div>
   <div class="d-flex mr-2">
     <v-menu
         ref="menu"
         v-model="menu"
         :close-on-content-click="true"
         transition="scale-transition"
         offset-y
         max-width="290px"
         min-width="auto"
     >
       <template v-slot:activator="{ on, attrs }">
         <v-text-field
             v-model="date"
             label="From Date"
             style="width: 83%"
             prepend-icon="mdi-calendar"
             readonly
             v-bind="attrs"
             v-on="on"
         ></v-text-field>
       </template>
       <v-date-picker
           v-model="date"
           no-title
           scrollable
       >
         <v-spacer></v-spacer>
       </v-date-picker>
     </v-menu>
     <v-menu
         ref="menu"
         v-model="menuEnd"
         :close-on-content-click="true"
         transition="scale-transition"
         offset-y
         max-width="290px"
         min-width="auto"
     >
       <template v-slot:activator="{ on, attrs }">
         <v-text-field
             v-model="dateEnd"
             label="To Date"
             style="width: 83%"
             prepend-icon="mdi-calendar"
             readonly
             v-bind="attrs"
             v-on="on"
         ></v-text-field>
       </template>
       <v-date-picker
           v-model="dateEnd"
           no-title
           scrollable
       >
         <v-spacer></v-spacer>
       </v-date-picker>
     </v-menu>
   </div>
    <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        item-key="id"
        class="elevation-1 mb-6 "
        disable-pagination
        :custom-sort="customSort"
        hide-default-footer>

    </v-data-table>
  </div>
</template>


<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "Velocity",
  data() {
    return {
      date:'',
      dateEnd:'',
      menu:false,
      menuEnd:false,
      items:[],
      headers:[
        { text: 'Shipped Year', value: 'shipped_year' },
        { text: 'Shipped Week', value: 'shipped_week' },
        { text: 'Managing Firm', value: 'managing_firm' },
        { text: 'Submissions', value: 'submission_count' },
        { text: 'Credits Expected', value: 'total_credits_expected_formatted' },
        { text: 'Fees Expected', value: 'total_fees_expected_formatted' },
      ],
      loading:false,
    }
  },
  computed:{
    todaysDate(){
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      let day = dateObj.getUTCDate();
      let year = dateObj.getUTCFullYear();

      const newdate = month + "/" + day + "/" + year;
      return newdate
    },
  },
  watch:{
    dateEnd(val){
      this.items=[]
      this.getData()
    },
  },
  methods:{
    getData(){
      this.loading=true
      ClientsService.getSubmissionsWeekly(this.date,this.dateEnd).then(res=>{
        this.items = res
      }).finally(res=>{
        this.loading=false
      })
    },
    getCurrMonth(){
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      const year = dateObj.getUTCFullYear();
      const day = dateObj.getUTCDate();
      if (month < 10){
        month = '0' + month
      }
      return year + "-" + month + "-" + day;
    },
    getLatest90(){
      let dateObj = new Date();
      dateObj = new Date(new Date().setDate(dateObj.getDate() - 90));
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      const year = dateObj.getUTCFullYear();
      const day = dateObj.getUTCDate();

      if (month < 10){
        month = '0' + month
      }

      return year + "-" + month  + "-" + day;
    },
    customSort(items, index, isDesc) {
      // console.log(items)
      isDesc = isDesc[0]
      const header = index[0]
      // if (!index[0] == "balance_due_formatted") return items;
      if (header === "total_credits_expected_formatted") {
        items.sort((a, b) => {
          if (!isDesc) {
            return a.total_credits_expected - b.total_credits_expected;
          } else {
            return b.total_credits_expected - a.total_credits_expected;
          }
        });
        return items;
      }
      if (header === "total_fees_expected_formatted") {
        items.sort((a, b) => {
          if (!isDesc) {
            return a.total_fees_expected - b.total_fees_expected;
          } else {
            return b.total_fees_expected - a.total_fees_expected;
          }
        });
        return items;
      }
      items.sort((a, b) => {
        if (!isDesc) {
          return a[header] - b[header];
        } else {
          return b[header] - a[header];
        }
      });
      return items;
    },
  },
  created() {
    this.date = this.getLatest90();
    this.dateEnd = this.getCurrMonth();
  }
}
</script>


<style scoped>

</style>
