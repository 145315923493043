import $axios from '../plugins/axios'

class UsersService {

    getUsers(data) {

        return $axios.get(`users/${data.clientId}`)
            .then((res) => {

                return res.data;
            });
    }

    getOneUser(data) {

        return $axios.get(`users/${data.clientId}/${data.userId}`)
            .then((res) => {

                return res.data;
            })
            .catch((error) => {
                this.$func.popMessage(`${error.response.data.error}`, 'fail')
            })
    }

    createUser(clientId, data) {
        return $axios.post(`users/${clientId}/users`, data)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                this.$func.popMessage(`${error.response.data.error}`, 'fail')
            })
    }

    editUser(data) {

        return $axios.put(`users/${data.clientId}/users`, data)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                this.$func.popMessage(`${error.response.data.error}`, 'fail')
            })
    }
    patchUser(id,data){
        return $axios.patch(`/users/${id}`,data)
            .then((res) => {
                return res.data;
            });
    }

    deleteUser(data) {

        return $axios.delete(`users/${data.clientId}/users`)
            .then(res => {
                return res.data;
            })
            .catch((error) => {
                this.$func.popMessage(`${error.response.data.error}`, 'fail')
            })
    }
}

export default new UsersService();
