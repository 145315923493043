<template>
    <v-container>
            <h1 class="mb-5">Deposits</h1>
            <v-data-table
            :headers="depositsHeaders"
            :items="deposits"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            :loading="dataLoading"
            loading-text="Loading... Please wait"
            item-key="id"
            show-expand
            disable-pagination
            hide-default-footer
            class="elevation-1"
        >

            <template v-slot:expanded-item="{ headers, item }">

            <td :colspan="headers.length">

                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="checksHeader"
                    :items="item.checks"
                    >
                </v-data-table>
      
            </td>

            </template>

            <template v-slot:item.actions="{ item }">

            


            <v-menu offset-y offset-x
			transition="slide-y-transition" bottom
            >
            <template v-slot:activator="{ on, attrs }">
            <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                            class="ml-5"
                            id="profile-button"
                            v-bind="attrs"
                            v-on="on"
                            icon>
                            <v-icon
                            small
                            >
                            mdi-dots-vertical
                        </v-icon>
                        </v-btn>
                </template>
            </v-tooltip>
            </template>
				
            <v-list class="main-list">
            <v-list-item class="permissions-dropdown-item" @click="deleteItem(item)">
                <v-list-item-title class="permissions-dropdown"><v-icon color="black">mdi-trash-can</v-icon>
                Delete</v-list-item-title>
            </v-list-item>
            </v-list>
        </v-menu>

        </template>     
            
        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="text-h5" style="word-break: break-word; color: #AF0808;"><p>Removing this deposit will unlink the checks associated with it. These checks can be found in the Reconciliation panel. Would you like to continue?</p></v-card-title>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Delete</v-btn>
                <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
    </v-container>
</template>
<script>
import ClientsService from "@/services/clients.service";

export default {
    data () {
        return{
            dialogDelete: false,
            expanded: [],
            singleExpand: true,
            itemToDelete:'',
            depositsHeaders: [
                {
                    text: 'Date',
                    align: 'start',
                    value: 'date_formatted',
                    sortable: false
                },
                { text: 'Amount', value: 'amount_formatted', sortable: true },
                { text: '', value: 'data-table-expand' },
                { text: '', value: 'actions', sortable: false },
            ],
            checksHeader: [
                { text: 'Check number', value: 'check_number' },
                { text: 'Check Amount', value: 'check_amount_formatted', sortable: true }
            ],
            deposits: [],
            dataLoading: false
        }
    },
    methods: {
        deleteItem (item) {
            this.itemToDelete = item;
            this.dialogDelete = true
        },

        deleteItemConfirm () {
            ClientsService.deleteDeposit(this.itemToDelete.id).then(()=>{
              this.closeDelete()
              this.getAllDeposits()
            })

        },
        getAllDeposits(){
          ClientsService.getReconciliationDeposits('deposit').then((res) => {
            this.deposits = res
            this.dataLoading = false
          })
        },
        closeDelete () {
        this.dialogDelete = false
        }
    },
    created(){
        this.dataLoading = true
        this.getAllDeposits()
    }
}
</script>

<style scoped>

tbody tr td {
width:400px;
background: #eee;
}

</style>
