<template>
  <div>
    <v-overlay :value="loadinOverlay">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row class="mx-8 mt-6 d-flex justify-space-between">
      <div class="d-flex flex-column align-center">
        <h1>Checks received:</h1>
        <h1 class="numbers">{{summary.check_count}}</h1>
      </div>
      <div class="d-flex flex-column align-center">
        <h1>Average fee:</h1>
        <h1 class="numbers">{{summary.average_fee}}</h1>
      </div>
      <div class="d-flex flex-column align-center">
        <h1>Total fees:</h1>
        <h1 class="numbers">{{summary.payable_fees}}</h1>

      </div>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="feeItems"
        class="elevation-5 mt-6"
        disable-pagination
        hide-default-footer
    >
    </v-data-table>
  </div>
</template>

<script>
import ClientsService from "../../services/clients.service";
export default {
  name: "AttorneyOfRecord",
  data () {
    return {
      headers: [
        {
          text: 'Client',
          align: 'start',
          sortable: false,
          value: 'trade_name',
        },
        { text: 'Contingency rate', value: 'contracted_contingency_rate' },
        { text: 'Quarter', value: 'quarter' },
        { text: 'Expected Fee', value: 'contingency_fee' },
        { text: 'Check number', value: 'check_number' },
        { value: 'amount', text: 'Check amount' },
        { value: 'amount_applied_to_fee', text: 'Applied to fee' },
        { value: 'percentage_paid_formatted', text: 'Paid' },
        { value: 'adjusted_fee_payable', text: 'Fee paid' },
        // { text: 'Fee Expected', value: 'fee_expected' },
        // { text: 'Due Before', value: 'due_before' },
        // { text: 'Late After', value: 'late_after' },
        // { text: 'Status', value: 'remitted' },
        // { text: 'Remitted Amount', value: 'remitted_amount' },
        // { text: 'Remitted on', value: 'remitted_on' },
        // { value: 'remittedSwitch' }
      ],
      feeItems: [],
      loadinOverlay: false,
   summary:{},
    }
  },
  methods: {
    getReport(){
      this.loadinOverlay = true
      ClientsService.getReports()
          .then(data => {
            this.feeItems = data.register
            this.summary = data.summary
            this.loadinOverlay = false
          }).catch(()=> {this.loadinOverlay = false})
    },

  },
  created(){
    this.getReport()
  }
}
</script>

<style scoped>
.numbers{
  font-size: 50px;
}
</style>
