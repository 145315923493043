<template>
  <div>

    <v-row class="mt-5 mx-12" >
      <v-col cols="10">
        <v-autocomplete
            v-model="searchValue"
            :items="autoCompleteItems"
            item-text="name"
            item-value="id"
            label="Search"
        ></v-autocomplete>
      </v-col>
      <v-col cols="2" class="text-end">
        <v-btn rounded color="#263B4F" class="mt-3">
          <span style="color: #FFFFFF">Search</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-radio-group
        row
        v-model="activeRadio"
        class="mx-12"
    >
      <v-radio
          label="All"
          :value="1"
          @click="getAll"
          
      ></v-radio>
      <v-radio
          label="Outstanding"
          :value="2"
          @click="getFiltered('outstanding')"
      ></v-radio>
      <v-radio
          label="Paid"
          :value="3"
          @click="getFiltered('paid')"
      ></v-radio>
      <v-radio
          label="Overpayments"
          :value="4"
          @click="openOverpayment()"
      ></v-radio>

    </v-radio-group>

    <v-data-table
        :headers="headers"
        :items="items"
        class="elevation-5 mt-6 mx-12"
        disable-pagination
        @click:row="openRightDialog"
        hide-default-footer>

      <template v-slot:item.balance="{ item }">
        <span v-if="item.balance < -1" style="color: #D21919;font-weight: 500">{{ $formatAmount(item.balance) }}</span>
        <span v-else style="color: #228920;font-weight: 550">{{ $formatAmount(item.balance) }}</span>
      </template>

      <template v-slot:item.status="{ item }">
        <span v-if="item.balance < -1" style="color: #D21919;font-weight: 500">{{ item.status }}</span>
        <span v-else style="color: #228920;font-weight: 550">{{ item.status }}</span>
      </template>

    </v-data-table>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <OverpaymentsRightAlignDialog
    :showOverPaymentDialog="showOverPaymentDialog"
    :key="showOverPaymentDialogKey"
     :client-data="clientData"/>
  </div>

</template>

<script>
import OverpaymentsRightAlignDialog from "@/components/Client/OverpaymentsRightAlignDialog";
import ClientsService from "@/services/clients.service";
import {mapGetters} from "vuex";

export default {
  name: "Overpayments",
  components: {
    OverpaymentsRightAlignDialog,
    ClientsService
  },

  data () {
    return {
      searchValue: '',
      clientData:{},
      autoCompleteItems:[],
      loading:false,
      activeRadio: 1,
      headers:[
        { text: 'Balance', value: 'balance' },
        { text: 'Client Name', value: 'name' },
        { text: 'Status', value: 'status' },
      ],
      showOverPaymentDialog:false,
      showOverPaymentDialogKey:0,
      items:[],
      originalFeesArray:[],
    }
    },
    getters: {
      showTourGuide (state) {
        return state.todos.filter(todo => todo.done)
      }
    },
    methods:{
    getAll(){
      this.loading = true;
      this.autoCompleteItems = []
      ClientsService.getOverpayments()
          .then(res => {
            this.originalFeesArray = res;
            this.items = res
            this.items.forEach(e => {
              this.autoCompleteItems.push({name:e.name,id: e.client})
            })
            this.loading = false;
          })
    },
      getFiltered(filter){
        this.autoCompleteItems = []
        this.loading = true;
      ClientsService.getOverpaymentsWithFilter(filter)
          .then(res => {
            this.originalFeesArray = res;
            this.items = res;
            this.items.forEach(e => {
              this.autoCompleteItems.push({name:e.name,id: e.client})
            })
            this.loading = false;
          })
      },
      openOverpayment(){
        this.activeRadio = 4
      },

     openRightDialog(client){
        this.clientData = client;
       this.showOverPaymentDialog = true;
       this.showOverPaymentDialogKey++;
       
     },
      searchInput(item){
        if (item){
          this.items.map(e=>{
            if (e.client == item){
              this.items = this.items.filter(x=>x.client == item)
            }
          })
        }
      },
    },
  watch: {
    searchValue(val) {
      if (val) {
        this.searchInput(val)
      } else {
          this.items = this.originalFeesArray
      }
    },
    activeRadio(val){
      if(val === 4) {
        this.getFiltered('overpayment')
      }
    }
  },
  created() {
    this.activeRadio=this.getOverPayment ||1 
    if(this.activeRadio !== 4){
      this.getAll()
    }
  },
  computed:  {
    ...mapGetters(
          { getOverPayment: 'payments/getOverPayment' },
      ),
  }
}
</script>

<style scoped>

</style>
