<template>
  <div>

    <v-row >
      <v-col class="d-flex justify-space-between align-center pa-5" cols="12" >
        <v-row class="d-flex justify-space-between align-center">
          <v-col cols="6"  md="3"><v-card  class="cardHeight px-xl-16 py-6 d-flex flex-column ml-lg-7 text-center">
            <h2>Total open leads</h2>
            <h1>{{ totalOpenLeads }}</h1>
          </v-card></v-col>
          <v-col cols="6"  md="3"> <v-card class="cardHeight px-xl-16 py-6 d-flex flex-column text-center">
            <h2>Registered contractors</h2>
            <h1>{{regContractors}}</h1>
          </v-card></v-col>
          <v-col cols="6"  md="3"> <v-card class="cardHeight px-xl-16 py-6 d-flex flex-column text-center">
            <h2>Active contractors</h2>
            <h1>{{activeContractors}}</h1>
          </v-card></v-col>
          <v-col cols="6"  md="3">
            <canvas id="doughnutChart"></canvas>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" style="height: 400px !important;">
        <canvas id="myChart"></canvas>
      </v-col>
      <v-col cols="12" class="d-flex align-center justify-center"><h3>Leads and conversions over the last 31 days.</h3></v-col>
<!--      <v-col class="d-flex justify-space-between align-center pa-5" cols="12">-->
<!--        <v-card  class="cardHeightBottomRow px-16 py-6 d-flex flex-column ml-7">-->
<!--          <h2>Most Leads</h2>-->
<!--          <h1>123</h1>-->
<!--          <h2 style="opacity: 0.7">Cheryl</h2>-->
<!--        </v-card>-->
<!--        <v-card class="cardHeightBottomRow px-16 py-6 d-flex flex-column">-->
<!--          <h2>Most Conversions</h2>-->
<!--          <h1>123</h1>-->
<!--          <h2 style="opacity: 0.7">Cheryl</h2>-->
<!--        </v-card>-->
<!--        <v-card class="cardHeightBottomRow px-16 py-6 d-flex flex-column">-->
<!--          <h2>Highest Velocity</h2>-->
<!--          <h1>123</h1>-->
<!--          <h2 style="opacity: 0.7">Cheryl</h2>-->
<!--        </v-card>-->
<!--      </v-col>-->
      <v-col class="d-flex justify-space-between align-center pa-5" cols="12" >
        <v-row class="d-flex justify-space-between align-center">
          <v-col cols="12"  md="4"><v-card  class="cardHeight px-xl-16 py-6 d-flex flex-column ml-lg-7 text-center">
            <h2>Most Leads</h2>
            <h1>{{mostLeads.lead_count}}</h1>
            <h2 style="opacity: 0.7">{{mostLeads.contractor}}</h2>
          </v-card></v-col>
          <v-col cols="6"  md="4"> <v-card class="cardHeight px-xl-16 py-6 d-flex flex-column text-center">
            <h2>Most Conversions</h2>
                      <h1>{{mostConversions.client_count}}</h1>
                      <h2 style="opacity: 0.7">{{mostConversions.contractor}}</h2>
          </v-card></v-col>
          <v-col cols="6"  md="4"> <v-card class="cardHeight px-xl-16 py-6 d-flex flex-column text-center">
            <h2>Highest Velocity</h2>
            <h1>{{highestVel.conversion_velocity}}</h1>
            <h2 style="opacity: 0.7">{{highestVel.contractor}}</h2>
          </v-card></v-col>
        </v-row>
      </v-col>
    </v-row>


    <div class="my-12">
      <h3 class="text-center">Leads Performance</h3>
      <p class="text-center text-subtitle-1 mb-5">Shows the number of leads generated over time per contractor (last 31 days)</p>
      <template>
      <v-data-table
        :headers="leadsHeaders"
        :items="leadsData" 
        class="elevation-1"
        disable-pagination
        hide-default-footer
        mobile-breakpoint="0"
      ></v-data-table>
    </template>
    </div>

    <h3 class="text-center">Conversion performance</h3>
    <p class="text-center text-subtitle-1 mb-5">Which contractors are closing the most deals</p>
    <div>
      <template>
      <v-data-table
        :headers="clientsHeaders"
        :items="clientsData" 
        class="elevation-1"
        disable-pagination
        hide-default-footer
        mobile-breakpoint="0"
      ></v-data-table>
    </template>
    </div>

  </div>


</template>

<script>
import ResourceService from "@/services/resource.service";
import Chart from "chart.js/auto";

export default {
  name: "Performance",
  data: () => ({
    items:[],
    leadsData: [],
    clientsData: [],
    mostLeads:0,
    mostConversions:0,
    highestVel:0,
    totalOpenLeads:0,
    regContractors:0,
    activeContractors:0,
    leadChartDates:[],
    clientChartDates:[],
    doughntDataset: [{label: 'Leads',
      data: [],
      fill:true,
      backgroundColor: ['rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)'],
      borderColor: ['rgb(103,0,154)'],
      borderWidth: 1
    }
    ],
    maxChartValue:0,
    datasets:[{
      label: 'Leads',
      data: [],
      fill:true,
      backgroundColor: ['rgba(229,227,229,0.47)'],
      borderColor: ['#263B4F'],
      borderWidth: 1
    },
      {
        label: 'Clients',
        fill:true,
        data: [],
        backgroundColor: ['rgba(102,127,243,0.2)',],
        borderColor: ['rgb(0,24,147)',],
        borderWidth: 1
      }
    ],
    leadsHeaders: [
      { text: 'Day of creation', value: 'day_of_creation' },
      { text: 'ID', value: 'contractor_id' },
      { text: 'Name', value: 'contractor' },
      { text: 'Leads that day', value: 'leads_that_day' },
      { text: 'Age', value: 'age' }
    ],
    clientsHeaders: [
      { text: 'ID', value: 'contractor_id' },
      { text: 'Name', value: 'contractor' },
      { text: 'Converted Lead', value: 'converted_lead' },
      { text: 'Converted On', value: 'converted_on' }
    ]
  }),
  methods:{
    getData(){  
      ResourceService.getMPPerformance(res=>{
        this.items=res
      }).then((res)=>{
        this.items= res
        this.leadsData = res.performance.leads
        this.clientsData = res.performance.clients
        this.totalOpenLeads = this.items.summary.open_lead_count
        this.activeContractors = this.items.summary.activated_users
        this.regContractors = this.items.summary.registered_users
        const ctx = document.getElementById('myChart');
      this.datasets[0].data = this.items.charts.leads.data
      this.datasets[1].data = this.items.charts.clients.data
      let maxClients = Math.max( ...this.datasets[0].data );
      let maxLeads = Math.max( ...this.datasets[1].data );
      this.leadChartDates = this.items.charts.leads.dates
      this.clientChartDates = this.items.charts.leads.dates
      this.maxChartValue = maxClients
      if (maxLeads > maxClients) this.maxChartValue = maxLeads
      //const leads = this.items.performance.leads.map(item => item.leads_that_day).reduce((prev, next) => prev + next);
      //const clients = this.items.performance.clients.map(item => item.converted_lead).reduce((prev, next) => prev + next);
       //const velocity = this.items.performance.velocity.map(item => item.converted_clients).reduce((prev, next) => prev + next);
      this.doughntDataset[0].data = this.items.charts.donut.data
      this.highestVel = this.items.winners.velocity
      this.mostLeads = this.items.winners.leads
      this.mostConversions = this.items.winners.clients
      const myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.leadChartDates,
          datasets: this.datasets
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          // scales: {
          //   y: {
          //     beginAtZero: true
          //   }
          // }
          scales: {
            x: {
              display: true,
              title: {
                display: true
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Value'
              },
              suggestedMin: 0,
              suggestedMax: this.maxChartValue
            }
          }
        }
      });
      //Doughnt
      const dought = document.getElementById('doughnutChart');

      const myDoughntChart = new Chart(dought, {
        type: 'doughnut',
        data: {
          labels: this.items.charts.donut.labels,
          datasets: this.doughntDataset
        },
        options: {
          plugins: {
            legend: {
              display: false
            }
          },
          responsive: true,
          // scales: {
          //   y: {
          //     beginAtZero: true
          //   }
          // }
          scales: {
            x: {
              display: false,
              title: {
                display: false
              }
            },
            y: {
              display: false,
              title: {
                display: false,
              },

            }
          }
        }
      });


      })
      // this.items = {
      //   "summary":
      //       {
      //         "open_lead_count": 37,
      //         "activated_users": 165,
      //         "registered_users": 643
      //       },
      //   "charts":
      //       {
      //         "leads":
      //             {
      //               "data":
      //                   [
      //                     5,
      //                     6,
      //                     1,
      //                     1,
      //                     3,
      //                     0,
      //                     1,
      //                     1,
      //                     0,
      //                     1,
      //                     2,
      //                     1,
      //                     0,
      //                     4,
      //                     1,
      //                     3,
      //                     1,
      //                     2,
      //                     0,
      //                     0,
      //                     2,
      //                     3,
      //                     1,
      //                     0,
      //                     4,
      //                     3,
      //                     0,
      //                     0,
      //                     1,
      //                     0,
      //                     0
      //                   ],
      //               "days":
      //                   [
      //                     "Dec-26",
      //                     "Dec-27",
      //                     "Dec-28",
      //                     "Dec-29",
      //                     "Dec-30",
      //                     "Dec-31",
      //                     "Jan-02",
      //                     "Jan-03",
      //                     "Jan-04",
      //                     "Jan-05",
      //                     "Jan-06",
      //                     "Jan-07",
      //                     "Jan-08",
      //                     "Jan-09",
      //                     "Jan-10",
      //                     "Jan-11",
      //                     "Jan-12",
      //                     "Jan-13",
      //                     "Jan-14",
      //                     "Jan-15",
      //                     "Jan-16",
      //                     "Jan-17",
      //                     "Jan-18",
      //                     "Jan-19",
      //                     "Jan-20",
      //                     "Jan-21",
      //                     "Jan-22",
      //                     "Jan-23",
      //                     "Jan-24",
      //                     "Jan-25",
      //                     "Jan-26"
      //                   ]
      //             },
      //         "clients":
      //             {
      //               "dates":
      //                   [
      //                     "01/03/23",
      //                     "01/04/23",
      //                     "01/05/23",
      //                     "01/06/23",
      //                     "01/07/23",
      //                     "01/08/23",
      //                     "01/09/23",
      //                     "01/10/23",
      //                     "01/11/23",
      //                     "01/12/23",
      //                     "01/13/23",
      //                     "01/14/23",
      //                     "01/15/23",
      //                     "01/16/23",
      //                     "01/17/23",
      //                     "01/18/23",
      //                     "01/19/23",
      //                     "01/20/23",
      //                     "01/21/23",
      //                     "01/22/23",
      //                     "01/23/23",
      //                     "01/24/23",
      //                     "01/25/23",
      //                     "01/26/23",
      //                     "01/27/23",
      //                     "01/28/23",
      //                     "01/29/23",
      //                     "01/30/23",
      //                     "01/31/23",
      //                     "02/01/23",
      //                     "02/02/23"
      //                   ],
      //               "data":
      //                   [
      //                     1,
      //                     2,
      //                     1,
      //                     0,
      //                     0,
      //                     0,
      //                     1,
      //                     0,
      //                     1,
      //                     1,
      //                     2,
      //                     0,
      //                     0,
      //                     0,
      //                     1,
      //                     1,
      //                     0,
      //                     1,
      //                     0,
      //                     0,
      //                     0,
      //                     2,
      //                     1,
      //                     0,
      //                     0,
      //                     0,
      //                     0,
      //                     0,
      //                     0,
      //                     0,
      //                     0
      //                   ]
      //             },
      //         "donut":
      //             {
      //               "labels":
      //                   [
      //                     "Registered users",
      //                     "Active users",
      //                     "Total users"
      //                   ],
      //               "data":
      //                   [
      //                     643,
      //                     165,
      //                     808
      //                   ]
      //             }
      //       },
      //   "winners":
      //       {
      //         "leads":
      //             {
      //               "contractor": "Cheryl Nelson",
      //               "lead_count": 10
      //             },
      //         "clients":
      //             {
      //               "contractor": "Jamie Silva",
      //               "client_count": 4
      //             },
      //         "velocity":
      //             {
      //               "contractor": "Josh Felts",
      //               "velocity": 150
      //             }
      //       },
      //   "performance":
      //       {
      //         "leads":
      //             [
      //               {
      //                 "day_of_creation": "2023-01-24",
      //                 "contractor_id": 4255,
      //                 "contractor": "Dramma Wertz-Russell",
      //                 "leads_that_day": 1,
      //                 "age": 1
      //               },
      //               {
      //                 "day_of_creation": "2023-01-21",
      //                 "contractor_id": 4146,
      //                 "contractor": "Audrey Powell",
      //                 "leads_that_day": 2,
      //                 "age": 4
      //               },
      //               {
      //                 "day_of_creation": "2023-01-21",
      //                 "contractor_id": 4741,
      //                 "contractor": "Brittney Walls",
      //                 "leads_that_day": 1,
      //                 "age": 4
      //               },
      //               {
      //                 "day_of_creation": "2023-01-20",
      //                 "contractor_id": 3256,
      //                 "contractor": "Josh Felts",
      //                 "leads_that_day": 1,
      //                 "age": 5
      //               },
      //               {
      //                 "day_of_creation": "2023-01-20",
      //                 "contractor_id": 3408,
      //                 "contractor": "Rance White",
      //                 "leads_that_day": 1,
      //                 "age": 5
      //               },
      //               {
      //                 "day_of_creation": "2023-01-20",
      //                 "contractor_id": 3570,
      //                 "contractor": "Kimberly Scavello",
      //                 "leads_that_day": 1,
      //                 "age": 5
      //               },
      //               {
      //                 "day_of_creation": "2023-01-20",
      //                 "contractor_id": 3796,
      //                 "contractor": "Jason Cosby",
      //                 "leads_that_day": 1,
      //                 "age": 5
      //               },
      //               {
      //                 "day_of_creation": "2023-01-18",
      //                 "contractor_id": 4426,
      //                 "contractor": "Teleth Herron",
      //                 "leads_that_day": 1,
      //                 "age": 7
      //               },
      //               {
      //                 "day_of_creation": "2023-01-17",
      //                 "contractor_id": 3412,
      //                 "contractor": "Cheryl Nelson",
      //                 "leads_that_day": 3,
      //                 "age": 8
      //               },
      //               {
      //                 "day_of_creation": "2023-01-16",
      //                 "contractor_id": 4426,
      //                 "contractor": "Teleth Herron",
      //                 "leads_that_day": 2,
      //                 "age": 9
      //               },
      //               {
      //                 "day_of_creation": "2023-01-13",
      //                 "contractor_id": 3424,
      //                 "contractor": "Warren Sander",
      //                 "leads_that_day": 1,
      //                 "age": 12
      //               },
      //               {
      //                 "day_of_creation": "2023-01-13",
      //                 "contractor_id": 3577,
      //                 "contractor": "Kristin Hunsaker",
      //                 "leads_that_day": 1,
      //                 "age": 12
      //               },
      //               {
      //                 "day_of_creation": "2023-01-12",
      //                 "contractor_id": 3262,
      //                 "contractor": "Michael Furdock",
      //                 "leads_that_day": 1,
      //                 "age": 13
      //               },
      //               {
      //                 "day_of_creation": "2023-01-11",
      //                 "contractor_id": 3456,
      //                 "contractor": "LaToya Chambliss",
      //                 "leads_that_day": 3,
      //                 "age": 14
      //               },
      //               {
      //                 "day_of_creation": "2023-01-10",
      //                 "contractor_id": 3262,
      //                 "contractor": "Michael Furdock",
      //                 "leads_that_day": 1,
      //                 "age": 15
      //               },
      //               {
      //                 "day_of_creation": "2023-01-09",
      //                 "contractor_id": 3408,
      //                 "contractor": "Rance White",
      //                 "leads_that_day": 1,
      //                 "age": 16
      //               },
      //               {
      //                 "day_of_creation": "2023-01-09",
      //                 "contractor_id": 3422,
      //                 "contractor": "John Orlando",
      //                 "leads_that_day": 2,
      //                 "age": 16
      //               },
      //               {
      //                 "day_of_creation": "2023-01-09",
      //                 "contractor_id": 3456,
      //                 "contractor": "LaToya Chambliss",
      //                 "leads_that_day": 1,
      //                 "age": 16
      //               },
      //               {
      //                 "day_of_creation": "2023-01-07",
      //                 "contractor_id": 3422,
      //                 "contractor": "John Orlando",
      //                 "leads_that_day": 1,
      //                 "age": 18
      //               },
      //               {
      //                 "day_of_creation": "2023-01-06",
      //                 "contractor_id": 3260,
      //                 "contractor": "Cristel Brito",
      //                 "leads_that_day": 1,
      //                 "age": 19
      //               },
      //               {
      //                 "day_of_creation": "2023-01-06",
      //                 "contractor_id": 3456,
      //                 "contractor": "LaToya Chambliss",
      //                 "leads_that_day": 1,
      //                 "age": 19
      //               },
      //               {
      //                 "day_of_creation": "2023-01-05",
      //                 "contractor_id": 3456,
      //                 "contractor": "LaToya Chambliss",
      //                 "leads_that_day": 1,
      //                 "age": 20
      //               },
      //               {
      //                 "day_of_creation": "2023-01-03",
      //                 "contractor_id": 3582,
      //                 "contractor": "Jamie Silva",
      //                 "leads_that_day": 1,
      //                 "age": 22
      //               },
      //               {
      //                 "day_of_creation": "2023-01-02",
      //                 "contractor_id": 3270,
      //                 "contractor": "Barbara Penn",
      //                 "leads_that_day": 1,
      //                 "age": 23
      //               },
      //               {
      //                 "day_of_creation": "2022-12-30",
      //                 "contractor_id": 3400,
      //                 "contractor": "Brandon Hudson",
      //                 "leads_that_day": 1,
      //                 "age": 26
      //               },
      //               {
      //                 "day_of_creation": "2022-12-30",
      //                 "contractor_id": 3412,
      //                 "contractor": "Cheryl Nelson",
      //                 "leads_that_day": 2,
      //                 "age": 26
      //               },
      //               {
      //                 "day_of_creation": "2022-12-29",
      //                 "contractor_id": 3498,
      //                 "contractor": "Michael Distefano",
      //                 "leads_that_day": 1,
      //                 "age": 27
      //               },
      //               {
      //                 "day_of_creation": "2022-12-28",
      //                 "contractor_id": 3412,
      //                 "contractor": "Cheryl Nelson",
      //                 "leads_that_day": 1,
      //                 "age": 28
      //               },
      //               {
      //                 "day_of_creation": "2022-12-27",
      //                 "contractor_id": 3262,
      //                 "contractor": "Michael Furdock",
      //                 "leads_that_day": 1,
      //                 "age": 29
      //               },
      //               {
      //                 "day_of_creation": "2022-12-27",
      //                 "contractor_id": 3270,
      //                 "contractor": "Barbara Penn",
      //                 "leads_that_day": 2,
      //                 "age": 29
      //               },
      //               {
      //                 "day_of_creation": "2022-12-27",
      //                 "contractor_id": 3422,
      //                 "contractor": "John Orlando",
      //                 "leads_that_day": 1,
      //                 "age": 29
      //               },
      //               {
      //                 "day_of_creation": "2022-12-27",
      //                 "contractor_id": 3515,
      //                 "contractor": "James Brown",
      //                 "leads_that_day": 1,
      //                 "age": 29
      //               },
      //               {
      //                 "day_of_creation": "2022-12-27",
      //                 "contractor_id": 3582,
      //                 "contractor": "Jamie Silva",
      //                 "leads_that_day": 1,
      //                 "age": 29
      //               },
      //               {
      //                 "day_of_creation": "2022-12-26",
      //                 "contractor_id": 3412,
      //                 "contractor": "Cheryl Nelson",
      //                 "leads_that_day": 2,
      //                 "age": 30
      //               },
      //               {
      //                 "day_of_creation": "2022-12-26",
      //                 "contractor_id": 3582,
      //                 "contractor": "Jamie Silva",
      //                 "leads_that_day": 3,
      //                 "age": 30
      //               }
      //             ],
      //         "clients":
      //             [
      //               {
      //                 "contractor_id": 4255,
      //                 "contractor": "Dramma Wertz-Russell",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/24/2023"
      //               },
      //               {
      //                 "contractor_id": 4146,
      //                 "contractor": "Audrey Powell",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/24/2023"
      //               },
      //               {
      //                 "contractor_id": 3256,
      //                 "contractor": "Josh Felts",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/20/2023"
      //               },
      //               {
      //                 "contractor_id": 3424,
      //                 "contractor": "Warren Sander",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/18/2023"
      //               },
      //               {
      //                 "contractor_id": 3456,
      //                 "contractor": "LaToya Chambliss",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/17/2023"
      //               },
      //               {
      //                 "contractor_id": 3262,
      //                 "contractor": "Michael Furdock",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/13/2023"
      //               },
      //               {
      //                 "contractor_id": 3456,
      //                 "contractor": "LaToya Chambliss",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/13/2023"
      //               },
      //               {
      //                 "contractor_id": 3422,
      //                 "contractor": "John Orlando",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/12/2023"
      //               },
      //               {
      //                 "contractor_id": 3262,
      //                 "contractor": "Michael Furdock",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/11/2023"
      //               },
      //               {
      //                 "contractor_id": 3260,
      //                 "contractor": "Cristel Brito",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/9/2023"
      //               },
      //               {
      //                 "contractor_id": 3270,
      //                 "contractor": "Barbara Penn",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/5/2023"
      //               },
      //               {
      //                 "contractor_id": 3412,
      //                 "contractor": "Cheryl Nelson",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/3/2023"
      //               },
      //               {
      //                 "contractor_id": 3582,
      //                 "contractor": "Jamie Silva",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/3/2023"
      //               },
      //               {
      //                 "contractor_id": 3400,
      //                 "contractor": "Brandon Hudson",
      //                 "converted_lead": 1,
      //                 "converted_on": "1/1/2023"
      //               },
      //               {
      //                 "contractor_id": 3412,
      //                 "contractor": "Cheryl Nelson",
      //                 "converted_lead": 1,
      //                 "converted_on": "12/30/2022"
      //               },
      //               {
      //                 "contractor_id": 3582,
      //                 "contractor": "Jamie Silva",
      //                 "converted_lead": 2,
      //                 "converted_on": "12/29/2022"
      //               },
      //               {
      //                 "contractor_id": 3262,
      //                 "contractor": "Michael Furdock",
      //                 "converted_lead": 1,
      //                 "converted_on": "12/27/2022"
      //               },
      //               {
      //                 "contractor_id": 3412,
      //                 "contractor": "Cheryl Nelson",
      //                 "converted_lead": 1,
      //                 "converted_on": "12/27/2022"
      //               },
      //               {
      //                 "contractor_id": 3582,
      //                 "contractor": "Jamie Silva",
      //                 "converted_lead": 1,
      //                 "converted_on": "12/27/2022"
      //               },
      //               {
      //                 "contractor_id": 3422,
      //                 "contractor": "John Orlando",
      //                 "converted_lead": 1,
      //                 "converted_on": "12/27/2022"
      //               }
      //             ],
      //         "velocity":
      //             [
      //               {
      //                 "contractor": "Michael Furdock",
      //                 "converted_clients": 3,
      //                 "avergage_time_to_convert": 2,
      //                 "conversion_velocity": 150
      //               },
      //               {
      //                 "contractor": "Jamie Silva",
      //                 "converted_clients": 4,
      //                 "avergage_time_to_convert": 3,
      //                 "conversion_velocity": 133
      //               },
      //               {
      //                 "contractor": "Josh Felts",
      //                 "converted_clients": 1,
      //                 "avergage_time_to_convert": 1,
      //                 "conversion_velocity": 100
      //               },
      //               {
      //                 "contractor": "Dramma Wertz-Russell",
      //                 "converted_clients": 1,
      //                 "avergage_time_to_convert": 1,
      //                 "conversion_velocity": 100
      //               },
      //               {
      //                 "contractor": "Cheryl Nelson",
      //                 "converted_clients": 3,
      //                 "avergage_time_to_convert": 3,
      //                 "conversion_velocity": 100
      //               },
      //               {
      //                 "contractor": "John Orlando",
      //                 "converted_clients": 2,
      //                 "avergage_time_to_convert": 4,
      //                 "conversion_velocity": 50
      //               },
      //               {
      //                 "contractor": "Brandon Hudson",
      //                 "converted_clients": 1,
      //                 "avergage_time_to_convert": 3,
      //                 "conversion_velocity": 33
      //               },
      //               {
      //                 "contractor": "Audrey Powell",
      //                 "converted_clients": 1,
      //                 "avergage_time_to_convert": 4,
      //                 "conversion_velocity": 25
      //               },
      //               {
      //                 "contractor": "Barbara Penn",
      //                 "converted_clients": 1,
      //                 "avergage_time_to_convert": 4,
      //                 "conversion_velocity": 25
      //               },
      //               {
      //                 "contractor": "LaToya Chambliss",
      //                 "converted_clients": 2,
      //                 "avergage_time_to_convert": 8,
      //                 "conversion_velocity": 25
      //               },
      //               {
      //                 "contractor": "Cristel Brito",
      //                 "converted_clients": 1,
      //                 "avergage_time_to_convert": 4,
      //                 "conversion_velocity": 25
      //               },
      //               {
      //                 "contractor": "Warren Sander",
      //                 "converted_clients": 1,
      //                 "avergage_time_to_convert": 6,
      //                 "conversion_velocity": 17
      //               }
      //             ]
      //       }
      // }

  

      
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style scoped>
@media only screen and (min-width: 1278px) {
  .cardHeight {
      font-size: 1rem;

  }
  h1 {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 400px) {
  h2 {
    font-size: 1.2rem;
  }
  h1{
    font-size: 1.5rem;
  }
  div.cardHeight{
    padding: 0 4px;
  }
}
.cardHeightBottomRow{
  max-height: 200px !important;
  display: flex;
  align-items: center;
}
 .cardHeight{
   max-height: auto !important;
   display: flex;
   align-items: center;
   justify-content: center;
 }
</style>
