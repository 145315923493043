<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div class="d-flex justify-end">
      <v-btn @click="download(items.csv)" color="primary">DOWNLOAD CSV</v-btn>
      <v-btn @click="download(items.pdf)" color="primary">DOWNLOAD PDF</v-btn>
    </div>

    <div v-if="!showTable">
      <v-btn @click="showTable=!showTable" color="warning">Click to toggle DataTable</v-btn>
    </div>
    <div v-if="showTable">
      <v-data-table
          :headers="headers"
          :items="items.breakdown"
          class="elevation-1 mt-5"
          hide-default-footer
          disable-pagination
          mobile-breakpoint="0"
      ></v-data-table>
    </div>

  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "ShippingLabels",
  data:()=>({
    csvFile:null,
    showTable:false,
    pdfFile:null,
    loading:true,
    headers: [
      { text: 'Legal Name', value: 'legal_name' },
      { text: 'Trade Name', value: 'trade_name' },
      { text: 'ein', value: 'ein' },
      { text: 'Street', value: 'street' },
      { text: 'State', value: 'state' },
      { text: 'Zip', value: 'zip' },
      { text: 'AOR', value: 'attorney_of_record' },
      { text: 'Adress', value: 'street' },
      { text: 'Q4 Expected', value: 'q4_expected' },
      { text: 'Q4 Paid', value: 'q4_paid' },
      { text: 'Q4 Diff', value: 'q4_diff' },

      { text: 'Q1 Expected', value: 'q1_expected' },
      { text: 'Q1 Paid', value: 'q1_paid' },
      { text: 'Q1 Diff', value: 'q1_diff' },

      { text: 'Q2 Expected', value: 'q2_expected' },
      { text: 'Q2 Paid', value: 'q2_paid' },
      { text: 'Q2 Diff', value: 'q2_diff' },

      { text: 'Q3 Expected', value: 'q3_expected' },
      { text: 'Q3 Paid', value: 'q3_paid' },
      { text: 'Q3 Diff', value: 'q3_diff' },
    ],
    items:{},
  }),
  methods:{
    download(item){
      const link = document.createElement("a");
      link.href = item;
      link.click();
    }
  },
  created() {
    ClientsService.getShippingLabels().then(res=>{
      this.items=res
      this.loading=false
    }).catch(e=>this.loading=false)
  }
}
</script>

<style scoped>

</style>
