<template>
  <div class="text-center">
    <v-row>
      <v-col v-if="loading"  lass="d-flex justify-center align-center mt-8">
        <v-progress-circular
            :size="70"
            :width="7"
            indeterminate
        ></v-progress-circular>
      </v-col>
      <v-col cols="12">
        <v-hover v-for="item in calcs"  class="mb-3" v-slot="{ hover }">
        <v-card :elevation="hover ? 8 : 4"  class="pa-6 d-flex flex-row justify-space-between align-center" >
          <div class="d-flex justify-start align-start flex-column">
            <h4 class="mb-3">{{item.original_file_name}}</h4>
            <h4>{{item.created}}</h4>
          </div>
          <v-chip color="green">{{ item.type }}</v-chip>
          <a :download="item.original_file_name"
             :href="item.url"
             v-show="item.id"
             target="_blank"
             class="ml-2"
             type="button"
             title="Download Calculation"
             id="downloadsBtn"
          >
            <v-icon size="35">
              mdi-download
            </v-icon>
          </a>

        </v-card>
        </v-hover>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "Downloads",
  data: () => ({
    loading: false,
    calcs: [],
  }),
  methods: {
    downloadURI(dataurl, filename) {
      const link = document.createElement("a");
      link.href = dataurl;
      link.download = filename;
      link.click();
    },
    async downloadCalc() {
      await ClientsService.downloadCalculations(this.clientId)
        .then((res) => {
          this.loading = false;
          // this.downloadURI(res.url, res.original_file_name);
          this.calcs=res
          // window.open(res.url);
          // window.focus()
        })
        .catch(() => (this.loading = false));
    },
  },
  created() {
    this.loading = true;

    this.downloadCalc()
  },
  props: {
    clientId: {
      type: Number,
      default: 0,
    },
    clientProp: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
