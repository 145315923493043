<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-container fluid>
      <v-row>
        <v-col class="text-right d-flex justify-end">
          <v-radio-group v-model="radioSelected" row style="margin: 0 !important;">
            <v-radio @click="getData(1)" label="In Progress" value="1"></v-radio>
            <v-radio @click="getData(2)" label="Problematic Upload" value="2"></v-radio>
            <v-radio @click="getData(3)" label="Completed" value="3"></v-radio>
            <!--           <v-radio @click="getData(4)" label="Received" value="3"></v-radio>-->
          </v-radio-group>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
        :single-expand="showExpand"
        :expanded.sync="expanded"
        :show-expand="showExpand"
        :headers="headers"
        :items="items"
        class="elevation-5"
        disable-pagination
        hide-default-footer
        item-key="client"
    >
      <template v-slot:item.client_name="{ item }">
        <div style="cursor: pointer;font-weight: 430" @click="$router.push(`/clients/${item.client}/profile`)">
          {{item.client_name}}
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="" :colspan="4">
          <h3 @click="downloadFile(item.url)" style="text-decoration: underline;color:dodgerblue;cursor:pointer;">Download Transcript</h3>
        </td>
        <td class="" :colspan="1">
          <v-btn @click="patchTranscript(item.id,3)" color="primary">Mark Completed</v-btn>
        </td>
        <td class="" :colspan="1">
          <v-btn @click="openDialog(item.id)" color="primary">Report Problem</v-btn>
        </td>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5" style="word-break: break-word; color: #AF0808;"><p>Describe the problem.</p></v-card-title>
        <v-card-subtitle>
          <v-textarea v-model="problemDescription" placeholder="..." outlined></v-textarea>
        </v-card-subtitle>
        <v-card-actions >
          <v-btn color="blue darken-1" text @click="dialog=false">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="patchTranscript(problematicId,2)">send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import router from "@/router";
import ClientsService from "@/services/clients.service";
import {mapGetters} from "vuex";
import AuthService from "@/services/auth.service";

export default {
  name: "TranscriptReport",
  data() {
    return {
      usersRole:0,
      radioSelected: '1',
      expanded:[],
      showExpand:true,
      problemDescription:'',
      selected: [],
      items:[],
      loading:false,
      dialogKye: 0,
      dialog:false,
      problematicId:null,
      headers:[
        { text: 'Client', value: 'client_name' },
        { text: 'Managing Firm', value: 'managing_firm' },
        { text: 'AOR', value: 'attorney_of_record' },
        { text: 'Legal Name', value: 'legal_name' },
        { text: 'Status', value: 'status' },
      ],
    }
  },
  methods: {
    router() {
      return router
    },
    openDialog(id){
      this.problematicId = id
      this.dialogKye++
      this.dialog = true
    },
    getData(filter) {
      this.showExpand = filter === 1;
      this.expanded = []
      this.loading = true
      ClientsService.getTranscriptReport(filter).then(res => {
        this.items = res
        this.loading = false
      }).catch(() => this.loading = false)
    },
    patchTranscript(id, status){
      this.dialog = false
      this.loading = true
      ClientsService.patchTranscript(id, {"status":status,"note":this.problemDescription}).then(res => {
        this.getData(1)
        this.radioSelected = '1'
        this.problemDescription = ''
      }).catch(() => this.loading = false)
    },
    downloadFile(item){
      try {
        if(item === null) {
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: `Problem with downloading file`,
            duration: 4000,
            mode: "fail",
          });
        }
        else window.open(item)
      } catch (e) {
      }

    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  async created() {
    await AuthService.getCurrentUser(this.currentUser.current_user_id).then(
        (res) => {
          this.usersRole = res.data.role_id;
        }
    );
    this.getData(1)
  }
}
</script>



<style scoped>

</style>
