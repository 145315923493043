<template>
  <div>
    <v-dialog
        v-model="errorMessage"
        width="500"
    >
      <v-card class="pa-16" style="background-color: #263B4F">
        <h2 style="color: #FFFFFF" class="text-center mb-7">The chosen file is over the File Size limit of 25Mb. Please
          try another file.</h2>
        <v-progress-linear absolute color="red"
                           :v-model="errProgressBarValue"
                           :buffer-value="bufferValue">
        </v-progress-linear>
      </v-card>


    </v-dialog>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row class="my-5 px-3" v-if="usersRole === 1">

      <!-- THIS IS FIRST CARD -->
      <v-card
          class="mr-2 mb-2 pa-7"
          outlined
          :style="`width: ${$vuetify.breakpoint.mdAndUp ? '45' : '100'}%`"
      >
        <v-card
            @drop.prevent="drop($event)"
            @dragover.prevent="dragoverData = true"
            @dragenter.prevent="dragoverData = true"
            @dragleave.prevent="dragoverData = false"
            :class="{ 'grey lighten-2': dragoverData }"
            height="10vh"
            class="d-flex  align-center justify-center"
            style="border: 5px dashed lightgray; box-shadow: none">
          <v-card-text>
            <v-row class="d-flex justify-center align-center">
              <div
                  class="p-12"
              >
                <input type="file" multiple name="fields[assetsFieldHandleUploads2][]"
                       id="assetsFieldHandleUploads2"
                       class="d-none absolute" @input="onChange" ref="file"
                />

                <label for="assetsFieldHandleUploads2" class="block cursor-pointer">
                   <span v-if="uploadedFile">
                                File uploaded: 
                                {{ uploadedFile}}
                   </span>
                   <div v-else>
                   <span class="underline"> CHOOSE  A FILE </span> OR DRAG IT <span style="color: blue;"> HERE </span>
                  </div>
                </label>

              </div>
            </v-row>
          </v-card-text>
        </v-card>
        <!--        <v-btn for="assetsFieldHandleUploads2" :loading="uploadLoading" :disabled="uploadLoading" style="height: 50px; width: 100%" color="#263B4F">-->
        <!--          <input type="file" name="fields[assetsFieldHandleUploads][]"-->
        <!--                 id="assetsFieldHandleUploads2"-->
        <!--                 class="d-none absolute" @input="onChange" ref="file"-->
        <!--          />-->
        <!-- <span style="color: #FFFFFF">Upload</span>
        </v-btn> -->

        <v-col
            class="mt-5 pa-0"
            sm="12"
        >

          <v-select
              v-model="selectedUploadType"
              :items="initialPageOptions"
              outlined
              item-text="name"
              return-object
              label="Choose upload type"
              item-value="id"></v-select>

          <v-select
              :items="roles"
              v-model="selectRole"
              multiple
              item-text="role_name"
              item-value="role_id"
              label="Select role"
              solo
          ></v-select>

          <v-select
          :items="laws"
          v-model="managingFirm"
          label="Select managing firm"
          :item-text="'name'"
					:item-value="'id'"
          solo
        ></v-select>
        </v-col>

        <v-btn :loading="postLoading" @click="upload" :disabled="postLoading" style="height: 50px; width: 100%"
               color="#263B4F">
          <span style="color: #FFFFFF">Post File</span>
        </v-btn>


      </v-card>


      <!-- THIS IS SECOND CARD -->
      <v-card
          class="mr-2 mb-2 pa-7"
          outlined
          :style="`width: ${$vuetify.breakpoint.mdAndUp ? '45' : '100'}%`"
      >

        <!--        <v-btn :loading="createLoading" @click="upload" :disabled="createLoading" style="height: 50px; width: 100%"-->
        <!--               color="#263B4F">-->
        <!--          <span style="color: #FFFFFF">Create</span>-->
        <!--        </v-btn>-->
        <!-- <div style="height: 25vh"></div> -->

        <v-col
            class="my-5 pa-0"
            sm="12"
        >
          <v-select
              v-model="selectedPageOption"
              :items="pageOptions"
              outlined
              item-text="name"
              label="Choose page type"
              item-value="id"></v-select>

          <v-text-field
              style="height: 59px"
              outlined
              v-model="pageName"
              label="Page Name"
          ></v-text-field>
        </v-col>

        <v-btn :loading="postLoading2" @click="createPage" :disabled="postLoading2" style="height: 50px; width: 100%"
               color="#263B4F">
          <span style="color: #FFFFFF">Create Page</span>
        </v-btn>

      </v-card>

    </v-row>

    <v-row fluid >
      <v-col>
  <v-row>
    <v-col cols="12" class="text-center">           {{headers[0].text }}</v-col>
    <v-col cols="12">
      <v-hover v-for="item in resourcesItems.pod" v-slot="{ hover }">
        <v-card :elevation="hover ? 8 : 2" style="cursor: pointer" class="text-center pa-4 mb-2 d-flex flex-column"><a
            :download="item.original_file_name"
            :href="item.url">{{ item.original_file_name }}</a></v-card>
      </v-hover>
    </v-col>
  </v-row>
       </v-col>
      <v-col md="4" cols="12">
        <v-row>
          <v-col cols="12" class="text-center">           {{headers[1].text }}</v-col>
          <v-col cols="12">
            <v-hover v-for="item in resourcesItems.faq" v-slot="{ hover }">
              <v-card v-if="item.original_file_name" :elevation="hover ? 8 : 2" style="cursor: pointer" class="text-center pa-4 mb-2 d-flex flex-column">
              <span  >
                <a
                    :download="item.original_file_name"
                    :href="item.url">{{ item.original_file_name }}</a>
              </span>
                <span  style="font-weight: bold">{{ item.page_name }}</span>
              </v-card>
              <v-card v-else @click="()=>$router.push(`/page-builder/${item.id}`)" :elevation="hover ? 8 : 2"
                      style="cursor: pointer" class="pa-4 mb-2 d-flex flex-column text-center"><span style="font-weight: bold">{{ item.page_name }}</span>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col cols="12" class="text-center">           {{headers[2].text }}</v-col>
          <v-col cols="12">
            <v-hover v-for="item in resourcesItems.puq" v-slot="{ hover }">
              <v-card @click="()=>$router.push(`/page-builder/${item.id}`)" :elevation="hover ? 8 : 2"
                      style="cursor: pointer" class="pa-4 mb-2 d-flex flex-column text-center"><span style="font-weight: bold">{{ item.page_name }}</span>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
        </v-col>

    </v-row>
  </div>
</template>
<script>
import ClientsService from "@/services/clients.service";
import AuthService from "@/services/auth.service";
import {mapGetters} from "vuex";
import PageBuilder from "@/views/PageBuilder.vue";
import ResourceService from "@/services/resource.service";
import LawService from "@/services/law.service"

export default {
  data() {
    return {
      operationalDocs: '',
      pageBuilder: false,
      pageBuilderKey: 0,
      selectRole: null,
      dragoverData:false,
      roles: [],
      checkbox1: false,
      checkbox2: false,
      uploadLoading: false,
      postLoading: false,
      createLoading: false,
      postLoading2: false,
      uploadedFile: '',
      usersRole: null,
      selectedPageOption: '',
      loading:false,
      selectedUploadType: '',
      pageName: '',
      pageOptions: [
        {id: 0, name: 'ERTC FAQs'},
        {id: 1, name: 'Patriot FAQs'},
      ],
      initialPageOptions:[
        {id: 0, name: 'ERTC FAQs',upload_type:'faq'},
        {id: 1, name: 'Operational Documents',upload_type:'pod'},
      ],
      tableLoading: false,
      headers: [
        {
          text: 'OPERATIONAL DOCUMENTS',
          align: 'start',
          value: 'pod',
        },
        {text: 'ERTC FAQs', value: 'faq'},
        {text: 'PATRIOT FAQs', value: 'puq'}
      ],
      resourcesItems: [
        // {
        //     operationalDoc: 'wtf.pdf',
        //     FAQs: 'wtf.pdf',
        //     userQuestions: 'link',
        // },
        // {
        //     operationalDoc: 'wow.pdf',
        //     FAQs: 'wow.pdf',
        //     userQuestions: 'link',
        // },
      ],
      laws: [],
      managingFirm: null,
      errorMessage: false,
      errProgressBarValue: 0,
      bufferValue: 0,
    }
  },
  methods: {
    drop(event) {
      // console.log('test');
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
    },
    onChange() {
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        if (this.$refs.file.files[i].size < 25000000) {
          this.isActiveButton = true;
          let uploadedFile = this.$refs.file.files[i]
          this.uploadedFile=this.$refs.file.files[i].name
          const newFile = {
            original_file_name: uploadedFile.name,
            url: uploadedFile,
            created_on: this.forDate,
            mime_type: uploadedFile.type,
            uploaded_by: this.currentUser.id,
            uploadedFile: uploadedFile,
            uploadingFiles: false,
          }
          // if (uploadedFile) {
          //   this.$emit('fileDropedNotSaved', true)
          //   this.awaiting_review.push(newFile)
          // }
        } else {
          const removeMessageAfter = 2500
          this.errorMessage = true;
          setTimeout(() => {
            this.errorMessage = false
            clearInterval(this.interval);
            this.errProgressBarValue = 0;
            this.bufferValue = 0;
          }, removeMessageAfter)
        }
      }
    },
    upload() {
      const file = this.$refs.file.files[0]
      if (!file) {
        this.$func.popMessage('Upload a file in order to continue!','danger')
        return;
      }
      if (!this.selectRole){
        this.$func.popMessage('Chose at least one role in order to continue!','danger')
        return;
      }
      if (!this.selectedUploadType){
        this.$func.popMessage('Chose at least one role in order to continue!','danger')
        return;
      }
      const newFile = {
        original_file_name: file.name,
        mime_type: file.type,
        uploaded_by: this.currentUser.id,
        uploadedFile: this.uploadedFile,
      }
      const csvFile = [this.$refs.file.files[0]];
      //
      // const formData = new FormData();
      // const csvFile = [this.$refs.file.files[0]];
      // formData.append("uploaded_file", csvFile[0]);
      // formData.append('upload_type', 'payroll');
      //
      const allRoles = this.selectRole
      allRoles.push(1)
      const formData = new FormData();
      formData.append("uploaded_file", csvFile[0]);
      formData.append('upload_type', this.selectedUploadType.upload_type);
      formData.append('selected_roles', allRoles);
      formData.append('managing_firm', Number(this.managingFirm));
      this.loading=true
      ResourceService.postUploads(formData).then(()=>{
        this.$func.popMessage('File uploaded successfuly!','success')
        this.getData()
      })
    },
    getData() {
      ResourceService.getResources().then(res => {
        this.resourcesItems = res
        this.loading = false
      })
    },
    createPage() {
      this.$store.dispatch('pagebuilder/changePageData', {'pageName': this.pageName, 'pageOption': this.selectedPageOption});
      this.$router.push("/page-builder/0");
    },
    postFile() {


    },
    getAllLaws() {
				return LawService.getLaws()
					.then((data) => {
						this.laws=data;
					})
	  },
  },
  created() {
    // this.upload()
    this.getData()
    AuthService.getCurrentUser(this.currentUser.current_user_id)
        .then(res => {
          this.usersRole = res.data.role_id
        })

    ClientsService.getUsers().then(res => {
      this.roles = res.available_roles
    })
    this.getAllLaws()
  },
  watch: {
    // whenever question changes, this function will run
    checkbox1(newValue) {
      if (newValue === true) {
        this.checkbox2 = false
        this.checkbox1 = true
      } else if (newValue === false) {
        this.checkbox2 = true
        this.checkbox1 = false
      }
    },
    checkbox2(newValue) {
      if (newValue === true) {
        this.checkbox2 = true
        this.checkbox1 = false
      } else if (newValue === false) {
        this.checkbox2 = false
        this.checkbox1 = true
      }
    }
  },
  computed: {
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
        },
    )
  },
}
</script>
