<template>
  <div class="mt-2 ">
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div v-if="!loading && usersRole===1">

      <v-row>
        <v-col
            cols="12"
            md="9"
            class="pb-0"
        >
          <v-text-field
              id="searchInput"
              class="pt-5 mr-2"
              v-model="searchQuery"
              label="Search by last name, email address, or domain..."
              @keydown.enter="searchUsers"
          />
        </v-col>
        <v-col
            class="d-flex align-start"
            cols="12"
            md="2"
        >
          <v-btn
              id="searchBtn"
              @click.native="searchUsers"
              color="primary"
              rounded
              block
              large
          >
            Search
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="text-center mt-8" v-show="cards.length > 0 && !loading">
      <h4 class="text-center">These are users in your organization who you have given you permssion to log in as them.</h4>
      <p class="text-center">If you do not see someone who you need to log in as, ask them to deputize you from their permissions menu.</p>
    </div>
    <v-row ref="scrollComponent">
      <v-col class="text-center mt-8" v-show="cards.length < 1 && !loading && searched">
        <h4>Nothing found.</h4>
        <h4>Probably no one has deputuzed you to log in as them.</h4>
        <p>Contact the people whose accounts you would like to access, and ask them to deputize you.</p>
      </v-col>
      <v-col
          id="impersoanteUsersCont"
          v-for="(card, index) in cards"
          :key="card.title + '-' + index"
          :cols="12"
          :lg="6"
          md="6"
          sm="12"
          xs="12"
      >
        <v-hover v-slot="{ hover }">
          <v-card
              class="mx-auto"
              height="100%"
              :elevation="hover ? 10 : 2"
              :class="{ 'on-hover': hover }"
              link
              outlined
          >
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-5 font-weight-bold">
                  {{ card.fullname }}
                </v-list-item-title>

                <v-list-item-subtitle class="mb-3">
                  {{ card.email }}
                </v-list-item-subtitle>
                <v-spacer></v-spacer>

                <v-list-item-subtitle class="mb-3">
                  {{ card.entity_name }}
                </v-list-item-subtitle>

                <v-list-item-subtitle class="mb-3">
                  {{ card.role }}
                </v-list-item-subtitle>

                <v-list-item-subtitle class="mb-3">
                  {{ card.id }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-img :src="`${card.img}`" max-width="500px">
              </v-img>
            </v-list-item>

            <v-card-actions class="btn-action">
              <v-col class="text-right">
                <v-btn
                    text
                    class="mb-3"
                    color="#1867C0"
                    @click.stop="impersonate(card.id)">
                  <u> Impersonate </u>
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>

  import SearchService from "@/services/search.service";
  import AuthService from "@/services/auth.service";
  import {ref} from 'vue'
  import {mapGetters} from "vuex";

  export default{

    name: 'Impersonate',

    data: () => ({
      items: [],
      inputSearch: null,
      isAdmin: false,
      showResults: false,
      loadingSearch: false,
      select: null,
      users: [],
      loading: false,
      cards: [],
      allCards: [],
      usersRole:0,
      searchQuery:'',
      searched:false,
    }),

    watch: {
      select(val) {
        // console.log("1: " + val);

        if (val) {
          this.getCurrentUser(this.select);
        }
      },
      search(val) {

        val && val !== this.select && this.querySelections(val)
      },
    },
    computed: {
      ...mapGetters(
          {
            currentUser: 'auth/getCurrentUser',
          },
      ),
    },
    methods: {

      showAllCards(){
        return AuthService.getImpersonated()
            .then(async(res) => {
              this.searched = true
              this.cards = res;
              this.loading = false
            })
      },

      // handleCardsElements(){
      //   this.cards.push(this.allCards.slice(0,10))
      //   this.cards = this.cards.flat()
      //   this.allCards = this.allCards.slice(10)
      // },

      impersonate(userId) {
        this.$store.dispatch('auth/impersonateUser',userId).then((res) => {
          //this.$router.replace('/dashboard');
          AuthService.getCurrentUser(this.currentUser.current_user_id)
            .then(res=>{
                this.$router.replace('/dashboard');
            })
        }).catch(error => {
          this.$func.popMessage(`${error.response.data.error}`, 'fail')
        })
      },

      // querySelections(v) {
      //
      //   this.loading = true
      //
      //   setTimeout(() => {
      //     this.items = this.users.filter(e => {
      //       return (e.legal_name || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
      //     })
      //     this.loading = false
      //   }, 500)
      // },
      //
      // async searchFromApi() {
      //   if (this.inputSearch) {
      //     this.loadingSearch = true;
      //     this.showResults = false;
      //
      //     new Promise(resolve => {
      //       setTimeout(async () => {
      //         this.items = await SearchService.getSearchResults(this.inputSearch);
      //         this.showResults = true;
      //         this.loadingSearch = false;
      //
      //       }, 500);
      //     });
      //   }
      // },
      async getUser(){
        await AuthService.getCurrentUser(this.currentUser.current_user_id)
            .then(res=>{
              this.usersRole = res.data.role_id
            })
      },
      async searchUsers(){
        this.searched = true;
        this.loading = true;
        await AuthService.searchImpersonateUsers(this.searchQuery)
            .then(res=> {
              this.cards = res
              this.loading=false
            }).catch(err=>this.loading=false)
      },
      // handleScroll(){
      //   if (window.scrollY + window.innerHeight >= document.body.scrollHeight - 60){
      //     this.handleCardsElements()
      //   }
      // }
    },

    // mounted() {
    //   window.addEventListener("scroll", this.handleScroll);
    // },
    async created(){
      this.loading=true;
      await this.getUser();
      if (this.usersRole != 1){
        this.showAllCards()
      }
      this.loading = false
      //this.loading = true
      // this.showAllCards();
    }
  }

</script>

<style lang="scss" scoped>
  .btn-action{
    display: inline-block!important;
  }
</style>
