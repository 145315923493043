<template>
  <v-hover v-slot="{ hover }">
    <v-card style="cursor: pointer" :elevation="hover ? 8 : 3" :loading="loading" rounded outlined>
      <v-card-title class="d-flex justify-space-between">
        <slot name="title">Announcement {{index + 1}}</slot>
        <v-chip small color="warning" v-if="ann.new">UNSAVED</v-chip>
        <v-btn @click="removeUnsavedAnn(index)" v-if="ann.new" icon><v-icon color="black">mdi-delete</v-icon></v-btn>
        <v-menu transition="scroll-y-transition" offset-y left>
          <template #activator="{on, attrs}">
            <v-btn
                :loading="loading"
                v-if="!ann.new"
                color="black"
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <slot name="actions"/>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <span class="text-primary">
          Type: &nbsp;<v-chip small class="white--text" :color="ann.type">{{ann.type}}</v-chip>
        </span>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
export default {
  name: 'SingleAnnouncement',
  props: {
    ann: {
      type: Object,
      required: true
    },
    index: {
      required: true
    },
    loading: {
      required: true,
      default: false
    }
  },
  methods: {
    removeUnsavedAnn(index) {
      this.$emit('removeUnsavedAnn', index)
    },
  }

}
</script>

<style scoped>
.selected {
  background-color: #E7F4FF
}
</style>
