<template>
<div>
    <v-container><v-row><v-col>
        <v-data-table
    :headers="headers"
    :items="downlineData"
    class="elevation-1"
  ></v-data-table>
</v-col></v-row></v-container>

</div>
</template>
<script>
  import ClientsService from "@/services/clients.service";
  export default {
    data () {
      return {
        headers: [
          {
            text: 'First',
            align: 'start',
            sortable: false,
            value: 'first',
          },
          { text: 'Last', value: 'last' },
          { text: 'Level', value: 'level' },
          { text: 'Your commissions', value: 'total_production_formatted' }
        ],
        downlineData: [
//   {
//     "first": "Keith",
//     "last": "Bliss",
//     "level": 1,
//     "lead": 35,
//     "clients": 5,
//     "total_production_formatted": "$34,126.00"
//   },
//   {
//     "first": "Carolyn",
//     "last": "Casey",
//     "level": 1,
//     "lead": 8,
//     "clients": 71,
//     "total_production_formatted": "$21,946.00"
//   }
],
      }
    },
    methods:{
        getDownline(){
            ClientsService.getDownlineReport()
            .then((res)=>{
                this.downlineData = res
            }).catch((err)=> {
            this.$func.popMessage(`${err.response.data.error}`, 'fail')
          })
        }
    },
    created(){
        this.getDownline()
    }
  }
</script>
<style>
</style>
