import { render, staticRenderFns } from "./MandatePDF.vue?vue&type=template&id=2a259133&scoped=true&"
import script from "./MandatePDF.vue?vue&type=script&lang=js&"
export * from "./MandatePDF.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a259133",
  null
  
)

export default component.exports