<script>
import AdoptionsService from "@/services/adoptions.service";

export default {
  name: "IntroLetter",
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data:  () => ({
    selectedLetters: [],
    search: '',
    loading: false,
    category: 0,
    printLoading: false,
    markLoading: false,
    status: 3,
    headers: [
      {
        text: 'Name',
        filterable: true,
        value: 'name'
      },
      {
        text: 'Email',
        filterable: true,
        value: 'contact_email'
      },
      {
        text: 'Company Name',
        filterable: true,
        value: 'legal_name'
      },
      {
        text: 'Address',
        filterable: true,
        value: 'address'
      },
    ],
    letters: []
  }),
  methods: {
    async getData(filter) {
      this.loading = true
      this.selectedLetters = []

      try {
        this.letters = await AdoptionsService.getAdoptionStatus(this.entityId, this.status, filter)
      } finally {
        this.loading = false
      }
    },
    async markAsShipped() {
      this.markLoading = true

      const payload = {
        letter_stage: 2
      }

      try {
        for (let i = 0; i < this.selectedLetters.length; i++) {
          let client = this.selectedLetters[i]
          await AdoptionsService.patchAdoption(client.adoption_id, payload)
        }
      } finally {
        this.markLoading = false;
      }

      await this.getData(1)
    },
    async print() {
      this.printLoading = true

      const payload = {
        letter_stage: 1
      }

      try {
        for (let i = 0; i < this.selectedLetters.length; i++) {
          let client = this.selectedLetters[i]
          await AdoptionsService.patchAdoption(client.adoption_id, payload)
        }
      } finally {
        this.printLoading = false;
      }

      await this.getData()
    },

  },
  watch: {
    category(newValue) {
      this.getData(newValue)
    }
  },
  async mounted() {
    await this.getData()
  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-row>
            <v-col class="d-flex justify-center align-center">
              <v-radio-group row v-model="category">
                <v-radio label="New Category" :value="0"/>
                <v-radio label="Printed Category" :value="1" />
                <v-radio label="Shipped Category" :value="2"/>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :loading="loading"
              :headers="headers"
              :items="letters"
              :search="search"
              show-select
              v-model="selectedLetters"
            >
              <template #item.name="{ item }">
                {{ item.contact_first }} {{ item.contact_last }}
              </template>
              <template #item.address="{ item }">
                {{ item.state }}, {{ item.street }}
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions v-if="category === 0">
            <v-btn :disabled="!selectedLetters.length" @click="print" :loading="printLoading" color="primary">Print</v-btn>
          </v-card-actions>
          <v-card-actions v-else-if="category === 1">
            <div class="d-flex justify-space-between align-center" style="width: 100%">
              <v-btn color="primary" :disabled="!selectedLetters.length">Download Letter Template</v-btn>
              <v-btn color="primary" :disabled="!selectedLetters.length" @click="markAsShipped" :loading="markLoading">Mark as Shipped</v-btn>
              <v-btn color="primary" :disabled="!selectedLetters.length">Export to CSV</v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>