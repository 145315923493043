var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{staticClass:"py-4 px-5",attrs:{"rounded":"xl","outlined":""}},[_c('v-row',[_c('v-col',[_c('p',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.status))])])],1),_vm._l((_vm.data),function(mandate,i){return _c('v-row',{key:i,staticClass:"mt-3",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","xs":"12","sm":"12"}},[_c('v-card',{class:{rainbowGradient : _vm.status == 'Blocked' && mandate.priority >= 100},style:({ boxShadow: _vm.styles.shadow }),attrs:{"rounded":"lg","outlined":"","to":`/mandate/${mandate.mandate_id}`}},[_c('v-tooltip',{attrs:{"disabled":mandate.review_status_id !== 7,"bottom":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-card-text',_vm._g({staticClass:"text-center pa-0 font-weight-medium",staticStyle:{"color":"black"}},on),[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('div',{staticClass:"d-flex flex-column px-2 py-5 fill-height",style:(_vm.isMobile
                        ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
                        : 'border-right: 1px solid rgba(0, 0, 0, 0.1)')},[_vm._v(" "+_vm._s(mandate.state_code ?? 'N/A')+" "),(_vm.status === 'Escalated' && mandate.priority_reason)?_c('v-chip',{staticClass:"text-caption mt-2 align-self-center",staticStyle:{"width":"min-content"},attrs:{"text-color":"white","color":_vm.styles.chipColor}},[_vm._v(" "+_vm._s(mandate.priority_reason)+" ")]):(_vm.status === 'Blocked' && _vm.isMobile)?_c('div',{staticClass:"text-caption",staticStyle:{"color":"#ff3355"}},[_vm._v(" "+_vm._s(mandate.review_status_reason)+" ")]):_vm._e()],1)]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex justify-center flex-column fill-height pa-2",style:(_vm.isMobile
                        ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
                        : 'border-right: 1px solid rgba(0, 0, 0, 0.1)')},[(!mandate.publication_date)?_c('p',{staticClass:"text-caption",staticStyle:{"color":"rgb(128, 128, 128)"}},[_vm._v(" No data for publication date ")]):_c('div',[_vm._v(" "+_vm._s(mandate.publication_date)+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex justify-center flex-column fill-height pa-2",style:(_vm.isMobile
                        ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
                        : 'border-right: 1px solid rgba(0, 0, 0, 0.1)')},[(!mandate.author)?_c('p',{staticClass:"text-caption",staticStyle:{"color":"rgb(128, 128, 128)"}},[_vm._v(" No data for author ")]):_c('h5',[_vm._v(" "+_vm._s(mandate.author)+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex justify-center flex-column fill-height pa-2"},[(!mandate.title)?_c('p',{staticClass:"text-caption",staticStyle:{"color":"rgb(128, 128, 128)"}},[_vm._v(" No data for title ")]):_c('h5',[_vm._v(" "+_vm._s(mandate.title)+" ")])])]),_c('v-col',{staticClass:"py-4",attrs:{"cols":"12","md":"2","align-self":"center"}},[_c('v-btn',{attrs:{"loading":mandate.checkLoading,"color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.checkMandate(mandate, i)}}},[_vm._v("Check "+_vm._s(`${ mandate.review_status_id === 5 && (_vm.userIsMasterTaxAttorney || _vm.currentUser.current_user_id === mandate.checked_out_to) ? "in" : "out" }`)+" ")])],1)],1)],1)]}}],null,true)},[(mandate.review_status_id === 7)?_c('span',[_vm._v(_vm._s(mandate.review_status_reason))]):_vm._e()])],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }