import $axios from "../plugins/axios";

class MandateService {
  uploadMandate(mandate) {
    return $axios.post("/mandates/uploads", mandate).then((res) => res.data);
  }

  getBusinessImpact(params) {
    return $axios.get("/eligibility/tests", { params }).then((res) => res.data);
  }

  getIndustries(params) {
    return $axios.get("/naics/codes", { params }).then((res) => res.data);
  }

  getStates(params) {
    return $axios.get("/mandates/states", { params }).then((res) => res.data);
  }

  getCounties(statedId, params) {
    return $axios.get(`/mandates/states/${statedId}/counties`, { params }).then((res) => res.data);
  }

  getCities(statedId, params) {
    return $axios.get(`/mandates/states/${statedId}/cities`, { params }).then((res) => res.data);
  }

  postMandate(payload) {
    return $axios.post("/mandates", payload).then((res) => res.data);
  }

  updateMandate(id, payload) {
    return $axios.put(`/mandates/${id}`, payload).then((res) => res.data);
  }

  patchMandate(id, payload) {
    return $axios.patch(`/mandates/${id}`, payload).then((res) => res.data);
  }

  getMandate(id) {
    return $axios.get(`/mandates/${id}`).then((res) => res.data);
  }

  updateIndividualMandate(id, payload) {
    return $axios.patch(`/mandates/queues/review/${id}`, payload).then((res) => res.data);
  }

  getMandatesReviewQueue() {
    return $axios.get("/mandates/queues/review").then((res) => res.data);
  }

  getMandateInReviewQueueById(id) {
    return $axios.get(`/mandates/queues/review/${id}`).then((res) => res.data);
  }

  deleteMandateInReviewQueueById(id) {
    return $axios.delete(`/mandates/queues/review/${id}`).then((res) => res.data);
  }

  postingNote(id, body) {
    return $axios.post(`/mandates/${id}/notes`, body).then((res) => res.data);
  }

  deleteNote(id) {
    return $axios.delete(`mandates/queues/notes/${id}`).then((res) => res.data);
  }

  // editingNote(mandateID, noteID, body) {
  //   return $axios.patch(`/mandates/${mandateID}/notes/${noteID}`, body).then((res) => res.data);
  // }

  getNote(id) {
    return $axios.get(`/mandates/${id}/notes`).then((res) => res.data);
  }

  searchMandates(params) {
    return $axios.get(`/mandates?q=${params}`).then((res) => res.data);
  }
  getReport() {
    return $axios.get(`/reports/qualifications`).then((res) => res.data);
  }

  deleteQualification(id) {
    return $axios.delete(`/queues/qualification/${id}`).then((res) => res.data);
  }
}

export default new MandateService();
