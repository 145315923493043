<template>
<div>
  <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1 mt-5"
      hide-default-footer
      disable-pagination
  ><template v-slot:item.legal_name="{ item }">
    <div style="cursor: pointer" @click="goToClient(item.id)">
      {{item.legal_name}}
    </div>
  </template>

    <template v-slot:item.trade_name="{ item }">
      <div style="cursor: pointer" @click="goToClient(item.id)">
        {{item.trade_name}}
      </div>
    </template></v-data-table>
  <ProfileClientDialog
      v-if="showRightDialogChosenClient"
      :key="dialogKeyChosenClient"
      :showRightDialogChosenClient.sync="showRightDialogChosenClient"
      :clientProp="clientProp"
      @openClientProfileDialog="openClientProfileDialog"

  />
</div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import ProfileClientDialog from "@/components/Client/ProfileClientDialog.vue";
import ClientService from "@/services/clients.service";
export default {
  name: "PeoClients",
  components: {ProfileClientDialog},
  data:()=>({
    showRightDialogChosenClient:false,
    dialogKeyChosenClient:33,
    clientId:'',
    clientProp:{},
    loading:false,
    selectedClient:{},
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      { text: 'Attorney of record', value: 'aor' },
      { text: 'Legal name', value: 'legal_name' },
      { text: 'Trade name', value: 'trade_name' },
      { text: 'ein', value: 'ein' },
      { text: 'Document score', value: 'document_score' },
      { text: 'Days in system', value: 'days_in_system' },
    ],
    items:[],
  }),
  methods:{
    goToClient(id){
      this.loading = true
      this.$router.push(`/clients/${id}/profile`)
    },
    openClientProfileDialog() {
      this.showRightDialogChosenClient = true;
      this.dialogKeyChosenClient++;
      this.loading = false
      this.showRightDialog = false;
    },
    getData(){
      ClientsService.getPeoClients()
          .then(res=>this.items=res)
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
