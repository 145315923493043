import $axios from '../plugins/axios'

class ResourceService {

    getMPPerformance(){      
        return $axios.get(`/reports/performance/contractors`)
            .then(res => {
                return res.data;
            })
    }
    getResources(){
        return $axios.get(`/resources`)
            .then(res => {

                return res.data;
            })
    }

    postUploads(data){
        return $axios.post(`/resources/uploads`,data)
            .then(res => {

                return res.data;
            })
    }

    postPage(data,filter){
        return $axios.get(`/resources/page/${filter}`,data)
            .then(res => {

                return res.data;
            })
    }
    getResourcePage(id){
        return $axios.get(`/resources/pages/${id}`)
            .then(res => {

                return res.data;
            })
    }

}
// $router->map('POST', '/resources/uploads', PostUploadsService::class);
// $router->map('GET', '/resources', ResourcesService::class);
// $router->map('POST', '/resources/page/{filter:word}', ResourcesPageService::class);
export default new ResourceService()
