<template>
	<div class="mt-2 ">



			<EntitiesDialog
			v-if="showManagingEntities"
			:key="dialogKey"
			:show-right-dialog-entity="showManagingEntities"
			/>


	</div>

</template>

<script>
	import EntitiesDialog from "../components/Entites/EntitiesDialog.vue"

	export default {
		name: 'Entities',
		components: {
			EntitiesDialog
		},
		data: () => ({
			showManagingEntities: false,
			dialogKey: 0,
			loading: false,
			items: []
		}),
		methods: {
			openEntitiesClientDialog(){
				this.showManagingEntities = !this.showManagingEntities;
				//console.log(this.showManagingEntities)
			},
		},
		mounted(){
			this.openEntitiesClientDialog();
		}
	}
</script>

<style lang="scss" scoped>

	#lateral .v-btn--example {
		bottom: 0;
		right: 0;
		position: absolute;
		margin: 0 0 16px 16px;
	}

</style>
