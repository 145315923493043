<template>
  <div>

    <DialogRightAlign :showDialog.sync="showRightDialogAddUser" :titleProp="`Add User`">
      <v-overlay :value="loading">
        <v-progress-circular
            :size="70"
            :width="7"
            color="white"
            indeterminate
        ></v-progress-circular>
      </v-overlay>
      <v-card-text class="mt-10">
        <v-container>
          <v-row class="d-flex justify-center">
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-autocomplete
                  id="entitySelectionList"
                  v-model="addUserForm.parent_entity"
                  :items="entityOptions"
                  @change="getAvailableRoles"
                  item-text="name"
                  item-value="id"
                  label="Search"
              ></v-autocomplete>
              <!--						<v-select-->
              <!--							v-model="addUserForm.parent_entity"-->
              <!--							:items="entityOptions"-->
              <!--							item-text="name"-->
              <!--							item-value="id"-->
              <!--              class="mb-3"-->
              <!--							label="Entity"-->
              <!--							required-->
              <!--							@blur="$v.addUserForm.parent_entity.$touch()"-->
              <!--							dense-->
              <!--						></v-select>-->

              <v-select
                  id="roleSelection"
                  v-model="addUserForm.role"
                  :items="roles"
                  item-text="role_name"
                  item-value="role_id"
                  label="Select a role for this user"
                  required
                  :error-messages="roleErrors"
                  @blur="$v.addUserForm.role.$touch()"
                  dense
              ></v-select>

              <v-row class="d-flex justify-center">
                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    style="padding-bottom: 0;"
                >
                  <v-text-field
                      id="userFirstName"
                      class="auth-form__field"
                      required
                      placeholder="First Name"
                      v-model="addUserForm.first"
                      :error-messages="firstNameErrors"
                      @blur="$v.addUserForm.first.$touch()"
                  />
                </v-col>

                <v-col
                    cols="12"
                    sm="12"
                    md="6"
                    class="pb-sm-0 pt-sm-0"
                >
                  <v-text-field
                      id="userLastName"
                      class="auth-form__field pt-sm-0 pt-md-6"
                      required
                      placeholder="Last Name"
                      v-model="addUserForm.last"
                      :error-messages="firstNameErrors"
                      @blur="$v.addUserForm.last.$touch()"
                  />
                </v-col>
              </v-row>

              <v-text-field
                  id="userEmail"
                  v-model="addUserForm.email"
                  :error-messages="emailErrors"
                  label="Email"
                  required
                  @blur="$v.addUserForm.email.$touch()"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="#263B4F"
            text
            @click.native="closeDialog"
        >
          cancel
        </v-btn>

        <v-btn
            id="createUserBtn"
            color="#263B4F"
            text
            @click.native="save"
            :disabled="$v.addUserForm.$invalid"
        >
          create user
        </v-btn>
      </v-card-actions>

    </DialogRightAlign>
  </div>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import {validationMixin} from "vuelidate";
import ClientsService from "@/services/clients.service";
import AuthService from "@/services/auth.service";
import DialogRightAlign from "../Base/DialogRightAlign";
import {mapGetters} from "vuex";

export default {
  name: "UserAddDialog",
  components: {DialogRightAlign},
  validations: {

    addUserForm: {
      parent_entity: {required},
      first: {required},
      last: {required},
      email: {required, email},
      role: {required},
    },
  },

  data: () => ({
    usersRole: null,
    loading: false,
    buttonLoadingSpinner: false,

    addUserForm: {
      parent_entity: '',
      email: '',
      first: '',
      last: '',
      role: '',
    },

    entityOptions: [],
    roles: []
  }),

  methods: {

    closeDialog() {
      this.$emit('update:showRightDialogAddUser', false);
    },

    save() {
      this.loading = true;
      let {parent_entity, ...employeeRest} = this.addUserForm;
      if (typeof parent_entity == 'object'){
        parent_entity=parent_entity.id
      }
      // if ()
      ClientsService.createNewUser(parent_entity, employeeRest)
          .then(res => {
            this.$func.popMessage('User created successfully', 'success')
            this.closeDialog()

          }).catch(() => this.loading = false)
    },
    getAvailableRoles(item) {
      if (item){
      // console.log(item)
      ClientsService.getEntitiesFiltered(item)
          .then(res=>{
            this.addUserForm.parent_entity = res
            this.roles = res.available_roles
          })
        }
    }

  },

  computed: {

    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
        },
    ),
    emailErrors() {

      const errors = [];
      if (!this.$v.addUserForm.email.$dirty) return errors;
      !this.$v.addUserForm.email.email && errors.push('Must be valid e-mail');
      !this.$v.addUserForm.email.required && errors.push('E-mail is required');
      return errors;
    },

    roleErrors() {
      const errors = [];
      if (!this.$v.addUserForm.role.$dirty) return errors;
      !this.$v.addUserForm.role.required && errors.push('Role is required');
      return errors;
    },

    firstNameErrors() {
      const errors = []
      if (!this.$v.addUserForm.first.$dirty) return errors
      !this.$v.addUserForm.first.required && errors.push('Name is required.')
      return errors
    },

    lastNameErrors() {
      const errors = []
      if (!this.$v.addUserForm.last.$dirty) return errors
      !this.$v.addUserForm.last.required && errors.push('Name is required.')
      return errors
    },
  },
  async created() {
    this.roles = this.availableRoles

    await AuthService.getCurrentUser(this.currentUser.current_user_id)
        .then(res => {
          this.usersRole = res.data.role_id
        })

    if (this.usersRole === 1) {
      await ClientsService.getEntitiesFiltered('all')
          .then(res => {
            this.entityOptions = res
            if (this.entityOptions.length === 1) {
              this.addUserForm.parent_entity = this.entityOptions[0].id
            }
          })
    } else {
      await ClientsService.getEntitiesFiltered('my')
          .then(res => {
            this.entityOptions = [res]
            this.addUserForm.parent_entity = this.entityOptions[0].id
            this.getAvailableRoles()
          })
    }
  },

  props: {
    showRightDialogAddUser: {
      type: Boolean,
      default: false
    },
    availableRoles: {
      type: Array,
      default: []
    },
  },
};
</script>

<style scoped>

</style>
