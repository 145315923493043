<template>
      <v-row class="mb-2">
        <v-col cols="auto">
          <v-avatar color="#E7E7E7">{{initials}}</v-avatar>
        </v-col>
        <v-col>
          <v-card outlined :style="[subtaskClosed ? {'background-color': '#b2b2b2'} : '']">
            <v-card-title class="py-1 px-2">
              <span v-if="!isDescription"  class="text-caption font-weight-bold">
                {{author}}
              </span>
              &nbsp;
              <span class="text-caption font-weight-light">{{date}}</span>

              <v-icon v-if="currentUser.user_id == authorID && !subtaskClosed" style="cursor:pointer;" @click="openEditDesc">mdi-pencil</v-icon>

            </v-card-title>
            <v-divider v-if="body"/>
            <v-card-text v-if="body">
              <span v-if="!isEditing" class="text--primary" style="white-space: pre-line" v-html="formattedText" />
              <v-text-field v-if="isEditing" outlined v-model="description">
                <template v-slot:append>
                  <v-icon  @click="patchTask" style="color: #4CAF50;font-size: 30px;cursor:pointer; ">mdi-send-circle</v-icon>
                </template>
              </v-text-field>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
</template>

<script>
import TasksService from "@/services/tasks.service";
import {mapGetters} from "vuex";

export default {
  name: "TranscriptQueueTaskNote",
  props: ["taskID","authorID", "author", "body", 'created_on', 'isDescription','subtaskClosed'],
  data: () => ({
    taggedUserRegex: /@(\w+\s+\w+)(&#39;)?\w+/g,
    isEditing: false,
    description: ''
  }),
  methods: {
    openEditDesc(){
      this.isEditing = true
      this.description = this.body
    },
    patchTask(){
      TasksService.patchClientTask(this.taskID, {description: this.description},'description').then((res) => {
        this.isEditing = false
        this.body = this.description
        this.$func.popMessage('Description changed successfully!', 'success')
        this.$emit('updateTasks')
      })
    }
  },
  computed: {
    initials() {
      if (typeof this.author === 'string')
        return this.author.split(' ').map((name) => name[0]).join("");
      else
        return 'N/A'
    },
    date() {
      let date = new Date(this.created_on)

      if (date instanceof Date && !isNaN(date))
        return `${date.toDateString()} ${date.toLocaleTimeString()}`
      else
        return this.created_on
    },
    formattedText() {
      return this.body.replace(this.taggedUserRegex, '<span class="tagged-user">$&</span>');
    },
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  }
};
</script>

<style>
.tagged-user {
  color: green;
  background-color: rgba(128, 128, 128, 0.2);
}
</style>
