<template>
  <v-container>
    <v-row class="mt-5">
      <v-hover v-for="(item,index) in items" :key="index" v-slot="{ hover }">
        <v-card @click="goToQueue(item.url)" :style="`width: ${$vuetify.breakpoint.lgAndUp ? '30' : '100'}%`" class="mr-2 mb-2 pa-2" :elevation="hover ? 16 : 2">
          <v-card-text><h2>{{item.display_name}}</h2></v-card-text>
        </v-card>
      </v-hover>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Queues',
  data: () => ({
    items: [
      {
        url: '/queues/qualification',
        display_name: 'Qualification Review'
      },
      {
        url: '/queues/transcript',
        display_name: 'Transcript Queue'
      }
    ]
  }),
  methods: {
    goToQueue(url){
      this.$router.push(url)
    }
  }
}
</script>