import $axios from '../plugins/axios'
import VueCookies from "vue-cookies";
import jwt_decode from "jwt-decode";

class AuthService {

	// getMe() {
	//
	// 	return $axios.get('me')
	// 		.then((res) => {
	//
	// 			return res.data;
	// 		}).catch((error) => {
	//
	// 			return new Promise((resolve, reject) => {
	// 				return reject(error)
	// 			})
	// 		})
	// }

	register(path, user) {

		return $axios.post(path, user)
			.then(res => {

				return res.data;
			})
	}

	// register(data) {
	//
	// 	$axios.post(data.path, data.user)
	// 		.then(res => {
	//
	// 			this.login(user);
	//
	// 			this.$store.dispatch('snackbar/showSnackbarMessage ',
	// 				{message: 'Registered successfully.', duration: 4000, mode: 'success'});
	// 		})
	// 		.catch(error => {
	//
	// 			this.$store.dispatch('snackbar/showSnackbarMessage ',
	// 				{message: error.response.data, duration: 4000, mode: 'fail'});
	// 		});
	// },

	login(user) {

		return $axios.post('auth/login', {
			user: user.email,
			pass: user.password
		}).then((res) => {
			return res.data.token;
		}).catch((error) => {
			_this.$store.dispatch("snackbar/showSnackbarMessage", {
				message: error.response.data.error,
				duration: 6000,
				mode: "fail",
			  });
		})
	}

	loginAfterRegistration(user) {

		return $axios.post('auth/login', {
			user: user.user,
			pass: user.pass,
			after_registration: true
		}).then((res) => {

			return res.data.token;
		}).catch((error) => {
			this.$func.popMessage(`${error.response.data.error}`, 'fail')
		})
	}

	signUp(user) {

		return $axios.post('users', user)
			.then(res => {

				return res
			});
	}

	getCurrentUser(id) {
		return $axios.get(`auth/users/${id}`)
			.then(res => {
				return res
			});
	}


	getCurrentUserFromCookie() {
		let cookie = VueCookies.get('current_user');
		let decodeData = jwt_decode(cookie);

		return $axios.get(`auth/users/${decodeData.current_user_id}`)
			.then(res => {
				return res
			});
	}

	impersonate(userID) {
		return $axios.post(`auth/impersonate/` + userID)
			.then(res => {
				return res.data;
			});
	}

	getUserNav(userId) {
		return $axios.get(`auth/users/${userId}/nav`).then(
			res => {
				return res.data;
			}
		)
	}

	activateAcc(password, postParams) {
		return $axios.post(`auth/users/activate/${postParams}`, password)
			.then(res => {
				return res.data;
			});
	}

	resetPassword(password, uuid) {
		return $axios.post(`auth/users/recover/${uuid}`, password)
			.then(res => {
				return res.data;
			});
	}

	loginAfterActivate(pass, user) {
		return $axios.post(`auth/login`, {
			user: user,
			pass: pass
		}).then((res) => {

			return res.data.token;
		}).catch((error) => {
			this.$func.popMessage(`${error.response.data.error}`, 'fail')
		})
	}

	getMyEntities() {
		return $axios.get(`entities/my`)
			.then((res) => {

				return res.data;
			});
	}

	checkForPermissions() {
		return $axios.get(`auth/impersonate/permissions`)
			.then((res) => {

				return res.data;
			});
	}

	setPermissions(permissioned) {
		return $axios.post(`auth/impersonate/permissions`, permissioned)
			.then((res) => {

				return res.data;
			});
	}

	getImpersonated() {
		return $axios.get(`/auth/impersonate/users`)
			.then((res) => {

				return res.data;
			});
	}

	depersonate() {
		return $axios.post(`auth/depersonate/`)
			.then(res => {
				return res.data;
			});
	}

	checkLinkExpiration(uuid) {
		return $axios.get(`auth/nonce/${uuid}`).then(
			res => {
				return res.data;
			}
		)
	}

	getMaintenanceStatus() {
		return $axios.get(`status`)
			.then(res => {
				return res.data;
			});
	}
	searchImpersonateUsers(searchTerm) {
		return $axios.get('/auth/impersonate/users/search', {
			params: {
				q: searchTerm,
			}
		})
			.then((res) => {
				return res.data;
			});
	}
}

export default new AuthService();
