<template>
  <v-container>
    <v-row align="center">
      <v-col cols="auto">
        <v-btn icon @click="exitEditing">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col class="d-flex align-center">
        <v-avatar color="blue" class="mr-3">
          <v-icon color="indigo darken-3" dark> mdi-account</v-icon>
        </v-avatar>
        <h3>{{ `${user.first} ${user.last}` }}</h3>
      </v-col>
    </v-row>
    <v-card outlined rounded elevation="2" width="600px" class="pa-7 mt-5">
      <v-form v-model="isValid">
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Role:</v-col>
          <v-col cols="12" md="9">
            <v-select
              v-model="editedUser.role_id"
              :items="availableRoles"
              item-text="role_name"
              label="Role"
              item-value="role_id"
              outlined
            />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">First Name:</v-col>
          <v-col cols="12" md="9">
            <v-text-field v-model="editedUser.first" outlined />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Last Name:</v-col>
          <v-col cols="12" md="9">
            <v-text-field :rules="[rules.required]" v-model="editedUser.last" outlined />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Email:</v-col>
          <v-col cols="12" md="9">
            <v-text-field
              :rules="[rules.required, rules.email]"
              v-model="editedUser.email"
              outlined
            />
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="mt-5">
        <v-row justify="space-between">
          <v-col cols="4">
            <v-btn @click="showDialog = true" block color="error">Delete</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn
              @click="saveUser"
              :disabled="!isValid || !changed"
              :loading="editLoading"
              block
              color="primary"
              >Save
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="showDialog" persistent max-width="600px">
      <v-card>
        <v-card-title
          class="text-h5 font-weight-bold"
          style="background-color: #263b4f; color: white"
        >
          Delete User
        </v-card-title>
        <v-card-text class="mt-10 red--text" style="font-size: 1.2rem"
          >Are you sure you want to delete this User?
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="showDialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" :loading="deleteLoading" text @click="deleteUser()"
            >Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "EditUserCard",
  props: ["user", "availableRoles"],
  data: () => ({
    showDialog: false,
    editLoading: false,
    deleteLoading: false,
    editedUser: {},
    rules: {
      required: (value) => !!value || "This field is required.",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    isValid: false,
  }),
  methods: {
    exitEditing(refresh = false) {
      this.$emit("exitEditingUser", refresh);
    },
    saveUser() {
      this.editLoading = true;
      const editFormModel = {
        email: this.editedUser.email,
        first: this.editedUser.first,
        last: this.editedUser.last,
        role: this.editedUser.role_id,
      };
      ClientsService.editUser(this.user.id, editFormModel)
        .then((res) => {
          this.$func.popMessage("User edited successfully", "success");
          this.exitEditing(true);
        })
        .finally(() => {
          this.editLoading = false;
        });
    },
    deleteUser() {
      this.deleteLoading = true;
      ClientsService.deleteUser(this.user.id)
        .then((res) => {
          this.$func.popMessage("User deleted successfully", "success");
          this.exitEditing(true);
        })
        .finally(() => (this.deleteLoading = false));
    },
  },
  created() {
    this.editedUser = JSON.parse(JSON.stringify(this.user));
  },
  computed: {
    changed() {
      return (
        this.editedUser.role_id !== this.user.role_id ||
        this.editedUser.first !== this.user.first ||
        this.editedUser.last !== this.user.last ||
        this.editedUser.email !== this.user.email
      );
    },
  },
};
</script>
