<template>
  <div>
    <!-- Mobile/Tablet navigation -->
    <v-menu offset-y class="hidden-md-and-up">
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar
          elevation="4"
          rounded
          shaped
          v-bind="attrs"
          v-on="on"
          class="hidden-md-and-up mb-3"
        >
          <v-app-bar-nav-icon></v-app-bar-nav-icon>
          {{ tabName }}
        </v-app-bar>
      </template>

      <v-list dense id="navMenu" class="hidden-md-and-up">
        <v-list-item link @click="() => $emit('changeNavTab', 1)">
          <v-list-item-content>
            <v-list-item-title>PROFILE</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="() => $emit('changeNavTab', 2)">
          <v-list-item-content>
            <v-list-item-title>UPLOADS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          v-if="client.uses_peo"
          @click="() => $emit('changeNavTab', 9)"
        >
          <v-list-item-content>
            <v-list-item-title>PEO DOCUMENTS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="() => $emit('changeNavTab', 3)">
          <v-list-item-content>
            <v-list-item-title>NOTES</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="() => $emit('changeNavTab', 4)">
          <v-list-item-content>
            <v-list-item-title>SUBMISSION</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="usersRole !== 6"
          link
          @click="() => $emit('changeNavTab', 5)"
        >
          <v-list-item-content>
            <v-list-item-title>DOWNLOADS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="usersRole === 1"
          link
          @click="() => $emit('changeNavTab', 6)"
        >
          <v-list-item-content>
            <v-list-item-title>ADMIN</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="() => $emit('changeNavTab', 7)">
          <v-list-item-content>
            <v-list-item-title>STATUS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="usersRole === 1 || usersRole === 4"
          link
          @click="() => $emit('changeNavTab', 8)"
        >
          <v-list-item-content>
            <v-list-item-title>TOOLS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="usersRole === 1"
          link
          @click="() => $emit('changeNavTab', 10)"
        >
          <v-list-item-content>
            <v-list-item-title>WORKFLOW</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Desktop navigation -->
    <v-slide-group
      show-arrows
      id="admin-tab"
      class="hidden-sm-and-down visible-sm-up"
    >
      <v-tabs id="admin-tabs-slider">
        <v-tab
          v-model="active_tab"
          style="padding: 0 !important"
          @click="() => $emit('changeNavTab', 1)"
          >PROFILE</v-tab
        >
        <v-tab
          v-model="active_tab"
          style="padding: 0 !important"
          @click="() => $emit('changeNavTab', 2)"
          >UPLOADS</v-tab
        >
        <v-tab
          v-model="active_tab"
          v-if="client.uses_peo && (this.usersRole == 1 || this.usersRole == 7)"
          style="padding: 0 !important"
          @click="() => $emit('changeNavTab', 9)"
          >PEO DOCUMENTS
        </v-tab>
        <v-tab
          v-model="active_tab"
          style="padding: 0 !important"
          @click="() => $emit('changeNavTab', 3)"
          >NOTES</v-tab
        >
        <v-tab
          v-model="active_tab"
          style="padding: 5px !important"
          @click="() => $emit('changeNavTab', 4)"
          >SUBMISSION</v-tab
        >
        <v-tab
          v-model="active_tab"
          v-if="usersRole !== 6 && usersRole !== 12"
          style="padding: 5px !important"
          @click="() => $emit('changeNavTab', 5)"
          >DOWNLOADS
        </v-tab>
        <v-tab
          v-model="active_tab"
          v-if="usersRole === 1"
          style="padding: 0 !important"
          @click="() => $emit('changeNavTab', 6)"
        >
          ADMIN
        </v-tab>
        <v-tab
          v-model="active_tab"
          v-if="usersRole !== 12"
          style="padding: 0 !important"
          @click="() => $emit('changeNavTab', 7)"
        >
          STATUS
        </v-tab>
        <v-tab
          v-model="active_tab"
          v-if="usersRole === 1 || usersRole === 4"
          style="padding: 0 !important"
          @click="() => $emit('changeNavTab', 8)"
          >TOOLS
        </v-tab>
        <v-tab
          v-model="active_tab"
          v-if="usersRole === 1"
          style="padding: 0 !important"
          @click="() => $emit('changeNavTab', 10)"
          >WORKFLOW
        </v-tab>
      </v-tabs>
    </v-slide-group>
  </div>
</template>
<script>
export default {
  name: "ProfileNavMenu",
  props: {
    active_tab: {},
    changeNavTab: {},
    client: {},
    tabName: {},
    usersRole: {},
  },
};
</script>
<style lang="scss">
::v-deep .theme--light.v-input--is-disabled input,
.theme--light.v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

::v-deep .v-text-field__slot input::-webkit-outer-spin-button,
::v-deep .v-text-field__slot input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
::v-deep .v-text-field__slot input[type="number"] {
  -moz-appearance: textfield !important;
}

#navMenu {
  background-color: #ffffff !important;
}
</style>
