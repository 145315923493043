<template>
  <div>
    <ClientHeader :client="client" />
    <v-row v-if="loading">
      <v-col cols="12" md="4"><v-skeleton-loader type="card" /></v-col>
      <v-col cols="12" md="8"><v-skeleton-loader type="card"/></v-col>
    </v-row>
    <v-row v-else-if="!loading && transcriptQueue.tasks.length" class="fill-height">
      <v-col v-if="selectedTaskIdx === null || !isMobile" cols="12" md="4" class="d-flex flex-column" style="gap: 10px">
        <div @click="selectTask(i)" v-for="(task, i) in transcriptQueue.tasks" :key="task.id">
          <TranscriptQueueTask
              :class="{ selected: selectedTaskIdx === i }"
              :task="task"
              :index="i"
              :loading="loadingTask[i]"
              :isAOR="true"
          >
            <template #title>
              <span v-if="task.type_id === 1">Work on task</span>
              <span v-else>Upload Document</span>
            </template>
            <template #actions>
              <v-list dense>
                <div v-for="status in statuses" :key="status.id">
                  <v-list-item @click="changeStatus(task.id, status.id, i)">{{status.name}}</v-list-item>
                  <v-divider />
                </div>
              </v-list>
            </template>
          </TranscriptQueueTask>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex align-center d-md-none" v-if="transcriptQueue.tasks[selectedTaskIdx]">
        <v-btn @click="selectedTaskIdx = null" icon>
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
        <span class="font-weight-bold">{{transcriptQueue.tasks[selectedTaskIdx].type_id === 1 ? 'Work on task' : 'Upload Document'}}</span>
      </v-col>
      <v-col cols="12" md="8" v-if="transcriptQueue.tasks[selectedTaskIdx]">
        <TranscriptQueueTaskDetails :task="transcriptQueue.tasks[selectedTaskIdx]">
          <template #file-upload>
            <v-row>
              <v-col cols="12">
                <div v-if="fileUploadLoading" class="pt-10">
                  <h2 class="text-center">Files are uploading please wait...</h2>
                  <v-progress-circular indeterminate></v-progress-circular>
                </div>
                <div style="padding-top: 20px" v-if="transcriptQueue.tasks[selectedTaskIdx].type_id === 2 && !fileUploadLoading">
                  <div style="background: #f8f8ff; width: 80%; margin: auto; padding: 33px;">
                    <div
                        id="drop-area"
                        @dragover.prevent
                        @drop="handleDrop"
                        @dragenter="dragEnter"
                        @dragleave="dragLeave"
                        class="drop-area"
                    >
                      <input
                          type="file"
                          name="fields[assetsFieldHandleUploads][]"
                          id="assetsFieldHandleUploads"
                          class="d-none absolute"
                          @input="uploadFiles"
                          ref="file"
                          accept="application/*"
                          multiple

                      />

                      <label for="assetsFieldHandleUploads" class="block cursor-pointer">
                    <span style="cursor: pointer" class="d-flex flex-column align-center">
                      <v-icon style="font-size: 5rem">mdi-upload</v-icon>
                      <b class="text-h6">Drag and drop files or Browse</b>
                    </span>
                      </label>
                    </div>
                  </div>
                  <div class="d-flex justify-center mt-3">
                    <v-btn @click="openFileDialog" class="primary">Upload Files</v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </template>
          <template #new-note>
            <v-row>
              <v-col cols="auto"><v-avatar color="#E7E7E7">{{getInitials(`${user.first} ${user.last}`)}}</v-avatar></v-col>
              <v-col>
                <v-card outlined>
                  <v-card-text>
                    <v-textarea v-model="noteDescription" filled label="New Note" />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="auto"><v-btn color="primary" @click="postNote" :disabled="submitNoteLoading">Answer</v-btn></v-col>
            </v-row>
          </template>
        </TranscriptQueueTaskDetails>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="4">
        <v-alert
            colored-border
            elevation="2"
            type="info"
        >
          There are no tasks currently!
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ClientHeader from "@/components/Client/ClientHeader.vue";
import ClientService from "@/services/clients.service";
import TranscriptQueueTask from "@/components/TranscriptQueue/TranscriptQueueTask.vue";
import TranscriptQueueTaskNote from "@/components/TranscriptQueue/TranscriptQueueTaskNote.vue";
import TranscriptService from "@/services/transcript.service";
import {mapGetters} from "vuex";
import AuthService from "@/services/auth.service";
import TranscriptQueueTaskDetails from "@/components/TranscriptQueue/TranscriptQueueTaskDetails.vue";
export default {
  name: "SingleTranscriptQueueAOR",
  components: {TranscriptQueueTaskDetails, TranscriptQueueTaskNote, TranscriptQueueTask, ClientHeader },
  data: () => ({
    transcriptQueue: {},
    noteDescription: "",
    client: null,
    selectedTaskIdx: null,
    user: {},
    submitNoteLoading: false,
    statuses: [],
    fileUploadLoading: false,
    loading: false,
    loadingTask: [],
  }),
  methods: {
    handleDrop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.handleFiles(files);
    },
    dragEnter(event) {
      event.preventDefault();
      event.target.classList.add('drag-over');
    },
    dragLeave(event) {
      event.preventDefault();
      event.target.classList.remove('drag-over');
    },
    handleFiles(files){
      const uploadPromises = [];
      this.fileUploadLoading = true;

      // Iterate through each selected file
      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        const file = files[i];

        // Append the file to the FormData object
        formData.append('uploaded_file', file);

        // Append additional parameters
        formData.append('upload_type', 'transcript-request');
        formData.append('task_id', this.transcriptQueue.tasks[this.selectedTaskIdx].id)

        const uploadPromise = ClientService.uploadClientCSVFileWithReturningData(this.client.id, formData)


        uploadPromises.push(uploadPromise);
      }

      // If you need to do something after all files are uploaded
      Promise.all(uploadPromises)
          .then(() => {
            this.$func.popMessage("Successfully uploaded.", "success")
            this.fileUploadLoading = false;
            this.getTranscript()
          })
          .catch((error) => {
            this.fileUploadLoading = false;
            //console.error('Error uploading files:', error);
          });
    },
    uploadFiles(event) {
      const files = event.target.files;
      this.handleFiles(files);
    },
    openFileDialog() {
      // Trigger the file input's click event
      this.$refs.file.click();
    },
    getClient() {
      return ClientService.getOneClient(this.transcriptQueue.client_id).then((data) => {
        this.client = data;
      });
    },
    getStatuses(){
      return TranscriptService.getTranscriptStatuses().then(response => {
        this.statuses = response
      })
    },
    postNote(){
      this.submitNoteLoading = true;

      const noteModel = {
        body: this.noteDescription,
        author: this.user.id
      }

      return TranscriptService.postTranscriptNote(this.transcriptQueue.tasks[this.selectedTaskIdx].id,noteModel).then((data) => {
        this.$func.popMessage("Submit Successfully.", "success")
        this.submitNoteLoading = false;
        this.noteDescription = "";
        let task = this.transcriptQueue.tasks[this.selectedTaskIdx]
        let date = new Date();
        date.setHours(date.getHours() - 7)
        let newNote = {...noteModel, author_name: `${this.user.first} ${this.user.last}`, created_on: date}
        if (task?.notes)
          task.notes.push(newNote)
        else
          task.notes = [newNote]

      }).catch(err => {
        this.$func.popMessage("Submit Failed.", "error")
      }).finally(() => {
        this.submitNoteLoading = false;
      })
    },
    getTranscript() {
      return TranscriptService.getTranscriptQueueById(this.$route.params.id).then((res) => {
        this.transcriptQueue = res;
      })
    },
    getInitials(user) {
      return user.split(' ').map((name) => name[0]).join("");
    },
    selectTask(i) {
      this.selectedTaskIdx = i

      if (this.isMobile)
        window.scroll(0, 0)
    },
    changeStatus(taskId, statusId, index){
      const statusModel = {
        status: statusId
      }
      this.loadingTask[index] = true
      this.loadingTask = [...this.loadingTask]
      return TranscriptService.patchTranscriptTaskStatus(taskId, statusModel).then((data) => {
        this.$func.popMessage("Status Changed Successfully.", "success")
        const { notes } = this.transcriptQueue.tasks[index]
        this.transcriptQueue.tasks[index] = {...data, notes }
      }).catch(err => {
        this.$func.popMessage("Changing Status Failed.", "error")
      }).finally(() => {
        this.loadingTask[index] = false
        this.loadingTask = [...this.loadingTask]
      })
    },
    async getUser(){
      await AuthService.getCurrentUser(this.currentUser.current_user_id)
          .then(res=>{
            this.user = res.data
          })
    },
  },
  computed: {
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser'
        },
    ),
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },
  async created() {
    this.loading = true
    await Promise.all([
      this.getTranscript(),
      this.getStatuses(),
      this.getUser()
    ]).finally(() => this.loading = false)
    await this.getClient();
  },
};
</script>
