<template>
  <v-container>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
      <v-card
      class="pa-2 mb-5"
      >
      <v-row no-gutters>
          <v-col
              cols="12"
              sm="12"
              md="6"
          >
              <div class="d-flex flex-column">
                  <p class="text-h5">Client ID: {{ clientID }}</p>
                  <p class="text-h5">Company name: {{ tradingName }} </p>
                  <p class="text-h5">Days in Queue: {{ daysInQueue }}</p>
              </div>
          
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
          >

              <div class="d-flex flex-md-row-reverse">
                  <div class="d-flex flex-column">
                    <v-btn v-if="showRelease" :loading="claimLoading" @click="releaseJob" :disabled="claimLoading" style="height: 50px; width: 100%;" color="#263B4F">
                      <span style="color: #FFFFFF">Release</span>
                    </v-btn>
                    <v-btn v-else :loading="claimLoading" @click="claimBtn" :disabled="claimLoading" style="height: 50px; width: 100%;" color="#263B4F">
                      <span id="claim-job-btn" style="color: #FFFFFF">Claim</span>
                    </v-btn>
                      
                      <v-switch
                      v-model="blockedSwitch"
                      label="Blocked"
                      style="height: 25px"
                      :disabled="!jobClaimed"
                      @click="blockJob"
                      ></v-switch>

                      <v-switch
                      id="completed-job-switch-btn"
                      v-model="completedSwitch"
                      label="Completed"
                      :disabled="!jobClaimed"
                      style="height: 25px"
                      ></v-switch>
                  </div>
              </div>
        
          </v-col>
      </v-row>
  </v-card>


  <!-- Completed section -->
  <div v-if="this.completedSwitch === true">
      <h4>Upload Completed Files</h4>
      <div class="mb-5 d-flex flex-row align-center">
          <v-file-input
          v-model="uploadedFile"
          counter
          show-size
          accept=".csv, .xlsx"
          truncate-length="15"
          label="File input"
          class="mr-5"
          ref="sanitizedFile"
          ></v-file-input>

          <v-btn id="upload-job-btn" :loading="uploadLoading" @click="uploadBtn"  :disabled="uploadLoading" style="height: 40px; width: 15%;" color="#263B4F">
              <span style="color: #FFFFFF">Upload</span>
          </v-btn>
      </div>
  </div>
  <!-- Completed section -->

  <!-- DOWNLOADABLES -->
  <h4>Downloads</h4>
  <template>
  <v-data-table
      :headers="headers"
      :items="downloadItems"
      item-key="url"
      :style="jobClaimed ? 'cursor:pointer' : ''"
      @click:row="getDownloadLink"
      :loading="downloadLoading"
      :class="!jobClaimed ? 'grey' : ''"
      class="elevation-1 my-5"
      disable-pagination
      hide-default-footer
  >

  <template v-slot:item.original_file_name="{ item }">
      <a v-if="jobClaimed" :href="item.url" :download="item.original_file_name">{{item.original_file_name}}</a>
      <span v-else >{{item.original_file_name}}</span>
    </template>

  </v-data-table>


  </template>

  <!-- COMMENT SECTION -->
  <h4>Comments</h4>
  <v-textarea
      solo
      name="input-7-4"
      class="mt-5"
      label="Enter comment here..."
      v-model="commentInput"
  ></v-textarea>

  <v-btn :loading="commentLoading" @click="PostComment" :disabled="commentLoading" style="height: 50px;" :style="`width: ${$vuetify.breakpoint.mdAndUp ? '25' : '100'}%`" color="#263B4F">
      <span style="display: flex; align-items: center; gap: 5px; color: #FFFFFF">Comment<v-icon style="font-size: 1.2rem;">mdi-send</v-icon></span>
  </v-btn>

  <v-card
  class="mx-auto mt-5"
  color="white"
  v-for="comment, index in comments"
  :key="index"
>
  <!-- <v-card-title>
    <span class="text-h6 font-weight-light">Title</span>
  </v-card-title> -->



  <v-card-actions>
    <v-list-item>
      <v-list-item-avatar color="grey darken-3">
        <v-img
          alt=""
          src="../assets/img/Asset-1.png"
        ></v-img>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ comment.author }} {{ comment.comment_timestamp }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-card-actions>
    <v-card-text class="text-h5 font-weight-bold">
     <span style="font-size: 1rem">{{ comment.body }}</span>
    </v-card-text>
</v-card>


  </v-container>
</template>
<script>
import ClientsService from "@/services/clients.service";
import AuthService from "@/services/auth.service";
import {mapGetters} from "vuex";
import SanitizerService from "@/services/sanitizer.service";
import ClientService from "@/services/clients.service";


export default {
  data () {
      return {
          usersRole: null,
        loading:true,
          userID: null,
          claimLoading: false,
          blockedSwitch: false,
          completedSwitch: false,
          jobClaimed:false,
          uploadLoading: false,

          headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'File Name', sortable: false, value: 'original_file_name' },
        { text: 'Created At', sortable: false, value: 'created_on' },
      ],
      downloadItems: [],
      downloadLoading: false,
        showRelease:false,
        daysInQueue:0,
        tradingName:'',
      commentLoading: false,
        clientID:'',
      commentInput: '',
        sanitizeJobID:'',
        uploadedFile:{},
      comments: []
      }
  },
  methods: {
    onChange(event){
      // console.log(event)

      // if (event.type.split('/')[0] != 'csv' || event.type.split('/')[0] != 'vnd.ms-excel') {
      //   this.$func.popMessage('Upload should be a csv type format!','danger')
      //   return
      // }
      // if (event.type.split('/')[0] == 'csv' || event.type.split('/')[0] == 'vnd.ms-excel') this.uploadedFile = event
    
    },
    blockJob(){
      this.loading = true
      let model;
      if (this.blockedSwitch=== true){
        model = {
          "checked_out_status": 3,
          "checked_out_to": null
        }
      }
      else if (this.blockedSwitch === false){
        model = {
          "checked_out_status": 1,
          "checked_out_to": null
        }
      }
      SanitizerService.patchJobStatus(this.sanitizeJobID,model)
          .then(()=>{
            this.SanitizationJob()
          })
    },
    completeJob(){
      this.loading = true
      let model;
      if (this.blockedSwitch=== true){
        model = {
          "checked_out_status": 4,
          "checked_out_to": null
        }
      }
      else if (this.blockedSwitch === false){
        model = {
          "checked_out_status": 1,
          "checked_out_to": null
        }
      }
      SanitizerService.patchJobStatus(this.sanitizeJobID,model)
          .then(()=>{
            this.SanitizationJob()
          })
    },
    releaseJob(){
      this.loading = true
      const model = {
        "checked_out_status": 1,
        "checked_out_to": null
      }
      SanitizerService.patchJobStatus(this.sanitizeJobID,model)
          .then(()=>{
            this.SanitizationJob()
          })
    },
    claimBtn(){
      this.loading = true
      const model = {
        "checked_out_status": 2,
        "checked_out_to": this.currentUser.current_user_id
      }
       SanitizerService.patchJobStatus(this.sanitizeJobID,model)
           .then(()=>{
             this.SanitizationJob()
           })
    },
    uploadBtn(){
      const formData = new FormData();
      const csvFile = [this.uploadedFile];
      if (Object.keys(this.uploadedFile).length === 0 && this.uploadedFile.constructor === Object) {
        this.$func.popMessage('Please select a file for upload','danger')
        return
      }
      formData.append("uploaded_file", csvFile[0]);
      formData.append('upload_type', 'payroll-candidate');
      ClientService.uploadClientCSVFile(this.clientID, formData)
          .then(()=> {
            this.$func.popMessage('Job completed and closed.','success')
            this.$router.push('/queues/sanitize')
          }
        )
    },
    GetComments(){
      ClientsService.getSanitazationComments(this.sanitizeJobID).then(res =>{
          this.comments = res
      })
    },
    PostComment(){
      let commentModel = {
          body: this.commentInput
      }

      ClientsService.postSanitazationComment(this.sanitizeJobID, commentModel).then(res =>{
        this.commentInput = ''
        this.comments.unshift(res)
        this.GetComments()
      })
    },
    SanitizationJob(){
      ClientsService.getSanitazationJob(this.sanitizeJobID).then(res =>{
        this.tradingName = res.client_name
        this.daysInQueue = res.days_in_queue
          // this.showRelease = res.released_status;
          // this.blockedSwitch = res.blocked_status;
          // this.jobClaimed = res.claimed_status
        switch (res.job_status){
          case 1:
              this.showRelease = false
              this.blockedSwitch = false
              this.completedSwitch = false
              this.jobClaimed = false
                break
          case 2:
            this.jobClaimed = true
            this.showRelease=true
                break
          case 3:
              this.blockedSwitch = true
              this.completedSwitch = false
                break
          case 4:
              this.completedSwitch = true
              this.blockedSwitch = false
                break
        }
        this.loading=false


      })
    },
    DownloadFiles(){
      this.downloadLoading = true
      ClientsService.getDownloadFiles(this.clientID).then(res =>{
          this.downloadItems = res
          this.downloadLoading = false
      })
    },
    async getDownloadLink(file){
      if (this.jobClaimed){

        //console.log(file.url)
        //window.open(file.url, "_blank");

        // var link = document.createElement("a");
        // link.href = file.url;
        // link.download = file.original_file_name;
        // link.click();

        //var uri =  + file.url;

        // var downloadLink = document.createElement("a");
        // downloadLink.href = file.url;
        // downloadLink.setAttribute('download', file.original_file_name);

        // document.body.appendChild(downloadLink);
        // downloadLink.click();
        // document.body.removeChild(downloadLink);


        // try {
        //   const response = await this.$axios({
        //     url: file.url,
        //     method: 'GET',
        //     responseType: 'blob',
        //   });

        // console.log(response)
        
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', file.original_file_name);
        // document.body.appendChild(link);
        // link.click();
      // } catch (error) {
      //   console.error(error);
      // }

      }else{
        this.$func.popMessage('Job has to be claimed first in order to download a file!','danger')
      }

    }
  },
watch:{
  completedSwitch(val){
    if (val && this.jobClaimed){
      this.blockedSwitch = false;
    }else{
      this.completedSwitch = false
    }
  }
},
  created(){
    const route = this.$route.path.split('/')
    this.sanitizeJobID = route[3]
    this.clientID = route[1]
    AuthService.getCurrentUser(this.currentUser.current_user_id)
      .then(res => {
          this.usersRole = res.data.role_id
      })

      this.DownloadFiles()
      this.SanitizationJob()
      this.GetComments()

  },
  mounted(){
    //console.log(this.sanitizeJobID)
  },  
  computed: {
  ...mapGetters(
      {
        currentUser: 'auth/getCurrentUser',
        // clientID: 'sanitization/getClientID',
        // daysInQueue: 'sanitization/getDaysInQueue',
        // sanitizeJobID: 'sanitization/getSanitizeJobID',
        // tradingName: 'sanitization/getTradingName'
      },
  ),
},
}
</script>
<style scoped>

</style>
