<template>
  <div class="pa-8 text-center">
    <h4 class="text-start">
      7. Taxable Social Security Wages
    </h4>
    <h6 class="ml-6 mt-6 text-start">
      Please enter the taxable social security wages for each of the quarters below. These numbers can be found on Line 5a Column 1 of your 941 filings
    </h6>

    <v-row class="my-6">
      <v-col cols="3" v-for="(q, index) in quarters" :key="index" class="d-flex flex-column text-center">
        <h5>{{q.year}} - Q{{q.quarter}}</h5>
        <v-text-field v-model="q.wages" dense outlined></v-text-field>
      </v-col>
      <v-col cols="12" class="my-6">
          <img style="object-fit: contain;width: 100%;height: 100%;right: 0" src="../../assets/img/Check3.png">
      </v-col>
    </v-row>

    <v-btn color="#263B4F" class="mt-4">
      <span style="color: #FFFFFF" @click="patchWages">Save and Continue</span>
    </v-btn>
  </div>
</template>

<script>
import ClientsService from "../../services/clients.service";
export default {
  name: "Step7",
  props: {
    clientId: {
      type: Number
    }
  },
  data () {
    return{
      quarters:[]
    }
  },
  methods:{
    getWages(){
      ClientsService.getWages(this.clientId)
          .then(res=>this.quarters = res.wages)
    },
    patchWages(){
      ClientsService.editWages(this.clientId, this.quarters)
          .then(res=>{
            this.$emit('goToNextStep', 8)
          })
    }
  },
  created(){
    this.getWages()
  }
}
</script>

<style scoped>

</style>