<script>
import AdoptionsService from "@/services/adoptions.service";

export default {
  name: "IntroEmail",
  props: {
    entityId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    status: 4,
    clients: [],
    headers: [
      {
        text: "Client",
        value: "trade_name",
        filterable: true,
      },
      {
        text: "EIN",
        value: "ein",
        filterable: true,
      },
      {
        text: "Legal Name",
        value: "legal_name",
        filterable: true,
      },
    ],
  }),
  methods: {
    handleRowClick(item) {
      this.$router.push(
        {
          path: `/clients/${ item.id }/emails`,
          query: { adopterId: this.entityId },
        },
      );
    },
  },
  async mounted() {
    this.loading = true;

    try {
      this.clients = await AdoptionsService.getAdoptionStatus(this.entityId, this.status);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-text>
            <v-data-table
              :loading="loading"
              :headers="headers"
              :items="clients"
              @click:row="handleRowClick"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>