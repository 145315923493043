<template>
  <div class="mt-2">
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <DialogRightAlign :showDialog.sync="showOverPaymentDialog" :titleProp="`${this.clientData.name}`"> 
      <div>
        <v-row>
          <v-col class="text-center">
            <h2 class="mt-5">Balance:</h2>
            <v-card class="text-center mt-7 py-7 mx-16">
              <h2 >{{$formatAmount(clientData.balance)}}</h2>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="mx-3 pa-6">
              <h3 class="text-start pt-5 ml-3">Issue a check</h3>
              <div class="ma-3 d-flex flex-row">
                <v-text-field v-model="checkNumber" label="Check number" class="mr-2 mt-3"></v-text-field>
                <v-text-field v-model="checkAmount"  v-restrict.number.decimal prefix="$" label="Check amount" class="ml-2 mt-3"></v-text-field>
                <v-btn @click="postRefund(checkNumber, checkAmount)"
                       :disabled="disableButton || clientData.balance == 0" color="#263B4F" class="mt-5 ml-5"><span style="color: #fff">Issue</span></v-btn>
              </div>
            </v-card>
          </v-col>
          <v-col cols="12" class="text-center pa-0" v-if="isPosted">
             <h2 style="color: #4CAF50">Check successfully recorded!</h2>
          </v-col>
<!--          <v-col cols="12">-->
<!--            <h1 class="ml-8 mb-6">Check History</h1>-->
<!--            <v-card class="mx-6 pa-5">-->
<!--              <div class="d-flex flex-row justify-space-between">-->
<!--                <h3>Check Number</h3>-->
<!--                <h3 class="mr-16">Check Amount</h3>-->
<!--                <h3 class="mr-16">Date</h3>-->
<!--              </div>-->
<!--              <v-divider></v-divider>-->
<!--             <div class="mx-2 my-3" v-for="item in refundedItems" >-->
<!--               <div class="d-flex flex-row justify-space-between">-->
<!--                 <h3 class="ml-6">{{item.check_number}}</h3>-->
<!--                 <h3 >{{item.amount}}</h3>-->
<!--                 <h3>{{item.check_entered}}</h3>-->
<!--               </div>-->
<!--               <v-divider></v-divider>-->

<!--             </div>-->
<!--              <v-divider></v-divider>-->
<!--            </v-card>-->
<!--          </v-col>-->
          <v-col>
            <v-data-table
                :headers="headers"
                :items="refundedItems"
                class="elevation-5 mt-6 mx-4"
                disable-pagination
                hide-default-footer>

            </v-data-table>
          </v-col>
        </v-row>
      </div>
    </DialogRightAlign>
  </div>
</template>

<script>
import DialogRightAlign from "@/components/Base/DialogRightAlign";
import ClientsService from "@/services/clients.service";
export default {
  name: "OverpaymentsRightAlignDialog",
  components: {DialogRightAlign,
  ClientsService},
  data(){
    return{
      loading:false,
      headers:[
        { text: 'Check Number', value: 'check_number' },
        { text: 'Check Amount', value: 'amount_formatted' },
        { text: 'Date', value: 'created_on' },
      ],
      checkNumber:'',
      checkAmount:'',
      refundedItems:[],
      isPosted:false
    }
  },
  computed:{
    disableButton() {
      return (
          !this.checkAmount ||
          !this.checkNumber
      );
    }
  },
  methods:{
    postRefund(){
      this.loading =true;
      const model = {check_number:this.checkNumber,amount:this.checkAmount};
      ClientsService.postOverpaymentRefund(this.clientData.client,model)
          .then(()=>{
            if (this.clientData.balance < 0){
              this.clientData.balance = parseFloat(this.clientData.balance) + parseFloat(this.checkAmount)
            }else {
              this.clientData.balance -= this.checkAmount
            }
            this.checkAmount = 0
            this.isPosted=true
            this.getRefunds()
            this.loading = false;
            this.$func.popMessage('Check issued successfully!','success')
          }).catch(()=>this.loading=false)
    },
    getRefunds(){
      if(!this.clientData.client) return
      ClientsService.getRefundedOverpayments(this.clientData.client)
          .then(res=>this.refundedItems=res)
      this.refundedItems = this.refundedItems.splice(0,2)
    },
     addCommas(num) {
      //adds commas to a number
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  },
  props:{
    showOverPaymentDialog:{
      type:Boolean,
      default:false
    },
    clientData:{
      type:Object,
      default: null
    }
  },
  mounted(){
    if(Object.keys(this.clientData).length !== 0) {
      if(this.clientData.balance[0]==="-"){
      this.checkAmount = this.clientData.balance.slice(1)
      } else {
        this.checkAmount = this.clientData.balance
      }
    }
    // this.checkAmount = this.checkAmount.replace('.', '');
    //this.checkAmount=this.addCommas(this.checkAmount)
  },
  created() {
    this.getRefunds()
  }
}
</script>

<style scoped>

</style>
