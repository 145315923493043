<template>
 <v-container class="backgroundImg d-flex justify-center" fluid fill-height>
   <v-progress-circular
       indeterminate
       color="white"
       v-if="loading"
   ></v-progress-circular>
        <v-card :width="width" class="py-3" v-if="!loading" >
          <div class="d-flex justify-center">
            <img src="../assets/img/PatriotLogo.png">
          </div>
        <v-card-title class="justify-center pt-2" style="font-size: 1.4rem;font-weight: 550;color: #283c4c"  >Activate your account</v-card-title>
          <div class="mt-5 mb-6 px-16">
            <v-row class="justify-center" v-if="linkActive">
              <v-col class="pa-6" cols="12">

                <v-text-field
                    type="password"
                    label="Password"
                    v-model="password"
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                ></v-text-field>
              </v-col>
              <v-col cols="12"  class="pa-6">
                <v-text-field
                    type="password"
                    label="Confirm Password"
                    v-model="passwordConfirm"
                    :append-icon="valueConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (valueConfirmPass = !valueConfirmPass)"
                    :type="valueConfirmPass ? 'password' : 'text'"
                ></v-text-field>
              </v-col>
              <v-col cols="12"  class="d-flex justify-center" >
                <v-btn
                color="#283c4c" @click="submit" :disabled="btnDisabled"  width="100%" class="px-2" depressed><span style="color: #ffffff">Activate account</span></v-btn>
              </v-col>
            </v-row>
            <v-row class="justify-center" v-if="!linkActive">
              <v-col class="pa-6 text-center" cols="12">
                <h2 style="color: #AF0808">Password reset link has expired!</h2>
              </v-col>
            </v-row>
          </div>
        </v-card>
 </v-container>
</template>

<script>
import ClientsService from "@/services/clients.service";
import AuthService from "@/services/auth.service";
import router from "@/router";

export default {
  name: "ActivateAccount",
  data(){
    return{
      passwordConfirm:null,
      password:null,
      btnDisabled: true,
      userID: null,
      value: true,
      valid: true,
      valueConfirmPass: true,
      linkActive: false,
      loading: true
    }
  },
  methods:{
    submit(){
      this.btnDisabled = true
      let formData = new FormData();
      formData.append('password', this.password);
      let path = this.$route.path
      let pathArr = path.split('/')
      let param = pathArr.slice(-1)
      AuthService.activateAcc(formData, param)
          .then(e=> {
            
            this.userID = e
            
            this.login()
          }).catch(error => {
        this.btnDisabled = false
        this.$func.popMessage(`${error.response.data.error}`, 'fail')
      })
    },
    checkLinkExpiration(){
      let path = this.$route.path
      let pathArr = path.split('/')
      let param = pathArr.slice(-1)
      AuthService.checkLinkExpiration(param)
          .then(res => {
            this.linkActive = true
            this.loading = false
          }).catch(()=> this.loading=false)
    },
    login(){
      
      this.$store.dispatch('auth/login', {email:this.userID.username, password:this.password})
          .then((res) => {
            //Set axios Authorization header
            this.$axios.defaults.headers.common['Authorization'] = `Bearer ${res}`;
            this.$router.push('/dashboard');
          })
      // AuthService.loginAfterActivate(this.password, this.userID.username).then(e=>{
      //   this.$store.dispatch('snackbar/showSnackbarMessage', {
      //     message: 'You have successfully activated your account. Logging you in to your dashboard.',
      //     duration: 3000,
      //     mode: 'success'
      //   });
      //   setTimeout(()=>{
      //     router.push('/dashboard')
      //   },2000)
      // }).catch(this.btnDisabled = false)
    }
  },
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 400
        case 'sm':
          return 400
        case 'md':
          return 500
        case 'lg':
          return 500
      }
    }
  },
  watch:{
    password(value){
      if (value == this.passwordConfirm){
        this.btnDisabled = false
      }else {
        this.btnDisabled = true
      }
    },
    passwordConfirm(value){
      if (value == this.password){
        this.btnDisabled = false
      }else {
        this.btnDisabled = true
      }
    }
  },
  beforeMount() {
    this.checkLinkExpiration()
  }
}
</script>

<style scoped>
.backgroundImg{
  width: 100%;
  height: 100%;
  position: absolute;
  background: url("../assets/img/Patriot Homepage.png")  no-repeat center center ;
  background-size: cover;

}
</style>