<template>
  <v-row class="d-flex justify-center align-center">
    <v-col
        v-show="!tourStarted"
        cols="12"
    >
      <v-hover v-slot="{ hover }">
        <v-card
            class="mx-auto"
            :elevation="hover ? 10 : 2"
            :class="{ 'on-hover': hover }"
            link
            outlined
            @click.native="$emit('openSelectOwnersRawDialog')"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-6 font-weight-bold">
                Owners and Family Members
              </v-list-item-title>

              <v-row>
                <v-col
                    cols="6"
                    style="padding-top: 5px; !important; padding-bottom: 5px;!important;"
                    v-for="(owner, index) in owners" :key="'owner' + index"
                >
                  <ul v-if="owners != ''"><li>{{ owner }}</li></ul>
                </v-col>
              </v-row>

              <!--                          <v-list-item-subtitle v-if="owners.length == 0 && hasOwners">-->
              <!--                            You have declared the uploaded CSV file does not contain any owner or-->
              <!--                            family records-->
              <!--                          </v-list-item-subtitle>-->

              <v-list-item-subtitle v-if="owners.length === 0 || owners == ''">
                Update your list of owners and family for this client
              </v-list-item-subtitle>

              <v-list-item-subtitle class="mb-4">
              </v-list-item-subtitle>
              <v-spacer></v-spacer>

              <v-card-actions>
                <v-btn
                    text
                    color="#1867C0"
                    @click.stop="$emit('openSelectOwnersRawDialog')"
                >
                  <u> Edit owners</u>
                </v-btn>
              </v-card-actions>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-hover>
    </v-col>

    <v-col
        v-show="tourStarted"
        cols="12"
    >
      <v-card
          class="mx-auto"
          link
          outlined
          @click.native="$emit('openSelectOwnersRawDialog')"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-6 font-weight-bold">
              Owners and family list
            </v-list-item-title>

            <v-row>
              <v-col
                  cols="6"
                  style="padding-top: 5px; !important; padding-bottom: 5px;!important;"
                  v-for="(owner, index) in owners" :key="'owner' + index"
              >
                <span>{{ owner }} </span>
              </v-col>
            </v-row>

            <!--                          <v-list-item-subtitle v-if="owners.length == 0 && hasOwners">-->
            <!--                            You have declared the uploaded CSV file does not contain any owner or-->
            <!--                            family records-->
            <!--                          </v-list-item-subtitle>-->

            <v-list-item-subtitle v-if="owners.length === 0">
              Update your list of owners and family for this client
            </v-list-item-subtitle>

            <v-list-item-subtitle class="mb-4">
            </v-list-item-subtitle>
            <v-spacer></v-spacer>

            <v-card-actions>
              <v-btn
                  text
                  color="#1867C0"
                  @click.stop="$emit('openSelectOwnersRawDialog')"
              >
                <u> Edit owners</u>
              </v-btn>
            </v-card-actions>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'ProfileRawOwnersCard',
  props: {
    owners: {},
    tourStarted: {}
  }
}
</script>
<style lang="scss">

::v-deep .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

::v-deep .v-text-field__slot input::-webkit-outer-spin-button,
::v-deep .v-text-field__slot input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
::v-deep .v-text-field__slot input[type=number] {
  -moz-appearance: textfield !important;
}


</style>
