<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <h1 class="my-4">Signature Verification</h1>
    <v-container fluid>
      <v-radio-group
          v-model="radioOption"
          row
      >
        <v-radio
            label="Awaiting"
            :value="0"
            @click="getData(0)"
        ></v-radio>
        <v-radio
            label="Completed"
            :value="1"
            @click="getData(1)"
        ></v-radio>
      </v-radio-group>
    </v-container>
    <v-data-table
        :headers="headers"
        :items="indexedItems"
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        item-key="index"
        disable-pagination
        hide-default-footer
        show-expand
        class="elevation-1"
    >
      <template v-slot:item.trade_name="{ item }">
        <div style="cursor: pointer" @click="goToClient(item.client)">
          {{item.trade_name}}
        </div>
      </template>

      <template v-slot:item.type="{ item }">
        <div v-if="item.type === '2020-supplement'" style="color: forestgreen" class="font-weight-black text-uppercase">
          {{item.type}}
        </div>
        <div v-else style="color: black">
          {{item.type}}
        </div>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
       <v-row class=" pa-4">
         <v-col>Enter the pages that contain the
           corresponding signatures below, and click
           "Verified" once you have verified all
           signatures. If a signature is not required,
           click "N/A"</v-col>
         <v-col><a :href="item.quarters[0].url"
                   target="_blank">Download Submission</a><v-icon>mdi-download</v-icon></v-col>
         <v-col class="d-flex flex-column" id="quarters">
           <div class="d-flex flex-row align-center" v-for="q in item.quarters">
             <span v-if="item.document_type === 2">Page{{q.quarter}}</span>
             <span v-else>Q{{q.quarter}}</span>
             <v-text-field
               v-model="q.actual_page ? q.actual_page : q.pdf_page"
               style="max-width: 70px"
               :disabled="q.checked || !!q.verified_on"
               class="mx-2"
             />
             <v-checkbox v-if="q.verified_on && !q.actual_page" disabled :true-value="true" :value="true" />
             <v-checkbox v-else :disabled="!!q.verified_on" v-model="q.checked" />
             <span>N/A</span>
           </div>
           
         </v-col>
         <v-row v-if="+item.expected_signatures !== +item.verified_signatures" id="confirm">
          <v-col v-if="item.document_type === 2"><v-btn @click="denySignature(item)" color="primary">Deny Signature</v-btn></v-col>
           <v-col><v-btn :disabled="confirmDisabled(item)" @click="confirmSignature(item)" color="primary">Confirm Signature</v-btn></v-col>
         </v-row>
         </v-row>
   
  
       <v-row></v-row>
        </td>
      </template>
    </v-data-table>
    <ProfileClientDialog
        v-if="showRightDialogChosenClient"
        :key="dialogKeyChosenClient"
        :showRightDialogChosenClient.sync="showRightDialogChosenClient"
        :clientProp="clientProp"
        @openClientProfileDialog="openClientProfileDialog"
  
    />
  </div>
  </template>
  
  <script>
  import ProfileClientDialog from "@/components/Client/ProfileClientDialog.vue";
  import ClientsService from "@/services/clients.service";
  import ClientService from "@/services/clients.service";
  export default {
    name: "SignatureVerification",
    components: {ProfileClientDialog},
    data:()=>({
      radioOption: 0,
      expanded: [],
      singleExpand: true,
      items:[],
      loading:false,
      showRightDialogChosenClient:false,
      dialogKeyChosenClient:33,
      clientId:'',
      clientProp:{},
      selectedClient:{},
      statuses:[],
      rejectReason:'',
      rejDialog:false,
      claimed:0,
      blocked:0,
      headers:[
        {
          text: 'Trade Name',
          value: 'trade_name'
        },
        {
          text: 'Legal Name',
          value: 'legal_name'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Days in queue',
          value: 'age'
        }
        ,
        {
          text: 'AOR',
          value: 'aor'
        },
        {
          text: 'EIN',
          value: 'ein'
        }
      ]
  }),
    methods:{
      goToClient(id){
        this.loading = true
        return ClientService.getOneClient(id)
            .then((data) => {
              // this.clientProp = data;
              // this.openClientProfileDialog();
              // this.clientId = Number(this.clientProp.id);
              this.clientProp = data;
              this.select = null;
              this.clientId = Number(this.clientProp.id);
              this.$router.push(`/clients/${this.clientId}/profile`)
              // this.showResults = false;
              // this.inputSearch = null;
            })
            .catch((error) => {
              this.loading = false
              this.$store.dispatch('snackbar/showSnackbarMessage', {
                message: `${error.response.data.error}. Please, try again.`,
                duration: 4000,
                mode: 'fail'
              });
            });
      },
      openClientProfileDialog() {
        this.showRightDialogChosenClient = true;
        this.dialogKeyChosenClient++;
        this.loading = false
        this.showRightDialog = false;
      },
      openRejectDialog(item){
        this.rejDialog = true
        this.selectedClient=item
      },
      confirmSignature(item){
        this.loading=true
        item.quarters.map(v => ({...v,status: v.checked ? 1 : v.status}))
        this.postQuarters(item.document_id,item.quarters.map(v => ({...v, status: v.pdf_page ? 1 : v.status,document_type:item.document_type})))
      },
      confirmDisabled(item) {
        const isNotCheckedAndTyped = ({pdf_page, actual_page, checked}) => !(pdf_page || actual_page) && !checked
        return item.quarters.some(isNotCheckedAndTyped)
      },
      denySignature(item){
        ClientsService.deleteDenySignature(item.document_id)
            .then(res=>{
              setTimeout(()=>{
                this.$func.popMessage('Signature Denied successfully!','success')
                this.getData()
              })
            }).finally(()=>{
          this.loading=false
        })
      },
      postQuarters(id,data,index){
        ClientsService.patchSignatureQuarters(id,data)
            .then(res=>{
              setTimeout(()=>{
                this.$func.popMessage('Client Verified successfully!','success')
                this.getData()
              },700)
            }).finally(()=>{
              this.loading=false
            })
      },
      getData(filter = 0) {
        this.loading = true

          ClientsService.getSignatureVerification(filter).then(res => {
            this.items = res
            for (let i = 0; i < this.items.length; i++) {
              this.items[i].quarters.map(x => (
                  {
                    ...x,
                    checked: x.status == 1 ? true : false
                  }
              ))
            }
            this.items = [...this.items]
            this.loading = false
          })
        }

    },
    computed: {
      indexedItems () {
        return this.items.map((item, index) => ({
          index: index,
          ...item
        }))
      }
    },
    created() {
      this.getData(0)
    }
  }
  </script>
  
  <style scoped>
  
  #confirm {
    width: 100%;
    margin: 0 0.2rem;
  }
  @media only screen and (min-width: 400px) {
    #confirm .col{
    position: relative;
    min-height: 60px;
    }
    #confirm .col button{
      position: absolute;
      right:330px;
    }
  }
  
  </style>
