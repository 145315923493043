const state = () => ({
    pageData: {},
});

const getters = {
    getPageData(state) {
        return state.pageData;
    }
}

const mutations = {
    setPageData(state, data) {
        state.pageData = data;
    }
}

const actions = {
    changePageData(vuexContext, data) {
        vuexContext.commit('setPageData', data);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
