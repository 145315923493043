import VueCookies from 'vue-cookies';
import AuthService from '@/services/auth.service';
import store from '../index'
import router from "@/router";
import jwt_decode from "jwt-decode";

// import Cookie from 'js-cookie'

const setUpCookie = (token) => {
	VueCookies.set('current_user', token, null, null, false, 'Lax');
};

const state = () => ({
	user: null,
	currentPath: null,
	sudoMode: false,
	preMaintenancePath: null
});

const getters = {
	isAuthenticated: (state) => () => {
		let cookie = VueCookies.get('current_user');
		decodeJwt(cookie)
		return cookie != null;
	},

	isSudoMode(state) {
		return state.sudoMode;
	},

	getCurrentUser(state) {
		return state.user;
	},

	getCurrentPath(state) {
		return state.currentPath
	},

	currentUserRole(state) {
		return state.user ? state.user.role_id : null;
	},

	getPreMaintenancePath(state) {
		return state.preMaintenancePath;
	}
};

const decodeJwt = (token) => {
	if (token) {
		try {
			let base64Url = token.split('.')[1]; // token you get
			// let base64 = base64Url.replace('-', '+').replace('_', '/');
			let decodedData = JSON.parse(Buffer.from(base64Url, 'base64').toString('binary'));
			isTokenValid(decodedData)
		} catch (e) {
			VueCookies.remove('current_user');
			VueCookies.remove('user_data');
			window.location.replace("/login");
		}
	}
}
const isTokenValid = (token) => {
	jwtTimesValid(token) !== true ? removeCookie() : ''
	function removeCookie() {
		state.user = null;
		VueCookies.remove('current_user');
		VueCookies.remove('user_data');
		router.replace('/login').catch(() => { })
	}
	function jwtTimesValid(DecodedData) {
		// let issuedBeforeNow = false;
		// let isValidNow = false;
		// let isNotExpired = false;
		// const ts = Math.round((new Date()).getTime() / 1000);
		// issuedBeforeNow = (Math.abs(DecodedData.iat - ts) < 30);
		// isValidNow = (Math.abs(DecodedData.nbf - ts) < 30);
		// isNotExpired = (Math.abs(DecodedData.exp - ts) > 30);
		//All three conditions must be true in order for the times to be valid.
		//return (issuedBeforeNow && isValidNow && isNotExpired)
		return true
	}
}
const mutations = {
	setUser(state, user) {
		let decodeData = jwt_decode(user);

		state.user = decodeData;
	},

	logout(state) {
		state.user = null;
	},

	toggleSudoMode(state, sudoModeState) {
		state.sudoMode = sudoModeState;
	},

	signOut(state) {
		if (state.user.current_user_id == state.user.user_id) {
			state.user = null;
			VueCookies.remove('current_user');
			VueCookies.remove('user_data');
		} else {
			store.dispatch('auth/depersonateUser').then((res) => {
				//router.replace('/dashboard');
				//window.location.replace('/dashboard')
			});
		}
	},

	setCurrentPath(state, payload) {
		state.currentPath = payload.path;
	},

	setPreMaintenancePath(state, path) {
		state.preMaintenancePath = path
	}

};

const actions = {
	depersonateUser(vuexContext) {
		return AuthService.depersonate()
			.then(res => {
				setUpCookie(res);
				vuexContext.commit('setUser', res);
				window.location.replace('/dashboard')
			});
	},

	impersonateUser(vuexContext, userID) {
		// const token = VueCookies.get('current_user');

		// VueCookies.set('real_user', token);

		return AuthService.impersonate(userID)
			.then(res => {
				setUpCookie(res);
				vuexContext.commit('setUser', res);
			})
	},
	signOut(vuexContext) {
		const oldToken = VueCookies.get('real_user');

		if (oldToken) {
			VueCookies.remove('real_user');
			vuexContext.commit('toggleSudoMode', false);
			return vuexContext.dispatch('getUserFromToken', oldToken);
		} else {
			vuexContext.commit('signOut');

			return Promise.resolve(null);
		}
	},

	getUserFromToken(vuexContext, token) {
		setUpCookie(token);
		window.location.replace("/login");

		// return $axios.get('/me')
		//     .then(res => {
		//         setUpCookie(token, res.data.user);
		//         vuexContext.commit('setUser', res.data);
		//         return res.data;
		//     });
	},

	changeCurrentPath({ commit }, payload) {
		commit("setCurrentPath", payload);
	},

	// impersonateUser(vuexContext, userID) {
	//     const token = Cookie.get('current_user');
	//     Cookie.set('real_user', token);
	//
	//
	//     return $axios.post(`users/${userID}/impersonate`)
	//         .then(res => {
	//             setUpAuth(res.data.users_bearer_token, res.data);
	//             vuexContext.commit('setUser', res.data);
	//             vuexContext.commit('toggleSudoMode', true);
	//             return res.data;
	//         });
	// },

	signUp(vuexContext, user) {
		return AuthService.signUp(user).then(res => {

			vuexContext.commit('setToken', res.token);
		});
	},

	getMe(vuexContext) {
		return AuthService.getMe().then(data => {

			vuexContext.commit('setUser', data);
		}).catch((error) => {
			this.$func.popMessage(`${error.response.data.error}`, 'fail')
		});
	},

	setUserFromToken(vuexContext) {
		const token = VueCookies.get('current_user');
		vuexContext.commit('setUser', token);
	},

	login(vuexContext, data) {
		let payload = {
			email: data.email,
			password: data.password,
		};

		return AuthService.login(payload).then(data => {
			setUpCookie(data, data);
			vuexContext.commit('setUser', data);

			return data;
		}).catch((error) => {
			this.$func.popMessage(`${error.response.data.error}`, 'fail')
		});
	},

	loginAfterRegistration(vuexContext, data) {
		let payload = {
			email: data.email,
			password: data.password,
			after_registration: true
		};

		return AuthService.loginAfterRegistration(payload).then(data => {
			setUpCookie(data, data);
			vuexContext.commit('setUser', data);
			return data;
		}).catch((error) => {
			this.$func.popMessage(`${error.response.data.error}`, 'fail')
		});
	},

};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
