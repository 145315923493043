<template>
  <div>
    <v-overlay :value="loadinOverlay">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="date"
            label="Payment period"
            style="width: 100%"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
          v-model="date"
          type="month"
          no-title
          scrollable
      >
<!--        <v-spacer></v-spacer>-->
<!--        <v-btn-->
<!--            text-->
<!--            color="primary"-->
<!--            @click="menu = false"-->
<!--        >-->
<!--          Cancel-->
<!--        </v-btn>-->
<!--        <v-btn-->
<!--            text-->
<!--            color="primary"-->
<!--            @click="getReport()"-->
<!--        >-->
<!--          OK-->
<!--        </v-btn>-->
      </v-date-picker>
    </v-menu>
    <div v-if="!feeItems.length">
      <h3 class="text-center mt-12" style="color: #D21919">
        No checks from your clients have been received during this period, and accordingly, no contingency fees are scheduled to be paid out yet.
      </h3>
    </div>
    <v-row         v-if="feeItems.length"
                   class="mx-8 mt-6 mb-12 d-flex justify-center" v-show="summary.payable_fees">
<!--      <div class="d-flex flex-column align-center">-->
<!--        <h1>Checks received:</h1>-->
<!--        <h1 v-if="summary.check_count" class="numbers">{{summary.check_count}}</h1>-->
<!--        <h1 v-else class="numbers">0</h1>-->
<!--      </div>-->
<!--      <div class="d-flex flex-column align-center">-->
<!--        <h1>Average fee:</h1>-->
<!--        <h1 v-if="summary.average_fee" class="numbers">{{summary.average_fee}}</h1>-->
<!--        <h1 v-else class="numbers">0</h1>-->
<!--      </div>-->
      <div class="d-flex flex-column align-center">
        <h1>Total fees:</h1>
        <h1 v-if="summary.payable_fees" class="numbers">{{summary.payable_fees}}</h1>
        <h1 v-else class="numbers">0</h1>

      </div>
    </v-row>
    <v-data-table
        v-if="feeItems.length"
        :headers="headers"
        :items="feeItems"
        class="elevation-5 mt-6"
        disable-pagination
        hide-default-footer

        item-key="client_id + quarter"
    >
<!--      <template v-slot:expanded-item="{ headers, item }">-->
<!--        &lt;!&ndash;        <td :colspan="headers.length">&ndash;&gt;-->
<!--        &lt;!&ndash;          More info about {{ item.name }}&ndash;&gt;-->
<!--        &lt;!&ndash;        </td>&ndash;&gt;-->
<!--        &lt;!&ndash;        <tr>&ndash;&gt;-->
<!--        &lt;!&ndash;          <td>{{item.check_count}}</td>&ndash;&gt;-->
<!--        &lt;!&ndash;                 <td>{{item.average_fee}}</td>&ndash;&gt;-->
<!--        &lt;!&ndash;                  <td>{{item.payable_fees}}</td>&ndash;&gt;-->
<!--        &lt;!&ndash;        </tr>&ndash;&gt;-->
<!--        <td :colspan="headers.length">-->
<!--          <v-data-table-->
<!--              disable-pagination-->
<!--              hide-default-footer-->
<!--              style="background-color: #ededed"-->
<!--              :headers="headers2"-->
<!--              :items="item.register">-->

<!--          </v-data-table>-->
<!--        </td>-->
<!--      </template>-->
    </v-data-table>
  </div>
</template>
<script>
import ClientsService from "../../services/clients.service";
export default {
  name: "Attorney",
  data () {
    return {
      expanded: [],
      singleExpand: true,
      headers: [
        { text: 'Client', value: 'trade_name' },
        { text: 'Quarter', value: 'quarter' },
        { text: 'Check Amount', value: 'adjusted_fee_payable_formatted' },

      ],
      headers2: [
        { text: 'Attorney', value: 'attorney_of_record' },
        { text: 'Total checks', value: 'check_count' },
        { text: 'Average fee', value: 'average_fee' },
        { text: 'Total fees', value: 'payable_fees' },
        { text: '', value: 'data-table-expand' },

      ],
      feeItems: [],
      loadinOverlay: false,
      radio1:null,
      radio2:null,
      radio3:null,
      menu:false,
      date:'',
      summary:{},
    }
  },
  methods: {
    getReport(){
      this.loadinOverlay = true
      ClientsService.getReports(this.date)
          .then(data => {
            this.feeItems = data.register
            this.summary = data;
            this.loadinOverlay = false
          }).catch(()=> {this.loadinOverlay = false})
    },
    getFee(item){
      this.loadinOverlay = true

      ClientsService.getFeeByID(item[0].client_id,this.date)
          .then(res=> {
            this.feeItems.map((e,index)=>{
              if (e.managing_firm_id == item[0].managing_firm_id){
                this.feeItems[index].register = res.register
              }
            })
            this.feeItems = [...this.feeItems]
            this.loadinOverlay = false

          }).catch(err=>this.loadinOverlay = false)
    },
    getCurrMonth(){
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      const year = dateObj.getUTCFullYear();
      if (month < 10){
        month = '0' + month
      }

      return year + "-" + month;
    }
  },
  watch:{
    date(val){
      this.getReport()
    },
    expanded(val){
      this.getFee(val)
    }
  },
  created(){
    this.date = this.getCurrMonth();

    // this.getReport()
  }
}
</script>

<style scoped>

</style>
