<template>
<div class="d-flex justify-center align-center" >
  <v-row>
    <v-col cols="12" class="d-flex justify-space-around">
      <v-card class="pa-4">
        <v-card-title>Average number of Ws</v-card-title>
        <v-card-subtitle id="averageWS" style="font-size: 2.5rem;margin-top: 20px;font-weight: bold">
          {{items.avarage_ws}}
        </v-card-subtitle>
        </v-card>
      <v-card class="pa-4 text-center">
        <v-card-title>Final Packets awaiting Shipment </v-card-title>
        <v-card-subtitle style="font-size: 3rem;margin-top: 20px;font-weight: bold">{{awaiting}}</v-card-subtitle>
      </v-card>
    </v-col>
    <v-col cols="12" style="height: 400px !important;">
      <canvas id="myChart"></canvas>
    </v-col>
  </v-row>

</div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import Chart from "chart.js/auto";
export default {
  name: "ClientPerformance",
  data () {
    return {
    items:[],
      maxChartValue:0,
      datasets:[
        {
          label: 'New EINs',
          fill:true,
          data: [],
          backgroundColor: ['rgba(102,127,243,0.2)',],
          borderColor: ['rgb(0,24,147)',],
          borderWidth: 1
        },
        {
          label: 'Categorized Documents',
          fill:true,
          data: [],
          backgroundColor: ['rgba(107,42,122,0.2)',],
          borderColor: ['rgb(81,19,91)',],
          borderWidth: 1
        },
        {
          label: 'Sanitized Clients',
          fill:true,
          data: [],
          backgroundColor: ['rgba(35,128,43,0.2)',],
          borderColor: ['rgb(0,24,1)',],
          borderWidth: 1
        },
        {
          label: 'Reconciled Clients',
          fill:true,
          data: [],
          backgroundColor: ['rgba(204,91,1,0.2)',],
          borderColor: ['rgb(70,25,0)',],
          borderWidth: 1
        },
        {
          label: 'Packets Shipped',
          fill:true,
          data: [],
          backgroundColor: ['rgba(234,48,48,0.2)',],
          borderColor: ['rgb(105,1,5)',],
          borderWidth: 1
        }
      ],
      leadChartDates:[],
      clientChartDates:[],
      avarage_ws:0,
      awaiting:0,
    }
  },
  methods:{
    getData(){
      ClientsService.getClientsPerformance()
          .then(res=>{
            this.items= res
            const ctx = document.getElementById('myChart');
            this.datasets[0].data = this.items.charts.clients.data
            // const colDocs = this.items.charts.documents.collected.map(e=>e.num_clients)
            const colDocs = Object.values(this.items.documents.collected).map(e=>e.avg_docs_collected)
                // this.items.documents.collected.map(e=>e.avg_docs_collected)
            this.datasets[1].data = colDocs
            this.awaiting= this.items.final_packets.awaiting_shipment
            this.datasets[2].data = Object.values(this.items.clients.sanitized).map(e=>e.num_clients_sanitized)
            this.datasets[3].data = Object.values(this.items.clients.reconciled).map(e=>e.reconciled_clients)
            // let maxClients = Math.max( ...this.datasets[0].data );
            let maxLeads = Math.max( ...this.datasets[0].data );
            this.clientChartDates = this.items.charts.clients.dates

            const myChart = new Chart(ctx, {
              type: 'line',
              data: {
                labels: this.clientChartDates,
                datasets: this.datasets
              },
              options: {
                maintainAspectRatio: false,
                responsive: true,
                // scales: {
                //   y: {
                //     beginAtZero: true
                //   }
                // }
                scales: {
                  x: {
                    display: true,
                    title: {
                      display: true
                    }
                  },
                  y: {
                    display: true,
                    title: {
                      display: true,
                      text: 'Value'
                    },
                    suggestedMin: 0,
                    suggestedMax: this.maxChartValue
                  }
                }
              }
            });
          })
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
