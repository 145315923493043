<template>
  <div class="pa-8 text-center">
    <h4 class="text-start">
      2. Company Representative
    </h4>
    <h6 class="ml-6 mt-6 text-start">
      The individual filling out this form certifies they have the authority and ability to accurately provide the information requested herein
    </h6>

    <div class="d-flex flex-column pl-5" style="width: 33rem">
      <v-text-field v-model="data2.trade_name" size="xs" placeholder="Name"></v-text-field>
      <v-select
          id="contact-saluattion"
          :append-icon="'mdi-arrow-down'"
          :items="salutationList"
          v-model="data2.contact_salutation"
          :item-text="'name'"
          :item-value="'name'"
          label="Mrs"
      ></v-select>
<!--      <v-text-field v-model="data2.contact_salutation" placeholder="Title"></v-text-field>-->
      <v-text-field v-model="data2.contact_email" placeholder="Email"></v-text-field>

    </div>
    <v-btn color="#263B4F" class="mt-4">
      <span style="color: #FFFFFF" @click="emitData">Save and Continue</span>
    </v-btn>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ClientsService from "@/services/clients.service";
import UserService from "@/services/user.service";
export default {
  name: "Step2",
  data(){
    return{
      salutationList: [
        {
          name: 'Mr.'
        },
        {
          name: 'Mrs.'
        },
        {
          name: 'Ms.'
        },
        {
          name: 'Dr.'
        },
      ],
      client:'',
      data2:{
        trade_name:"",
        contact_salutation:"",
        contact_email:"",
      }
    }
  },
  methods:{
    // getUser(){
    //   ClientsService.getUsersRole(this.currentUser.current_user_id)
    //       .then(res=>{
    //         
    //         this.data2 = {
    //
    //         }
    //         this.getClient(res.parent_entity)
    //       })
    // },
    // getClient(){
    //   ClientsService.getOneClient(this.clientId)
    //       .then(res=>{
    //         this.client = res;
    //         
    //         this.data2.contact_email=res.contact_email
    //         this.data2.title= res.contact_salutation
    //         this.data2.contact_name= res.trade_name
    //         console.log(this.data2)
    //       })
    // },
    emitData(){
      this.$emit('returnStep2Data',this.data2,2)
    }
  },
  props:{
    clientId:{
      type: Number
    },
    clientProp:{
      type:Object
    }
  },
  computed: {
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
        },
    ),
  },
  created() {
    this.data2.contact_email=this.clientProp.contact_email
    this.data2.contact_salutation= this.clientProp.contact_salutation
    this.data2.trade_name= this.clientProp.trade_name
  }
}
</script>

<style scoped>

</style>