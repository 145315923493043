<template>
  <div class="pa-8 text-center">
    <h4 class="text-start">
      9. Required Attachments
    </h4>
    <h5 class="ml-6 mt-6 text-start">
      In addition to completing all of the items in this Information Checklist, please also attach and return the following:
    </h5>

    <v-row class="my-6">
      <v-col cols="12" class="d-flex ml-6 align-center">
        <v-icon>mdi-star</v-icon>
        <v-col cols="5" class="text-start"><h4>941s from 2020 Q4, and 2021 Q1, Q2, Q3</h4></v-col>
        <v-col cols="3" class="text-start">
          <v-card
              @drop.prevent="drop($event,'quarters')"
              @dragover.prevent="dragoverData = true"
              @dragenter.prevent="dragoverData = true"
              @dragleave.prevent="dragoverData = false"
              :class="{ 'grey lighten-2': dragoverData }"
              height="35px"
              class="d-flex  align-center justify-center"
              style="background-color: #283c4c;
                   box-shadow: none;width: 130px;
                   border-radius: 9999px !important;cursor: pointer" >
            <label style="color: #FFFFFF;font-size:0.875rem;
            cursor: pointer;font-weight: 500;    letter-spacing: 0.0892857143em;text-transform: uppercase;">Upload

              <input type="file" multiple name="fields[assetsFieldHandleQuarters][]"
                     id="assetsFieldHandleQuarters"
                     class="d-none absolute" @change="onChange('quarters')" ref="qarterFile" /></label>
          </v-card>

        </v-col>
        <v-col cols="4" class="text-start fileNames" v-if="quartersFile.length" :class="{ show: qarterShow }"
               @click="qarterShow = !qarterShow">
          <span class="fileNames"  v-for="(qFile,index) in quartersFile" >{{qFile.original_file_name}}
            <span  v-if="index + 1 != quartersFile.length"> , </span>  </span>
        </v-col>
      </v-col>

      <v-col cols="12" class="d-flex ml-6 align-center">
        <v-icon>mdi-star</v-icon>
        <v-col cols="5" class="text-start"><h4>Form 3508 for each of your PPP loans (if any)</h4></v-col>
        <v-col cols="3" class="text-start">
          <v-card
              @drop.prevent="drop($event,'ppp')"
              @dragover.prevent="dragoverData = true"
              @dragenter.prevent="dragoverData = true"
              @dragleave.prevent="dragoverData = false"
              :class="{ 'grey lighten-2': dragoverData }"
              height="35px"
              class="d-flex  align-center justify-center"
              style="background-color: #283c4c;
                   box-shadow: none;width: 130px;
                   border-radius: 9999px !important;cursor: pointer" >
            <label style="color: #FFFFFF;font-size:0.875rem;
            cursor: pointer;font-weight: 500;    letter-spacing: 0.0892857143em;text-transform: uppercase;">Upload

              <input type="file" multiple name="fields[assetsFieldHandlePpp][]"
                     id="assetsFieldHandlePpp"
                     class="d-none absolute" @change="onChange('ppp')" ref="pppFile" /></label>
          </v-card>

        </v-col>
        <v-col cols="4" class="text-start fileNames" v-if="pppFormFile.length" :class="{ show: pppShow }"
               @click="pppShow = !pppShow">
          <span   v-for="(pppFile,index) in pppFormFile" >{{pppFile.original_file_name}} <span  v-if="index + 1 != pppFormFile.length"> , </span>
             </span>
        </v-col>
      </v-col>

      <v-col cols="12" class="d-flex ml-6 align-center">
        <v-icon>mdi-star</v-icon>
        <v-col cols="5" class="text-start"><h4>Payroll Spreadsheet (as described above in #8)</h4></v-col>
        <v-col cols="3" class="text-start">
          <v-card
              @drop.prevent="drop($event,'payroll')"
              @dragover.prevent="dragoverData = true"
              @dragenter.prevent="dragoverData = true"
              @dragleave.prevent="dragoverData = false"
              :class="{ 'grey lighten-2': dragoverData }"
                  height="35px"
                  class="d-flex  align-center justify-center"
                  style="background-color: #283c4c;
                   box-shadow: none;width: 130px;
                   border-radius: 9999px !important;cursor: pointer" >
            <label style="color: #FFFFFF;font-size:0.875rem;
            cursor: pointer;font-weight: 500;    letter-spacing: 0.0892857143em;text-transform: uppercase;">Upload

            <input type="file" multiple name="fields[assetsFieldHandlePayroll][]"
                   id="assetsFieldHandlePayroll"
                   class="d-none absolute" @change="onChange('payroll')" ref="file" /></label>
          </v-card>

        </v-col>
        <v-col cols="4" class="text-start fileNames" v-if="payRollFile.length" :class="{ show: payrollShow }"
               @click="payrollShow = !payrollShow">
          <span   v-for="(payRoll,index) in payRollFile" >{{payRoll.original_file_name}}
            <span  v-if="index + 1 != payRollFile.length"> ,</span> </span>
        </v-col>
      </v-col>
    </v-row>

    <v-btn color="#263B4F" class="mt-16">
      <span style="color: #FFFFFF" @click="save">Save and Continue</span>
    </v-btn>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-dialog
        v-model="errorMessage"
        width="500"
    >
      <v-card class="pa-16" style="background-color: #263B4F">
        <h2 style="color: #FFFFFF" class="text-center mb-7">The chosen file is over the File Size limit of 25Mb. Please try uploading a new file.</h2>
        <v-progress-linear absolute color="red"
                           :v-model="errProgressBarValue"
                           :buffer-value="bufferValue">
        </v-progress-linear>
      </v-card>


    </v-dialog>
  </div>
</template>

<script>
import ClientService from "../../services/clients.service";
import AuthService from "../../services/auth.service";
export default {
  name: "Step9",
  props: {
    clientId: {
      type: Number
    }
  },
  data () {
    return{
      pppShow:false,
      qarterShow:false,
      payrollShow:false,
      errorMessage: false,
      errProgressBarValue: 0,
      bufferValue: 0,
      payRollFile:[],
      pppFormFile:[],
      quartersFile:[],
      currentUser: null,
      loading:false,
      fileList:[],
      dragoverData:false,
      forDate: new Date(),
      interval: 0,
    }
  },
  methods:{
    async drop(event,type) {
      event.preventDefault();
      this.dragoverData = false;

      const input = event.dataTransfer.files[0];
      const reader = new FileReader();

      reader.readAsText(input);

      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(type); // Trigger the onChange event manually
    },
    myFilter(type){
      switch (type) {
        case 'payroll':
          this.payrollShow = !this.payrollShow;
          break;
        case 'quarters':
          this.qarterShow = !this.qarterShow;
          break;
        case 'ppp':
          this.pppShow = !this.pppShow;
          break;
      }
    },
    factoryFiles(type){
      switch (type) {
      case 'payroll':
        return this.$refs.file.files;
        break;
      case 'quarters':
        return this.$refs.qarterFile.files;
        break;
      case 'ppp':
        return this.$refs.pppFile.files;
        break;
      }
    },
    onChange(type){
      const uploadedFiles = this.factoryFiles(type);
      for (let i = 0; i < uploadedFiles.length; i++) {
        if (uploadedFiles[i].size < 25000000){
          this.isActiveButton = true;
          let uploadedFile = uploadedFiles[i]
          const newFile = {
            original_file_name: uploadedFile.name,
            url: uploadedFile,
            created_on: this.forDate,
            mime_type: uploadedFile.type,
            uploaded_by: this.currentUser.id,
            uploadedFile: uploadedFile,
            uploadingFiles: false,
          }
          if (uploadedFile) {
            this.onChangeFactory(type,newFile)
          }
        }else{
          const removeMessageAfter = 2500
          this.errorMessage = true;
          this.startBuffer()
          setTimeout(()=>{
            this.errorMessage = false
            clearInterval(this.interval);
            this.errProgressBarValue = 0;
            this.bufferValue = 0;
          }, removeMessageAfter)
        }

      }
    },
    startBuffer() {
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.errProgressBarValue += 2;
        this.bufferValue += 2;
      }, 50);
    },
    onChangeFactory(type,item) {
      switch (type) {
        case 'payroll':
          this.payRollFile.push(item)
          break;
        case 'quarters':
          this.quartersFile.push(item)
          break;
        case 'ppp':
          this.pppFormFile.push(item)
          break;
      }
      // this.fileList = [this.$refs.file.files[0]];
    },
    save(){
      if (!finalData.length){
        window.location.replace("/dashboard");
      }
      this.loading = true;
      const finalData = [...this.pppFormFile,...this.quartersFile,...this.payRollFile]
      finalData.forEach((e, index) => {
          let formData = new FormData();
          formData.append('uploaded_file', e.uploadedFile);
          formData.append('upload_type', 'filedrop');
          this.postItem(formData,index)
      })
      this.loading = false

      setTimeout(()=>{
        window.location.replace("/dashboard");
      },1000)
    },
    postItem(formData) {
      ClientService.uploadClientCSVFile(this.clientId, formData)
          .then((res) => {
            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: 'File uploaded successfully',
              duration: 4000,
              mode: 'success'
            });
          }).catch(error => {
        this.$func.popMessage(`${error.response.data.error}`, 'fail')
      })

    }},

  async created(){
    const data = await AuthService.getCurrentUserFromCookie()
    this.currentUser = data.data
  }
}
</script>

<style scoped>
.fileNames{
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}
.show{
  white-space: break-spaces;
  overflow: unset;
}
</style>