<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
          :loading="itemsLoading"
          :headers="headers"
          :items="items"
          hide-default-footer
          class="elevation-2"
          style="cursor: pointer"
          :search="searchValue"
          @click:row="(item) => this.$router.push(`/queues/transcript/${item.queue_id}`)"
      >
        <template #top>
          <div class="pa-3">
            <v-text-field
                v-model="searchValue"
                :disabled="!items.length"
                hide-details
                append-icon="mdi-magnify"
                label="Search client"
            />
          </div>
        </template>
        <template v-slot:item.completion="{ item }">
          <span class="font-weight-bold" v-if="!+item.completed_tasks && !+item.uncompleted_tasks">NO TASKS YET!</span>
          <v-progress-linear
              v-else
              :style="{width: isMobile ? '150px' : null}"
              :value="item.completion"
              :height="25"
          >
            <strong style="color: white"
            >{{item.completion}}%</strong
            >
          </v-progress-linear>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import TranscriptService from "@/services/transcript.service";

export default {
  name: "TranscriptQueueAOR",
  data: () => ({
    searchValue: "",
    itemsLoading: false,
    items: [],
    headers: [
      { text: "Client", sortable: false, value: "trade_name", width: '33%' },
      { text: "Legal Name", sortable: false, value: "legal_name", width: '33%' },
      { text: "Progress", sortable: true, value: "completion", width: '33%' },
    ],
    showInProgress: true,
  }),
  methods: {
    getItems() {
      this.itemsLoading = true;
      Promise.all([
          TranscriptService.getTranscriptQueueByFilter(0),
          TranscriptService.getTranscriptQueueByFilter(1)
      ]).then((res) => {
        this.items = [...res[0], ...res[1]];
        this.items.forEach(e => {
          let progress = this.calculatePercentage(e.completed_tasks, +e.completed_tasks + +e.uncompleted_tasks)
          e.completion = isNaN(progress) ? -1 : progress
        })
      }).finally(() => (this.itemsLoading = false));

    },
    calculatePercentage(part, whole) {
      return Math.ceil((part / whole) * 100);
    },
  },
  created() {
    this.getItems();
  },
  watch: {
    showInProgress() {
      this.getItems();
    },
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  }
};
</script>
