import $axios from '../plugins/axios'

class ReleaseService {

    getReleases() {
        return $axios.get(`releases`)
            .then((res) => {
                return res.data;
            });
    }

    getSingleRelease(id) {
        return $axios.get(`releases/${id}`)
            .then((res) => {
                return res.data;
            });
    }

}

export default new ReleaseService();