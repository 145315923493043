<template>
  <div class="main-permissions-div">
    <h4 class="text-center">Click the <strong>deputize</strong> toggle to enable or disable that person's ability to log into your account.</h4>
    <p class="text-center">When the toggle is <i>enabled</i>, that person will be able to log in as you. When it is <i>disabled</i>, that person will not be able to log in as you.</p>
    <v-progress-linear indeterminate v-show="loading"></v-progress-linear>
    <v-row class="titles-row" v-show="!loading">
      <v-col>
        <div class="col-titles">
          <p>Name</p>
        </div>
      </v-col>

      <v-col>
        <div class="col-titles">
          <p>Deputize</p>
        </div>
      </v-col>
    </v-row>
    <v-row v-show="!loading" v-for="user in users" :key="'user' + user.id" class="deputize-row">
      <v-col>
        <div class="users-name">
          {{ user.fullname }}
        </div>
      </v-col>
      <v-col>
        <div class="users-toggle">
          <v-switch
              @click="newUserDeputized(user)"
              v-model="user.deputized"
              color="#48A64C"
              class="switchDraftOrFinal"
              hide-details
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AuthService from "../services/auth.service"

export default {

  name: "Permissions",

  data: () => ({
    users: [],
    objectDeDeputized: [],
    loading: false,
  }),

  methods: {

    setCurrentPermissions() {
      return AuthService.checkForPermissions()
          .then((res) => {
            this.users = res;
            this.handleDeputized()
          })
    },

    sendDeputizedPermissions(permissioned) {
      return AuthService.setPermissions(permissioned)
          .then((res) => {
            ;
          })
    },
    handleDeputized() {
      this.users.forEach(user => {
        if (user.deputized) {
          this.objectDeDeputized.push(user.id)
          
        }
      })
      this.loading = false
    },
    newUserDeputized(user) {
      if (user.deputized) {
        this.objectDeDeputized.push(user.id)
        this.sendDeputizedPermissions(this.objectDeDeputized)
      } else {
        this.objectDeDeputized.forEach((e, index) => {
          if (e === user.id) {
            this.objectDeDeputized.splice(index, 1)
            this.sendDeputizedPermissions(this.objectDeDeputized)
          }
        })
      }
    }
  },


  // watch: {
  //   users: {
  //     handler(newValue) {
  //       let objectDeDeputized = [];
  //
  //       for (let i = 0; i < newValue.length; i++) {
  //         if (newValue[i].deputized) {
  //           objectDeDeputized.push(newValue[i].id);
  //         }
  //       }
  //
  //       this.sendDeputizedPermissions(objectDeDeputized);
  //
  //     }, deep: true
  //   }
  // },

  created() {
    this.loading = true
    this.setCurrentPermissions();
  }
}

</script>

<style lang="scss" scoped>
.main-permissions-div {
  margin-top: 50px;
}

.col-titles {
  color: gray;
  font-size: 1.2rem;
}

.titles-row {
  border-bottom: 2px solid black;
}

.deputize-row {
  border-bottom: 2px solid black;
}
</style>
