const state = () => ({
    refreshUploadReports: false,
});

const getters = {
    getRefreshUploadReports(state) {
        return state.refreshUploadReports;
    }
}

const mutations = {
    setRefreshUploadReports(state, data) {
        state.refreshUploadReports = data;
    }
}

const actions = {
    changeRefreshUploadReports(vuexContext, data) {
        vuexContext.commit('setRefreshUploadReports', data);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
