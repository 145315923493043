<template>
  <div>
    <div class="mb-5">
      <h2 class="mb-2" v-if="!switchBtn">Ready to Ship</h2>
      <h2 class="mb-2" v-if="switchBtn">Shipped</h2>
      <v-row justify="space-between" align="center">
        <v-col v-if="!switchBtn" class="d-flex" style="gap: 15px">
          <v-btn color="primary" :disabled="!selectedItemsUT.length" :loading="markLoading" @click="markAsShipped">mark as shipped</v-btn>
          <v-chip v-if="selectedItemsUT.length" @click:close="selectedItemsUT = []" close color="primary">{{selectedItemsUT.length}} selected</v-chip>
        </v-col>
        <v-col class="d-flex justify-end">
          <div class="d-flex align-center">
            <h3 class="mr-4">Ready to Ship/Shipped</h3>
            <v-switch v-model="switchBtn" @click="switchClicked"></v-switch>
          </div>
        </v-col>
      </v-row>
      <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          :show-select="!switchBtn"
          v-model="selectedItemsUT"
          disable-pagination
          class="elevation-1 mt-5"
          hide-default-footer
          loading-text="Loading... Please wait"
          :search="search"
      >
        <template v-slot:item.uses_peo="{ item }">
          <div>
            {{peoField(item.uses_peo)}}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
           <v-row justify="space-between">
             <v-col class="d-flex" style="gap: 7px">
               <v-btn :disabled="!item.collated_url" small color="primary" @click="downloadFinalPackage(item)">final package</v-btn>
               <v-btn :disabled="!item.label_url" small color="primary" @click="downloadLabel(item)">label</v-btn>
             </v-col>
           </v-row>
        </template>
        <template v-slot:top="item" >
          <v-text-field
              v-model="search"
              :disabled="!items.length"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="mx-5"
          ></v-text-field>
        </template>
      </v-data-table>
    </div>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        dark
        fixed
        bottom
        right
        color="primary"
        @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
    <v-dialog  v-model="fedexDialog" >
    <v-card class="pa-10" style="height: 40vh">
    <h2 class="mb-4 font-weight-medium text-p ml-2" >Create Box</h2>
    <v-card
      elevation="4"
      outlined
      style="height: 25vh"
    >
    <div class="d-flex justify-center flex-wrap">
      <div class="box-container mx-2">
      <v-select
        v-model="selectedClients"
        :items="combined"
        item-text="trade_name"
        item-value="id"
        label="Clients"
        multiple
      >
      </v-select>
      <v-btn color="#263B4F" class="white--text" id="shipIRS" @click="shipToIRS" :disabled="!selectedClients.length">Ship to IRS</v-btn>
      </div>
    <v-text-field placeholder="Fedex tracking number" v-model="fedexNumber" class="box-container mx-2"></v-text-field>  
    </div>
    </v-card>
    </v-card>
    </v-dialog>
    
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
export default {
  name: "ReadyToFile",
  data(){
    return{
      search: '',
      items:[],
      selectedItemsUT: [],
      UtahIDs: [],
      selectedItemsOH: [],
      OhioIDs: [],
      switchBtn:false,
      loading:true,
      fedexDialog:false,
      fedexNumber:null,
      selectedOH:[],
      selectedUT:[],
      combined:[],
      selectedClients:[],
      selectedTraderIDs:[],
      selectedClientsIDsAndFedex:{},
      fab: false,
      headers: [
        { text: 'Client', value: 'legal_name' },
        { text: 'PEO', value: 'uses_peo' },
        { text: 'Q4', value: 'q4_refund_amount_formatted' },
        { text: 'Q1', value: 'q1_refund_amount_formatted' },
        { text: 'Q2', value: 'q2_refund_amount_formatted' },
        { text: 'Q3', value: 'q3_refund_amount_formatted' },
        { text: 'Download', value: 'actions', sortable: false },
      ],
      markLoading: false,
    }
  },
  methods:{
    markAsShipped() {
      this.markLoading = true;
      this.selectedItemsUT.map(item => this.UtahIDs.push(item.id))

      ClientsService.markAsShipped(this.UtahIDs)
        .then(res => {
          this.$func.popMessage('Successfully submitted', 'success')
          this.selectedItemsUT = []
          this.switchBtn = true;
          this.switchClicked()
        }).finally(() => this.markLoading = false)
    },
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    shipToIRS(){
       let model = {
         "fedex_number":this.fedexNumber,
         "clients":this.selectedClients
       }

      ClientsService.patchReadyToFile(model)
          .then(()=>this.getItems(0)).catch((err)=> {
            this.$func.popMessage(`${err.response.data.error}`, 'fail')
          })
    },
    switchClicked(){
      this.loading=true
      this.switchBtn ? this.getItems(1) : this.getItems(0)
    },
    downloadFinalPackage(item){
      window.open(item.collated_url, '_blank');
    },
    downloadLabel(item){
      window.open(item.label_url, '_blank');
    },
    getItems(filter){
      this.loading=true
       ClientsService.getReadyToFile(filter).then(res=>{
         // if (filter == 1){
         //   this.headers = [
         //     { text: 'Client', value: 'trade_name' },
         //     { text: 'Q4', value: 'additional_data[0].refund_amount_formatted' },
         //     { text: 'Q1', value: 'additional_data[1].refund_amount_formatted' },
         //     { text: 'Q2', value: 'additional_data[2].refund_amount_formatted' },
         //     { text: 'Q3', value: 'additional_data[3].refund_amount_formatted' },
         //     { text: 'Fedex Number', value: 'fedex_tracking' },
         //   ]
         // }
         this.items=res
         this.loading=false      
       }).then(()=>{
        //this.combined=[...this.items.OH_office,...this.items.UT_office]
       }).catch(()=>this.loading=false)
    },
    peoField(field) {
      return field==1? 'Yes':'No'
    }
  },
  created() {
    this.getItems(0)
  }
}
</script>

<style scoped>
.box-container{
  min-width: 300px;
  width: 45%;
}
#shipIRS{
  width: 100%;
}
</style>
