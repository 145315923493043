<template>
<div id="ready-to-reconcile">
  <RadioGroupComponent @radio="radioFilter"></RadioGroupComponent>
  <v-container id="Rtable">
    <v-data-table
    v-model="selected"
    :headers="headers"
    :items="documents"
    item-key="client_id"
    disable-pagination
    hide-default-footer
    class="elevation-1"
    mobile-breakpoint="0"
  >
      <template v-slot:item.legal_name="{ item }">
        <div style="cursor: pointer" @click="goToClient(item.client_id, 1)">
          {{item.legal_name}}
        </div>
      </template>

      <template v-slot:item.trade_name="{ item }">
        <div style="cursor: pointer" @click="goToClient(item.client_id, 1)">
          {{item.trade_name}}
        </div>
      </template>
      <template v-slot:item.original_file_name="{ item}">
       <a
           :download="item.original_file_name"
           :href="item.url"
           target="_blank"
           class="ml-2"
           type="button"
           title="Download file">{{item.original_file_name}}</a>
      </template>
  <template v-slot:item.status_name="{ item}">
      <v-chip
        :color="getColor(item.status_name)"
      >
        {{ item.status_name }}
      </v-chip>
    </template>

    <template v-slot:item.actions="{ item }">
       <v-btn @click="acceptFile(item)" class="mr-3"  color="success">Accept</v-btn>
    </template>

      <template v-slot:item.actions2="{ item }">
       <v-btn @click="openRejectDialog(item)" color="red">Reject</v-btn>
      </template>
      <template v-slot:item.uses_peo="{ item }">
      <div>
        {{peoField(item.uses_peo)}}
      </div>
    </template>
  </v-data-table>
    <div v-if="documents.length" class="d-flex justify-space-between mx-12 mt-6">
      <h3 v-if="documents.length" class=" ml-2">Total client count: {{documents.length}}</h3>
     <div >
       <!-- <h4>Blocked: {{blocked}}</h4>
       <h4>Checked out: {{claimed}}</h4> -->
       <h4>Available: {{ documents.length - claimed }}</h4>
     </div>
    </div>
  </v-container>
  <v-overlay :value="loading">
    <v-progress-circular
        :size="70"
        :width="7"
        color="white"
        indeterminate
    ></v-progress-circular>
  </v-overlay>

  <v-dialog width="800" v-model="rejDialog">
    <div class="pa-10">
      <v-card>
        <div class="pa-2">
          <h4>Please describe why you reject that file</h4>
          <div class="mb-5 d-flex flex-row align-center">
            <v-text-field
                v-model="rejectReason"

            ></v-text-field>

            <v-btn  @click="rejectFile" class="ml-5" style="height: 40px; width: 15%;" color="#263B4F">
              <span style="color: #FFFFFF">reject</span>
            </v-btn>
          </div>
        </div>
      </v-card>
    </div>
  </v-dialog>

  <v-dialog width="800" v-model="dialog">

    <v-card>
      <div class="pa-2">
        <h4>Upload Completed Files for {{selectedClient.trade_name}}</h4>
        <div class="mb-5 d-flex flex-row align-center">
          <v-file-input
              v-model="uploadedFile"
              counter
              show-size
              accept=".csv, .xlsx"
              truncate-length="15"
              label="File input"
              class="mr-5"
              ref="sanitizedFile"
          ></v-file-input>

          <v-btn :loading="uploadLoading" @click="uploadBtn"  style="height: 40px; width: 15%;" color="primary">
            <span style="color: #FFFFFF">Upload</span>
          </v-btn>
        </div>
      </div>
    </v-card>

  </v-dialog>
  <ProfileClientDialog
      v-if="showRightDialogChosenClient"
      :key="dialogKeyChosenClient"
      :showRightDialogChosenClient.sync="showRightDialogChosenClient"
      :clientProp="clientProp"
      @openClientProfileDialog="openClientProfileDialog"

  />
</div>
</template>
<script>
import ClientsService from "@/services/clients.service";
import ClientService from "@/services/clients.service";
import ProfileClientDialog from "@/components/Client/ProfileClientDialog.vue";
import SanitizerService from "@/services/sanitizer.service";
import RadioGroupComponent from  "./RadioGroupComponent.vue"
import {mapGetters} from "vuex";
export default {
  components: {ProfileClientDialog,RadioGroupComponent},
    data () {
      return {
        dialog:false,
        uploadedFile:'',
        uploadLoading: false,
        selected: [],
        headers: [
          {text: 'Legal name',align: 'start',sortable: false, value: 'legal_name'},
          {text: 'Trade name',align: 'start',sortable: false, value: 'trade_name'},
          { text: 'Uploaded File', value: 'original_file_name' },
          { text: 'Days in queue', value: 'days_in_queue' },
          { text: 'Status', value: 'status_name' },
          { text: '', value: 'actions' },
          { text: '', value: 'actions2' },
          { text: 'PEO', value: 'uses_peo' }
        ],
        documents: [],
        showRightDialogChosenClient:false,
        dialogKeyChosenClient:33,
        clientId:'',
        clientProp:{},
        loading:false,
        selectedClient:{},
        statuses:[],
        rejectReason:'',
        rejDialog:false,
        claimed:0,
        blocked:0,
        selectedClientName:'',
        radioRow:'radio-0'
      }
    },
  computed: {
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
          // clientID: 'sanitization/getClientID',
          // daysInQueue: 'sanitization/getDaysInQueue',
          // sanitizeJobID: 'sanitization/getSanitizeJobID',
          // tradingName: 'sanitization/getTradingName'
        },
    ),
  },
    methods: {
      // claimJob(item){
      //   this.loading=true
      //   const model = {
      //     "status": 4
      //   }
      //   ClientsService.patchReconciliation(item.queue_id,model)
      //       .then(()=>{
      //         this.getReconciled()
      //       })
      // },
      goToClient(id, routeState){
        this.loading = true
        if(routeState === 1) this.$router.push(`/clients/${id}/profile`)
        else if(routeState === 2) this.$router.push(`/clients/${id}/admin`)
      },
      openClientProfileDialog() {
        this.showRightDialogChosenClient = true;
        this.dialogKeyChosenClient++;
        this.loading = false
        this.showRightDialog = false;
      },
      openRejectDialog(item){
        this.rejDialog = true
        this.selectedClient=item
      },
      uploadBtn() {
        this.uploadLoading= true
        const formData = new FormData();
        const csvFile = [this.uploadedFile];
        formData.append("uploaded_file", csvFile[0]);
        formData.append('upload_type', 'payroll');
        ClientService.uploadClientCSVFile(this.selectedClient.client_id, formData)
            .then(() => {
              this.$store.dispatch('snackbar/showSnackbarMessage', {
                message: 'File uploaded successfully',
                duration: 4000,
                mode: 'success'
              });
              this.patchClient(2)
              this.goToClient(this.selectedClient.client_id, 2)
            })
            .catch(()=>this.processingFile = false)
            .finally(() => this.uploadLoading = false)
      },
      patchClient(stat){
        const model = {
          'status':stat,
          'reason':this.rejectReason
        }
        ClientsService.patchReconciliation(this.selectedClient.queue_id,model)
            .then(res=>{
              this.getReconciled()
              this.uploadedFile = ''
              this.rejDialog=false
              this.dialog=false
              this.loading=false
            })
      },
      acceptFile(item) {
        this.dialog = true
        this.selectedClient = item
      },
      rejectFile(item) {
        this.patchClient(3)
      },
      getColor (status) {
        if (status== 'Rejected') return 'red'
        else if (status== 'Reconciled') return 'black'
        else return 'green'
      },

      getReconciled(payload){
        ClientsService.getReadyToReconcileDocs(payload)
          .then((res)=>{
            this.claimed=0
            this.blocked=0
            this.documents = res
            this.documents.forEach(e=>{
              if (e.status_id == 3){
                this.blocked++
              }else if(e.status_id == 4){
                this.claimed++
              }
            })
            this.loading=false
          }).catch((err) => {console.log('err:',err)})
      },
      getStatuses() {
        ClientsService.getReconciliationQueueStatuses()
          .then(res => {
            this.statuses = res
          }).catch((err) => {console.log('err:',err)})
      },
      reconcile(id,status){
        ClientsService.patchReadyToReconcile(id, status)
          .then((res)=>{}).catch((err) => {console.log('err:',err)})
      },
      patch(status){
        if(!this.selected.length) return
        status=Number(status)
        const data = {
          "status":status
        };
        this.selected.forEach((element,i) => {
          this.reconcile(element.queue_id, data)
        });
        this.getReconciled()
      },
      peoField(field) {
        return field==1? 'Yes':'No'
      },
    radioFilter(value){
      this.radioRow=value
      this.switchBusinessType(value)
    },
    switchBusinessType(value){
        const payload= {
        business_type:'941'
      }
      if (value=='radio-1'){
        payload.business_type='943'
      } else if (value=='radio-2') {
        payload.business_type='944'
      }
        this.getReconciled(payload)
      },
    },
    mounted(){
      this.getReconciled()
      this.getStatuses()
    }
};
</script>
<style scoped>
#ready-to-reconcile .v-btn{
    color:#fff;
    background: #263B4F;

}
#ready-to-reconcile .btnHolder{
    text-align: center;
}
#Rtable .v-data-table{
    margin: 0 auto;
}

#ready-to-reconcile .text-start span{
  color: white;
}
</style>
