<template>
  <section :id="item.id" :class="{editable:editing}" data-fields="title=txt&amp;body=rte">
    <div class="container">
      <div class="w-50 m-auto text-center">
        <h2>{{item.title}}</h2>
        <p>{{item.body}}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Featured",
  props: ['item','editing'],
}
</script>

<style scoped>
@import "~@/components/PageBuilderComponents/editor/editor.css";

</style>
