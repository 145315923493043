<template>
	<v-dialog v-model="dialogDelete" max-width="500px">
		<v-card class="d-flex flex-column">

			<v-card-title style="background: #263B4F;">
				<h4 class="white--text"> Delete Client </h4>
			</v-card-title>

			<v-card-subtitle class="py-10 text--primary">
				<span style="color: #AF0808">
					WARNING: Deleting this client will delete the client, the reports, receivables, payroll data, and
				anything else assocated with the client as if it was never entered into the system. This action is
				IRREVERSEABLE. Are you sure you want to delete this client?
				</span>

			</v-card-subtitle>

			<v-spacer></v-spacer>

			<v-card-text>
				<h4>To continue, Please type “DELETE” In the text box below</h4>
				<v-text-field
					dense
					v-model="name"
					outlined
				>
				</v-text-field>
			</v-card-text>

			<v-container>
				<v-row class="d-flex justify-end">
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							id="cancel-button"
							text
							@click.native="closeDelete"
						>
							Cancel
						</v-btn>

						<v-btn
							id="confirm-delete-button"
							text
							@click.native="deleteItemConfirm"
						>
							ACCEPT
						</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</v-row>
			</v-container>
		</v-card>

	</v-dialog>
</template>

<script>
	import ClientService from "../../services/clients.service";

	export default {
		name: "ClientDeleteDialog",

		props: {

			value: {
				Boolean,
				default: false
			},

			editedItemProp: {
				type: Object,
				required: true
			},
		},

		data() {
			return {
				name: '',
				editedItem: this.editedItemProp,
			}
		},

		methods: {

			deleteItemConfirm() {

				if (this.name == 'DELETE') {

					return ClientService.deleteClient(this.editedItemProp.id)
						.then((data) => {

							this.$emit('forceUpdate');
							this.$store.dispatch('snackbar/showSnackbarMessage',
								{message: 'Client was deleted!', duration: 6000})
						})
						.catch((error) => {
              this.$func.popMessage(`${error.response.data.error}`, 'fail')
						});

					this.closeDelete();
				} else {

					this.$store.dispatch('snackbar/showSnackbarMessage', {
						message: 'To delete Client you must write DELETE in the box',
						duration: 6000,
						mode: 'fail'
					});

					return;
				}

			},

			closeDelete() {

				this.dialogDelete = false;
			},
		},

		computed: {

			dialogDelete: {

				get() {
					return this.value
				},

				set(value) {
					this.$emit('input', value)
				}
			},

		},
	}
</script>

<style scoped>

</style>