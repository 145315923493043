<template>
  <DialogRightAlign :showDialog.sync="showRightDialog" titleProp="Create Client">
    <v-card-text>
      <v-container fluid>
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" style="padding-top: 15px !important">
            <v-text-field
              id="business-name"
              v-model="client.legal_name"
              outlined
              label="Legal Business Name"
              color="black"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              id="trade-name"
              v-model="client.trade_name"
              :error-messages="trade_nameErrors"
              required
              outlined
              @input="$v.client.trade_name.$touch()"
              @blur="$v.client.trade_name.$touch()"
              label="Trade Name"
              color="black"
            />
          </v-col>
          <v-col cols="12">
            <v-switch
              v-model="client.uses_peo"
              :value="1"
              :input-value="0"
              :true-value="1"
              :false-value="0"
              class="mt-0 mb-3"
              label="Client Uses PEO"
            ></v-switch>
          </v-col>
          <v-col cols="12" id="radioGroup">
            <v-container fluid class="pa-0">
              <v-radio-group v-model="client.business_type" row>
                <v-radio
                  v-for="(radio, i) in radioButtons"
                  :key="i"
                  :label="`${radio.name}`"
                  :value="i + 1"
                  @input="$v.client.business_type.$touch()"
                ></v-radio>
              </v-radio-group>
            </v-container>
          </v-col>
          <v-col cols="12">
            <v-text-field
              id="ein"
              @keydown.space.prevent
              v-model="client.ein"
              :error-messages="einErrors"
              required
              outlined
              @input="$v.client.ein.$touch()"
              @blur="$v.client.ein.$touch()"
              label="EIN"
              maxlength="10"
              color="black"
            />
          </v-col>

          <v-col cols="12">
<!--            <div class="loader" v-if="loadingNaics">-->
<!--              <div class="loaderBar"></div>-->
<!--            </div>-->

            <v-combobox
              id="naics-code"
              outlined
              @input="$v.client.naics_code.$touch()"
              @blur="$v.client.naics_code.$touch()"
              v-model="client.naics_code"
              :items="naicsItems"
              item-text="code"
              item-value="id"
              no-filter
              @update:search-input="searchNaicsCodes"
              label="Naics Code"
              @input.native="naicSearch=$event.srcElement.value"
            >
              <template v-slot:no-data>
                <h6 v-if="naicSearch && !loadingNaics" class="ml-5 font-weight-bold">No results</h6>
                <div v-if="loadingNaics" class="ml-5">
                  <v-progress-circular indeterminate></v-progress-circular>
                </div>
              </template>
            </v-combobox>


            <!--            <v-combobox-->
            <!--              :item-text="(obj) => obj.code"-->
            <!--              :item-value="(obj) => obj.id"-->
            <!--              v-model="client.naics_code"-->
            <!--              @update:search-input="searchNaicsCodes"-->
            <!--              :search-input.sync="client['naics_code']"-->
            <!--              :items="naicsItems"-->
            <!--              :return-object="false"-->
            <!--            >-->
            <!--            </v-combobox>-->
          </v-col>

          <v-col cols="12">
            <v-text-field
              id="contact_phone"
              v-model="client.contact_phone"
              outlined
              label="Contact phone"
              maxlength="10"
              color="black"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              id="street"
              v-model="client.street"
              outlined
              label="Street"
              color="black"
            />
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col cols="4">
                <v-text-field id="city" v-model="client.city" outlined label="City" color="black" />
              </v-col>
              <v-col cols="4">
                <v-select
                  id="state"
                  :append-icon="'mdi-arrow-down'"
                  outlined
                  :items="states"
                  v-model="client.state"
                  :item-text="'name'"
                  :item-value="'abbreviation'"
                  label="States"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  id="zip"
                  v-model="client.zip"
                  type="number"
                  outlined
                  label="ZIP"
                  color="black"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <v-select
                  id="contact-saluattion"
                  :append-icon="'mdi-arrow-down'"
                  outlined
                  :items="salutationList"
                  :error-messages="salutation_err"
                  required
                  v-model="client.contact_salutation"
                  @input="$v.client.contact_salutation.$touch()"
                  @blur="$v.client.contact_salutation.$touch()"
                  :item-text="'name'"
                  :item-value="'name'"
                  label="Mrs"
                ></v-select>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  id="contact-first-name-full"
                  v-model="client.contact_first"
                  :error-messages="contact_firstErrors"
                  required
                  outlined
                  @input="$v.client.contact_first.$touch()"
                  @blur="$v.client.contact_first.$touch()"
                  label="First Name for the point of contact"
                  color="black"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-text-field
              id="contact-last-name"
              v-model="client.contact_last"
              :error-messages="contact_lastErrors"
              required
              outlined
              @input="$v.client.contact_last.$touch()"
              @blur="$v.client.contact_last.$touch()"
              label="Last Name for the point of contact"
              color="black"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              id="contact-email"
              v-model="client.contact_email"
              :error-messages="contact_emailErrors"
              required
              outlined
              @input="$v.client.contact_email.$touch()"
              @blur="$v.client.contact_email.$touch()"
              label="Email for the point of contact"
              color="black"
            />
          </v-col>

          <v-col cols="12">
            <v-select
              id="tax-firm"
              :append-icon="'mdi-arrow-down'"
              v-model="client.tax_firm"
              :error-messages="tax_firmErrors"
              required
              type="number"
              outlined
              @input="$v.client.tax_firm.$touch()"
              @blur="$v.client.tax_firm.$touch()"
              label="Tax Firm"
              :items="taxes"
              :item-text="'name'"
              :item-value="'id'"
            />
          </v-col>

          <v-col cols="12">
            <v-select
              id="managing-firm"
              :append-icon="'mdi-arrow-down'"
              v-model="client.managing_firm"
              @input="$v.client.managing_firm.$touch()"
              @blur="$v.client.managing_firm.$touch()"
              :error-messages="managing_firmErrors"
              required
              outlined
              :items="laws"
              :item-text="'name'"
              :item-value="'id'"
              label="Managing Firm"
            />
          </v-col>
          <v-col cols="12">
            <v-select
              id="aor"
              v-model="client.attorney_of_record"
              item-text="name"
              item-value="id"
              :append-icon="'mdi-arrow-down'"
              :items="attorneys"
              label="Attorney of Record"
              :loading="attorneysLoading"
              :disabled="client.managing_firm === ''"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field
              @keydown.space.prevent
              id="contingency-rate"
              v-model="client.contingency_rate"
              required
              outlined
              label="Contingency rate (in points)"
              color="black"
            />
          </v-col>
        </v-row>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-card-text>

    <v-spacer></v-spacer>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#263B4F" text @click.native="closeDialog"> close</v-btn>

      <v-btn id="dialog-create-client-btn" color="#263B4F" text @click.native="createNewClient" :disabled="$v.client.$invalid">
        Create Client Profile
      </v-btn>
    </v-card-actions>
  </DialogRightAlign>
</template>

<script>
import { email, required, helpers } from "vuelidate/lib/validators";

import DialogRightAlign from "@/components/Base/DialogRightAlign";
import ClientService from "../../services/clients.service";
import { statesConstData } from "@/constants";
import { mapGetters } from "vuex";
import TaxService from "../../services/tax.service";
import LawService from "../../services/law.service";
import AuthService from "../../services/auth.service";

const einValidator = helpers.regex("einValidator", /[0-9]{2}-[0-9]{7}/);

export default {
  name: "CreateClientDialog",
  components: { DialogRightAlign },

  props: {
    showRightDialog: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    business_type: 1,
    naicSearch: '',
    overlay: false,
    radioButtons: [],
    salutationList: [
      {
        name: "Mr.",
      },
      {
        name: "Mrs.",
      },
      {
        name: "Ms.",
      },
      {
        name: "Dr.",
      },
    ],
    client: {
      legal_name: "",
      trade_name: "",
      ein: "",
      naics_code: "",
      contact_phone: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      contact_first: "",
      contact_last: "",
      contact_email: "",
      attorney_of_record: "",
      owner: "",
      tax_firm: "",
      managing_firm: "",
      contingency_rate: "",
      contact_salutation: "",
      business_type: 1,
      uses_peo: 0,
    },
    selectedNaics: null,
    naicsItems: [],
    loadingNaics: false,
    states: statesConstData,
    taxes: [],
    laws: [],
    currentUserEntityId: null,
    attorneys: [],
    userRole: null,
    currUserObject: null,
    attorneysLoading: false,
  }),

  validations: {
    client: {
      legal_name: {
        required,
      },
      trade_name: {
        required,
      },
      business_type: {
        // required
      },
      ein: {
        required,
        einValidator,
      },
      naics_code: {
        required,
      },
      contact_first: {
        required,
      },
      contact_last: {
        required,
      },
      contact_email: {
        required,
        email,
      },
      tax_firm: {
        required,
      },
      managing_firm: {
        required,
      },
      contact_salutation: {
        required,
      },
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    selectTaxOrLawFirmIfIdTheSame() {
      this.laws.forEach((law) => {
        if (this.currentUserEntityId == law.id) {
          this.client.managing_firm = this.currentUserEntityId;
        }
      });

      this.taxes.forEach((tax) => {
        if (this.currentUserEntityId == tax.id) {
          this.client.tax_firm = this.currentUserEntityId;
        }
      });
    },

    contingency_rateErrors() {
      return this.handleErrors(
        this.$v.client.contingency_rate,
        "Please enter a contingency rate. 20 is normal."
      );
    },
    legal_nameErrors() {
      return this.handleErrors(this.$v.client.legal_name, "Legal Name Field is required.");
    },
    salutation_err() {
      return this.handleErrors(this.$v.client.contact_salutation, "Salutation Field is required.");
    },
    trade_nameErrors() {
      return this.handleErrors(this.$v.client.trade_name, "Trade Name Field is required.");
    },

    streetErrors() {
      return this.handleErrors(this.$v.client.street, "Street Field is required.");
    },

    cityErrors() {
      return this.handleErrors(this.$v.client.city, "City Field is required.");
    },

    stateErrors() {
      return this.handleErrors(this.$v.client.state, "State Field is required.");
    },

    zipErrors() {
      return this.handleErrors(this.$v.client.zip, "ZIP Field is required.");
    },

    naics_codeErrors() {
      return this.handleErrors(this.$v.client.naics_code, "Naics code Field is required.");
    },

    contact_firstErrors() {
      return this.handleErrors(this.$v.client.contact_first, "Contact First Field is required.");
    },

    contact_lastErrors() {
      return this.handleErrors(this.$v.client.contact_last, "Contact Last Field is required.");
    },

    einErrors() {
      const errors = [];
      if (!this.$v.client.ein.$dirty) return errors;
      !this.$v.client.ein.einValidator &&
        errors.push("Your EIN format is wrong. Ex: 00-0000000 (Use Digits only)");
      !this.$v.client.ein.required && errors.push("EIN is required.");
      return errors;
    },

    contact_emailErrors() {
      const errors = [];
      if (!this.$v.client.contact_email.$dirty) return errors;
      !this.$v.client.contact_email.email && errors.push("Please add a valid email.");
      !this.$v.client.contact_email.required && errors.push("Email Field is required.");
      return errors;
    },

    tax_firmErrors() {
      return this.handleErrors(this.$v.client.tax_firm, "Tax Firm Field is required.");
    },

    managing_firmErrors() {
      return this.handleErrors(this.$v.client.managing_firm, "Managing Firm Field is required.");
    },
  },

  methods: {
    searchNaicsCodes(search) {
      // Fetch the data from your service and update naicsItems
      if (search === null || !search.trim()) return
      else {
        this.loadingNaics = true;
        this.naicsItems = []
        ClientService.getNaicsCodes(search).then((res) => {
          this.naicsItems = res;
          this.loadingNaics = false;
        });
      }
    },
    deleteClient() {
      this.$emit("deleteDialog");
    },

    handleErrors(valObject, errorMsg) {
      const errors = [];

      if (!valObject.$error) return errors;
      !valObject.required && errors.push(errorMsg);
      return errors;
    },

    createNewClient() {
      if (this.client.naics_code && this.client.naics_code.id) {
        this.client.naics_code = this.client.naics_code.id;
      } else {
        // Handle the case where naics_code is missing or doesn't have an 'id'
        // You can display an error message or take appropriate action here
      }

      this.client.owner = this.currentUser.current_user_id;

      return ClientService.createClient({ ...this.client })
        .then(async (data) => {
          await this.$emit("getCurrentClient", data.id);
          await this.$emit("forceUpdate");
          await this.$emit("openClientProfileDialog");
          this.$emit("forceUpdate");

          this.closeDialog();

          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: "Client was created!",
            duration: 6000,
          });
        })
        .catch((error) => {
          this.$func.popMessage(`${error.response.data.error}`, "fail");
        });
    },

    getAllTaxes() {
      return TaxService.getTaxes().then((data) => {
        this.taxes = data;
      });
    },

    getAllLaws() {
      return LawService.getLaws().then((data) => {
        this.laws = data;
      });
    },

    closeDialog() {
      this.$emit("update:showRightDialog", false);
    },

    getCurrentUserEntityId() {
      return AuthService.getCurrentUser(this.currentUser.current_user_id).then((res) => {
        this.currentUserEntityId = res.data.parent_entity;
        this.userRole = res.data.role_id;
        this.currUserObject = res.data;
        if ((res.data.role_id === 7 || res.data.role_id === 4) && !this.attorneys.length){
          this.attorneys.push({
            "name":this.currUserObject.first + " " + this.currUserObject.last,
            "id":this.currUserObject.id
          })
          this.client.attorney_of_record = this.currUserObject.id;
        }
      });
    },
    //Loads managing attorneys per entity
    loadAttorneys() {
      if (this.userRole && (this.userRole === 7 || this.userRole === 4)){
        this.attorneys.push({
          "name":this.currUserObject.first + " " + this.currUserObject.last,
          "id":this.currUserObject.id
        })
        this.client.attorney_of_record = this.currUserObject.id;
        return
      }
      this.attorneysLoading = true;
      this.attorneys = [];
      ClientService.getEntitiesFiltered(`${this.client.managing_firm}`).then((res) => {
        res.users.map((e) => {
          if (e.role_id === 7 || e.role_id == 4) this.attorneys.push(e);
        });
      }).finally(() => this.attorneysLoading = false);
    },
    loadBusinessTypes() {
      ClientService.getBusinessTypes().then((res) => {
        this.radioButtons = res;
      });
    },
  },

  watch: {
    'client.managing_firm'(val) {
      if(val !== "")
        this.loadAttorneys()
    },
    laws(value) {
      if (value.length == 1) {
        this.client.managing_firm = value[0].id;
      }
    },

    taxes(value) {
      if (value.length == 1) {
        this.client.tax_firm = value[0].id;
      }
    },
  },

  async created() {
    await this.getCurrentUserEntityId();
    await this.loadBusinessTypes();
    this.client.uses_peo = 0;

    await this.getAllTaxes();
    await this.getAllLaws();
    // await this.loadAttorneys();
  },
};
</script>

<style scoped>
.delete_btn {
  background-color: red;
}

.col-12 {
  padding: 0px !important;
}

.v-text-field {
  border-top: 0px !important;
}

#radioGroup {
  margin-top: -25px;
}

.loader {
  margin:0 auto;
  border-radius:10px;
  bottom: 10px;
  position:relative;
  padding:1px;
}
.loader:before {
  content:'';
  border-radius:10px;
  position:absolute;
  top:-4px;
  right:-4px;
  bottom:-4px;
  left:-4px;
}
.loader .loaderBar {
  position:absolute;
  border: 1px black solid;
  border-radius:10px;
  top:0;
  right:100%;
  bottom:0;
  left:0;
  background:#fff;
  width:0;
  animation:borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left:0%;
    right:100%;
    width:0%;
  }
  10% {
    left:0%;
    right:75%;
    width:25%;
  }
  90% {
    right:0%;
    left:75%;
    width:25%;
  }
  100% {
    left:100%;
    right:0%;
    width:0%;
  }
}
</style>