<script>
import AdoptionsService from "@/services/adoptions.service";

export default {
  name: "PostAdoptReport",
  data: () => ({
    entities: [],
    filteredEntities: [],
    loading: true,
    searchValue: '',
    activeEntity: 0,
    adoptedEntities: {},
    adoptedEntitiesLoading: {},
  }),
  methods: {
    searchEntity(value) {
      this.filteredEntities = this.entities.filter((entity) => {
        return entity.name.toLowerCase().startsWith(value.toLowerCase())
      })
    },
    handleItemClick(item) {
      this.$router.push(`/post-adopt-report/${item.id}`)
    },
    async getEntities() {
      try {
        this.entities = await AdoptionsService.getAllAdoptions()
      } catch (e) {
        console.error(e)
      }
    },
    async getAdoptedEntities(id) {
      this.adoptedEntitiesLoading[id] = true;
      this.adoptedEntitiesLoading = {...this.adoptedEntitiesLoading}
      try {
        this.adoptedEntities[id] = await AdoptionsService.getAdoptedClientsOfEntity(id)
      } finally {
        this.adoptedEntitiesLoading[id] = false;
        this.adoptedEntitiesLoading = {...this.adoptedEntitiesLoading}
      }
    }
  },
  watch: {
    searchValue(newValue) {
      if (newValue)
        this.searchEntity(newValue)
      else
        this.filteredEntities = [...this.entities]
    },
    async activeEntity(val) {
      console.log(val)
      if (!this.adoptedEntities[val])
        await this.getAdoptedEntities(val)
    }
  },
  async mounted() {
    try {
      await this.getEntities()
    } finally {
      this.loading = false
    }

    this.filteredEntities = [...this.entities]
  }

};
</script>

<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
        :size="30"
        :width="3"
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-row v-if="!loading">
      <v-col cols="12">
        <div>
          <v-text-field
            outlined
            hide-details
            label="Search..."
            prepend-inner-icon="mdi-magnify"
            v-model="searchValue"
          ></v-text-field>
        </div>
      </v-col>
      <v-col>
        <v-list three-line>
          <v-col cols="12">
            <v-progress-linear
              :active="loading"
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>

          <v-list>
            <v-list-group
              v-for="(item) in filteredEntities" :key="item.id" style="border: 1px solid gray" class="mb-2"
              @click="activeEntity = item.id"
              color="primary"
            >
              <template #prependIcon>
                <v-avatar color="blue" class="mr-3">
                <v-icon dark>mdi-account-circle</v-icon>
                </v-avatar>
              </template>
              <template #activator>
                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                  <v-list-item-subtitle>{{ item.firm_type_name }} </v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <v-progress-circular
                v-if="adoptedEntitiesLoading[item.id]"
                :size="30"
                :width="3"
                color="primary"
                indeterminate
                class="my-2"
              ></v-progress-circular>

              <div v-if="!adoptedEntitiesLoading[item.id]" class="px-10 pb-3">
                <p class="text-center">Adopted</p>
                <v-list-item
                  v-for="entity in adoptedEntities[item.id]"
                  :key="entity.id"
                  @click="handleItemClick(entity)"
                  style="border: 1px dashed gray"
                  class="mb-2"
                  ripple
                >
                  <v-avatar color="blue" class="mr-3">
                      <v-icon dark> mdi-account-circle</v-icon>
                  </v-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="entity.name"></v-list-item-title>
                    <v-list-item-subtitle>{{ entity.firm_type_name }} </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-list-group>
          </v-list>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>