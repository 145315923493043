<template>
  <div id="draft-container" class="pa-md-16 d-flex justify-center">
    <v-row v-if="stage == 0">
      <v-col cols="12">
        <h1 style="font-weight: 300; font-size: 4rem">Draft Report</h1>
      </v-col>
      <v-col cols="12">
        Below, you will find your client's ERTC draft report. Click the download
        button to download and review it.
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn color="primary" style="font-size: 0.7rem">Download Draft</v-btn>
      </v-col>
      <v-col cols="12">
        <h1 style="font-weight: 400">Important</h1>
      </v-col>
      <v-col cols="12">
        <p>
          If the report is correct, requires no changes, and you are ready for a
          final report, click the "Accept Draft" button. Once you accept the
          draft, the final report will be queued for creation, and you will be
          notified when it is ready.
        </p>
        <p>
          If the report needs revision of any kind, click the "Reject Draft"
          button. You will be prompted to enter a reason why the report needs
          revision.
        </p>
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between">
        <v-btn color="red" style="font-size: 0.7rem" @click="openModal"
          >Reject Draft</v-btn
        >
        <v-btn color="success"
          ><span style="font-size: 0.7rem" @click="acceptDraft"
            >Accept Draft</span
          ></v-btn
        >
      </v-col>
    </v-row>
    <v-row v-if="stage == 1">
      <v-col cols="12">
        <h1 style="font-weight: 300; font-size: 4rem">Draft Report</h1>
      </v-col>
      <v-col cols="12">
        This draft report was approved by FIRSTNAME LASTNAME on 4/20/2023 @
        11:58am.
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn color="primary" style="font-size: 0.7rem">Download Draft</v-btn>
      </v-col>
    </v-row>
    <v-row v-if="stage == 2">
      <v-col cols="12">
        <h1 style="font-weight: 300; font-size: 4rem">Draft Report</h1>
      </v-col>
      <v-col cols="12">
        This draft report was <span style="color: red">rejected</span> by
        FIRSTNAME LASTNAME on 4/20/2023 @ 11:58am.
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-btn color="primary" style="font-size: 0.7rem">Download Draft</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" persistent max-width="600px">
      <div class="d-flex flex-column pa-16" style="background-color: #fff">
        <h1 class="text-center mb-4" style="font-weight: 300">
          Details required
        </h1>
        <h3 class="text-center" style="font-weight: 400">
          Rejecting a draft means it needs revisions or corrections.
        </h3>
        <p class="text-center" style="font-weight: 300">
          Please describe what revisions and / or corrections are necessary for
          this client`s ERTC draft package.
        </p>
        <v-text-field
          height="200px"
          outlined
          v-model="rejectedReason"
        ></v-text-field>
        <div class="d-flex flex-md-row flex-column justify-space-between">
          <v-btn class="mb-2" color="primary" @click="showDialog = false"
            >Nevermind, go back</v-btn
          >
          <v-btn color="red" @click="rejectDraft">Reject draft</v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ClientDraft",

  data() {
    return {
      clientId: null,
      stage: 0,
      showDialog: false,
      rejectedReason: "",
    };
  },

  mounted() {
    this.clientId = this.$route.params.id;
  },

  methods: {
    acceptDraft() {
      this.stage = 1;
    },
    openModal() {
      this.showDialog = true;
    },
    rejectDraft() {
      this.showDialog = false;
      this.stage = 2;
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1025px) {
  #draft-container {
    max-width: 700px;
  }
}
</style>
