<template>
  <div class="text-center">
    <v-card
      elevation="2"
      class="d-flex justify-space-between align-center px-5 mb-5"
      v-for="(assingCard, index ) in assingCards"
      :key="index"
    >

    <h3>{{ assingCard.name }}</h3>
   
    <v-col
        class="mt-5"

        sm="6"
      >
        <v-select
          id="tooldDropdown"
          :items="assingCard.getItems()"
          @change="patchAssignTool()"
          item-text="full_name"
          item-value="id"
          v-model="attorneyID"
          label="Select a new attorney of record."
          solo
        ></v-select>
      </v-col>
      
    </v-card>
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import AuthService from "@/services/auth.service";
import {mapGetters} from "vuex";

export default {
  name: "Tool",
  data: vm => ({
    loading: false,
    usersRole: null,
    assingCards: [],
    attorneys: [],
    contractors: [],
    attorneyID: null
  }),
  methods: {
    async getAssignTools(){
      await ClientsService.getAssignTools(this.clientId, 'attorney').then((res) => {
        
        this.attorneys = res

      }).catch()

      if(this.usersRole === 1){
        await ClientsService.getAssignTools(this.clientId, 'contractor').then((res) => {
          
          this.contractors = res

        }).catch()
      }
    },
    async patchAssignTool(){
      const model = {
        attorney_of_record: this.attorneyID,
      };

      await ClientsService.patchAssignTools(this.clientId, model).then((res) => {
        
        this.$func.popMessage("Updated successfully.", "success");

      }).catch()
    }
  },
  created(){
    this.getAssignTools()

    AuthService.getCurrentUser(this.currentUser.current_user_id)
        .then(res => {
          this.usersRole = res.data.role_id
          this.user = res.data
          if(this.usersRole === 1){
            this.assingCards = [
            { name: "Attorney of record", getItems: () => this.attorneys}
            // { name: "Contractors", getItems: () => this.contractors}
            ]
          }
          else if(this.usersRole === 4){
            this.assingCards = [
            { name: "Attorney of record", getItems: () => this.attorneys}
            ]
          }
        })
  },
  computed: {
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
        },
    )
  },
  props: {
    clientId: {
      type: Number,
      default: 0
    },
    clientProp: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
