import $axios from '../plugins/axios'

class PackageService {

	getPackages(id) {
		return $axios.get(`/clients/${id}/packages`)
			.then((res) => {
				return res.data;
			});
	}
    getInvoices(id) {
		return $axios.get(`/clients/${id}/invoice`)
			.then((res) => {
				return res.data;
			});
	}
	generateInvoice(id) {
		return $axios.post(`/clients/${id}/invoice`)
			.then((res) => {
				return res.data;
			});
	}

	deletePackage(id) {
		return $axios.delete(`/packages/${id}`,)
			.then((res) => {
				// return res.data;
			});
	}
}

export default new PackageService();
