<template>
  <DialogRightAlign :showDialog.sync="showRightDialog" :titleProp="`Upload CSV`">
    <v-card-text class="mt-10">
      <v-container>
        <div class="d-flex justify-start flex-column py-5" v-if="loading == true">
          <v-progress-linear
              indeterminate
              color="green"
          />
          <div> Processing file... Please wait this might take some time</div>
        </div>
        <v-overlay :value="loading">
          <v-progress-circular
              :size="70"
              :width="7"
              color="white"
              indeterminate
          ></v-progress-circular>
        </v-overlay>

        <v-row class="d-flex justify-center">
          <v-col
              cols="12"
              sm="12"
              md="12"
          >
            <v-card
                @drop.prevent="drop($event)"
                @dragover.prevent="dragoverData = true"
                @dragenter.prevent="dragoverData = true"
                @dragleave.prevent="dragoverData = false"
                :class="{ 'grey lighten-2': dragoverData }"
                height="50vh"
                class="d-flex  align-center justify-center"
                style="border: 5px dashed lightgray; box-shadow: none"
            >
              <v-card-text>
                <v-row class="d-flex justify-center align-center">
                  <div
                      class="p-12"
                  >
                    <input type="file" multiple name="fields[assetsFieldHandleRaw][]"
                           id="assetsFieldHandleRaw"
                           class="d-none absolute" @change="onChange" ref="file" accept=".csv"/>

                    <label v-if="fileList.length < 1 " for="assetsFieldHandleRaw"
                           class="block cursor-pointer d-flex justify-center align-center">
                      <span class="underline"> CHOOSE  A FILE OR DRAG IT </span> <span
                        style="color: blue;"> &nbsp HERE </span>
                    </label>
                    <label v-if="fileList.length >= 1" for="assetsFieldHandleRaw"
                           class="block cursor-pointer d-flex justify-center align-center">
                      <span class="underline"> To upload a different file, click<span
                          style="color: blue;"> &nbsp HERE </span> , </span> OR DRAG IT <span
                        style="color: blue;"> &nbsp HERE </span>
                    </label>
<!--                    <v-list class="mt-4" v-if="this.fileList.length" dense>-->
<!--                      <v-list-item-->
<!--                          v-for="(file, index) in fileList"-->
<!--                          :key="`${index}-file`"-->
<!--                          style="border: dashed lightgray;"-->
<!--                      >-->
<!--                        {{ file.name }}-->
<!--                        <v-spacer></v-spacer>-->
<!--                        <button class="ml-2" type="button"-->
<!--                                @click="remove(fileList.indexOf(file))"-->
<!--                                title="Remove file"-->
<!--                        >-->

<!--                          <v-icon>-->
<!--                            mdi-trash-can-outline-->
<!--                          </v-icon>-->
<!--                        </button>-->
<!--                      </v-list-item>-->
<!--                    </v-list>-->
                  </div>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-list class="mt-4" v-if="this.uploadedData" dense>
      <v-list-item
          v-for="(file, index) in uploadedData"
          :key="`${index}-file`"
          class="pa-3 mx-8 mb-3"
          :class="{'activeItemClass': index % 2 !== 0}"
          style="background-color: #f0f0f0"
      >
        <div class="d-flex flex-column">
          <h3 style="font-size: 0.8rem;" class="text-left">{{ file.original_file_name }}</h3>

          <h4 style="font-weight: 500;font-size: 12px;opacity: 70%" class="text-left">Uploaded by :
            <template v-if="file.uploaded_by == clientIdProp">You</template>
            <template v-else>{{ file.uploaded_by_name }}</template>
            <span class="ml-5">{{ file.created_on }}</span>
          </h4>
        </div>
        <v-spacer></v-spacer>
        <a :download="file.original_file_name"
           :href="file.url"
           v-show="file.id"
           target="_blank"
           class="ml-2"
           type="button"
           title="Download file"
        >

          <v-icon>
            mdi-download
          </v-icon>
        </a>
        <template v-if="file.uploaded_by === clientIdProp">
          <button class="ml-2" type="button"
                  @click="remove(uploadingFiles.indexOf(file), file)"
                  title="Remove file"
          >

            <v-icon>
              mdi-trash-can-outline
            </v-icon>

          </button>
        </template>
        <template v-else>
          <div style="margin-left: 32px"></div>
        </template>
      </v-list-item>
    </v-list>
    <v-spacer></v-spacer>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn
          color="#263B4F"
          text
          @click.native="closeDialog"
      >
        close
      </v-btn>
    </v-card-actions>
  </DialogRightAlign>

</template>

<script>
import ClientService from "@/services/clients.service";
import DialogRightAlign from "@/components/Base/DialogRightAlign";
import $axios from "@/plugins/axios";

export default {
  name: "ClientDragAndDropRawFile",
  components: {DialogRightAlign},
  data: () => ({
    dragoverData: false,
    fileList: [], // Store our uploaded files
    uploadedList: [],
    dataFromCsvFile: '',
    processingFile: false,
    loading: false
  }),
  methods: {

    upload() {
      const test = this.$refs.file.files
      for (let i = 0; i < test.length; i++) {
        this.loading = true
        const formData = new FormData();
        const csvFile = [this.$refs.file.files[i]];
        formData.append("uploaded_file", csvFile[0]);
        formData.append('upload_type', 'raw_payroll');
        ClientService.uploadClientCSVFileWithReturningData(this.clientIdProp, formData)
            .then((res) => {
              this.$store.dispatch('snackbar/showSnackbarMessage', {
                message: 'CSV uploaded successfully',
                duration: 4000,
                mode: 'success'
              });
              this.uploadedData.splice()
              this.uploadedData.push(res.data)
              this.loading = false
              // this.$emit('getDataForOwnersDialog');
              // this.$emit('getUpdatedInfo');
            })
      }

    },

    onChange() {
      this.loading = true
      this.fileList = [this.$refs.file.files[0]];
      this.upload()
    },

    remove(i) {
      this.fileList.splice(i, 1);
      this.$refs.file.files = []
      this.dataFromCsvFile = [];
    },


    async drop(event) {
      this.loading=true
      event.preventDefault();
      this.dragoverData = false;
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },
    closeDialog(){
      this.$emit('update:showRightDialog', false);
    },
    
    // getData() {
    //   $axios.get(`clients/${this.clientIdProp}/uploads`, {
    //         headers: {
    //           'x-mock-match-request-body': 'true'
    //         }
    //       },
    //   ).then(res => {
    //     
    //     this.loading = false
    //     if (res.data.length > 0) {
    //       this.uploadedList = res.data
    //     }
    //   }).catch(error => {
    //     this.$func.popMessage(`${error.response.data.error}`, 'fail')
    //     this.loading = false
    //   })
    // },
    //

    // save(value) {
    //   this.upload();
    //   this.buttonLoadingSpinner = true;
    //   this.processingFile = true;
    //
    //   setTimeout(() => {
    //     this.processingFile = false;
    //   }, 5000)
    // }
  },

  mounted() {
  },


  props: {
    showRightDialog: {
      type: Boolean,
      default: false
    },
    uploadedData: {
      type: Array,
      default: []
    },
    // multiple: {
    //   type: Boolean,
    //   default: false
    // },

    clientIdProp: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>

</style>