import { render, staticRenderFns } from "./PeoUploads.vue?vue&type=template&id=3884e83d&scoped=true&"
import script from "./PeoUploads.vue?vue&type=script&lang=js&"
export * from "./PeoUploads.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3884e83d",
  null
  
)

export default component.exports