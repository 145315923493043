import $axios from '../plugins/axios'

class TranscriptService {
  getTranscriptQueueByFilter(filter) {
    return $axios.get(`/queues/transcripts/${filter}`).then(res => res.data)
  }

  getTranscriptQueueById(id) {
    return $axios.get(`clients/queues/transcripts/${id}`).then(res => res.data)
  }

  getTranscriptStatuses() {
    return $axios.get(`/transcripts/tasks/statuses`).then(res => res.data)
  }

  createTranscriptQueueTask(id, payload) {
    return $axios.post(`/queues/transcripts/${id}/task`, payload).then((res) => res.data)
  }

  removeTranscriptFromQueue(id) {
    return $axios.delete(`/queues/transcript/${id}`).then((res) => res.data)
  }

  postTranscriptNote(id, payload) {
  return $axios.post(`/transcripts/tasks/${id}/notes`, payload).then((res) => res.data)
  }


  patchTranscriptTaskStatus(id, payload) {
    return $axios.patch(`/transcripts/tasks/${id}`, payload).then((res) => res.data)
  }
}

export default new TranscriptService();
