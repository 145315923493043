<template>
	<v-row justify="center">
		<v-dialog v-model="showDialog" 
		persistent
		max-width="600px">
			<v-card>
				<h3 style="background-color:#263b4f;color:white;padding:12px;font-size:0.9rem">
					Clear PPP {{deleteRoundID}}
				</h3>
					<p style="font-weight:bold;font-size:1rem;margin-left:11px"> Are you sure you would like to clear data for Round {{deleteRoundID}}? </p>

				<v-card-actions>
					<v-spacer></v-spacer>
						<p></p>

					<v-btn
						color="blue darken-1"
						text
                        @click="acceptedDelete"

					>
						ACCEPT
					</v-btn>

					<v-btn
						color="blue darken-1"
						text
                        @click="closeDialog"

					>
						CANCEL
					</v-btn>
				</v-card-actions>
			</v-card>

		</v-dialog>
	</v-row>
</template>

<script>

	export default {
		name: 'DeleteConfirm',
		props: {
			deleteRoundID:{
				type:Number,
				default: 1
			},
			showDialog: {
				type: Boolean,
				default: false
			}
		},

		data: () => ({
        }),
		methods: {
            acceptedDelete(event){
            this.$emit('clicked', {
				acceptedDelete:true,
				closeWindow:false,
			});

            },
			closeDialog(event) {
             this.$emit('clicked', {
				 acceptedDelete:false,
				closeWindow:false,
			});

		}
	}
	}
</script>

<style lang="scss" scoped>

	.dialog-message {
		margin-bottom: 1.6rem;
	}
</style>