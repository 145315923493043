<template>
  <v-dialog v-model="show" persistent max-width="600px">
    <v-card>
      <v-card-title
          class="text-h5 font-weight-bold"
          style="background-color: #263b4f; color: white"
      >
        {{ cardTitle }}
      </v-card-title>

      <v-card-text class="mt-3" style="font-size: 1.2rem; color: red">{{ cardDesc }}</v-card-text>

      <v-card-actions>
        <v-btn color="blue darken-1" text @click="closeDialog"> {{ cancelBtnText }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="blue darken-1" text @click="confirmDialog"> {{ confirmBtnText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmCardDialog',
  props: {
    cardTitle: {
      required: true
    },
    cardDesc: {
      required: true
    },
    cancelBtnText: {
      required: true,
    },
    confirmBtnText: {
      required: true,
    },
    show: {
      required: true
    },
    loading: {
      required: false,
      default: false
    }
  },
  methods: {
    closeDialog(){
      this.$emit('update:show', false)
    },
    confirmDialog(){
      this.$emit('confirmDialog')
    }
  }
}
</script>