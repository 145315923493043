var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"mt-6 d-flex flex-column flex-md-row",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"restaurants houston tx","dense":""},on:{"change":_vm.getMandates},model:{value:(_vm.searchStr),callback:function ($$v) {_vm.searchStr=$$v},expression:"searchStr"}}),_c('v-btn',{staticClass:"ml-md-8",attrs:{"color":"primary"},on:{"click":_vm.getMandates}},[_vm._v("Search")])],1),(!_vm.mandates.length && !_vm.loading)?_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h3',{staticStyle:{"color":"#6c757d"}},[_vm._v(" Mandate search supports searching by keywords, NAICS codes or descriptions, states, counties, cities, and other relevant terms. ")])]):_vm._e(),(_vm.loading)?_c('v-col',{attrs:{"cols":"12"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e()],1):_vm._e(),(!_vm.loading && _vm.mandates.length)?_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.mandates),function(mandate){return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"mb-2 py-6 px-4",attrs:{"elevation":hover ? 12 : 4},on:{"click":function($event){return _vm.goToMandate(mandate.id)}}},[_c('v-card-text',{staticClass:"text-center pa-0 font-weight-medium",staticStyle:{"color":"black"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex flex-column px-2 py-5 fill-height",style:(_vm.isMobile
                    ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
                    : 'border-right: 1px solid rgba(0, 0, 0, 0.1)')},[_vm._v(" "+_vm._s(mandate.state_code ?? "N/A")+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex justify-center flex-column fill-height pa-2",style:(_vm.isMobile
                    ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
                    : 'border-right: 1px solid rgba(0, 0, 0, 0.1)')},[(!mandate.publication_date)?_c('p',{staticClass:"text-caption",staticStyle:{"color":"rgb(128, 128, 128)"}},[_vm._v(" No data for publication date ")]):_c('div',[_vm._v(" "+_vm._s(mandate.publication_date)+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex justify-center flex-column fill-height pa-2",style:(_vm.isMobile
                    ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
                    : 'border-right: 1px solid rgba(0, 0, 0, 0.1)')},[(!mandate.author)?_c('p',{staticClass:"text-caption",staticStyle:{"color":"rgb(128, 128, 128)"}},[_vm._v(" No data for author ")]):_c('h5',[_vm._v(" "+_vm._s(mandate.author)+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"d-flex justify-center flex-column fill-height pa-2"},[(!mandate.title)?_c('p',{staticClass:"text-caption",staticStyle:{"color":"rgb(128, 128, 128)"}},[_vm._v(" No data for title ")]):_c('h5',[_vm._v(" "+_vm._s(mandate.title)+" ")])])])],1)],1)],1)]}}],null,true)})}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }