import $axios from '../plugins/axios'

class LeadService {
    getScoreboard() {		
		return $axios.get(`reports/leads/scoreboard`)
			.then((res) => {
				return res.data;
			});
	}

    getLeadNotes(id) {
        return $axios.get(`/leads/${id}/notes`)
            .then((res) => {
                return res.data;
            });
    }

    postLeadNote(id, data) {
        return $axios.post(`/leads/${id}/notes`,data)
            .then((res) => {
                return res.data;
            });
    }

    patchLeadNote(id, data) {
        return $axios.patch(`/leadnotes/${id}`,data)
            .then((res) => {
                return res.data;
            });
    }

    deleteLeadNote(id, data) {
        return $axios.delete(`/leadnotes/${id}`,{data: {
                id: data
            }})
            .then((res) => {
                return res.data;
            });
    }
    getLeads(){
        return $axios.get(`/leads`)
            .then((res) => {
                return res.data;
            });
    }
    getSingleLead(id){
        return $axios.get(`/leads/${id}`)
            .then((res) => {
                return res.data;
            });
    }
    createLead(data){
        return $axios.post(`/leads`,data)
            .then((res) => {
                return res.data;
            });
    }
    editLead(id,data){
        return $axios.patch(`/leads/${id}`,data)
            .then((res) => {
                return res.data;
            });
    }

    getLeadStatuses() {
        return $axios.get(`/leadstatus`)
            .then((res) => {
                return res.data;
            });
    }

    deleteLead(id) {
        return $axios.delete(`/leads/${id}`)
            .then((res) => {
                return res.data;
            });
    }

    leadPromoteToClient(id) {
        return $axios.post(`/leads/${id}/promote`)
            .then((res) => {
                return res.data;
            });
    }

    getConvertedLeads() {
        return $axios.get(`/leads/converted`)
            .then((res) => {
                return res.data;
            });
    }
}

export default new LeadService;
