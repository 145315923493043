<template>
  <div class="pa-8 text-center">
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <h4 class="text-start">
      4. Qualifying for the ERC
    </h4>
    <h6 class="ml-6 mt-6 text-start">
      There are two primary mechanisms for a business to qualify for the ERC. The more information you can complete below the better your chances
      of qualifying. NOTE: Qualifying under both mechanisms is NOT a requirement for accessing the ERC.
    </h6>

    <div class="text-start ml-6 mt-5">
      <h4>A. Revenue Reduction</h4>
      <h6 class="ml-6">
        Please enter your business’s GROSS REVENUE for each of the applicable quarters and years. Please leave the cell blank if the business
        did not exist during that quarter.
      </h6>
      <div class="ml-6 mt-6">
        <v-col class="d-flex justify-space-between ml-16 mr-16" cols="10">
          <span v-for="qat in quarters[0].quarters">{{qat.quarter}}</span>
        </v-col>
        <v-row v-for="(q, index) in quarters" :key="index">

          <v-col class="d-flex flex-row" cols="12">
            <span class="mt-2">{{q.year}}</span>
            <v-text-field v-restrict.number.decimal prefix="$" v-for="qat in q.quarters" v-model="qat.amount" class="mx-5" dense outlined></v-text-field>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="text-start">
      <h4>B. Nominal Business Impact</h4>
      <h6 class="mt-5 ml-6">Please check each box that accurately describes COVID’s impact on your business.</h6>
      <v-row class="mt-6">
       <v-col cols="6" >
         <div class="d-flex flex-row align-center" v-for="(radioText, index) in checkBoxTexts" :key="index">
           <v-checkbox v-model="radioText.chosen"></v-checkbox>
           <h5 style="font-weight: 430">{{radioText.text}}</h5>
         </div>
       </v-col>
        <v-col cols="6">
          <div class="d-flex flex-row align-center" v-for="(radioText, index) in secondCheckBoxTexts" :key="index">
            <v-checkbox v-model="radioText.chosen" ></v-checkbox>
            <h5 style="font-weight: 430">{{radioText.text}}</h5>
          </div>
          <v-textarea class="mt-5" label="Please explain..." v-model="secondCheckBoxTexts[4].note" :disabled="!secondCheckBoxTexts[4].chosen" outlined>
          </v-textarea>
        </v-col>

      </v-row>
    </div>
    <v-btn color="#263B4F" class="mt-4">
      <span style="color: #FFFFFF" @click="()=>post()">Save and Continue</span>
    </v-btn>
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
export default {
  name: "Step4",
  components:{
    ClientsService
  },
  data () {
    return {
      loading:false,
      note:'',
      quarters:[
        {year:2019,quarters:[{quarter: "Q1",amount:''}, {quarter: "Q2",amount:''}, {quarter: "Q3",amount:''}, {quarter: "Q4",amount:''}]},
        {year:2020,quarters:[{quarter: "Q1",amount:''}, {quarter: "Q2",amount:''}, {quarter: "Q3",amount:''}, {quarter: "Q4",amount:''}]},
        {year:2021,quarters:[{quarter: "Q1",amount:''}, {quarter: "Q2",amount:''}, {quarter: "Q3",amount:''}, {quarter: "Q4",amount:''}]},

      ],
      checkBoxTexts:[
        {text:"Partial or full shutdown per government mandates",id:1,chosen:false},
        {text:"Restrictions on number of people in room or\n" +
             "building",id:2,chosen:false},
        {text:"Inability to attend normal networking\n" +
            "events and functions such as professional\n" +
                     "conferences",id:3,chosen:false},
        {text:"Disruption to sales force’s ability to function\n" +
              "normally",id:4,chosen:false},
        {text: "Supply chain interruptions",id:5,chosen:false},
        {text:"Inability to access equipment",id:6,chosen:false},
        {text:"Limited capacity to operate",id:7,chosen:false},
        {text: "Inability to work with your vendors",id:8,chosen:false},
        {text: "Reduction in services or goods offered to your\n" +
              "customers ",id:9,chosen:false}

      ],
      secondCheckBoxTexts:[
        {text:"Cut down in your hours of operation",id:10,chosen:false,note:''},
        {text:"Shifting hours to increase sanitation of\n" +
        "your facility",id:11,chosen:false,note:''},
        {text:"Projects were canceled or delayed to\n" +
        "COVID-related disruptions",id:12,chosen:false,note:''},
        {text:"Delayed production timelines caused by\n" +
        "supply chain disruptions",id:13,chosen:false,note:''},
        {text:"Other (please explain)",id:14,chosen:false,note:''}
      ]
    }
  },
  methods:{
    emitData(){
      this.$emit('goToNextStep', 5)
    },
      post(){
        this.loading=true;
        let finalData = []
        this.quarters.forEach(e=>{
          e.quarters.map(q=>{
            if (q.amount){
              const quarterAsInt = q.quarter.slice(1,2)
              const quarter = {year:e.year,quarter:quarterAsInt,amount:q.amount}
              finalData.push(quarter)
            }
          })
        })
        ClientsService.postRevenuereduction(this.clientId,finalData)
            .then(res=> {
              let model = []
              this.checkBoxTexts.map(e=>{
                if (e.chosen){
                  model.push({selection: e.id,notes: ""})
                }
              })
              this.secondCheckBoxTexts.map(e=>{
                if (e.chosen){
                  model.push({selection: e.id,notes: e.note})
                }
              })
             this.postBussinessImpact(this.clientId,model)
            } )
      },
    postBussinessImpact(id,data){
      ClientsService.postBussinessImpact(id,data).then(()=>{
        this.loading=false;
        this.emitData()
      })
    }
  },
  props:{
    clientId:{
      type: Number
    }
  }

}
</script>

<style scoped>

</style>