<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-radio-group row v-model="statusRadio" class="ml-3">
      <v-radio
          label="Qualified "
          :value="0"
          @click="getData(0)"
      ></v-radio>
      <v-radio
          label="Awaiting"
          :value="1"
          @click="getData(4)"
      ></v-radio>
    </v-radio-group>

    <!-- Year Dropdown -->
    <div v-if="statusRadio === 0">
      <v-radio-group row v-model="filterRadio" class="ml-3">
        <v-radio
            label="All Q's met "
            :value="0"
            @click="getData(0)"
        ></v-radio>
        <v-radio
            label="2021 met"
            :value="2"
            @click="getData(2)"
        ></v-radio>
         <v-radio
            label="2020 met"
            :value="1"
            @click="getData(1)"
        ></v-radio>
         <v-radio
            label="Partial Q's met"
            :value="3"
            @click="getData(3)"
        ></v-radio>
      </v-radio-group>
    </div>

    <v-data-table
        v-if="statusRadio === 0"
        :single-expand="false"
        :headers="headers"
        :items="items"
        style="cursor:pointer;"
        class="elevation-5 mt-6"
        disable-pagination
        hide-default-footer
        item-key="id"
    >
      <template v-slot:item="{ item }">
        <tr @click="goToClient(item.client)">
          <td>{{ item.year }}</td>
          <td>{{ item.quarters }}</td>
          <td>{{ item.legal_name }}</td>
          <td>{{ item.trade_name }}</td>
        </tr>
      </template>
    </v-data-table>
    <v-data-table
        v-if="statusRadio === 1"
        :single-expand="false"
        :headers="headers2"
        :items="items"
        class="elevation-5 mt-6"
        disable-pagination
        hide-default-footer
        item-key="id"
    >
      <template v-slot:item="{ item }">
        <tr style="cursor:pointer;" @click="goToClient(item.id)">
          <td>{{ item.legal_name }}</td>
          <td>{{ item.trade_name }}</td>
          <td>{{ item.ein }}</td>
          <td>{{ item.entity }}</td>
        </tr>
      </template>
    </v-data-table>
    <ProfileClientDialog
        v-if="showRightDialogChosenClient"
        :key="dialogKeyChosenClient"
        :showRightDialogChosenClient.sync="showRightDialogChosenClient"
        :clientProp="clientProp"
        @openClientProfileDialog="openClientProfileDialog"
    />
  </div>
</template>


<script>
import ClientsService from "@/services/clients.service";
import ClientService from "@/services/clients.service";
import ProfileClientDialog from "@/components/Client/ProfileClientDialog.vue";
import RadioGroupComponent from  "./RadioGroupComponent.vue"
import {mapGetters} from "vuex";
export default {
  name: "UnreviwedUploads",
  components: {
    ProfileClientDialog,
  },
  data() {
    return {
      items: [],
      loading: true,
      showRightDialogChosenClient: false,
      dialogKeyChosenClient: 13,
      clientId: '',
      clientProp: {},
      filterRadio: 0,
      headers: [
        { text: 'Year', value: 'year' },
        { text: 'Quarters', value: 'quarters' },
        { text: 'Legal Name', value: 'legal_name' },
        { text: 'Trade Name', value: 'trade_name' }
      ],
      headers2: [
        { text: 'Legal Name', value: 'legal_name' },
        { text: 'Trade Name', value: 'trade_name' },
        { text: 'Ein', value: 'ein' },
        { text: 'Entity', value: 'entity' },
      ],
      statusRadio: 0,
      selectedYear: null,
      selectedQuarter: null,
      years: [2019, 2020, 2021],
      quarters: [1, 2, 3, 4],
    };
  },
  methods: {
    getData(status) {
      this.loading = true;
      ClientsService.getRevenueReduction(status).then(res => {
        this.items = res;
        this.loading = false;
      }).catch(() => this.loading = false);
    },
    goToClient(id) {
      if (!id) return
      this.loading = true;
      this.$router.push(`/clients/${id}/profile`);
    },
    openClientProfileDialog() {
      this.showRightDialogChosenClient = true;
      this.dialogKeyChosenClient++;
      this.loading = false;
      this.showRightDialog = false;
    },
  },
  created() {
    this.getData(0);
  }
}
</script>


<style scoped>

</style>
