<template>
  <div class="pt-2">
    <ClientHeader :client="client" />
    <v-overlay :value="loading">
      <v-progress-circular :size="70" :width="7" color="white" indeterminate></v-progress-circular>
    </v-overlay>

    <!--  Exclude  -->
    <DeleteConfirmation
        :showDialog.sync="excludeDialog"
        note-title="Are you sure you want to exclude this mandate"
        note-description=""
        confirm-btn="Send"
        :reason-value.sync="reasonValue"
        @clicked="onClickExcludeDialog"
    ></DeleteConfirmation>

    <!--  Reject  -->
    <DeleteConfirmation
        :showDialog.sync="rejectDialog"
        note-title="Are you sure you want to reject this client"
        note-description="Are you sure you want to reject this client? The client will be returned to Mandate selection, and the AOR will be notified."
        confirm-btn="Send"
        :reason-value.sync="reasonValue2"
        @clicked="onClickRejectDialog"
    ></DeleteConfirmation>

    <v-row style="height: 100vh; display: flex" v-if="data && !loading">
      <v-col id="firstColumn" cols="12" md="5">
        <v-col cols="12">
          <div class="d-flex flex-column mb-5">
            <h1 class="mr-16">{{ data.client }}</h1>
            <h4 style="font-weight: 300">{{ data.managing_firm }}</h4>
            <h4 style="font-weight: 300">{{ data.aor }}</h4>
          </div>

          <v-sheet outlined elevation="1" class="pa-3 rounded">
            <p class="text-h6">Client Documents</p>
            <div v-if="draftPackageLoading || calculationsLoading">
              <v-skeleton-loader type="text"></v-skeleton-loader>
              <br/>
              <v-skeleton-loader type="text"></v-skeleton-loader>
            </div>
            <div v-else-if="!draftPackages.length && !calculationsFiles.length">
              <p class="text-caption text-center">CLIENT HAS NO DOCUMENTS</p>
            </div>
            <div v-else>
              <p
                  v-if="draftPackages.length"
                  style="font-weight: 350; opacity: 0.8; font-size: 0.8rem"
              >
                Reports
              </p>
              <v-card v-for="pkg in draftPackages" :key="pkg.id" class="mb-1">
                <v-card-text>
                  <v-row justify="space-between">
                    <v-col class="d-flex justify-space-between" cols="12">
                      <span>{{ pkg.label }}</span>
                      <v-btn @click="downloadFile(pkg.url)" icon>
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <p
                  v-if="calculationsFiles.length"
                  class="mt-2"
                  style="font-weight: 350; opacity: 0.8; font-size: 0.8rem"
              >
                Calculations
              </p>
              <v-card v-for="calc in calculationsFiles" :key="calc.id" class="mb-1">
                <v-card-text>
                  <v-row justify="space-between">
                    <v-col class="d-flex justify-space-between" cols="12">
                      <span>{{ calc.original_file_name }}</span>
                      <v-btn @click="downloadFile(calc.url)" icon>
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-sheet>
        </v-col>
        <v-col cols="12">
          <v-card class="mx-auto pa-4" outlined elevation="1">
            <p class="text-h6 mb-5">Mandate Review</p>
            <v-hover v-for="mandate in data.mandates" v-slot="{ hover }">
              <v-card
                  style="cursor: pointer"
                  outlined
                  class="mb-3 pa-2"
                  :class="{ approved: mandate.approved, active: activeMandateId === mandate.id }"
                  @click.native="renderPdf(mandate.upload_url, mandate.id)"
                  :elevation="hover ? 3 : 1"
              >

                <div style="border: #6c757d 1px solid; border-radius: 10px; margin-bottom: 45px">
                  <v-card-title
                      style="
                      word-break: break-word;
                      line-height: 16pt;
                      font-size: 0.8rem;
                      font-weight: 730;
                    "
                      class="pb-0"
                  >{{ mandate.title }}
                  </v-card-title>
                  <div class="d-flex justify-space-between">
                    <v-card-subtitle class="pt-0">{{ mandate.author }}</v-card-subtitle>
                    <v-card-subtitle class="pt-0">{{ mandate.publication_date }}</v-card-subtitle>
                  </div>
                </div>
                <hr/>
                <v-card-actions>
                  <v-btn depressed color="error" text @click="openExcludeDialog(mandate)">
                    Exclude
                  </v-btn>

                  <v-spacer></v-spacer>

                  <v-btn
                      :loading="approveLoading"
                      @click="approveMandate(mandate.id)"
                      :disabled="!!mandate.approved"
                      depressed
                      color="primary"
                      text
                  >
                    {{ mandate.approved ? "Approved" : "Approve" }}
                  </v-btn>
                  <v-icon v-if="mandate.approved" color="green">mdi-check-circle</v-icon>
                </v-card-actions>
              </v-card>
            </v-hover>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-sheet outlined elevation="1" class="pa-4 rounded">
            <p class="text-h6">{{documentOptions.title}}</p>
            <v-row
              v-for="choice in documentOptions.options"
              :key="choice.value"
              class="d-block"
            >
              <v-col cols="12">
                <v-checkbox
                    hide-details
                    class="font-weight-bold"
                    dense
                    v-model="choice.selected"
                >
                  <template v-slot:label>
                    <div class="black--text font-weight-regular">{{ choice.checklist_item }}</div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col cols="12">
          <v-sheet outlined elevation="1" class="pa-4 rounded">
            <p class="text-h6">{{reviewOptions.title}}</p>
            <v-row
                v-for="choice in reviewOptions.options"
                :key="choice.value"
                class="d-block"
            >
              <v-col cols="12">
                <v-checkbox
                    hide-details
                    class="font-weight-bold"
                    dense
                    v-model="choice.selected"
                >
                  <template v-slot:label>
                    <div class="black--text font-weight-regular">{{ choice.checklist_item }}</div>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col cols="12" class="text-center d-flex justify-space-between">
          <v-btn class="mr-3" color="error" @click="openRejectDialog()">Reject</v-btn>
          <v-btn id="qualification-finalize-btn" @click="finalizeQualification()" :loading="finalizeLoading" color="primary">Finalize</v-btn>
        </v-col>
      </v-col>
      <v-col cols="7" id="secondColumn" class="text-center" v-if="!isMobile && selectedMandatePdf">
        <v-btn @click="downloadMandate" color="primary" style="margin-bottom: 30px;"
        >Download
          <v-icon right dark> mdi-download</v-icon>
        </v-btn>
        <MandatePDF :pdf="selectedMandatePdf"></MandatePDF>
        <!--          <iframe :src="mandate.upload_url" width="100%" height="100%"></iframe>-->
      </v-col>
    </v-row>
    <v-row v-else-if="!data && !loading">
      <v-col>
        <v-alert type="warning"
        >Client has no mandates for qualification, return to
          <span class="text-decoration-underline profile-link" @click="goToProfile">profile</span>.
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MandateService from "@/services/mandate.service";
import QualificationService from "@/services/qualification.service";
import ClientsService from "@/services/clients.service";
import {mapGetters} from "vuex";
import DeleteConfirmation from "@/components/Client/DeleteConfirmation.vue";
import MandatePDF from "@/components/Mandates/MandatePDF.vue";
import ClientService from "@/services/clients.service";
import ClientHeader from "@/components/Client/ClientHeader.vue";

export default {
  name: "SingleMandate",
  components: {ClientHeader, MandatePDF, DeleteConfirmation},
  data() {
    return {
      canvasSize: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
      clientId: undefined,
      client: null,
      data: null,
      currentMandateID: null,
      loading: false,
      excludeDialog: false,
      reasonValue: "",
      reasonValue2: "",
      rejectDialog: false,
      selectedMandatePdf: null,
      pageNum: 1,
      pdfDoc: null,
      pageRendering: false,
      pageNumPending: null,
      scale: 1,
      canvasEl: null,
      ctx: null,
      clickTimeout: null,
      reasonDialog: false,
      reasonTitle: "",
      reason: "",
      reasonSubmitAction: null,
      reasonSubmitButtonText: "",
      reasonDialogLoading: false,
      isEditingNote: false,
      showDeleteDialog: false,
      notes: [],
      currRole: null,
      approveLoading: false,
      activeMandateId: null,
      calculationsLoading: false,
      draftPackageLoading: false,
      draftPackages: [],
      calculationsFiles: [],
      documentOptions: {
        title: "Documents Included",
        options: []
      },
      reviewOptions: {
        title: 'Review',
        options: []
      },
      finalizeLoading: false,
      isMandateApproved: false,
    }
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
        "src",
        "//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.min.js"
    );
    // recaptchaScript.setAttribute("src", "//mozilla.github.io/pdf.js/build/pdf.js");
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    downloadMandate() {
      window.open(this.selectedMandatePdf, "_blank");
    },
    renderPdf(pdf, id) {
      this.selectedMandatePdf = pdf;
      this.activeMandateId = id;
    },
    openExcludeDialog(mandate) {
      this.excludeDialog = true;
      this.currentMandateID = mandate.id;
    },
    openRejectDialog() {
      this.rejectDialog = true;
    },
    async onClickExcludeDialog({closeWindow, acceptedDelete}) {
      this.excludeDialog = closeWindow;
      if (acceptedDelete) {
        try {
          const excludeModel = {
            reason: this.reasonValue,
          };
          await QualificationService.deleteQualification(this.currentMandateID, excludeModel)
              .then((res) => {
                if(res) this.getData();
              })
              .catch((err) => console.error(err));
        } catch (e) {
          this.$func.popMessage(e.response.data.error, "error");
        }
      }
    },
    onClickRejectDialog({closeWindow, acceptedDelete}) {
      this.rejectDialog = closeWindow;

      if (acceptedDelete) {
        try {
          const rejectModel = {
            reason: this.reasonValue2,
          };

          QualificationService.rejectQualificationClient(this.clientId, rejectModel)
              .then((res) => {
                if(res) this.getData()
              })
              .catch((err) => console.error(err));
        } catch (e) {
          this.$func.popMessage(e.response.data.error, "error");
        }
      }
    },
    getData() {
      return ClientsService.getQualifications(this.clientId).then((res) => {
        if (!Array.isArray(res)) {
          if (res) this.data = res;
          let approvedCount = 0
          // for (const mandate of res.mandates) {
          //   if (mandate.qualification_status === 1) {
          //     approvedCount++
          //   }
          // }
          // if (approvedCount === res.mandates.length){
          //   this.isMandateApproved = true
          //   this.$func.popMessage("A Final Report has already been generated for this client and the Qualification information cannot be changed", "error");
          // }
        }
        else this.data = null
      });
      this.selectedMandatePdf = this.data.mandates[0].upload_url;
    },
    getMandate() {
      //this.loading = true;
      return MandateService.getMandate(this.clientId)
          .then((response) => {
            this.mandate = response;
            this.originalMandate = response;
            this.selectedState = {id: this.mandate.state, state_name: this.mandate.state_name};
            this.selectedCities = [...this.mandate.cities];
            this.selectedCounties = [...this.mandate.counties];
            this.selectedImpacts = [...this.mandate.impact];
            this.selectedNaics = [...this.mandate.naics];
            if (this.isMobile) {
              this.loading = false;
              return;
            }
            let pdfjsLib = window["pdfjs-dist/build/pdf"];
            pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js`;
            this.canvasEl = document.getElementById("the-canvas");
            this.ctx = this.canvasEl.getContext("2d");
            setTimeout(() => {
              pdfjsLib.getDocument(this.mandate.upload_url).promise.then((pdfDoc_) => {
                this.pdfDoc = pdfDoc_;
                document.getElementById("page_count").textContent = this.pdfDoc.numPages;

                // Initial/first page rendering
                this.renderPage(this.pageNum, 1, this.canvasEl, this.ctx);
              });
              this.loading = false;
            }, 1500);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    approveMandate(id) {
      const approveModel = {
        approved: 1,
      };
      this.approveLoading = true;
      QualificationService.patchQualification(id, approveModel)
          .then((res) => {
            this.getData();
          })
          .finally(() => (this.approveLoading = false));
    },
    getDraftPackages() {
      this.draftPackageLoading = true;
      ClientsService.getDraftPackages(this.clientId)
          .then((res) => {
            this.draftPackages = res;
          })
          .finally(() => (this.draftPackageLoading = false));
    },
    getCalculations() {
      this.calculationsLoading = true;
      ClientsService.downloadCalculations(this.clientId)
          .then((res) => {
            this.calculationsFiles = res;
          })
          .finally(() => {
            this.calculationsLoading = false;
          });
    },
    downloadFile(url) {
      window.open(url, "_blank");
    },
    goToProfile() {
      this.$router.push(`/clients/${this.clientId}/profile`);
    },
    getCurrentClient(){
      return ClientService.getOneClient(this.clientId).then(res=>{
        this.client = res
      })
    },

    getReturnChecklist() {
      return ClientService.getReturnChecklist(this.clientId).then((res) => {
        this.documentOptions.options = res.documents;
        this.reviewOptions.options = res.review;
      });
    },

    getSelectedIds(options) {
      return options
          .filter(obj => obj.selected)
          .map(obj => obj.id);
    },
    async finalizeQualification() {
      try {
        this.finalizeLoading = true;

        const checklistModel = {
          selected: [
            ...this.getSelectedIds(this.reviewOptions.options),
            ...this.getSelectedIds(this.documentOptions.options)
          ]
        }

        await ClientsService.updateReturnChecklist(this.clientId, checklistModel)
        if(this.isMandateApproved) await ClientService.postPackage(this.clientId, { request: "final" })
        else await QualificationService.finalizeQualification(this.clientId);

        this.$router.push(`/clients/${this.clientId}/packages`)
      }
      catch (e) {}
      finally {
        this.finalizeLoading = false
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        default:
          return false;
      }
    },

    notAllowed() {
      return this.alert.type === "error";
    },
  },
  async created() {
    this.clientId = this.$route.params.clientId;
    this.loading = true;
    this.currRole = (await ClientsService.getUsersRole(this.currentUser.current_user_id)).role_id;
    await this.getData();
    this.getReturnChecklist();
    this.getCalculations();
    this.getDraftPackages();
    this.getCurrentClient();
    this.loading = false;
  },
};
</script>

<style scoped>
#firstColumn {
  height: 100%;
  width: 45%;
//border:1px solid red; float: left; overflow-y: scroll;
}

#secondColumn {
  height: 100%;
  width: 45%;
//margin-left:50%; //border:1px solid blue; overflow-y: scroll;
}

.approved {
  background-color: #F3F3F3;
}

.active {
  background-color: #eaefff;
}

.profile-link {
  cursor: pointer;
}

@media only screen and (min-width: 960px) {
  #firstColumn::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  #firstColumn::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }

  #firstColumn::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #979797;
  }

  #secondColumn::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
}
</style>
