<template>
  <v-dialog
      v-model="alertsOpened"
      content-class="my-custom-dialog full-height"
      max-width="700px"
  >
    <v-card  class="d-flex flex-column" style="min-height: 100vh">
      <v-card-title style="background: #263b4f;position: fixed;width: 100%;max-width: 700px;z-index: 888">
        <v-toolbar-title class="white--text">Alerts</v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="header-button">
          <v-btn color="white" icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text class="font-weight-regular mt-16">
        <v-radio-group row v-model="showUnread">
          <v-radio :value="true" label="Unread Alerts" />
          <v-radio :value="false" label="Read Alerts" />
          <v-spacer />
          <v-btn @click="handleMarkReadAll" color="primary">mark all as read</v-btn>
        </v-radio-group>
        <v-select
          v-model="filterBy"
          outlined
          dense
          clearable
          label="Filter by"
          :items="filterByItems"
          :disabled="!filterByItems.length"
          item-value="id"
          item-text="name"
        />


        <v-row v-if="uploadsNotifs && unreviewed && !notificationsLoading">
          <v-col cols="12">
            <template v-if="showUnread">
              <v-hover v-slot="{ hover }">
                <v-card
                    class="d-flex justify-space-between font-weight-medium pa-3"
                    :to="uploadsNotifs.url.replace('{id}', uploadsNotifs.client)"
                    @click="close()"
                    rounded
                    width="100%"
                    height="100%"
                    color="#79aad1"
                    :elevation="hover ? 8 : 2"
                >
                  <div>{{uploadsNotifs.type}}</div>

                </v-card>
              </v-hover>
            </template>
          </v-col>
        </v-row>
                     <!--  -->
        <v-row v-if="notificationsLoading">
          <v-col cols="12"><v-skeleton-loader height="50px" type="card"></v-skeleton-loader></v-col>
          <v-col cols="12"><v-skeleton-loader height="50px" type="card"></v-skeleton-loader></v-col>
        </v-row>
        <v-row v-else-if="!notificationsLoading && notifications.length" v-for="(notification, i) in notifications" :key="notification.id">
          <v-col cols="12">
            <template v-if="showUnread">
              <v-hover v-slot="{ hover }">
            <v-card
              class="d-flex justify-space-between font-weight-medium pa-3"
              :to="notification.url.replace('{id}', notification.client)"
              @click="close()"
              rounded
              width="100%"
              height="100%"
              color="#B1C2D1"
              :elevation="hover ? 8 : 2"
            >
              <div>{{notification.legal_name}} - {{notification.type}}</div>
              <div>
                <v-btn v-if="showUnread" small color="primary" :loading="notifIndexLoading === i" @click.prevent="markAsRead(notification, i); $event.stopPropagation()">mark as read</v-btn>
              </div>
            </v-card>
            </v-hover>
            </template>
            <template v-else>
              <v-card
                class="d-flex justify-space-between font-weight-medium pa-3"
                rounded
                width="100%"
                height="100%"
                color="#D4D4D4"
              >
                <div>{{notification.legal_name}} - {{notification.type}}</div>
                <div>
                  <v-btn small color="primary" :loading="notifIndexLoading === i" @click.prevent="markAsUnread(notification, i)">mark as unread</v-btn>
                </div>
              </v-card>
            </template>
          </v-col>
        </v-row>
        <p v-else-if="!uploadsNotifs" class="text-center">No notifications!</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import NotificationService from "@/services/notification.service";
import ClientsService from "@/services/clients.service";

export default {
  name: "Notifications",
  data() {
    return {
      alertsOpened: false,
      showUnread: true,
      filterByItems: [],
      notifications: [],
      notificationsLoading: false,
      notifIndexLoading: null,
      filterBy: null,
      uploadsNotifs: null,
      unreviewed: true
    };
  },
  props: {
    unreadNotifications: {
      type: Object,
      default: {}
    },
    showNotif: {
      type: Boolean,
      default: false,
    },
  },
  methods:{
    close(){
      this.alertsOpened = false
    },
    loadNotifications() {
      this.notificationsLoading = true
      const filter = this.filterBy ?? 0
      const status = this.showUnread ? 0 : 1

      NotificationService.getNotifs(filter, status).then(res => {
        if (filter === 0 && status === 0) {
          this.notifications = []
          res.map(notif => {
            if (notif.url === "/unreviewed-documents") {
              this.uploadsNotifs = notif
              this.unreviewed = true
              return
            }
            this.notifications.push(notif)
          })
        }else {
          this.unreviewed = false
          this.uploadsNotifs = null
          this.notifications = res
        }

      }).finally(() => this.notificationsLoading = false)
    },
    markAsRead(notif, i) {
      this.notifIndexLoading = i
      const payload = {
        read_status: 1
      }
      NotificationService.patchNotification(notif.id, payload)
        .then(() => {
          this.loadNotifications()
          this.$emit('markedAsRead')
        })
        .finally(() => this.notifIndexLoading = null)

    },
    handleMarkReadAll() {
      NotificationService.markAsReadAll()
          .then(() => this.loadNotifications())
    },
    unreviewedUploads(){
      ClientsService.getUnreviwedDocs().then(res=>{
         this.unreviewed = res.length
      }).catch(()=>this.loading=false)
    },
    markAsUnread(notif, i) {
      this.notifIndexLoading = i
      const payload = {
        read_status: 0
      }
      NotificationService.patchNotification(notif.id, payload)
          .then(() => {
            this.loadNotifications()
            this.$emit('markedAsUnRead')
          })
          .finally(() => this.notifIndexLoading = null)
    },
    // notIfNotesAlerts(count){
    //   console.log('notIfNotesAlerts count',count)
    //   // this.notIfNotesCount=count
    //   this.menuItems[1].count=count
    //   console.log("this.menuItems.count",this.menuItems[1].count)
    // }
  },
  created() {
    this.alertsOpened = this.showNotif
    this.unreviewedUploads()
    NotificationService.getNotifTypes().then(res => {
      this.filterByItems = res
    })

    this.notificationsLoading = true
    NotificationService.getNotifs(0,0).then(res => {
      this.notifications = []
      res.map(notif => {
        if (notif.url === "/unreviewed-documents") {
          this.uploadsNotifs = notif
          return
        }
        this.notifications.push(notif)
      })
    }).finally(() => this.notificationsLoading = false)
  },
  watch: {
    filterBy() {
      this.loadNotifications()
    },
    showUnread() {
      this.loadNotifications()
    }
  }
}
</script>

<style lang="scss" scoped>

.activeMenuItem{
  background: #263b4f;
  color: #FFFFFF;
}
.menuItem{
  cursor: pointer;
}
.menuItem:hover{
  box-shadow: 0px 2px #000000;

  background: #263b4f;
  color: #FFFFFF;
}
::v-deep  .v-dialog {
  margin: 0px;
  z-index: 9999;
}

.v-dialog__content {
  z-index: 99999999;
  display: flex !important;
  align-items: flex-start;
  justify-content: flex-start;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {

  max-height: 100% !important;
}
.button__badge { 
    background-color: #f34b4b;
    border-radius: 30px;
    color: white;
    padding: 1px 10px;
    font-size: 13px;
    font-weight: bold;
    float:right;
    margin-right: 20px;
    margin-top: -23px;
  }
</style>
