<template>
 <DialogRightAlign  :showDialog.sync="showRawOner" >
    <v-textarea class="ma-6 mt-12"  v-model="ownerData" outlined dense label="Enter Owners, one per line"></v-textarea>

   <v-spacer></v-spacer>
   <v-card-actions>
     <v-btn
         color="#263B4F"
         class="text-right"
         text
         @click.native="closeDialog"
     >
       close
     </v-btn>
     <v-btn
         color="#263B4F"
         class="text-right"
         text
         @click.native="save"
     >
       save
     </v-btn>
   </v-card-actions>
 </DialogRightAlign>
</template>

<script>
import ClientsService from "@/services/clients.service";
import DialogRightAlign from "@/components/Base/DialogRightAlign";

export default {
  name: "OwnerRawDialog",
  components: {DialogRightAlign},
  data:()=>({
    ownerData: '',
  }),
  methods:{
    closeDialog(){
      this.$emit('update:showRawOner', false);
    },
    save(){

      if (this.ownersSubmitted){
        this.putOwners();
      }else{
        this.postOwners();
      }
    },
    postOwners(){
      ClientsService.postRawClients({owner_family_list: this.ownerData},this.clientIdProp )
          .then(res => {
            this.$func.popMessage(`Successfully added owner`, 'success')
          })
          .catch(error => {
            this.$func.popMessage(`${error.response.data.error}`, 'fail')
          })
    },
    putOwners(){
      ClientsService.putRawClients({owner_family_list: this.ownerData},this.rawOwnerID )
          .then(res => {
              this.$func.popMessage(`Successfully edited owner`, 'success')
          })
          .catch(error => {
            this.$func.popMessage(`${error.response.data.error}`, 'fail')
          })
    }
  },

  created() {
    this.ownerData = this.rawOners;
  },
  props: {
    showRawOner: {
      type: Boolean,
      default: false
    },
    clientIdProp: {
      type: Number,
      default: 0
    },
    rawOners:{
      type: String,
      default: ''
    },
    ownersSubmitted:{
      type: Boolean,
      default: false
    },
    rawOwnerID: {
      type:Number,
      default:0
    }
  }
}
</script>

<style scoped>

</style>