<template>
  <v-row class="d-flex justify-center align-center">

    <v-col v-show="!tourStarted">
      <v-hover v-slot="{ hover }">
        <v-card
            id="clientEditCard"
            class="mx-auto"
            :elevation="hover ? 10 : 2"
            :class="{ 'on-hover': hover }"
            link
            outlined
            @click.native="$emit('goToEdit')"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-col 
                  :cols="12"
                  class="d-flex flex-column flex-md-row"
                >
                <v-list-item-title class="text-h5 mb-6 font-weight-bold" style="white-space: normal;">
                  {{ client.legal_name }}
                  <h3 style="font-size: 0.8rem;opacity: 0.6;margin-top: 0.5em" class="d-flex justify-lg-space-between"><span>Date entered: {{client.created}} ({{client.age}} days ago)</span></h3>
                </v-list-item-title>
                
                <div class="d-flex flex-column">
                  <div class="d-flex justify-center mx-auto mb-2" style="width: 135px">
                    <b class="mr-1">Client ID:</b>
                    <span>{{client.id}}</span>
                  </div>
                  <div class="d-flex justify-center mx-auto" style="width: 135px">
                    <b class="mr-1">EIN:</b>
                    <span>{{client.ein}}</span>
                  </div>
                </div>
               
              </v-col>

      
              <v-row justify="center">
                <v-col :cols="12"
                    lg="4"
                    md="6"
                    sm="6"
                    xs="12">
                  <v-sheet class="pa-2">
                    <p style="font-weight: bold">Address</p>
                    <p style="margin-bottom: 3px">{{ client.street }}</p>
                    <p class="mb-1">
                      {{ client.city }}, {{ client.state }}
                      {{ client.zip }}
                    </p>
                  </v-sheet>
                </v-col>
                <v-col :cols="12"
                    lg="8"
                    md="6"
                    sm="6"
                    xs="12">
                  <v-sheet class="pa-2">
                    <p style="font-weight: bold">Contact</p>

                    <p class="mb-1">
                      {{ client.contact_first }} {{ client.contact_last }}
                    </p>

                    <p v-if="client.contact_phone !== null" class="mb-1">
                      {{ client.contact_phone }}
                    </p>


                    <p class="mb-1">
                      {{ client.contact_email }}
                    </p>
                  </v-sheet>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>

            </v-list-item-content>

          </v-list-item>
        </v-card>
      </v-hover>
    </v-col>
    <!--              Doing this cause hover component adds light&#45;&#45;theme class again-->
    <v-col v-show="tourStarted">
      <v-card
          id="clientEditCard"
          class="mx-auto"
          outlined
          @click.native="$emit('goToEdit')"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-6 font-weight-bold">
              {{ client.legal_name }}
              <h3 style="font-size: 0.8rem;opacity: 0.6;">Date entered: {{client.created}} ({{client.age}} days
                ago)</h3>
            </v-list-item-title>

            <v-list-item-subtitle class="mb-4">
              {{ client.street }}, {{ client.city }}, {{ client.state }},
              {{ client.zip }}
            </v-list-item-subtitle>

            <v-list-item-subtitle class="mb-4">
              {{ client.ein }}
            </v-list-item-subtitle>

            <v-list-item-subtitle v-if="client.contact_phone !== null" class="mb-4">
              {{ client.contact_phone }}
            </v-list-item-subtitle>

            <v-list-item-subtitle class="mb-4">
              {{ client.contact_first }} {{ client.contact_last }}
            </v-list-item-subtitle>

            <v-list-item-subtitle class="mb-4">
              {{ client.contact_email }}
            </v-list-item-subtitle>

            <v-spacer></v-spacer>

          </v-list-item-content>

        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'ProfileInfoCard',
  props: {
    client: {},
    tourStarted: {}
  }
}
</script>
<style lang="scss">

::v-deep .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

::v-deep .v-text-field__slot input::-webkit-outer-spin-button,
::v-deep .v-text-field__slot input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
::v-deep .v-text-field__slot input[type=number] {
  -moz-appearance: textfield !important;
}


</style>
