import $axios from "../plugins/axios";

class QualificationService {
  deleteQualification(id, payload) {
    return $axios.delete(`/queues/qualification/${id}`, {data:payload}).then((res) => res.data);
  }

  rejectQualificationClient(id, payload) {
    return $axios.delete(`/clients/${id}/qualification`, payload).then((res) => res.data);
  }

  finalizeQualification(id) {
    return $axios.post(`/clients/${id}/qualification/finalize`).then((res) => res.data);
  }

  patchQualification(id, payload) {
    return $axios.patch(`/queues/qualifications/${id}`, payload).then((res) => res.data);
  }
}

export default new QualificationService();
