<template>
  <DialogRightAlign :showDialog.sync="showRightDialog" :titleProp="`Upload CSV`">
    <v-textarea class="ma-6 mt-12"  v-model="ownerData" outlined dense label="Enter Owners, one per line">

    </v-textarea>
    <v-spacer></v-spacer>

    <v-spacer></v-spacer>
    <v-card-actions>
    <v-btn
        color="#263B4F"
        class="text-right"
        text
        @click.native="closeDialog"
    >
      close
    </v-btn>
      <v-btn
          color="#263B4F"
          class="text-right"
          text
          @click.native="save"
      >
        save
      </v-btn>
    </v-card-actions>
  </DialogRightAlign>
</template>


<script>
import ClientsService from "@/services/clients.service";
import DialogRightAlign from "@/components/Base/DialogRightAlign";
export default {
  name: "OwnerDialog",
  components: {DialogRightAlign},
  data:()=>({
     ownerData: ''
  }),
  methods:{
    closeDialog(){
      this.$emit('update:showRightDialog', false);
    },
    save(){
      ClientsService.editClient(this.clientIdProp, {raw_owners: this.ownerData})
      .then(this.closeDialog())
      .catch(error => {
        this.$func.popMessage(`${error.response.data.error}`, 'fail')
      })
    },
  },
  props: {
    showRightDialog: {
      type: Boolean,
      default: false
    },

    // multiple: {
    //   type: Boolean,
    //   default: false
    // },

    clientIdProp: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>

</style>