import axios from 'axios';
import VueCookies from 'vue-cookies';
import store from '../store';
import router from '../router';

let $axios = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	withCredentials: true,
	headers: {
		// 'x-mock-match-request-body': true
	}
});

$axios.interceptors.request.use(
	(config) => {
		let cookie = VueCookies.get('current_user');
		// config.headers['x-mock-match-request-body'] = true;

		if (cookie) {
			config.headers.Authorization = `Bearer ${VueCookies.get('current_user')}`;
		}
		// config.headers.Cookie = `current_user=${Cookie.get('current_user')}`;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

let pathArr;
$axios.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		let errorMessage;
		errorMessage = (typeof error.response.data.error !== 'undefined')  ? error.response.data.error : error.response.data.reason_phrase;
		if (typeof errorMessage === 'undefined') errorMessage = "Oops! There has been an error. Our team is working on it.";

		switch (error.response.status) {

			// case 404: errorMessage = error.response.data.error; break;
			case 422: errorMessage = `Validation error`; break;
			case 503:
				// errorMessage = error.response.data.error;
				pathArr = window.location.pathname + window.location.hash
				if (pathArr == '/maintenance') return;
				store.commit("auth/setPreMaintenancePath", pathArr)
				router.push('/maintenance');
				break;
			// default: errorMessage = error.response.data.error; break;
		}

		store.dispatch('snackbar/showSnackbarMessage', {
			message: errorMessage,
			duration: 4000,
			mode: 'fail',
		});

		if (401 === error.response.status) {
			VueCookies.remove('current_user');

			if (router.currentRoute.path !== '/login') {
				router.push('/login');
			}
		}

		return Promise.reject(error);
	},
);

export default $axios;
