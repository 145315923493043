<template>
<!--  <div>-->
<!--    <v-overlay :value="loadinOverlay">-->
<!--      <v-progress-circular-->
<!--          :size="70"-->
<!--          :width="7"-->
<!--          color="white"-->
<!--          indeterminate-->
<!--      ></v-progress-circular>-->
<!--    </v-overlay>-->
<!--&lt;!&ndash;    <v-radio-group&ndash;&gt;-->
<!--&lt;!&ndash;        row&ndash;&gt;-->
<!--&lt;!&ndash;    >&ndash;&gt;-->
<!--&lt;!&ndash;      <v-radio&ndash;&gt;-->
<!--&lt;!&ndash;          label="All"&ndash;&gt;-->
<!--&lt;!&ndash;          @click="getReport"&ndash;&gt;-->
<!--&lt;!&ndash;          value="radio1"&ndash;&gt;-->
<!--&lt;!&ndash;      ></v-radio>&ndash;&gt;-->
<!--&lt;!&ndash;      <v-radio&ndash;&gt;-->
<!--&lt;!&ndash;          label="Outstanding"&ndash;&gt;-->
<!--&lt;!&ndash;          value="radio2"&ndash;&gt;-->
<!--&lt;!&ndash;          @click="getFilteredReports('outstanding')"&ndash;&gt;-->
<!--&lt;!&ndash;      ></v-radio>&ndash;&gt;-->
<!--&lt;!&ndash;      <v-radio&ndash;&gt;-->
<!--&lt;!&ndash;          label="Remitted"&ndash;&gt;-->
<!--&lt;!&ndash;          value="radio3"&ndash;&gt;-->
<!--&lt;!&ndash;          @click="getFilteredReports('remitted')"&ndash;&gt;-->
<!--&lt;!&ndash;      ></v-radio>&ndash;&gt;-->
<!--&lt;!&ndash;    </v-radio-group>&ndash;&gt;-->
<!--    <v-menu-->
<!--        ref="menu"-->
<!--        v-model="menu"-->
<!--        :close-on-content-click="false"-->
<!--        :return-value.sync="date"-->
<!--        transition="scale-transition"-->
<!--        offset-y-->
<!--        min-width="auto"-->
<!--    >-->
<!--      <template v-slot:activator="{ on, attrs }">-->
<!--        <v-text-field-->
<!--            v-model="date"-->
<!--            label="Payment period"-->
<!--            style="width: 100%"-->
<!--            prepend-icon="mdi-calendar"-->
<!--            readonly-->
<!--            v-bind="attrs"-->
<!--            v-on="on"-->
<!--        ></v-text-field>-->
<!--      </template>-->
<!--      <v-date-picker-->
<!--          v-model="date"-->
<!--          type="month"-->
<!--          no-title-->
<!--          scrollable-->
<!--      >-->
<!--        <v-spacer></v-spacer>-->
<!--        <v-btn-->
<!--            text-->
<!--            color="primary"-->
<!--            @click="menu = false"-->
<!--        >-->
<!--          Cancel-->
<!--        </v-btn>-->
<!--        <v-btn-->
<!--            text-->
<!--            color="primary"-->
<!--            @click="getReport()"-->
<!--        >-->
<!--          OK-->
<!--        </v-btn>-->
<!--      </v-date-picker>-->
<!--    </v-menu>-->
<!--    <div v-if="!feeItems.length">-->
<!--      <h3 class="text-center mt-12" style="color: #D21919">-->
<!--        No checks from your clients have been received during this period, and accordingly, no contingency fees are scheduled to be paid out yet.-->
<!--      </h3>-->
<!--    </div>-->
<!--    <v-row         v-if="feeItems.length"-->
<!--                   class="mx-8 mt-6 mb-12 d-flex justify-space-between" v-show="summary">-->
<!--      <div class="d-flex flex-column align-center">-->
<!--        <h1>Checks received:</h1>-->
<!--        <h1 v-if="summary.check_count" class="numbers">{{summary.check_count}}</h1>-->
<!--        <h1 v-else class="numbers">0</h1>-->
<!--      </div>-->
<!--      <div class="d-flex flex-column align-center">-->
<!--        <h1>Average fee:</h1>-->
<!--        <h1 v-if="summary.average_fee" class="numbers">{{summary.average_fee}}</h1>-->
<!--        <h1 v-else class="numbers">0</h1>-->
<!--      </div>-->
<!--      <div class="d-flex flex-column align-center">-->
<!--        <h1>Total fees:</h1>-->
<!--        <h1 v-if="summary.payable_fees" class="numbers">{{summary.payable_fees}}</h1>-->
<!--        <h1 v-else class="numbers">0</h1>-->

<!--      </div>-->
<!--    </v-row>-->

<!--    <v-data-table-->
<!--        v-if="feeItems.length"-->
<!--        :headers="headers"-->
<!--        :items="feeItems"-->
<!--        :single-expand="singleExpand"-->
<!--        :expanded.sync="expanded"-->
<!--        item-key="id"-->
<!--        show-expand-->
<!--        class="elevation-1"-->
<!--        disable-pagination-->
<!--        hide-default-footer-->
<!--    >-->

<!--      <template v-slot:expanded-item="{ headers, item }">-->
<!--&lt;!&ndash;        <td :colspan="headers.length">&ndash;&gt;-->
<!--&lt;!&ndash;          More info about {{ item.name }}&ndash;&gt;-->
<!--&lt;!&ndash;        </td>&ndash;&gt;-->
<!--&lt;!&ndash;        <tr>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>{{item.check_count}}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                 <td>{{item.average_fee}}</td>&ndash;&gt;-->
<!--&lt;!&ndash;                  <td>{{item.payable_fees}}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        </tr>&ndash;&gt;-->
<!--        <td :colspan="headers.length">-->
<!--          <v-data-table-->
<!--              disable-pagination-->
<!--              hide-default-footer-->
<!--              style="background-color: #ededed"-->
<!--              :headers="childRowHeaders"-->
<!--              :items="item.register">-->

<!--          </v-data-table>-->
<!--        </td>-->
<!--      </template>-->
<!--    </v-data-table>-->
<!--&lt;!&ndash;    <v-data-table&ndash;&gt;-->
<!--&lt;!&ndash;        :headers="headers"&ndash;&gt;-->
<!--&lt;!&ndash;        :items="feeItems"&ndash;&gt;-->
<!--&lt;!&ndash;        class="elevation-5 mt-6"&ndash;&gt;-->
<!--&lt;!&ndash;        disable-pagination&ndash;&gt;-->
<!--&lt;!&ndash;        hide-default-footer&ndash;&gt;-->
<!--&lt;!&ndash;    >&ndash;&gt;-->
<!--&lt;!&ndash;      <template v-slot:item="{item}">&ndash;&gt;-->
<!--&lt;!&ndash;        <tr class="green-bg" >&ndash;&gt;-->
<!--&lt;!&ndash;          <td ><span  style="cursor: pointer;padding:5px"  @click="loadAttorneyData(item)">&ndash;&gt;-->
<!--&lt;!&ndash;            {{item.attorney_of_record}}</span>&ndash;&gt;-->
<!--&lt;!&ndash;            <v-sheet&ndash;&gt;-->
<!--&lt;!&ndash;                style="position: absolute;z-index: 99"&ndash;&gt;-->
<!--&lt;!&ndash;                v-show="item.isLoading"&ndash;&gt;-->
<!--&lt;!&ndash;                :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"&ndash;&gt;-->
<!--&lt;!&ndash;                class="pa-3"&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <v-skeleton-loader&ndash;&gt;-->
<!--&lt;!&ndash;                  class=""&ndash;&gt;-->
<!--&lt;!&ndash;                  width="100"&ndash;&gt;-->
<!--&lt;!&ndash;                  height="100"&ndash;&gt;-->
<!--&lt;!&ndash;                  type="card"&ndash;&gt;-->
<!--&lt;!&ndash;              ></v-skeleton-loader>&ndash;&gt;-->
<!--&lt;!&ndash;            </v-sheet>&ndash;&gt;-->
<!--&lt;!&ndash;            <v-card&ndash;&gt;-->
<!--&lt;!&ndash;                v-show="item.showInfo"&ndash;&gt;-->
<!--&lt;!&ndash;                style="position: absolute;z-index: 99"&ndash;&gt;-->
<!--&lt;!&ndash;                max-width="300"&ndash;&gt;-->
<!--&lt;!&ndash;                tile&ndash;&gt;-->
<!--&lt;!&ndash;            >&ndash;&gt;-->
<!--&lt;!&ndash;              <v-list shaped>&ndash;&gt;-->
<!--&lt;!&ndash;                <v-subheader>REPORTS</v-subheader>&ndash;&gt;-->
<!--&lt;!&ndash;                <v-list-item-group&ndash;&gt;-->
<!--&lt;!&ndash;                    v-model="item.register"&ndash;&gt;-->
<!--&lt;!&ndash;                    color="primary"&ndash;&gt;-->
<!--&lt;!&ndash;                    v-for="(i,index) in item.register"&ndash;&gt;-->
<!--&lt;!&ndash;                    :key="index"&ndash;&gt;-->
<!--&lt;!&ndash;                >&ndash;&gt;-->
<!--&lt;!&ndash;                  <v-list-item>&ndash;&gt;-->
<!--&lt;!&ndash;                    <v-list-item-content>&ndash;&gt;-->
<!--&lt;!&ndash;                      <v-list-item-title v-text="i.trade_name"></v-list-item-title>&ndash;&gt;-->
<!--&lt;!&ndash;                    </v-list-item-content>&ndash;&gt;-->
<!--&lt;!&ndash;                  </v-list-item>&ndash;&gt;-->
<!--&lt;!&ndash;                  <v-list-item>&ndash;&gt;-->
<!--&lt;!&ndash;                    <v-list-item-content>&ndash;&gt;-->
<!--&lt;!&ndash;                      <v-list-item-title v-text="i.check_number"></v-list-item-title>&ndash;&gt;-->
<!--&lt;!&ndash;                    </v-list-item-content>&ndash;&gt;-->
<!--&lt;!&ndash;                  </v-list-item>&ndash;&gt;-->
<!--&lt;!&ndash;                  <v-list-item>&ndash;&gt;-->
<!--&lt;!&ndash;                    <v-list-item-content>&ndash;&gt;-->
<!--&lt;!&ndash;                      <v-list-item-title v-text="i.amount_formatted"></v-list-item-title>&ndash;&gt;-->
<!--&lt;!&ndash;                    </v-list-item-content>&ndash;&gt;-->
<!--&lt;!&ndash;                  </v-list-item>&ndash;&gt;-->
<!--&lt;!&ndash;                </v-list-item-group>&ndash;&gt;-->
<!--&lt;!&ndash;              </v-list>&ndash;&gt;-->
<!--&lt;!&ndash;            </v-card>&ndash;&gt;-->

<!--&lt;!&ndash;          </td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>{{item.check_count}}</td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>{{item.average_fee}}</td>&ndash;&gt;-->
<!--&lt;!&ndash;          <td>{{item.payable_fees}}</td>&ndash;&gt;-->
<!--&lt;!&ndash;        </tr>&ndash;&gt;-->
<!--&lt;!&ndash;      </template>&ndash;&gt;-->
<!--&lt;!&ndash;    </v-data-table>&ndash;&gt;-->
<!--  </div>-->
  <div>
    <v-overlay :value="loadinOverlay">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="date"
            label="Payment period"
            style="width: 100%"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
          v-model="date"
          type="month"
          no-title
          scrollable
      >
        <!--        <v-spacer></v-spacer>-->
        <!--        <v-btn-->
        <!--            text-->
        <!--            color="primary"-->
        <!--            @click="menu = false"-->
        <!--        >-->
        <!--          Cancel-->
        <!--        </v-btn>-->
        <!--        <v-btn-->
        <!--            text-->
        <!--            color="primary"-->
        <!--            @click="getReport()"-->
        <!--        >-->
        <!--          OK-->
        <!--        </v-btn>-->
      </v-date-picker>
    </v-menu>
    <div v-if="!feeItems.length">
      <h3 class="text-center mt-12" style="color: #D21919">
        No checks from your clients have been received during this period, and accordingly, no contingency fees are scheduled to be paid out yet.
      </h3>
    </div>
    <v-row         v-if="feeItems.length"
                   class="mx-8 mt-6 mb-12 d-flex justify-space-between" v-show="summary">
      <div class="d-flex flex-column align-center">
        <h1>Checks received:</h1>
        <h1 v-if="summary.check_count" class="numbers">{{summary.check_count}}</h1>
        <h1 v-else class="numbers">0</h1>
      </div>
      <div class="d-flex flex-column align-center">
        <h1>Average fee:</h1>
        <h1 v-if="summary.average_fee" class="numbers">{{summary.average_fee}}</h1>
        <h1 v-else class="numbers">0</h1>
      </div>
      <div class="d-flex flex-column align-center">
        <h1>Total fees:</h1>
        <h1 v-if="summary.payable_fees" class="numbers">{{summary.payable_fees}}</h1>
        <h1 v-else class="numbers">0</h1>

      </div>
    </v-row>
    <v-data-table
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        v-if="feeItems.length"
        :headers="headers"
        :items="feeItems"
        class="elevation-5 mt-6"
        disable-pagination
        hide-default-footer
        show-expand
        item-key="aor_id"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <!--        <td :colspan="headers.length">-->
        <!--          More info about {{ item.name }}-->
        <!--        </td>-->
        <!--        <tr>-->
        <!--          <td>{{item.check_count}}</td>-->
        <!--                 <td>{{item.average_fee}}</td>-->
        <!--                  <td>{{item.payable_fees}}</td>-->
        <!--        </tr>-->
        <td :colspan="headers.length">
          <v-data-table
              disable-pagination
              hide-default-footer
              style="background-color: #ededed"
              :headers="headers2"
              :items="item.register">

          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>


<script>
import ClientsService from "../../services/clients.service";
import {mapGetters} from "vuex";
import AuthService from "../../services/auth.service";
export default {
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  name: "ManagingAttorney",
  data () {
    return {
      headers: [
        { text: 'Attorney', value: 'attorney_of_record' },
        { text: 'Total checks', value: 'check_count' },
        { text: 'Average fee', value: 'average_fee' },
        { text: 'Total fees', value: 'payable_fees' },
        { text: '', value: 'data-table-expand' },
      ],
      headers2: [
        { text: 'Attorney', value: 'trade_name' },
        { text: 'Quarter', value: 'quarter' },
        // { text: 'Total checks', value: 'check_count' },
        // { text: 'Average fee', value: 'average_fee' },
        { text: 'Total fees', value: 'adjusted_fee_payable_formatted' },

      ],
      childRowHeaders:[
        {
          text: 'Client',
          align: 'start',
          sortable: false,
          value: 'trade_name',
        },
        { text: 'Contingency rate', value: 'contracted_contingency_rate' },
        { text: 'Quarter', value: 'quarter' },
        { text: 'Expected Fee', value: 'contingency_fee' },
        { text: 'Check number', value: 'check_number' },
        { value: 'amount', text: 'Check amount' },
        { value: 'amount_applied_to_fee', text: 'Applied to fee' },
        { value: 'percentage_paid_formatted', text: 'Paid' },
        { value: 'adjusted_fee_payable', text: 'Fee paid' },
      ],
      expanded: [],
      singleExpand: true,
      feeItems: [],
      loadinOverlay: false,
      summary:{},
      loading:false,
      date:'',
      menu:false,
      parent_entity:''
    }
  },
  methods: {

    getCurrMonth(){
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      const year = dateObj.getUTCFullYear();
      if (month < 10){
        month = '0' + month
      }

      return year + "-" + month;
    },
    getReport(){
      this.loadinOverlay = true
      ClientsService.getReports(this.date)
          .then(data => {
            this.feeItems = data.register;
            // this.feeItems.map((e,index)=>e.id=index)
            this.summary = data;
            this.loadinOverlay = false
          }).catch(()=> {this.loadinOverlay = false})
    },
    getFee(item){
      this.loadinOverlay = true

      ClientsService.getManagingAttFeeByID(item[0].aor_id,this.date,this.parent_entity.data.parent_entity)
          .then(res=> {
            this.feeItems.map((e,index)=>{
              if (e.managing_firm_id == item[0].managing_firm_id){
                this.feeItems[index].register = res.register
              }
            })
            this.feeItems = [...this.feeItems]
            this.loadinOverlay = false

          }).catch(err=>{
            this.$func.popMessage(`${err.response.data}`,'danger')
            this.expanded = []
        this.loadinOverlay = false
      })
    },
    getUser(){
      AuthService.getCurrentUser(this.currentUser.current_user_id).then(res=>this.parent_entity = res)
    }
  },
  computed: {
    ...mapGetters(
        {
          currentUser: 'auth/getCurrentUser',
        },
    ),
  },
  watch:{
    date(val){
      this.getReport()
    },
    expanded(val){
      if (val.length){
        this.getFee(val)
      }
    }
  },
  created(){
    this.getUser()
    this.date = this.getCurrMonth();
    // this.getReport()
  }
}
</script>

<style scoped>

</style>
