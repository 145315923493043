<template>
  <div>
    <v-row>
      <v-col cols="12">
        <p class="text-h6">System Announcements</p>
      </v-col>
      <v-col cols="12">
        <v-btn :small="isMobile" @click="requestNewAnn" color="primary" rounded>
          <v-icon left>mdi-plus</v-icon>
          Create New
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="loading">
      <v-col cols="12" md="4"><v-skeleton-loader type="card" /></v-col>
      <v-col cols="12" md="8"><v-skeleton-loader type="card"/></v-col>
    </v-row>
    <v-row v-else-if="!loading && announcements.length" class="fill-height">
      <v-col v-if="selectedAnnIdx === null || !isMobile" cols="12" md="4" class="d-flex flex-column" style="gap: 10px">
        <div @click="selectAnn(i)" v-for="(ann, i) in announcements" :key="ann.id">
          <Announcement
              :class="{ selected: selectedAnnIdx === i }"
              :ann="ann"
              :index="i"
              :loading="loadingAnn[i]"
              @removeUnsavedAnn="(j) => announcements.splice(j, 1)"
          >
            <template #actions>
              <v-list dense>
                  <v-list-item @click="deleteAnnouncement(ann.id)">Delete</v-list-item>
              </v-list>
            </template>
          </Announcement>
        </div>
      </v-col>
      <v-col class="d-flex align-center d-md-none" v-if="announcements[selectedAnnIdx]">
        <v-btn @click="selectedAnnIdx = null" icon>
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
        <span class="font-weight-bold">Announcement {{selectedAnnIdx + 1}}</span>
      </v-col>
      <v-col cols="12" md="8" v-if="announcements[selectedAnnIdx]">
        <AnnouncementDetails @postAnn="postAnn" :ann="announcements[selectedAnnIdx]"/>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="4">
        <v-alert
          colored-border
          elevation="2"
          type="info"
        >
          There are no announcements currently!
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AnnouncementService from "@/services/announcement.service";
import Announcement from "../components/System/Announcements/Announcement.vue";
import AnnouncementDetails from "../components/System/Announcements/AnnouncementDetails.vue";

export default {
  name: 'SystemAnnouncements',
  components: {Announcement, AnnouncementDetails},
  data: () => ({
    loading: true,
    announcements: [],
    selectedAnnIdx: null,
    loadingAnn: []
  }),
  methods: {
    selectAnn(i) {
      this.selectedAnnIdx = i

      if (this.isMobile)
        window.scroll(0, 0)
    },
    requestNewAnn() {
      this.announcements.unshift({
        new: true,
        type: 'success',
        valid_from: `${new Date().toISOString().substring(0, 10)} 12:00`,
        valid_until: `${new Date().toISOString().substring(0, 10)} 12:00`,
      })
      this.selectedAnnIdx = this.isMobile ? null : 0;
    },
    getAllAnnouncements() {
      return AnnouncementService.getAnnouncements()
        .then(response => {
          this.announcements = response;
        })
    },
    postAnn(isNew, payload, id) {
      this.loadingAnn[this.selectedAnnIdx] = true
      this.loadingAnn = [...this.loadingAnn]
      const promise = isNew ? AnnouncementService.postAnnouncement(payload) : AnnouncementService.patchAnnouncement(id, payload)

      promise.then(() => {
        this.$func.popMessage(`Announcement ${isNew ? 'created' : 'updated'} successfully`, "success");
        this.getAllAnnouncements()
      }).finally(() => {
        this.loadingAnn[this.selectedAnnIdx] = false
        this.loadingAnn = [...this.loadingAnn]
      })

    },
    deleteAnnouncement(id){
      this.loadingAnn[this.selectedAnnIdx] = true
      this.loadingAnn = [...this.loadingAnn]

      AnnouncementService.deleteAnnouncement(id).then(() => {
        this.$func.popMessage("Announcement deleted successfully", "success");
        this.getAllAnnouncements();
      }).finally(() => {
        this.loadingAnn[this.selectedAnnIdx] = false
        this.loadingAnn = [...this.loadingAnn]
      })
    },
  },
  async created() {
    await this.getAllAnnouncements()
    this.loading = false;
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  }
}
</script>
