<template>
	<v-app>
		<transition name="fade" mode="out-in">
			<router-view />
		</transition>

		<app-snackbar />
	</v-app>
</template>

<script>
import AppSnackbar from "./components/Base/AppSnackbar";
import VueCookies from "vue-cookies";

export default {
	name: 'App',
	components: { AppSnackbar },

	data: () => ({
		//
	}),

	created() {
		let cookie = VueCookies.get('current_user');

		if (cookie != null) {
			this.$store.dispatch('auth/setUserFromToken');
		}
	}
};
</script>
