<template>
  <div class="pa-8 text-center">
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <h2 class="mb-5 text-start">Employee Retention Tax Credit Checklist</h2>
    <h4 class="text-start">
      5. PPP Loans
    </h4>
    <h6 class="ml-6 mt-6 text-start">
      All PPP loans received by your business must be properly accounted for in order to calculate any Employee Retention Credits due to your business.
      The information needed below can be found on Form 3508 that you were required to complete as part of your PPP forgiveness application
    </h6>


     <v-row class="mt-6 px-4">
       <v-col cols="6" class="text-center pr-12">
         <h2>PPP Round 1</h2>
         <h4 class="my-4" style="font-weight: 350">Did you receive a first round PPP loan?</h4>
<!--         <div class="d-flex flex-row justify-space-around">-->
<!--           <div class="d-flex"><v-radio></v-radio> <h3 style="font-weight: 350">Yes</h3></div>-->
<!--           <div class="d-flex"><v-radio></v-radio> <h3 style="font-weight: 350">No</h3></div>-->
<!--         </div>-->
         <div class="d-flex justify-center">
           <v-radio-group
               v-model="hasRound1"
               row
           >
             <v-radio
                 label="YES"
                 :value="1"
             ></v-radio>
             <v-radio
                 label="NO"
                 :value="2"
             ></v-radio>
           </v-radio-group>
         </div>
         <div class="text-start mt-4">
           <h5>If “Yes”...</h5>
           <v-row class="mt-3">
             <v-col>
               <h5>PPP Loan Amount
                 See “A” below.</h5>
             </v-col>
             <v-col><v-text-field :disabled="hasRound1==2" v-model="amount1"
                                  v-restrict.number.decimal prefix="$" dense outlined></v-text-field></v-col>
           </v-row>
<!--           <v-row class="mt-3">-->
<!--             <v-col>-->
<!--               <h5>Amount of Loan Spent on-->
<!--                 Payroll Costs-->
<!--                 See “B” below.-->
<!--               </h5>-->
<!--             </v-col>-->
<!--             <v-col><v-text-field dense outlined></v-text-field></v-col>-->
<!--           </v-row>-->
           <v-row class="mt-3">
             <v-col>
               <h5>Covered Period Start Date
                 See “C” below</h5>
             </v-col>
             <v-col>
               <v-menu
                   :disabled="hasRound1==2"
                   v-model="menu1"
                   :close-on-content-click="false"
                   :nudge-right="40"
                   transition="scale-transition"
                   offset-y
                   min-width="auto"
               >
                 <template v-slot:activator="{ on, attrs }">
                   <v-text-field
                       v-model="date1"
                       :error-messages="startDateErrors"
                       label="Coverage Start Date:"
                       prepend-icon="mdi-calendar"
                       readonly
                       v-bind="attrs"
                       v-on="on"

                   ></v-text-field>
                 </template>
                 <v-date-picker
                     v-model="date1"
                     @input="menu1 = false"
                 ></v-date-picker>
               </v-menu>
             </v-col>
           </v-row>
<!--           <v-row class="mt-3">-->
<!--             <v-col>-->
<!--               <h5>Covered Period End Date-->
<!--                 See “C” below</h5>-->
<!--             </v-col>-->
<!--             <v-col><v-text-field dense outlined></v-text-field></v-col>-->
<!--           </v-row>-->
         </div>
       </v-col>

       <v-divider
           vertical
       ></v-divider>
       <v-col cols="6" class="text-center pl-12">
         <h2>PPP Round 2</h2>
         <h4 class="my-4" style="font-weight: 350">Did you receive a first round PPP loan?</h4>
<!--         <div class="d-flex flex-row justify-space-around">-->
<!--           <div class="d-flex"><v-radio></v-radio> <h3 style="font-weight: 350">Yes</h3></div>-->
<!--           <div class="d-flex"><v-radio></v-radio> <h3 style="font-weight: 350">No</h3></div>-->
<!--         </div>-->
        <div class="d-flex justify-center">
          <v-radio-group
              v-model="hasRound2"
              row
          >
            <v-radio
                label="YES"
                :value="1"
            ></v-radio>
            <v-radio
                label="NO"
                :value="2"
            ></v-radio>
          </v-radio-group>
        </div>
         <div class="text-start mt-4">
           <h5>If “Yes”...</h5>
           <v-row class="mt-3">
             <v-col>
               <h5>PPP Loan Amount
                 See “A” below.</h5>
             </v-col>
             <v-col><v-text-field :disabled="hasRound2==2" v-model="amount2"
                                  v-restrict.number.decimal prefix="$" dense outlined></v-text-field></v-col>
           </v-row>
<!--           <v-row class="mt-3">-->
<!--             <v-col>-->
<!--               <h5>Amount of Loan Spent on-->
<!--                 Payroll Costs-->
<!--                 See “B” below.-->
<!--               </h5>-->
<!--             </v-col>-->
<!--             <v-col><v-text-field dense outlined></v-text-field></v-col>-->
<!--           </v-row>-->
           <v-row class="mt-3">
             <v-col>
               <h5>Covered Period Start Date
                 See “C” below</h5>
             </v-col>
             <v-col>
               <v-menu
                   v-model="menu"
                   :disabled="hasRound2==2"
                   :close-on-content-click="false"
                   :nudge-right="40"
                   transition="scale-transition"
                   offset-y
                   min-width="auto"
               >
                 <template v-slot:activator="{ on, attrs }">
                   <v-text-field
                       v-model="date2"
                       :error-messages="startDateErrors2"
                       label="Coverage Start Date:"
                       prepend-icon="mdi-calendar"
                       readonly
                       v-bind="attrs"
                       v-on="on"

                   ></v-text-field>
                 </template>
                 <v-date-picker
                     v-model="date2"
                     @input="menu = false"
                 ></v-date-picker>
               </v-menu>
             </v-col>
           </v-row>
<!--           <v-row class="mt-3">-->
<!--             <v-col>-->
<!--               <h5>Covered Period End Date-->
<!--                 See “C” below</h5>-->
<!--             </v-col>-->
<!--             <v-col><v-text-field dense outlined></v-text-field></v-col>-->
<!--           </v-row>-->
         </div>
       </v-col>
       <v-col cols="12" class="mb-6">
         <img style="object-fit: contain;width: 100%;height: 100%;right: 0" src="../../assets/img/Check2.png">
       </v-col>
     </v-row>
    <v-btn color="#263B4F" 				:disabled="$v.$invalid"  class="mt-4">
      <span style="color: #FFFFFF" @click="save">Save and Continue</span>
    </v-btn>
  </div>
</template>

<script>
import {required, requiredIf} from "vuelidate/lib/validators";
import ClientService from "../../services/clients.service";

export default {
  name: "Step5",
  props: {
    clientId: {
      type: Number
    }
  },
  data() {
    return {
      menu1:false,
      menu:false,
      hasRound1:1,
      hasRound2:1,
      amount1:'',
      amount2:'',
      date1:"2020-03-01",
      date2:'2021-01-01',
      loading:false,
    }
  },
  validations: {
      amount1: {
        required: requiredIf(function () {
          return this.hasRound1==1;
        }),
      },
    amount2: {
      required: requiredIf(function () {
        return this.hasRound2==1;
      }),
    },
    date1: {
      required: requiredIf(function () {
        return this.hasRound2==1;
      }),
    },
    date2: {
      required: requiredIf(function () {
        return this.hasRound2==1;
      }),
    },
    },
  props:{
    clientId:{
      type: Number
    },
  },
  methods:{
    save() {
      this.loading=true;
      let roundData1 = {
        number: 1,
        start: this.date1.split(' ')[0],
        amount: this.amount1 ? '$' + this.amount1 : null,
        did_not_receive: this.hasRound1==1 ? false : true,
      }
      let roundData2 = {
        number: 2,
        start: this.date2.split(' ')[0],
        amount: this.amount2 ? '$' + this.amount2 : null,
        did_not_receive: this.hasRound2==1 ? false : true,
      }

       ClientService.addRound({clientId: this.clientId, round: roundData1})
          .then((data) => {
            this.$store.dispatch('snackbar/showSnackbarMessage',
                {message: 'Round changes was saved !', duration: 5000})

          })
          .catch((error) => {
            this.$func.popMessage(`${error.response.data.error}`, 'fail')
          });

      ClientService.addRound({clientId: this.clientId, round: roundData2})
          .then((data) => {
            this.$store.dispatch('snackbar/showSnackbarMessage',
                {message: 'Round changes was saved !', duration: 5000})
            this.$emit('goToNextStep', 6)

          })
          .catch((error) => {
            this.$func.popMessage(`${error.response.data.error}`, 'fail')
          });
      this.loading = false;
    }
  },
  computed:{
    startDateErrors() {
      const errors = []
      if (!this.$v.date1.$dirty) return errors
      !this.$v.roundForm.start.required && errors.push('Start date is required.')
      return errors
    },
    startDateErrors2() {
      const errors = []
      if (!this.$v.date2.$dirty) return errors
      !this.$v.roundForm.start.required && errors.push('Start date is required.')
      return errors
    },
  }
}
</script>

<style scoped>

</style>