<template>
  <v-container class="pl-0 pl-md-10">
    <v-overlay :value="loading">
      <v-progress-circular :size="70" :width="7" color="white" indeterminate></v-progress-circular>
    </v-overlay>
    <div v-if="!loading">
      <MandatesList
        v-if="escalatedMandates.length"
        :mandates="escalatedMandates"
        status="Escalated"
      />
      <v-alert
        color="blue"
        class="text-center white--text"
        v-if="
          !blockedMandates.length && !otherMandates.length && !escalatedMandates.length && !loading
        "
      >
        There are no mandates in the queue. As new ones are uploaded to the library, they will
        appear here.
      </v-alert>
      <br />
      <MandatesList v-if="blockedMandates.length" :mandates="blockedMandates" status="Blocked" />
      <br />
      <MandatesList v-if="otherMandates.length" :mandates="otherMandates" status="Available" />
    </div>
    <v-btn
      @click="uploadMandate"
      dark
      color="primary"
      elevation="2"
      fab
      fixed
      bottom
      right
      class="ma-5"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import MandateService from "@/services/mandate.service";
import MandatesList from "@/components/Mandates/MandatesList.vue";

export default {
  name: "MandateLibrary",
  components: { MandatesList },
  data: () => ({
    mandatesReviewQueue: [],
    highPriorityBound: 100,
    blockedStatusId: 7,
    legallyReviewedStatusId: 6,
    escalatedMandates: [],
    blockedMandates: [],
    otherMandates: [],
    searchTerm: "",
    loading: false,
  }),
  methods: {
    // goToMandate(mandate) {
    //   this.$router.push(`/mandate/1`);
    // },
    uploadMandate() {
      this.$router.push("/upload-mandate");
    },
    async getMandatesReviewQueue() {
      this.loading = true;
      this.mandatesReviewQueue = await MandateService.getMandatesReviewQueue();
      this.separateMandatesByStatus();
    },
    separateMandatesByStatus() {
      this.mandatesReviewQueue.forEach((e) => {
        if (e.review_status_id === this.blockedStatusId) this.blockedMandates.push(e);
        else if (e.is_escalated === "true") this.escalatedMandates.push(e);
        else if (e.review_status_id !== this.legallyReviewedStatusId) this.otherMandates.push(e);
      });
      // Sorting them by priority
      this.blockedMandates.sort((a,b) => b.priority - a.priority ); // b - a for reverse sort
      this.loading = false;
    },
  },
  created() {
    this.getMandatesReviewQueue();
  },
};
</script>

<style scoped></style>
