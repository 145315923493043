import { render, staticRenderFns } from "./MandatesList.vue?vue&type=template&id=22be3361&scoped=true&"
import script from "./MandatesList.vue?vue&type=script&lang=js&"
export * from "./MandatesList.vue?vue&type=script&lang=js&"
import style0 from "./MandatesList.vue?vue&type=style&index=0&id=22be3361&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22be3361",
  null
  
)

export default component.exports