<template>
  <DialogRightAlign
    :showDialog="showRightDialogEntity"
    :titleProp="`Manage Entities`"
    v-click-outside="dialogClicked"
  >
    <template #header-button>
      <v-btn id="createNewEntityBtn" color="white" block @click="openCreateNewEntityDialog">
        Add New Entity
      </v-btn>
    </template>

    <CreateEntitiesDialog
      v-if="showCreateNewEntity"
      :key="dialogKeyNewEntity"
      :showRightDialog.sync="showCreateNewEntity"
      :entityID="entityID"
      :entityName="entityName"
      v-on:entity-create="entitiesEmitEvent"
    />

    <ViewEntitiesDialog
      v-if="showViewEntity"
      :key="dialogKeyNewEntity"
      :showRightDialog.sync="showViewEntity"
      :entityObject="entityObject"
      :entityID="entityID"
      v-on:entity-update="entitiesEmitEvent"
    />

    <v-card-text class="mt-5">
      <v-container>
        <div
          v-if="usersRole === 1"
          class="d-flex flex-column flex-sm-row align-center justify-space-between"
        >
          <v-text-field
            label="Search entity..."
            v-model="searchValue"
            @keyup.enter.native="searchEntity"
            class="mr-3"
            :style="`width: ${$vuetify.breakpoint.sm ? 'auto' : '100'}%`"
          ></v-text-field>
          <v-btn
            :style="`width: ${$vuetify.breakpoint.xs ? '100%' : '20%'}`"
            :loading="btnLoading"
            @click="searchEntity"
            color="#263B4F"
          >
          <span slot="loader" style="color: white; font-size: 0.7rem">searching...</span>
            <span style="color: #ffffff">Search</span>
          </v-btn>
        </div>

        <v-list three-line>
          <v-col class="text-subtitle-1 text-center" cols="12" v-if="loading">
            Loading...
          </v-col>
          <v-col cols="12">
            <v-progress-linear
              :active="loading"
              color="deep-purple accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>

          <template v-for="(item, index) in entities">
            <v-subheader
              v-if="item.header"
              :key="item.header"
              v-text="item.header"
            ></v-subheader>

            <v-divider
              v-else-if="item.divider"
              :key="index"
              :inset="item.inset"
            ></v-divider>

            <v-list-item
              v-else
              :key="item.title"
              @click="openViewEntityDialog(item.id, item)"
              v-ripple
            >
              <v-avatar
                v-if="item.firm_type.id === 1"
                color="green"
                class="mr-3"
              >
                <v-icon dark> mdi-account-circle </v-icon>
              </v-avatar>

              <v-avatar
                v-if="item.firm_type.id === 2"
                color="indigo"
                class="mr-3"
              >
                <v-icon dark> mdi-account-circle </v-icon>
              </v-avatar>

              <v-avatar
                v-if="item.firm_type.id === 3"
                color="blue"
                class="mr-3"
              >
                <v-icon dark> mdi-account-circle </v-icon>
              </v-avatar>

              <v-avatar
                  v-if="item.firm_type.id === 4"
                  color="red"
                  class="mr-3"
              >
                <v-icon dark> mdi-account-circle </v-icon>
              </v-avatar>

              <v-list-item-content>
                <v-list-item-title v-html="item.name"></v-list-item-title>
                <v-list-item-subtitle
                >{{item.firm_type.type}}</v-list-item-subtitle>
              </v-list-item-content>
              <!-- <v-chip
              close-icon="mdi-close-outline"
              label
              >
              Pending...
              </v-chip> -->
            </v-list-item>
          </template>
        </v-list>
      </v-container>
    </v-card-text>

    <v-spacer></v-spacer>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="#263B4F" text @click.native="closeDialog"> done </v-btn>
    </v-card-actions>
  </DialogRightAlign>
</template>

<script>
import ClientsService from "@/services/clients.service";

import DialogRightAlign from "../Base/DialogRightAlign";

import ViewEntitiesDialog from "./ViewEntitiesDialog.vue";

import CreateEntitiesDialog from "../../components/Entites/CreateEntitiesDialog.vue";

import {mapGetters} from "vuex";

import AuthService from "@/services/auth.service";

export default {
  name: "EntitesDialog",
  components: { DialogRightAlign, CreateEntitiesDialog, ViewEntitiesDialog },

  props: {
    showRightDialogEntity: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    usersRole: null,
    searchValue: "",
    btnLoading: false,
    clickedOutside: false,
    loading: false,
    dialogKeyNewEntity: 15,
    showCreateNewEntity: false,
    showViewEntity: false,
    closeEntitiesOutside: false,
    entities: "",
    isDialogOpen: false,

    roles: [
      {
        id: 1,
        name: "Admin",
      },
    ],
  }),
  methods: {
    dialogClicked() {
      const dialog = document.getElementsByClassName("v-dialog--active");
      if (dialog.length) return;
      else this.closeDialog();
    },
    openCreateNewEntityDialog() {
      this.entityID = null;
      this.entityName = "";
      this.showCreateNewEntity = true;
      this.dialogKeyNewEntity++;
    },
    openViewEntityDialog(id, index) {
      //console.log(id)
      // ClientsService.getEntity(id)
      //     .then(res=>  {

      //     })
      this.showViewEntity = true;
      this.dialogKeyNewEntity++;
      this.entityObject = index
      this.entityID = id;
    },
    async searchEntity() {
      this.entities = [];
      this.btnLoading = true;
      this.loading = true;

      await ClientsService.searchEntity(this.searchValue).then((res) => {
        // ,
          (this.entities = res)
      });
      this.btnLoading = false;
      this.loading = false;
    },
    closeDialog() {
      //this.$emit("update:showRightDialog", false);
      this.$emit("update:showRightDialogEntity", false);
      this.$router.push("/dashboard");
    },
    async entitiesEmitEvent() {
      //Get all entities if delete or edit is triggered
      await this.GetEntities();
    },
    async GetEntities() {
      this.loading = true;

      await ClientsService.getEntities().then((res) => {
        // ,
          (this.entities = res)
      });

      this.loading = false;
    },
  },
  created() {
    this.GetEntities();
    AuthService.getCurrentUser(this.currentUser.current_user_id)
          .then(res=>{
            this.usersRole = res.data.role_id
            this.user = res.data
          })
  },
  computed:  {
      ...mapGetters(
          {
            currentUser: 'auth/getCurrentUser',
          }
      )
  }
};
</script>

<style scoped></style>
