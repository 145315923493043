<template>
 <div>
   <h1>Test</h1>
   <v-row class="mt-12">
     <v-col v-for="(item, index) in items" :key="index" cols="11" class="mx-6">
       <h2>{{item.trade_name}}</h2>
       <v-row>
         <v-col cols="6" v-for="(q, i) in item.quarters" :key="index + i + 'quarter'">
           <v-card class="mt-8" >
             <v-card-title>Quarter:{{q.quarter}} - {{q.quarter_id}}</v-card-title>
             <div class="d-flex justify-space-between px-4">
               <span> Amount:{{q.refund_amount_formatted}}</span>
             </div>
             <v-card-actions class="px-4">
               <v-text-field
                   label="Enter in an amount received for that quarter"></v-text-field>
             </v-card-actions>
             <div class="d-flex justify-center">
               <v-btn color="rgb(38, 59, 79)" class="mb-4 px-7"><span style="color: #FFFFFF">Save</span></v-btn>
             </div>
           </v-card>
         </v-col>
       </v-row>
       <v-divider></v-divider>
     </v-col>
   </v-row>
 </div>
</template>

<script>
export default {
  name: "Returns",
  data: () => ({
    items:[
      {
        "id": 210917,
        "legal_name": "Sonic Drive In of Vernon, Inc",
        "trade_name": "Sonic Drive In of Vernon, Inc",
        "ein": "47-1407536",
        "street": "44237 Highway 17 S",
        "city": "Vernon",
        "state": "AL",
        "zip": "35592",
        "contingency_rate": 20,
        "quarters": [
          {
            "quarter_id": 2226,
            "round": 2,
            "quarter": "1",
            "refundable_wages": 50665.93,
            "refund_amount": 35466.15,
            "refundable_wages_formatted": "$50,665.93",
            "refund_amount_formatted": "$35,466.15"
          },
          {
            "quarter_id": 2227,
            "round": 2,
            "quarter": "2",
            "refundable_wages": 69948.96,
            "refund_amount": 48964.28,
            "refundable_wages_formatted": "$69,948.96",
            "refund_amount_formatted": "$48,964.28"
          },
          {
            "quarter_id": 2228,
            "round": 2,
            "quarter": "3",
            "refundable_wages": 61257.99,
            "refund_amount": 42880.61,
            "refundable_wages_formatted": "$61,257.99",
            "refund_amount_formatted": "$42,880.61"
          }
        ]
      },
      {
        "id": 210905,
        "legal_name": "Sonic Drive In of Belmont, Inc",
        "trade_name": "Sonic Drive In of Belmont, Inc",
        "ein": "27-2850513",
        "street": "291 2nd Street",
        "city": "Belmont",
        "state": "MS",
        "zip": "38827",
        "contingency_rate": 20,
        "quarters": [
          {
            "quarter_id": 2347,
            "round": 1,
            "quarter": "4",
            "refundable_wages": 181555.88,
            "refund_amount": 90778.1,
            "refundable_wages_formatted": "$181,555.88",
            "refund_amount_formatted": "$90,778.10"
          },
          {
            "quarter_id": 2348,
            "round": 2,
            "quarter": "1",
            "refundable_wages": 75032.46,
            "refund_amount": 52522.73,
            "refundable_wages_formatted": "$75,032.46",
            "refund_amount_formatted": "$52,522.73"
          },
          {
            "quarter_id": 2349,
            "round": 2,
            "quarter": "2",
            "refundable_wages": 87035.39,
            "refund_amount": 60924.77,
            "refundable_wages_formatted": "$87,035.39",
            "refund_amount_formatted": "$60,924.77"
          },
          {
            "quarter_id": 2350,
            "round": 2,
            "quarter": "3",
            "refundable_wages": 83978.15,
            "refund_amount": 58784.74,
            "refundable_wages_formatted": "$83,978.15",
            "refund_amount_formatted": "$58,784.74"
          }
        ]
      },
      {
        "id": 210906,
        "legal_name": "Sonic Drive In of Calhoun City, Inc",
        "trade_name": "Sonic Drive In of Calhoun City, Inc",
        "ein": "82-1110866",
        "street": "597 E. Veterans",
        "city": "Calhoun City",
        "state": "MS",
        "zip": "38916",
        "contingency_rate": 20,
        "quarters": [
          {
            "quarter_id": 2361,
            "round": 1,
            "quarter": "4",
            "refundable_wages": 237370.51,
            "refund_amount": 118685.4,
            "refundable_wages_formatted": "$237,370.51",
            "refund_amount_formatted": "$118,685.40"
          },
          {
            "quarter_id": 2362,
            "round": 2,
            "quarter": "1",
            "refundable_wages": 93803.91,
            "refund_amount": 65662.77,
            "refundable_wages_formatted": "$93,803.91",
            "refund_amount_formatted": "$65,662.77"
          },
          {
            "quarter_id": 2363,
            "round": 2,
            "quarter": "2",
            "refundable_wages": 104040.34,
            "refund_amount": 72828.26,
            "refundable_wages_formatted": "$104,040.34",
            "refund_amount_formatted": "$72,828.26"
          },
          {
            "quarter_id": 2364,
            "round": 2,
            "quarter": "3",
            "refundable_wages": 94283.36,
            "refund_amount": 65998.37,
            "refundable_wages_formatted": "$94,283.36",
            "refund_amount_formatted": "$65,998.37"
          }
        ]
      },
      {
        "id": 210908,
        "legal_name": "Sonic Drive In of Ecru, Inc",
        "trade_name": "Sonic Drive In of Ecru, Inc",
        "ein": "20-0769509",
        "street": "P. O. Box 444",
        "city": "Ecru",
        "state": "MS",
        "zip": "38840",
        "contingency_rate": 20,
        "quarters": [
          {
            "quarter_id": 2375,
            "round": 1,
            "quarter": "4",
            "refundable_wages": 155756.98,
            "refund_amount": 77878.62,
            "refundable_wages_formatted": "$155,756.98",
            "refund_amount_formatted": "$77,878.62"
          },
          {
            "quarter_id": 2376,
            "round": 2,
            "quarter": "1",
            "refundable_wages": 66356.28,
            "refund_amount": 46449.42,
            "refundable_wages_formatted": "$66,356.28",
            "refund_amount_formatted": "$46,449.42"
          },
          {
            "quarter_id": 2377,
            "round": 2,
            "quarter": "2",
            "refundable_wages": 87980.94,
            "refund_amount": 61586.7,
            "refundable_wages_formatted": "$87,980.94",
            "refund_amount_formatted": "$61,586.70"
          },
          {
            "quarter_id": 2378,
            "round": 2,
            "quarter": "3",
            "refundable_wages": 80994.75,
            "refund_amount": 56696.34,
            "refundable_wages_formatted": "$80,994.75",
            "refund_amount_formatted": "$56,696.34"
          }
        ]
      },
      {
        "id": 210911,
        "legal_name": "Sonic Drive In of Okolona, Inc",
        "trade_name": "Sonic Drive In of Okolona, Inc",
        "ein": "20-0898214",
        "street": "403 E. Monroe",
        "city": "Okolona",
        "state": "MS",
        "zip": "38860",
        "contingency_rate": 20,
        "quarters": [
          {
            "quarter_id": 2389,
            "round": 1,
            "quarter": "4",
            "refundable_wages": 122124.38,
            "refund_amount": 61062.29,
            "refundable_wages_formatted": "$122,124.38",
            "refund_amount_formatted": "$61,062.29"
          },
          {
            "quarter_id": 2390,
            "round": 2,
            "quarter": "1",
            "refundable_wages": 52842.35,
            "refund_amount": 36989.66,
            "refundable_wages_formatted": "$52,842.35",
            "refund_amount_formatted": "$36,989.66"
          },
          {
            "quarter_id": 2391,
            "round": 2,
            "quarter": "2",
            "refundable_wages": 58316.98,
            "refund_amount": 40821.92,
            "refundable_wages_formatted": "$58,316.98",
            "refund_amount_formatted": "$40,821.92"
          },
          {
            "quarter_id": 2392,
            "round": 2,
            "quarter": "3",
            "refundable_wages": 55768.87,
            "refund_amount": 39038.23,
            "refundable_wages_formatted": "$55,768.87",
            "refund_amount_formatted": "$39,038.23"
          }
        ]
      },
      {
        "id": 210912,
        "legal_name": "Sonic Drive In of Ripley, Inc",
        "trade_name": "Sonic Drive In of Ripley, Inc",
        "ein": "27-2805210",
        "street": "710 City Ave S",
        "city": "Ripley",
        "state": "MS",
        "zip": "38663",
        "contingency_rate": 20,
        "quarters": [
          {
            "quarter_id": 2403,
            "round": 1,
            "quarter": "4",
            "refundable_wages": 229943.58,
            "refund_amount": 114971.91,
            "refundable_wages_formatted": "$229,943.58",
            "refund_amount_formatted": "$114,971.91"
          },
          {
            "quarter_id": 2404,
            "round": 2,
            "quarter": "1",
            "refundable_wages": 100063.32,
            "refund_amount": 70044.35,
            "refundable_wages_formatted": "$100,063.32",
            "refund_amount_formatted": "$70,044.35"
          },
          {
            "quarter_id": 2405,
            "round": 2,
            "quarter": "2",
            "refundable_wages": 112464.99,
            "refund_amount": 78725.51,
            "refundable_wages_formatted": "$112,464.99",
            "refund_amount_formatted": "$78,725.51"
          },
          {
            "quarter_id": 2406,
            "round": 2,
            "quarter": "3",
            "refundable_wages": 104882.66,
            "refund_amount": 73417.89,
            "refundable_wages_formatted": "$104,882.66",
            "refund_amount_formatted": "$73,417.89"
          }
        ]
      }
    ]
  }),
}
</script>

<style scoped>

</style>