import Vue from 'vue'
import Vuex from 'vuex'

import snackbar from "./modules/snackbar";
import auth from "./modules/auth";
import tour from "./modules/tour";
import tasks from "./modules/tasks";
import payments from "./modules/payments";
import leads from "@/store/modules/leads";
import pagebuilder from "@/store/modules/pagebuilder";
import reports from "@/store/modules/reports";
import sanitization from "@/store/modules/sanitization";
Vue.use(Vuex)

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		snackbar,
		pagebuilder,
		reports,
		auth,
		tour,
		tasks,
		payments,
		leads,
		sanitization
	}
})
