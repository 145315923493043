<template>
  <div>
    <div class="d-flex justify-center mt-16" v-if="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="purple"
          indeterminate
      ></v-progress-circular>
    </div>
    <component :is="dashboardComponent" ></component>


  </div>
</template>

<script>
import Admin from "@/views/CustomFeesPageForRoles/Admin";
import AuthService from "@/services/auth.service";
import NonAdmin from "@/views/CustomFeesPageForRoles/NonAdmin";
import ManagingAttorney from "./CustomFeesPageForRoles/ManagingAttorney";
import Attorney from "./CustomFeesPageForRoles/Attorney";
import AttorneyOfRecord from "./CustomFeesPageForRoles/AttorneyOfRecord";
import TaxAttorney from "./CustomFeesPageForRoles/TaxAttorney";
import MasterTaxAttorneyFees from "@/components/MasterTaxAttorneyFees.vue";

const RoleDashboardMapping = {
  1: Admin,
  //1: ManagingAttorney,
  2: NonAdmin,
  3: TaxAttorney,
  4: ManagingAttorney,
  5: NonAdmin,
  6: NonAdmin,
  7: Attorney,
  8: NonAdmin,
  13:MasterTaxAttorneyFees
}

export default {
  name: "CustomReportsTemplate",
  data: () => ({
    dashboardComponent: 0,
    loading: true
  }),

  async beforeCreate() {
    this.loading = true
    await AuthService.getCurrentUser(this.$store.state.auth.user.current_user_id)
        .then(res=>{
          this.loading = false
          this.dashboardComponent = RoleDashboardMapping[res.data.role_id]
        }).catch(()=>this.loading=false)
  }
}
</script>

<style scoped>

</style>