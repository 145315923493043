<template>
  <v-row>
    <v-dialog v-model="errorMessage" width="500">
      <v-card class="pa-16" style="background-color: #263b4f">
        <h2 style="color: #ffffff" class="text-center mb-7">
          {{ errorText }}
        </h2>
        <v-progress-linear
          absolute
          color="red"
          :v-model="errProgressBarValue"
          :buffer-value="bufferValue"
        >
        </v-progress-linear>
      </v-card>
    </v-dialog>
    <v-col cols="12" md="6">
      <h1 class="ml-1">Upload a mandate</h1>
      <v-card
        v-if="error !== noSearchableTextError"
        @drop.prevent="drop($event)"
        @dragover.prevent="dragoverData = true"
        @dragenter.prevent="dragoverData = true"
        @dragleave.prevent="dragoverData = false"
        class="d-flex align-center justify-center"
        elevation="0"
      >
        <v-row class="d-flex justify-center align-center">
          <div class="p-12">
            <input
              type="file"
              name="fields[assetsFieldHandleUploads][]"
              id="assetsFieldHandleUploads"
              class="d-none absolute"
              @input="onChange"
              ref="file"
              accept="application/pdf"
            />

            <label for="assetsFieldHandleUploads" class="block cursor-pointer">
              <span style="cursor: pointer" class="d-flex flex-column align-center mt-8">
                <!-- <h1>Upload a mandate</h1> -->
                <v-icon style="font-size: 5rem">mdi-upload</v-icon>
                <p>Drag and drop a PDF mandate here to upload</p>
              </span>
            </label>
          </div>
        </v-row>
      </v-card>
      <div v-else-if="!fileUploaded && error === noSearchableTextError">
        <v-alert type="error">
          The uploaded PDF does not contain any searchable or indexable text. Please run OCR on the
          PDF and try re-uploading.
        </v-alert>
        <p class="text-body-1">
          The uploaded PDF appears to be a scan of a document, and is only a
          <b>picture</b> of text. This mandate library requires all PDFs to have searchable text in
          order to be uploaded.
        </p>
        <p class="text-body-1">
          You can easily fix this by using the OCR tool available in many PDF editors. We highly
          recommend
          <a href="https://www.adobe.com/acrobat/acrobat-pro.html" class="text-decoration-none"
            >Adobe Acrobat Pro</a
          >
          for this task.
        </p>
        <p>
          To fix this problem, open this PDF in Adobe Acrobat Pro, and use the
          <b>Scan & OCR</b>
          button, then the <b>Recognize Text</b> button, and <b>In This File</b> button to recognize
          all text, and then re-save, and re-upload here.
        </p>
        <v-img src="../assets/img/pdf_1.png"></v-img>
        <v-img class="mt-6" src="../assets/img/pdf_2.png"></v-img>
        <v-row justify="center">
          <div>
            <input
              type="file"
              multiple
              name="fields[assetsFieldHandleUploads2][]"
              id="assetsFieldHandleUploads2"
              class="d-none absolute"
              @input="onChange"
              ref="file"
            />
            <v-btn class="mt-6" color="primary">
              <label for="assetsFieldHandleUploads2" class="block cursor-pointer">
                Click here to upload another PDF
              </label>
            </v-btn>
          </div>
        </v-row>
      </div>
      <v-overlay :value="loading">
        <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </v-col>
  </v-row>
</template>

<script>
import AuthService from "@/services/auth.service";
import MandateService from "@/services/mandate.service";

export default {
  name: "Mandate",
  data() {
    return {
      dragoverData: false,
      file: null,
      forDate: new Date(),
      loading: false,
      errorMessage: null,
      errorText: null,
      interval: null,
      bufferValue: null,
      errProgressBarValue: null,
      error: "",
      noSearchableTextError: "The uploaded PDF does not contain searchable text.",
      currentUser: null,
      uploadId: null,
    };
  },
  methods: {
    addHashToLocation() {
      history.pushState({}, null, this.$route.path + "#" + "success");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
    },
    uploadFile() {
      this.loading = true;
      const formData = new FormData();
      formData.append("uploaded_file", this.$refs.file.files[0]);
      MandateService.uploadMandate(formData)
        .then((res) => {
          this.uploadId = res.id;
          this.error = null;
          MandateService.postMandate({
            mandate_upload: res.id,
          });

          this.$func.popMessage("PDF uploaded successfully.", "success");
        })
        .catch((e) => {
          this.error = e.response.data.error;
          if (this.error === this.noSearchableTextError)
            this.$store.dispatch("snackbar/showSnackbarMessage", {
              message: `The uploaded PDF does not contain any searchable or indexable text. Please run OCR on the PDF and try re-uploading.`,
              duration: 4000,
              mode: "fail",
            });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    startBuffer() {
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.errProgressBarValue += 2;
        this.bufferValue += 2;
      }, 50);
    },
    onChange() {
      if (this.$refs.file.files.length === 0) return;
      this.loading = true;
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        if (this.$refs.file.files[i].type !== "application/pdf") {
          this.errorText = "The uploaded file is not a PDF. Please upload a PDF file.";
        } else if (this.$refs.file.files[i].size > 25000000) {
          this.errorText =
            "The uploaded file is over the File Size limit of 25Mb. Please use a PDF optimizer to make the file smaller and try again.";
        } else {
          this.errorText = null;
        }

        if (!this.errorText) {
          this.loading = true;
          let file = this.$refs.file.files[i];
          const newFile = {
            original_file_name: file.name,
            url: file,
            created_on: this.forDate,
            mime_type: file.type,
            uploaded_by: this.currentUser.id,
            file: file,
            uploadingFiles: false,
          };
          this.file = newFile;
          this.uploadFile();
        } else {
          const removeMessageAfter = 3500;
          this.errorMessage = true;
          this.startBuffer();
          this.loading = false;
          setTimeout(() => {
            this.errorMessage = false;
            clearInterval(this.interval);
            this.errProgressBarValue = 0;
            this.bufferValue = 0;
          }, removeMessageAfter);
        }
      }
    },
  },
  async created() {
    const data = await AuthService.getCurrentUserFromCookie();
    this.currentUser = data.data;
    if (this.$route.hash === "#success") {
      await this.$func.popMessage("Mandate saved successfully", "success");
      setTimeout(() => {
        history.pushState({}, null, this.$route.path);
      }, 2000);
    }
    this.businessImpactChoices = await MandateService.getBusinessImpact();
  },
};
</script>

<style scoped></style>