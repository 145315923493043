<template>
  <v-container>
    <v-row align="center">
      <v-col cols="auto">
        <v-btn icon @click="exitCreating">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col class="d-flex align-center">
        <h3>Create new user</h3>
      </v-col>
    </v-row>
    <v-card outlined rounded elevation="2" width="600px" class="pa-7 mt-5">
      <v-form v-model="isValid">
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Firm:</v-col>
          <v-col cols="12" md="9">
            <v-autocomplete
                v-model="addUserForm.parent_entity"
                :items="entityOptions"
                @change="getAvailableRoles"
                :rules="[rules.required]"
                item-text="name"
                item-value="id"
                label="Search"
                outlined
            />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Role:</v-col>
          <v-col cols="12" md="9">
            <v-select
              v-model="addUserForm.role"
              :items="roles"
              :loading="rolesLoading"
              :disabled="!addUserForm.parent_entity"
              :rules="[rules.required]"
              item-text="role_name"
              label="Role"
              item-value="role_id"
              outlined
            />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">First Name:</v-col>
          <v-col cols="12" md="9">
            <v-text-field :rules="[rules.required]" v-model="addUserForm.first" outlined />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Last Name:</v-col>
          <v-col cols="12" md="9">
            <v-text-field :rules="[rules.required]" v-model="addUserForm.last" outlined />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Email:</v-col>
          <v-col cols="12" md="9">
            <v-text-field
              :rules="[rules.required, rules.email]"
              v-model="addUserForm.email"
              outlined
            />
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="mt-5">
        <v-row justify="space-between">
          <v-col cols="4">
            <v-btn @click="exitCreating" block color="error">Cancel</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn
              @click="saveUser"
              :disabled="!isValid"
              :loading="loading"
              block
              color="primary"
              >Create
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import AuthService from "@/services/auth.service";
import ClientsService from "@/services/clients.service";
import { mapGetters } from "vuex";

export default {
  name: "CreateUserCard",
  data: () => ({
    isValid: false,
    entityOptions: [],
    roles: [],
    addUserForm: {
      parent_entity: '',
      email: '',
      first: '',
      last: '',
      role: '',
    },
    rules: {
      required: (value) => !!value || "This field is required.",
      email: (value) => {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    loading: false,
    rolesLoading: false,
  }),
  methods: {
    exitCreating(refresh = false) {
      this.$emit("exitCreatingUser", refresh);
    },
    getAvailableRoles(item) {
      if (item){
        this.rolesLoading = true;
        ClientsService.getEntitiesFiltered(item)
            .then(res=>{
              this.addUserForm.parent_entity = res
              this.roles = res.available_roles
              this.rolesLoading = false;
            })
      }
    },
    saveUser() {
      this.loading = true;
      let {parent_entity, ...employeeRest} = this.addUserForm;
      if (typeof parent_entity == 'object'){
        parent_entity = parent_entity.id
      }
      // if ()
      ClientsService.createNewUser(parent_entity, employeeRest)
          .then(res => {
            this.$func.popMessage('User created successfully', 'success')
            this.exitCreating(true)

          }).finally(() => this.loading = false)
    }
  },
  async created() {
    await AuthService.getCurrentUser(this.currentUser.current_user_id).then((res) => {
      this.usersRole = res.data.role_id;
    });

    if (this.usersRole === 1) {
      await ClientsService.getEntitiesFiltered("all").then((res) => {
        this.entityOptions = res;
        if (this.entityOptions.length === 1) {
          this.addUserForm.parent_entity = this.entityOptions[0].id;
        }
      });
    } else {
      await ClientsService.getEntitiesFiltered("my").then((res) => {
        this.entityOptions = [res];
        this.addUserForm.parent_entity = this.entityOptions[0].id;
        this.getAvailableRoles();
      });
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
};
</script>