<template>

  <div>

    <h1>Qualification Report</h1>

    <v-data-table
        :headers="headers"
        :items="items"
        item-key="client_id"
        class="elevation-1 mt-5"
        style="cursor:pointer;"
        hide-default-footer
        :loading="loadingTable"
        loading-text="Loading... Please wait"
        @click:row="handleRowClick"
    >
    </v-data-table>


  </div>

</template>

<script>
import ClientsService from "@/services/clients.service";
import MandateService from "@/services/mandate.service";

export default {
  name: "QualificationReport",
  data(){
    return{
      headers: [
        { text: 'Client ID', value: 'client_id' },
        { text: 'Client', value: 'client' },
        { text: 'AOR', value: 'aor' },
        { text: 'Days In Queue', value: 'days_in_system' },
      ],
      items:[],
      loadingTable:false,
    }
  },
  methods:{
    getQualificationReport(){
      this.loadingTable = true;
       MandateService.getReport().then(res=>{
        this.items = res
       }).finally(()=>{this.loadingTable = false;})
    },
    handleRowClick(row) {
      this.$router.push(`/clients/${row.client_id}/mandates`)
    },
  },
  created() {
    this.getQualificationReport()
  }
}
</script>

<style scoped>

</style>
