import $axios from '../plugins/axios'

class NotificationService {
    getNotifs(type,status) {
        return $axios.get(`/notifications/${type}/status/${status}`)
            .then((res) => {
                return res.data;
            });
    }

	getNotifTypes(){
		return $axios.get(`/notifications/types`)
			.then((res) => {
				return res.data;
			});
	}

	patchNotification(id, payload) {
		return $axios.patch(`/notifications/${id}`, payload).then(res => res.data)
	}

	markAsReadAll() {
			return $axios.patch('/notifications/devour').then(res => res.data)
	}

	getAllNotfications() {
		return $axios.get(`/notifications`)
			.then((res) => {
				return res.data;
			});
	}
    closeNotif(id) {
		return $axios.patch(`/notifications/${id}/read`)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {
				throw error;
				popFailure(`${error.response.data.error}`);
			})
	}
    snoozeNotif(id, filter) {
		return $axios.patch(`/notifications/${id}/snooze`,filter)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {

				throw error;
				popFailure(`${error.response.data.error}`);
			})
	}
}

export default new NotificationService();
