import $axios from '../plugins/axios'

class TaxService {

	getTaxes(data) {
		return $axios.get(`entities/tax`)
			.then((res) => {
				return res.data;
			});
	}

}

export default new TaxService();