import { render, staticRenderFns } from "./PPPRoundDialog.vue?vue&type=template&id=6d6c5ce4&scoped=true&"
import script from "./PPPRoundDialog.vue?vue&type=script&lang=js&"
export * from "./PPPRoundDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d6c5ce4",
  null
  
)

export default component.exports