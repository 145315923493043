<template>
  <v-container>
    <v-row align="center">
      <v-col cols="auto">
        <v-btn icon @click="exitEditing">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col class="d-flex align-center">
        <v-avatar :color="avatarColor" class="mr-3">
          <v-icon dark> mdi-account-circle</v-icon>
        </v-avatar>
        <h3>{{ entity.name }}</h3>
      </v-col>
    </v-row>
    <v-card outlined rounded elevation="2" width="600px" class="pa-7 mt-5">
      <v-form v-model="isValid">
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Entity Name:</v-col>
          <v-col cols="12" md="9">
            <v-text-field :rules="[rules.required]" v-model="editedEntity.name" outlined />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">EIN:</v-col>
          <v-col cols="12" md="9">
            <v-text-field counter="10" :rules="[rules.required, rules.ein]" v-model="editedEntity.ein" outlined />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Firm Type:</v-col>
          <v-col cols="12" md="9">
            <v-select
              v-model="editedEntity.firm_type"
              :rules="[rules.required]"
              :items="firm_types"
              item-text="type"
              item-value="id"
              outlined
            />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">First Name:</v-col>
          <v-col cols="12" md="9">
            <v-text-field v-model="editedEntity.primary_user.first" outlined />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Last Name:</v-col>
          <v-col cols="12" md="9">
            <v-text-field :rules="[rules.required]" v-model="editedEntity.primary_user.last" outlined />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Email:</v-col>
          <v-col cols="12" md="9">
            <v-text-field :rules="[rules.required, rules.email]" v-model="editedEntity.primary_user.email" outlined />
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="mt-5">
        <v-row justify="space-between">
          <v-col cols="4">
            <v-btn @click="showDialog = true" block color="error">Delete</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn @click="saveEntity" :disabled="!isValid || !changed" :loading="editLoading" block color="primary">Save</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-row align="center" class="mt-2">
      <v-col cols="12" md="3">
        <v-btn @click="handleNewAdoption" color="primary">
          <v-icon left>
            mdi-plus
          </v-icon>
          new adoption
        </v-btn>
      </v-col>
    </v-row >
    <v-row v-if="newAdoption">
      <v-col  cols="12" sm="7" md="3">
        <v-autocomplete
            :items="allEntities"
            item-value="id"
            item-text="name"
            :rules="[rules.required]"
            dense
            outlined
            hide-details
            placeholder="Adopted Entity *"
            v-model="clientToAdopt"
        />
      </v-col>
      <v-col cols="12" sm="7" md="3">
        <v-text-field
            outlined
            hide-details
            dense
            :rules="[rules.required]"
            placeholder="Fees to collect *"
            type="number"
            @blur="handleFeeBlur"
            max="100"
            min="0"
            v-model="fees"
            suffix="%"
        />
      </v-col>
      <v-col cols="12" sm="7" md="3">
        <v-autocomplete
            :items="entityUsers"
            v-model="newAor"
            item-value="id"
            item-text="name"
            :loading="!entityUsers.length"
            dense
            outlined
            hide-details
            placeholder="New AOR (optional)"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-row>
          <v-col cols="auto">
            <v-btn @click="handleCancelNewAdoption" color="primary" outlined>cancel</v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="handleAddAdoption" :disabled="!clientToAdopt || !fees" color="primary">save</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col cols="12">
        <v-card>
          <v-card-title>Adoptions
            <v-spacer></v-spacer>
            <v-text-field
                v-model="searchAdoption"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field></v-card-title>
            <v-data-table
                :loading="adoptionLoading"
                :headers="adoptionTableHeaders"
                :search="searchAdoption"
                style="cursor:pointer;"
                :items="adoptions"
                @click:row="handleAdoptionRowClick"
                hide-default-footer
                disable-pagination
            />
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showDialog" persistent max-width="600px">
      <v-card>
        <v-card-title
          class="text-h5 font-weight-bold"
          style="background-color: #263b4f; color: white"
        >
          Delete Entity
        </v-card-title>
        <v-card-text class="mt-10 red--text" style="font-size: 1.2rem"
          >Are you sure you want to delete this Entity?
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="showDialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" :loading="deleteLoading" text @click="deleteEntity()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import DeleteConfirmation from "@/components/Client/DeleteConfirmation.vue";
import ClientsService from "@/services/clients.service";
import AdoptionsService from '@/services/adoptions.service'

export default {
  name: "EditEntityCard",
  components: { DeleteConfirmation },
  props: ["entity","allEntities"],
  data: () => ({
    editedEntity: {
      name: "",
      ein: "",
      firm_type: "",
      primary_user: {
        first: "",
        last: "",
        email: "",
      },
    },
    firm_types: [
      { id: 1, type: "Processor" },
      { id: 2, type: "Managing Firm" },
      { id: 3, type: "Tax Firm" },
      { id: 4, type: "Referral Partner" },
    ],
    showDialog: false,
    editLoading: false,
    deleteLoading: false,
    rules: {
      ein: (value) => {
        const pattern = /^[0-9]{2}-[0-9]{7}$/;
        return pattern.test(value) || "Invalid ein";
      },
      required: (value) => !!value || "This field is required.",
      email: (value) => {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    isValid: false,
    adoptionLoading: false,
    adoptions: [

    ],
    searchAdoption: "",
    adoptionTableHeaders: [
      {text: 'Client', value: 'client_id'},
      {text: 'Legal Name', value: 'legal_name'},
      {text: 'EIN', value: 'ein'},
      {text: 'AOR', value: 'aor'},
      {text: 'Previous AOR', value: 'previous_aor'},
      {text: 'Managing Firm', value: 'managing_firm'},
    ],
    newAdoption: false,
    clientToAdopt: undefined,
    newAor: undefined,
    fees: undefined,
    entityUsers: []
  }),
  methods: {
    exitEditing(refresh = false) {
      this.$emit("exitEditingEntity", refresh);
    },
    async deleteEntity() {
      this.deleteLoading = true;

      await ClientsService.deleteEntities(this.entity.id).finally(() => {
        this.deleteLoading = false;
      });
      this.showDialog = false;
      this.$emit("exitEditingEntity");
    },
    async saveEntity() {
      this.editLoading = true;
      const entityModel = {
        name: this.editedEntity.name,
        ein: this.editedEntity.ein,
        firm_type: this.editedEntity.firm_type,
        first: this.editedEntity.primary_user.first,
        last: this.editedEntity.primary_user.last,
        email: this.editedEntity.primary_user.email,
      };
      await ClientsService.editEntities(this.entity.id, entityModel).finally(
        () => (this.editLoading = false)
      );
      this.exitEditing(true);
      this.$store.dispatch("snackbar/showSnackbarMessage", {
        message: `Entity updated successfully!`,
        duration: 4000,
        mode: "success",
      });
    },
    getAdoptions() {
      this.adoptionLoading = true

      AdoptionsService.getAdoptions(this.entity.id)
        .then(res => {
          this.adoptions = res
        })
        .finally(() => {
          this.adoptionLoading = false
        })

      // setTimeout(() => {
      //   this.adoptions.push({
      //     id: 8,
      //     legal_name: "Louisville Panels Inc",
      //     client_id: 4,
      //     trade_name: "Fischer Sips",
      //     ein: "47-1654655",
      //     managing_firm: "Patriot Data Processing, LLC",
      //     aor: "Fernando Sierra",
      //     previous_aor: "Fernando Sierra"
      //   })
      //   this.adoptionLoading = false
      // }, 1000)
    },
    handleNewAdoption() {
      this.newAdoption = true
      if (!this.entityUsers.length)
        this.getEntityUsers()
    },
    handleCancelNewAdoption() {
      this.newAdoption = false
      this.clientToAdopt = undefined
      this.newAor = undefined
      this.fees = 0
    },
    getEntityUsers() {
      ClientsService.getEntitiesFiltered(this.entity.id).then((res) => {
        let users = res.users;
        this.entityUsers = users.filter((user) => user.role_id === 4 || user.role_id === 7)
      })
    },
    handleAdoptionRowClick({client_id}) {
      this.$router.push(`/clients/${client_id}/profile`)
    },
    handleAddAdoption() {
      this.handleFeeBlur()
      let payload = {
        master_entity: this.entity.id,
        slave_entity: this.clientToAdopt,
        contingency_fees: +this.fees
      }

      if (this.newAor)
        payload.attorney_of_record = this.newAor

      AdoptionsService.createAdoption(payload)
        .then(() => {
          this.getAdoptions()
          this.handleCancelNewAdoption()
        })
    },
    handleFeeBlur() {
      if (this.fees < 0)
        this.fees = "0"

      if (this.fees > 100)
        this.fees = 100
    }
  },
  created() {
    this.editedEntity = JSON.parse(JSON.stringify(this.entity));
    this.editedEntity.firm_type = this.entity.firm_type.id;

    this.getAdoptions()
  },
  computed: {
    avatarColor() {
      switch (this.entity.firm_type.id) {
        case 1:
          return "green";
        case 2:
          return "indigo";
        case 3:
          return "blue";
        case 4:
          return "red";
      }
    },
    changed() {
      return (
          this.editedEntity.name !== this.entity.name ||
          this.editedEntity.ein !== this.entity.ein ||
          this.editedEntity.firm_type !== this.entity.firm_type.id ||
          this.editedEntity.primary_user.first !== this.entity.primary_user.first ||
          this.editedEntity.primary_user.last !== this.entity.primary_user.last ||
          this.editedEntity.primary_user.email !== this.entity.primary_user.email
      );
    }
  },
};
</script>
