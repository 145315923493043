<template>
  <div>
    <v-overlay :value="loadinOverlay">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div class="ml-14 mr-14 mb-4">
      <v-row class="mt-5">
        <v-col cols="10">
          <v-autocomplete
              v-model="searchValue"
              :items="autoCompleteItems"
              item-text="name"
              item-value="pay_to"
              label="Search"
          ></v-autocomplete>
        </v-col>
        <v-col cols="2" class="text-end">
          <v-btn rounded color="#263B4F" class="mt-3">
            <span style="color: #FFFFFF">Search</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="true"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="date"
              label="Payment period"
              style="width: 83%"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="date"
            type="month"
            no-title
            scrollable
        >
          <v-spacer></v-spacer>
<!--          <v-btn-->
<!--              text-->
<!--              color="primary"-->
<!--              @click="menu = false"-->
<!--          >-->
<!--            Cancel-->
<!--          </v-btn>-->
<!--          <v-btn-->
<!--              text-->
<!--              color="primary"-->
<!--              @click="getFilteredReports()"-->
<!--          >-->
<!--            OK-->
<!--          </v-btn>-->
        </v-date-picker>
      </v-menu>
    </div>
<!--    <v-radio-group-->
<!--        row-->
<!--        v-model="activeRadio"-->
<!--    >-->
<!--      <v-radio  -->
<!--          class="radioBtn"-->
<!--          @click="getReport"-->
<!--          :value="1"-->
<!--      >-->
<!--        <template v-slot:label>-->
<!--          <div class="radioBtn">All</div>-->
<!--        </template>-->
<!--      </v-radio>-->
<!--      <v-radio-->
<!--          :value="2"-->
<!--         @click="getFilteredReports('outstanding')"-->
<!--      >-->
<!--        <template v-slot:label>-->
<!--          <div class="radioBtn">Outstanding</div>-->
<!--        </template>-->
<!--      </v-radio>-->
<!--      <v-radio-->
<!--          :value="3"-->
<!--          @click="getFilteredReports('remitted')"-->
<!--      >-->
<!--        <template v-slot:label>-->
<!--          <div class="radioBtn">Remitted</div>-->
<!--        </template>-->
<!--      </v-radio>-->
<!--      <v-radio-->
<!--          :value="4"-->
<!--          @click="getFilteredReports('group')"-->
<!--      >-->
<!--        <template v-slot:label>-->
<!--          <div class="radioBtn">Grouped</div>-->
<!--        </template>-->
<!--      </v-radio>-->
<!--    </v-radio-group>-->



<!--      <v-data-table-->
<!--          :headers="headers"-->
<!--          :items="feeItems"-->
<!--          class="elevation-5 mt-6"-->
<!--          disable-pagination-->
<!--          hide-default-footer-->
<!--      >-->
<!--        <template v-slot:item.remitted="{item}">-->
<!--          <span v-if="item.remitted === 0" style="color: #AF0808">Outstanding</span>-->
<!--          <span v-if="item.remitted === 1" style="color: #4CAF50">Remitted</span>-->
<!--        </template>-->

<!--        <template v-slot:item.fee_expected="{item}">-->
<!--          <span>{{formatAmount(item.fee_expected)}}</span>-->
<!--        </template>-->
<!--        <template v-slot:item.remittedSwitch="{item, index}">-->
<!--          <v-switch v-model="item.remitted" @click="updateReport(item, index)"></v-switch>-->
<!--        </template>-->

<!--      </v-data-table>-->

<!--    </div>-->

<!--    <div v-else>-->
    <div v-if="groupItems.length">
      <v-card
          class="mx-auto mb-5"
          max-width="90%"
          outlined
          elevation="6"
      >
        <v-data-table
            v-if="groupedEntities.length > 1"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :headers="entityHeaders"
          :items="groupedEntities">

          
          <template v-slot:item="row">

            <tr v-if="row.item.last" >
               <td class="text-xs-right" style="border-top: 3px solid #263B4F;font-weight: 650">  {{ row.item.pay_to_the_order_of }} </td>
               <td class="text-xs-right text-end"  style="border-top: 3px solid #263B4F;font-weight: 650">{{ row.item.adjusted_fees_payable_formatted}} </td>
            </tr>
            <tr v-else>
              <td class="text-xs-right">{{ row.item.pay_to_the_order_of }} </td>
              <td class="text-xs-right  text-end">{{ row.item.adjusted_fees_payable_formatted}} </td>
            </tr>


          </template>


      </v-data-table>
      </v-card>
      <v-card
        class="mx-auto mb-5"
        max-width="90%"
        outlined
        elevation="6"
        v-for="(check, index) in groupItems"
        :key="index"
      >
        <v-list-item three-line  v-show="!check.successfullyPosted">
          <v-list-item-content>
            <div class="text-overline mb-4">
              <p class="text-h5">{{check.pay_to_the_order_of}}</p>
            </div>

            <v-data-table
                item-key="id + client_name"
                :headers="headers2"
              :items="check.register"
              class="elevation-1"
              disable-pagination
              hide-default-footer
            >
              <template v-slot:header.checkItem="{ header }">
                {{ header.text }}
                <v-checkbox
                    @click="checkAllFees(check.areAllChecked, index)" v-model="check.areAllChecked" />
                </template>
            <template v-slot:item.checkItem="{item}">
              <v-checkbox
                v-model="item.isChecked"
                @click="feeCheckbox(check.adjusted_fees_payable, item.adjusted_fee_payable, index, item.isChecked)"
              ></v-checkbox>
            </template>
            </v-data-table>

             <p v-show="!check.successfullyPosted" class="font-italic text-center font-weight-bold text-h6 mt-3">
              Total payable:   {{ $formatAmount(check.adjusted_fees_payable) }}
            </p>

          </v-list-item-content>
        </v-list-item>

        <div class="checkCard ma-3" >
          <div class="d-flex justify-space-between align-center pa-5">
            <h4 class="mr-2">Check Number:</h4>
              <v-text-field class="pr-16"
                            v-model="check.check_number"
                            @blur="reRenderItems"
                            label="Check Number"></v-text-field>

            <p>Date: <span class="text-decoration-underline">{{todaysDate}}</span></p>
          </div>
          <div class="d-flex align-center pa-5">
          <p class="ma-0">Pay to the order of: </p>
          <div class="text-decoration-underline pl-2">
            <span class="ma-0 font-weight-bold text-h6"> {{check.pay_to_the_order_of}}</span>
          </div>

            <div class="pa-2 ml-auto">
              <p class="font-italic text-decoration-underline text-center text-h6 mt-3">
                {{ $formatAmount(check.adjusted_fees_payable) }}
              </p>
            </div>
          </div>

        </div>
        <div class="d-flex justify-end ma-3" v-show="!check.successfullyPosted" >
          <v-btn  color="#263B4F"
                  v-show="!check.successfullyPosted"
                  :disabled="isCheckPosting"
                  @click="postFees(check, index)">
            <span style="color: #fff">Remit Check</span>
          </v-btn>
        </div>

      </v-card>
    </div>

  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "Admin",
  data () {
    return {
      headers: [
        { text: 'Pay to the order of', value: 'pay_to_the_order_of' },
        {
          text: 'Paid To  The Order Of',
          align: 'start',
          sortable: false,
          value: 'pay_to_the_order_of',
        },
        { text: 'Fee Payable', value: 'adjusted_fees_payable_formatted' },
        {
          text: 'Client Name',
          sortable: false,
          value: 'trade_name',
        },
        { text: 'Quarter', value: 'quarter' },
        { text: 'Due Before', value: 'due_before' },
        { text: 'Late After', value: 'late_after' },
        // { text: 'Status', value: 'remitted' },
        // { text: 'Remitted Amount', value: 'remitted_amount' },
        // { text: 'Remitted on', value: 'remitted_on' },
        { text: 'Remitted' , value: 'remittedSwitch',sortable: false }
      ],
      feeItems: [],
      loadinOverlay: false,
      radio1:null,
      radio2:null,
      radio3:null,
      radio4:null,
      date:'',
      menu:false,
      activeRadio: 4,
      feeTable: true,
      searchValue: '',
      isCheckPosting:false,
      autoCompleteItems:[],
      searchFilteringItems:[],
      originalFeesArray:[],
      isRemitted: false,
      groupedEntities: [],
      entityHeaders:[
        { text: 'Entity', value: 'pay_to_the_order_of', sortable: false,},
        { text: 'Total Due', value: 'adjusted_fees_payable',align: 'end', sortable: false,},
      ],
      headers2: [
          { value: 'checkItem', sortable: false },
          {
            text: 'Client',
            align: 'start',
            value: 'trade_name',
          },
          { text: 'Quarter', value: 'quarter' },
          { text: 'Fee', value: 'adjusted_fee_payable_formatted' },

        ],
        groupItems: [],
        summary:'',
        checkbox: true,
        totalPayable: 0,
        currentCheck: 0
    }
  },
  computed:{
    todaysDate(){
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      let day = dateObj.getUTCDate();
      let year = dateObj.getUTCFullYear();

      const newdate = month + "/" + day + "/" + year;
      return newdate
    },
  },
   methods: {
    getCurrMonth(){
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      const year = dateObj.getUTCFullYear();
      if (month < 10){
        month = '0' + month
      }

      return year + "-" + month;
    },
    feeCheckbox(totalAmount, checkItem, index, event){
      if (event === true) {
        this.groupItems[index].adjusted_fees_payable = (parseFloat(totalAmount) + parseFloat(checkItem)).toFixed(2)
      }else if(event === false){
        this.groupItems[index].adjusted_fees_payable = (parseFloat(totalAmount) - parseFloat(checkItem)).toFixed(2)
        this.groupItems = [...this.groupItems]

      }
    },
    // getFilteredGroups(filter){
    //   this.feeTable = false
    //
    //   this.loadinOverlay = true
    //    ClientsService.getFilteredReports(filter)
    //        .then(data => {
    //          console.log(data)
    //          this.groupItems = data
    //          this.loadinOverlay = false
    //        }).catch(()=> {this.loadinOverlay = false})
    //
    //
    // },
     reRenderItems(){
      this.groupItems=[...this.groupItems]
     },
    // getReport(){
    //   this.feeTable = true
    //   this.loadinOverlay = true
    //   ClientsService.getReports()
    //         .then(data => {
    //           this.searchFilteringItems = data
    //           this.originalFeesArray = data
    //           this.feeItems = data
    //           this.feeItems.forEach(e => {
    //             this.autoCompleteItems.push({name:e.client_name,id: e.client_id})
    //           })
    //           this.loadinOverlay = false
    //         }).catch(()=> {this.loadinOverlay = false})
    // },
     getFilteredReports(filter){
      this.feeTable = true
      this.loadinOverlay = true
       // switch (filter) {
       //   case 'outstanding':
       //     this.isRemitted = false
       //     break;
       //   case 'remitted':
       //     this.isRemitted = true
       //     break;
       // }
       ClientsService.getReports(this.date)
           .then(data => {
             this.searchValue = ''
             this.autoCompleteItems = []
             if (data.register.length){
               this.groupItems = data.register
               if (!this.groupedEntities.length){
                 data.register.forEach(x=>{
                   this.groupedEntities.push({'pay_to_the_order_of':x.pay_to_the_order_of,'adjusted_fees_payable_formatted':x.adjusted_fees_payable_formatted})
                 })
                 this.groupedEntities.push({'pay_to_the_order_of':'Total','adjusted_fees_payable_formatted':data.adjusted_fees_payable_formatted,'last':true})
               }
               this.searchFilteringItems = data.register
               this.originalFeesArray = data
               this.groupItems.map(e => {
                 e.areAllChecked = true
                 e.check_number = ''
                 this.autoCompleteItems.push({name: e.pay_to_the_order_of, pay_to: e.pay_to})
                 e.register.map(reg => {
                   reg.isChecked = true
                 })
               })
             }
             this.loadinOverlay = false

             // }else{
             //   this.searchFilteringItems = data
             //   this.originalFeesArray = data
             //   this.feeItems = data
             //   this.feeItems.forEach(e => {
             //     this.autoCompleteItems.push({name:e.client_name,id: e.client_id})
             //   })
             //   this.loadinOverlay = false
             // }
           }).catch(()=> {this.loadinOverlay = false})
     },
     searchInput(item){
        // if (this.feeTable){
        //   this.feeItems = []
        //   this.searchFilteringItems.forEach(firm =>{
        //     if(firm.client_id == item){
        //       this.feeItems.push(firm)
        //     }
        //   })
        // }else if(!this.feeTable){
          this.groupItems = this.searchFilteringItems.filter(firms => firms.pay_to == item)
      //}
     },
     checkAllFees(value, index){
       if (value === false){
         this.groupItems[index].register.map(e => {
           if (e.isChecked !== value){
             e.isChecked = false
             this.groupItems[index].adjusted_fees_payable = parseFloat(this.groupItems[index].adjusted_fees_payable) - parseFloat(e.adjusted_fee_payable)
           }
         })
       }else if(value === true){
         this.groupItems[index].register.map(e => {
          if (e.isChecked !== value){
            e.isChecked = true
            this.groupItems[index].adjusted_fees_payable = parseFloat(this.groupItems[index].adjusted_fees_payable) + parseFloat(e.adjusted_fee_payable)
          }
         })
       }
       this.groupItems = [...this.groupItems]
     },
     postFees(check, index){
      this.isCheckPosting = true;
      const model={
        "check_number": check.check_number,
        "check_amount": check.adjusted_fees_payable,
        "register": []
      }
       check.register.forEach(e => {
          if (e.isChecked){
            model.register.push({ "adjusted_fee_payable": e.adjusted_fee_payable, "credit_id": e.credit_id})
          }
       })
      ClientsService.postFeePayments(model,check.pay_to)
          .then(res => {
            this.isCheckPosting = false
            this.$func.popMessage('Payment successfully remitted','success')
            this.groupItems[index].successfullyPosted = true
            this.groupItems = [...this.groupItems]
          })
          .catch(err => this.$func.popMessage(err.response.data,'danger'))
     },
     updateReport(item, index){
       const model = {
         // "id": item.id,
         "remitted": item.remitted === 1 ? false : true
       }
      ClientsService.editReport(item.id ,model)
      

     },
     isDisable(text) {
       if (text){
         return text.length > 0;
       }
     }
  },
  watch:{
    date(val){
      this.groupItems=[]
      this.groupedEntities=[]
      this.getFilteredReports()
    },
    searchValue(val){
      if (val){
        this.searchInput(val)
      }else {
        if (this.feeTable){
          this.feeItems = this.originalFeesArray
        }else{
          this.groupItems = this.originalFeesArray
        }
      }
    },
    // isRemitted(val){
    //   if (val === false){
    //     this.headers = [
    //       {
    //         text: 'Paid To  The Order Of',
    //         align: 'start',
    //         sortable: false,
    //         value: 'pay_to_the_order_of',
    //       },
    //       { text: 'Fee Payable', value: 'fee_expected' },
    //       {
    //         text: 'Client Name',
    //         sortable: false,
    //         value: 'client_name',
    //       },
    //       { text: 'Quarter', value: 'quarter' },
    //       { text: 'Due Before', value: 'due_before' },
    //       { text: 'Late After', value: 'late_after' },
    //       // { text: 'Remitted Amount', value: 'remitted_amount' },
    //       // { text: 'Remitted on', value: 'remitted_on' },
    //       { text: 'Status', value: 'remittedSwitch' }
    //     ]
    //   }else if(val === true) {
    //     this.headers = [
    //       {
    //         text: 'Paid To  The Order Of',
    //         align: 'start',
    //         sortable: false,
    //         value: 'pay_to_the_order_of',
    //       },
    //       { text: 'Fee Payable', value: 'fee_expected' },
    //       {
    //         text: 'Client Name',
    //         sortable: false,
    //         value: 'client_name',
    //       },
    //       { text: 'Quarter', value: 'quarter' },
    //       { text: 'Due Before', value: 'due_before' },
    //       { text: 'Late After', value: 'late_after' },
    //       { text: 'Status', value: 'remitted' },
    //       { text: 'Remitted Amount', value: 'remitted_amount' },
    //       { text: 'Remitted on', value: 'remitted_on' },
    //       { text: 'Status', value: 'remittedSwitch' }
    //     ]
    //   }
    //}
  },
  created(){
    this.date = this.getCurrMonth();
    // this.getFilteredReports('group')
  },
}
</script>

<style lang="scss" scoped>

::v-deep .v-data-table tbody {
    display: contents !important;
}

.checkCard{
  background: #BEC7E682;
  border-style: dotted;

}
@media only screen and (max-width: 600px) {
  .radioBtn{
      font-size: 12px;
  }
}


</style>
