<template>
  <v-sheet class="py-4 px-5" rounded="xl" outlined>
    <v-row>
      <v-col>
        <p class="text-h6">{{ status }}</p>
      </v-col>
    </v-row>
    <v-row class="mt-3" dense v-for="(mandate, i) in data" :key="i">
      <v-col cols="12" md="12" xs="12" sm="12">
        <v-card
          rounded="lg"
          outlined
          :class="{rainbowGradient : status == 'Blocked' && mandate.priority >= 100}"
          :to="`/mandate/${mandate.mandate_id}`"
          :style="{ boxShadow: styles.shadow }"
        >
          <v-tooltip :disabled="mandate.review_status_id !== 7" bottom color="error">
            <template v-slot:activator="{ on, attrs }">
              <v-card-text
                v-on="on"
                style="color: black"
                class="text-center pa-0 font-weight-medium"
              >
                <v-row dense>
                  <v-col cols="12" md="1">
                    <div
                      :style="
                        isMobile
                          ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
                          : 'border-right: 1px solid rgba(0, 0, 0, 0.1)'
                      "
                      class="d-flex flex-column px-2 py-5 fill-height"
                    >
                      {{mandate.state_code ?? 'N/A'}}
                      <v-chip
                        v-if="status === 'Escalated' && mandate.priority_reason"
                        class="text-caption mt-2 align-self-center"
                        text-color="white"
                        style="width: min-content"
                        :color="styles.chipColor"
                      >
                        {{ mandate.priority_reason }}
                      </v-chip>
                      <div
                        v-else-if="status === 'Blocked' && isMobile"
                        class="text-caption"
                        style="color: #ff3355"
                        v-else
                      >
                        {{ mandate.review_status_reason }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3">
                    <div
                      class="d-flex justify-center flex-column fill-height pa-2"
                      :style="
                        isMobile
                          ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
                          : 'border-right: 1px solid rgba(0, 0, 0, 0.1)'
                      "
                    >
                      <p
                        class="text-caption"
                        style="color: rgb(128, 128, 128)"
                        v-if="!mandate.publication_date"
                      >
                        No data for publication date
                      </p>
                      <div v-else>
                        {{ mandate.publication_date }}
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3">
                    <div
                      class="d-flex justify-center flex-column fill-height pa-2"
                      :style="
                        isMobile
                          ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.1)'
                          : 'border-right: 1px solid rgba(0, 0, 0, 0.1)'
                      "
                    >
                      <p
                        class="text-caption"
                        style="color: rgb(128, 128, 128)"
                        v-if="!mandate.author"
                      >
                        No data for author
                      </p>
                      <h5 v-else>
                        {{ mandate.author }}
                      </h5>
                    </div>
                  </v-col>
                  <v-col cols="12" md="3">
                    <div class="d-flex justify-center flex-column fill-height pa-2">
                      <p
                        class="text-caption"
                        style="color: rgb(128, 128, 128)"
                        v-if="!mandate.title"
                      >
                        No data for title
                      </p>
                      <h5 v-else >
                        {{ mandate.title }}
                      </h5>
                    </div>
                  </v-col>
                  <v-col class="py-4" cols="12" md="2" align-self="center">
                    <v-btn
                      :loading="mandate.checkLoading"
                      @click.prevent="checkMandate(mandate, i)"
                      color="primary"
                      >Check
                      {{
                        `${
                          mandate.review_status_id === 5 &&
                          (userIsMasterTaxAttorney ||
                            currentUser.current_user_id === mandate.checked_out_to)
                            ? "in"
                            : "out"
                        }`
                      }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
            <span v-if="mandate.review_status_id === 7">{{ mandate.review_status_reason }}</span>
          </v-tooltip>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import MandateService from "@/services/mandate.service";
import AuthService from "@/services/auth.service";
import { mapGetters } from "vuex";

export default {
  name: "MandatesList",
  props: ["mandates", "status"],
  data: () => ({
    visibleElementIndexes: [],
    data: [],
    userIsMasterTaxAttorney: false,
  }),
  methods: {
    // 1	New
    // 2	Pending search indexing
    // 3	Pending AI Processing
    // 4	Ready for legal review
    // 5	In review
    // 6	Legally reviewed
    // 7	Blocked
    async checkMandate({ mandate_id, review_status_id, checked_out_to, mandate_queue_id }, i) {
      this.data[i].checkLoading = true;
      this.data = [...this.data];
      const mandateIsCheckedOut = review_status_id === 5;
      const review_status =
        mandateIsCheckedOut &&
        (this.userIsMasterTaxAttorney || this.currentUser.current_user_id === checked_out_to)
          ? 4
          : 5;
      let mandate = await MandateService.getMandateInReviewQueueById(mandate_queue_id);

      if (mandate.review_status_id === 6) {

        this.$func.popMessage(
          `Mandate is already legally reviewed and it cannot be checked out`,
          "error"
        );
        this.data.splice(i, 1);
        this.data = [...this.data];
        return;
      }

      try {
        const payload = {
          review_status,
        };
        const updatedMandate = await MandateService.updateIndividualMandate(mandate_queue_id, payload);
        if (!mandateIsCheckedOut) {
          this.$router.push(`/mandate/${mandate_id}`);
          return;
        }
        this.data[i] = updatedMandate;
        this.data = [...this.data];
        await this.updateList();
      } catch (e) {
        this.$func.popMessage(e.response.data.error, "error");
      } finally {
        this.data[i].checkLoading = false;
        this.data = [...this.data];
      }
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    styles() {
      if (this.status === "Blocked") {
        return { shadow: "0px 4px 4px 0px #FF3355", chipColor: "red" };
      } else if (this.status === "Escalated") {
        return { shadow: "0px 4px 4px 0px #F9C941", chipColor: "#E3C218" };
      } else {
        return { shadow: "none" };
      }
    },
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
      }
    },
  },
  async created() {
    const data = await AuthService.getCurrentUserFromCookie();
    this.userIsMasterTaxAttorney = data.data.role_id === 13;
    this.data = [...this.mandates];
    this.visibleElementIndexes = Array(this.mandates.length).fill(undefined);
  },
};
</script>
<style scoped>
.rainbowGradient {
  box-shadow: 0 5px 12px 2px #FF3355,  0 -2px 12px 4px #E3C218 !important;
  //background: linear-gradient(to right, rgba(255,0,0,0) , #ffeca0);
}
</style>
