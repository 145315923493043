<template>
  <div style="position: absolute; z-index: 1111">
    <DialogRightAlign
      :showDialog.sync="showRightRoundDialog"
      :titleProp="titleRoundProp"
    >
      <v-card-text class="mt-10">
        <v-container>
          <v-row class="d-flex justify-center">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                id="pppInput"
                v-model="roundForm.amount"
                :error-messages="amountErrors"
                required
                @input="$v.roundForm.amount.$touch()"
                @blur="$v.roundForm.amount.$touch()"
                prefix="$"
              />

              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="roundForm.start"
                    :error-messages="startDateErrors"
                    label="Coverage Start Date:"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @blur="$v.roundForm.start.$touch()"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="roundForm.start"
                  @input="menu = false"
                  :min="minDate"
                  :max="endDate"
                ></v-date-picker>
              </v-menu>

              <v-switch
                v-model="didNotReceive"
                label="Did Not Receive This Round"
              ></v-switch>

              <!--						<v-select-->
              <!--							id="state"-->
              <!--							:append-icon="'mdi-arrow-down'"-->
              <!--							outlined-->
              <!--							:error-messages="durationError"-->
              <!--							:items="durationArr"-->
              <!--							required-->
              <!--							v-model="roundForm.duration"-->
              <!--							:item-text="'name'"-->
              <!--							:item-value="'value'"-->
              <!--							label="Duration"-->
              <!--						/>-->
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-spacer></v-spacer>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="#263B4F" text @click.native="closeDialog"> close </v-btn>

        <v-btn
          color="#263B4F"
          :disabled="$v.roundForm.$invalid"
          text
          id="savePPP"
          @click.native="save"
        >
          save
        </v-btn>
      </v-card-actions>
    </DialogRightAlign>
  </div>
</template>

<script>
import { helpers, required, requiredIf } from "vuelidate/lib/validators";
import ClientService from "../services/clients.service";

import DialogRightAlign from "@/components/Base/DialogRightAlign";

export default {
  name: "PPPRoundDialog",
  components: { DialogRightAlign },

  props: {
    clientId: {
      type: Number,
      default: 0,
    },
    showRightRoundDialog: {
      type: Boolean,
      default: false,
    },

    titleRoundProp: {
      type: String,
      default: "Add round ",
    },

    currentRound: {
      type: Number,
      default: null,
    },
  },

  validations: {
    roundForm: {
      amount: {
        required: requiredIf(function () {
          return !this.didNotReceive;
        }),
      },
      start: { required },
    },
  },

  data: () => ({
    didNotReceive: false,
    buttonLoadingSpinner: false,
    durationArr: [
      { name: "8 weeks", value: 8 },
      { name: "24 weeks", value: 24 },
    ],
    roundForm: {
      amount: "",
      start: "2020-03-01",
    },
    menu: false,
    menu2: false,
    minDate: "2020-01-01",
    endDate: "2020-12-31",
  }),

  methods: {
    closeDialog() {
      this.roundForm = {
        amount: "",
        start: "",
      };

      this.$emit("update:showRightRoundDialog", false);
      this.$emit("getCurrentClient", this.clientId);
    },

    save() {
      let roundData = {
        number: this.currentRound,
        start: this.roundForm.start.split(" ")[0],
        amount: this.roundForm.amount ? "$" + this.roundForm.amount : null,
        did_not_receive: this.didNotReceive,
      };

      // console.log('current round is ',this.currentRound)
      // console.log('clientID is ', this.clientId)
      // console.log('round is ', roundData)

      return ClientService.addRound({
        clientId: this.clientId,
        round: roundData,
      })
        .then((data) => {
          this.closeDialog();
          //console.log('new data is ',data)
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: "Round changes was saved !",
            duration: 5000,
          });
        })
        .catch((error) => {
          this.$func.popMessage(`${error.response.data.error}`, "fail");
        });

      this.$v.$touch();
      this.buttonLoadingSpinner = true;
    },
  },

  computed: {
    startDateErrors() {
      const errors = [];
      if (!this.$v.roundForm.start.$dirty) return errors;
      !this.$v.roundForm.start.required &&
        errors.push("Start date is required.");
      return errors;
    },

    amountErrors() {
      const errors = [];
      if (!this.$v.roundForm.amount.$dirty) return errors;
      !this.$v.roundForm.amount.required &&
        errors.push("This field is required.");
      return errors;
    },
  },

  watch: {
    // currentRound(value) {
    // 	this.roundForm = value;
    //
    // 	this.$forceUpdate();
    //
    // 	if (value) {
    // 		this.roundForm.amount = this.roundForm.amount.replace('$', '');
    // 		this.roundForm.amount = this.roundForm.amount.replace(',', '');
    // 		this.roundForm.amount = Number(this.roundForm.amount);
    // 		// console.log('value123')
    // 		// console.log(this.roundForm)
    // 	}
    // }
  },

  mounted() {
    if (this.currentRound == 2) {

      this.roundForm.start = "2021-01-01";
      this.minDate = "2021-01-01";
      this.endDate = "2021-12-31";
    }
  },
};
</script>

<style scoped lang="scss"></style>
