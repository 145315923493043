<template>
  <v-container>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

      <v-btn :loading="saveLoading" @click="save(refferedInput)" class="mb-5" style="height: 50px; width: 20%" color="#263B4F">
      <span style="color: #FFFFFF">Save</span>
      </v-btn>

      <!-- Table -->
      <template>
      <v-data-table
          :headers="headers"
          :items="orphanedItems"
          :loading="tableLoading"
          class="elevation-1"
          item-key="firstName"
          disable-pagination
          hide-default-footer
      >

      <template v-slot:item.inputAnswer="{ item, index }">
        <p v-if="item.autocomplete.name === undefined">Nothing selected...</p>
        <p v-if="item.autocomplete.name !== undefined">
        {{ item.autocomplete.name }}
        </p>
    </template>
      
        
      <template v-slot:item.refferedName="{ item,index }">
        <v-autocomplete
            v-model="item.autocomplete"
            @input="selectItem(item,index)"
            :items="item.orphanOptions"
            @update:search-input="event => searchOrphans(event,index)"
            item-text="name"
            item-value="id"
            :no-filter=true
            return-object
            label="Search"
            :loading="item.loadWhileSearching"
          ></v-autocomplete>
      </template>
    
      </v-data-table>
      </template>

  </v-container>
</template>
<script>
import ClientsService from "@/services/clients.service";
import AuthService from "@/services/auth.service";
import {mapGetters} from "vuex";
import UserService from "@/services/user.service";

export default {
  data () {
      return {
          tableLoading: false,
        loading:true,
          saveLoading: false,
          refferedInput: '',
        loadWhileSearching:false,
          headers: [
          {
              text: 'First Name',
              align: 'start',
              value: 'first',
          },
          { text: 'Last Name', value: 'last' },
          { text: 'Email', value: 'email' },
          { text: 'Selected contractor', value: 'inputAnswer' },
          { text: 'Reffered by', value: 'refferedName' },
          ],
          orphanedItems: [],
          searchOrphan: '',
          searchOrphanData: null,
          orphanOptions: [],
          selectedNames: []
      }
  },
  methods: {
    getOrphans(){
      this.loading=true
      ClientsService.getOrphaned()
      .then(res => {
          this.orphanedItems = res
        this.orphanedItems.forEach(object => {
          object['autocomplete'] = '';
          object['orphanOptions'] = [];
        });
        this.loading=false
      })
    },
    async selectItem(item,index){
      this.refferedInput = item
      this.selectedNames.push({userID: item.id, uplineID: this.refferedInput.autocomplete.id})
    },
    async save(){
      this.selectedNames.map(async (item) => {
        this.postOrphanContractor(item.userID, item.uplineID)
      })  
    },
    postOrphanContractor(useerID, uplineID){
      UserService.patchUser(useerID,{"upline_parent": uplineID}).then(()=> {
        this.$func.popMessage("Successfully changed.")
        this.getOrphans()
      }).catch(err => {
        this.$func.popMessage(`${err.response.data[0]}`, "fail")
      })
    },
    searchOrphans(item,index) {
      if (!item) return;
      // this.orphanedItems[index].orphanOptions=[]
      this.loadWhileSearching=true
      this.orphanedItems[index].loadWhileSearching = true
      ClientsService.searchOrphan(item)
        .then(res=>{
          this.orphanedItems[index].orphanOptions = res
          this.orphanedItems = [...this.orphanedItems]
          this.loadWhileSearching = false
          this.orphanedItems[index].loadWhileSearching = false
        })
    }
  },
  created(){
    this.getOrphans()
    AuthService.getCurrentUser(this.currentUser.current_user_id)
      .then(res => {
          this.usersRole = res.data.role_id
      })

      ClientsService.getUsers().then(res =>{
          this.roles = res.available_roles
      })
  },
  computed: {
  ...mapGetters(
      {
        currentUser: 'auth/getCurrentUser',
      },
  )
},
}
</script>
