<template>
  <div>
    <ClientHeader :client="client" />
    <v-container>
      <div class="d-flex justify-start flex-column py-5" v-if="processingFile == true">
        <v-progress-linear
            indeterminate
            color="green"
        />
        <div> Processing file... Please wait this might take some time</div>
      </div>
      <v-overlay :value="loadingOnPost">
        <v-progress-circular
            :size="70"
            :width="7"
            color="white"
            indeterminate
        ></v-progress-circular>
      </v-overlay>
<!--      Outstanding-->
      <v-row>
        <v-col cols="12">
          <v-btn id="documents-upload-btn" @click="openUploadDialog" color="primary" class="ml-3 mb-3">Upload</v-btn>
        </v-col>
        <v-col md="4" v-if="this.outstanding.length===0">
          <v-progress-linear
              indeterminate
              color="green"
              v-if="loading"
          />
          <div v-if="loading === false">All documents have been collected. There are no outstanding documents
            at this time.
          </div>
        </v-col>
        <v-col md="4" cols="12" v-if="this.outstanding.length">
          <h3 class="ml-3">Outstanding :</h3>

          <v-list class="mt-1" dense>
            <v-hover
                v-for="(file, index) in outstanding"
                :key="`${index}-outstanding-file`"
                v-slot="{ hover }">
              <v-list-item
                  class=""
                  :style="hover ? 'background-color: yellow;cursor:pointer' : ''"
              >
                <h3 style="color: #D21919;font-weight: bold">{{ file.document_name }}</h3>
                <button class="ml-2" v-show="hover" style="right: 0;position: absolute" type="button"
                        @click="deleteOutstanding(file)"
                        title="Remove outstanding"
                >

                  <v-icon>
                    mdi-trash-can-outline
                  </v-icon>
                </button>
              </v-list-item>
            </v-hover>
          </v-list>
          <v-btn v-if="currentUser.role_id === 1" color="#263B4F" @click="sendEmail" class="white--text">Request Outstanding</v-btn>
        </v-col>

        <v-col md="4" cols="12">
          <h3>Awaiting Review :</h3>
          <v-progress-linear
              indeterminate
              color="green"
              v-if="loading"
          />
          <v-list class="mt-4" v-if="this.awaiting_review.length" dense>
            <v-list-item
                v-for="(file, index) in awaiting_review"

                class="pa-3"
                :class="{'activeItemClassAwaitingReview': index % 2 !== 0}"
                style="background-color: #E0B0B0"
            >
              <UploadItem
                  @getUploads="getData"
                  @checkOutstanding="getOutstanding"
                  v-show="awaiting_review.length"
                  :key="`${index + loopKeyMultiplier}-file`"
                  :file="file"
                  :reqDocuments="outstanding"
                  :allReqDocuments="reqDocuments"
                  :current-user="currentUser"
                  @patchUpload="patchUpload"
                  @deleteUpload="deleteUpload(file,index)"/>
            </v-list-item>
          </v-list>
          <h4 v-if="!this.awaiting_review.length && !loading">There are no documents awaiting review for this
            client.</h4>
        </v-col>
<!--        Reviewed-->
          <v-col   md="4" cols="12">
            <h3>Reviewed :</h3>
            <v-list v-if="this.reviewed.length" class="mt-4" dense>
              <v-list-item
                  v-for="(file, index) in reviewed"

                  class="pa-3"
                  :class="{'activeItemClass': index % 2 !== 0}"
                  style="background-color: #f0f0f0"
              >
                <UploadItem
                    @getUploads="getData"
                    @checkOutstanding="getOutstanding"
                    :key="`${index + 633 + loopKeyMultiplier}-file`"
                    v-show="reviewed.length"
                    :is-reviewed="true"
                    :file="file"
                    :reqDocuments="outstanding"
                    :allReqDocuments="reqDocuments"
                    :current-user="currentUser"
                    @patchUpload="patchUpload"
                    @deleteUpload="deleteUpload"/>
              </v-list-item>
            </v-list>
            <h2 v-else>
              There are no reviewed documents for this client.
            </h2>
          </v-col>
        <v-col md="4" cols="12">
          <h3>Rejected :</h3>

          <v-list class="mt-4" v-if="this.rejected" dense>
            <v-list-item
                v-for="(file, index) in rejected"
                class="pa-3"
                :class="{'activeItemClass': index % 2 !== 0}"
                style="background-color: #f0f0f0"
            >
              <UploadItem
                  @getUploads="getData"
                  @checkOutstanding="getOutstanding"
                  :key="`${index + 112 + loopKeyMultiplier}-file`"
                  v-show="rejected.length"
                  :allReqDocuments="reqDocuments"
                  :file="file"
                  :reqDocuments="outstanding"
                  :current-user="currentUser"
                  @patchUpload="patchUpload"
                  @deleteUpload="deleteUpload"/>
            </v-list-item>
          </v-list>
        </v-col>
<!--        Rejected -->
      </v-row>

      <!--      Outstanding-->

<!--      <v-row class="d-flex justify-center">-->
<!--        <v-col-->
<!--            cols="12"-->
<!--            sm="12"-->
<!--            md="12"-->
<!--        >-->
<!--          <v-card-->
<!--              @drop.prevent="drop($event)"-->
<!--              @dragover.prevent="dragoverData = true"-->
<!--              @dragenter.prevent="dragoverData = true"-->
<!--              @dragleave.prevent="dragoverData = false"-->
<!--              height="20vh"-->
<!--              class="d-flex  align-center justify-center"-->
<!--              style="border: 5px dashed lightgray; box-shadow: none">-->
<!--            <v-card-text>-->
<!--              <v-row class="d-flex justify-center align-center">-->
<!--                <div-->
<!--                    class="p-12"-->
<!--                >-->
<!--                  <input type="file" multiple name="fields[assetsFieldHandleUploads][]"-->
<!--                         id="assetsFieldHandleUploads"-->
<!--                         class="d-none absolute" @input="onChange" ref="file"-->
<!--                  />-->

<!--                  <label for="assetsFieldHandleUploads" class="block cursor-pointer">-->
<!--                    <span class="underline"> CHOOSE  A FILE </span> OR DRAG IT <span-->
<!--                      style="color: blue;"> HERE </span>-->
<!--                  </label>-->
<!--                  <div v-if="$refs.file" cols="12" class="displayFileName">-->
<!--          <span v-for="(file,index) in $refs.file.files"-->
<!--                style="font-size: 12px"-->
<!--                :key="index + 999">{{ file.name }}-->
<!--            <span v-if="index + 1 != $refs.file.files.length"> ,</span>-->
<!--          </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </v-row>-->
<!--            </v-card-text>-->
<!--          </v-card>-->
<!--        </v-col>-->
<!--      </v-row>-->
    </v-container>
    <UploadFile v-if="showUploadDialog" @getUploads="getData" :client="clientId" @closeUpload="closeUpload" :currentUser='currentUser' :key="uploadKey"  :show-dialog="showUploadDialog" />
  </div>
</template>


<script>

import ClientService from "@/services/clients.service";
import AuthService from "@/services/auth.service";
import $axios from "@/plugins/axios";
import UploadItem from "@/components/UploadItem.vue";
import UploadFile from "@/components/Client/UploadFile.vue";
import ClientHeader from "@/components/Client/ClientHeader.vue";

export default {
  name: "ClientDocuments",
  components: {
    ClientHeader,
    UploadFile,
    AuthService,
    ClientService,
    UploadItem
  },
  data() {
    return {
      client:null,
      loopKeyMultiplier: 1,
      reqDocuments: [],
      isActiveButton: true,
      dragoverData: false,
      forDate: new Date(),
      processingFile: false,
      currentUser: null,
      loading: false,
      uploadingFiles: false,
      loadingOnPost: false,
      errorMessage: false,
      errProgressBarValue: 0,
      bufferValue: 0,
      interval: 0,
      awaiting_review: [],
      rejected: [],
      reviewed: [],
      outstanding: [],
      clientId: '',
      uploadKey:13,
      showUploadDialog:false,
      // fileDropedNotSaved: false,
    }
  },
  methods: {
    closeUpload(){
      this.showUploadDialog = false;

    },
    openUploadDialog(){
      this.uploadKey++;
      this.showUploadDialog = true;
    },
    deleteOutstanding(file) {
      this.loadingOnPost = true
      const payload = {
        "requirement_id": file.requirement_id
      }
      ClientService.deleteOutstanding(this.clientId, payload).then(res => {
        this.outstanding = res
        this.getData();
        this.getOutstanding();
        this.loadingOnPost = false
      }).catch(er => this.loadingOnPost = false)
    },
    sendEmail() {
      ClientService.sendEmailOutstandingUploads(this.clientId).then(() => this.$func.popMessage('Email sent successfully!', 'success'))
    },
    patchUpload(data) {
      const model = {
        "document": data.document,
        "document_status": data.docStatus,
        "client_id": this.clientId
      }
      this.loading = true
      ClientService.patchUpload(data.uploadId, model)
          .then(res => {
            // if (data.file.review_status == 0){
            //   this.loopKeyMultiplier += 25
            //   this.getData()
            // }
            this.loading = false
          }).catch(err => this.loading = false)
    },


    async deleteUpload(file, index) {
      if (!file.id) {
        this.awaiting_review.splice(index, 1)
        return
      }
      this.loading = true

      await ClientService.deleteUpload(file.id)
          .then((res) => {
            this.$store.dispatch('snackbar/showSnackbarMessage', {
              message: 'File deleted successfully',
              duration: 4000,
              mode: 'success'
            });
            this.getData()
          }).finally(() => this.loading = false)
    },
    getCurrentClient(){
      ClientService.getOneClient(this.clientId).then(res=>{
        this.client = res
      })
    },

    ////
    async getOutstanding() {
      await ClientService.getUploadReqDocs(this.clientId).then(res => {
        this.reqDocuments = res
      })
    },
    getData() {
      $axios.get(`clients/${this.clientId}/uploads`,
      ).then(res => {
        this.awaiting_review = []
        this.reviewed = []
        this.rejected = []
        this.awaiting_review = [...res.data.awaiting_review]
        this.rejected = [...res.data.rejected]
        this.reviewed = [...res.data.reviewed]
        this.outstanding = res.data.outstanding
        this.loading = false

      }).catch(error => {
        this.$func.popMessage(`${error.response.data.error}`, 'fail')
        this.loading = false
      })
    },
  },
  async created() {
    this.clientId = this.$route.params.clientId
    const data = await AuthService.getCurrentUserFromCookie()
    this.currentUser = data.data
    this.getOutstanding()
    this.loading = true
    this.getData()
    this.getCurrentClient()

  },

}
</script>


<style scoped>
.activeItemClass {
  background-color: #FFFFFF !important;
  margin: 2px 0;
}

.activeItemClassAwaitingReview {
  background-color: #F4DCDC !important;
  margin: 2px 0;
}

[v-cloak] {
  display: none;
}

.displayFileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  max-width: 400px;
}

</style>
