const state = () => ({
    clientID: null,
    daysInQueue: null,
    sanitizationJobID: null,
    tradeName: null,
});

const getters = {
    getClientID(state) {
        return state.clientID;
    },
    getDaysInQueue(state) {
        return state.daysInQueue;
    },
    getSanitizeJobID(state) {
        return state.sanitizationJobID;
    },
    getTradingName(state) {
        return state.tradeName;
    }
}

const mutations = {
    setSanitizeData(state, data) {
        state.clientID = data.id;
        state.daysInQueue = data.days
        state.sanitizationJobID = data.jobID
        state.tradeName = data.name
    }
}

const actions = {
    setSanitizeData(vuexContext, data) {
        vuexContext.commit('setSanitizeData', data);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
