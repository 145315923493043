<template>
  <v-row v-if="announcements.length">

    <v-col class="py-0" cols="12" v-for="(alert,i) in announcements" :key="i">

      <v-alert
                :prominent="alert.type === 'error'"
                :type="alert.type"
                title="Announcement"
                >
        {{alert.message}}
      </v-alert>
    </v-col>
  </v-row>
</template>


<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "AnnouncementsAlert",
  data: () => ({
    announcements: [],
  }),
  methods: {
    async getAnnouncements() {
      this.announcements = await ClientsService.getAnnouncements();
    },
  },
  async created() {
    await this.getAnnouncements();
  },

}
</script>


<style scoped>

</style>
