<template>
  <v-row justify="space-between" class="text-start">
    <v-col cols="12" class="d-flex justify-space-between align-center">
      <h2 v-if="client">
        {{ client.trade_name }}
        <v-icon>mdi-{{ getIconName() }}</v-icon>
      </h2>
      <h2 v-else>
        <v-skeleton-loader width="200px" type="text" ></v-skeleton-loader>
      </h2>
      <h4 v-if="client">Client ID: {{ client.id }}</h4>
      <h4 v-else>
        <v-skeleton-loader width="150px"  type="text" />
      </h4>
    </v-col>
    <v-col v-if="!hideDivider" cols="12">
      <v-divider class="mb-6" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ClientHeader",
  props: {
    client: {
      type: Object,
    },
    hideDivider: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  methods: {
    getIconName() {
      let peo = this.client.uses_peo;
      let businessType = this.client.business_type;

      switch (businessType) {
        case 1:
          return peo ? "donkey" : "";
        case 2:
          return "tractor";
        case 3:
          return "charity";
        default:
          return "";
      }
    },
  },
};
</script>
