<template>
<div>
    <v-container fluid>
      <v-radio-group
        v-model="radioRow"
        row
      > 
        <v-radio
          label="941"
          value="radio-0"
        ></v-radio>
        <v-radio
          label="943"
          value="radio-1"
        ></v-radio>
        <v-radio
          label="944"
          value="radio-2"
        ></v-radio>
      </v-radio-group>
    </v-container>
</div>
</template>
<script>
export default {
    data() {
    return {
      radioRow:'radio-0' 
    }
  },

  watch:{
    radioRow(newChoice, oldChoice){
        this.$emit('radio', newChoice)
  }
}
};
</script>
<style>
</style>