<template>
  <v-list-item class="bordered" three-line>
    <v-list-item-content>
      <v-list-item-title class="text-h5 font-weight-bold">
        <slot name="title"></slot>
      </v-list-item-title>
      <slot name="desc"></slot>
      <v-row>
        <v-col cols="12">
          <v-list class="mt-4" v-if="packages && packages.length" dense>
            <v-list-item
              class="highlight-hover"
              v-for="(onePackage, index) in packages"
              :key="'packages-generated-' + index"
            >
              <slot :pkg="onePackage" :pkgIndex="index">
                <span>
                  <a :href="onePackage.url" :download="onePackage.download_attrib" target="”_blank”">
                    {{ onePackage.label || onePackage.original_file_name }}
                  </a>
                </span>
              </slot>
              <v-spacer></v-spacer>
              <slot name="delete-button" :package-id="onePackage.id"></slot>
            </v-list-item>
          </v-list>
          <div class="mt-5 text-center" v-else-if="!packages.length && !loading" >
            <slot name="empty-text"></slot>
          </div>
          <div v-else class="mt-5">
            <v-skeleton-loader type="text"></v-skeleton-loader>
            <v-skeleton-loader type="text"></v-skeleton-loader>
          </div>
        </v-col>
      </v-row>
      <slot name="actions"></slot>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: 'ClientPackagesList',
  props: {
    packages: {
      type: Array
    },
    loading: {
      type: Boolean
    }
  }
}
</script>
