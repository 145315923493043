import $axios from '../plugins/axios'

class LawService {

	getLaws(data) {
		return $axios.get(`entities/law`)
			.then((res) => {
				return res.data;
			});
	}

}

export default new LawService();