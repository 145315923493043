<script>
import AdoptionsService from "@/services/adoptions.service";

export default {
  name: "UnresponsiveClientsSecondAttempt",
  props: {
    entityId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    loading: false,
    status: 8,
    clients: [],
    headers: [
      {
        text: 'Client',
        value: 'client',
        filterable: true
      },
      {
        text: 'EIN',
        value: 'ein',
        filterable: true
      },
      {
        text: 'Legal Name',
        value: 'legal_name',
        filterable: true
      },
      {
        text: 'Date contact initiated',
        value: 'date',
      }
    ],
  }),
  async mounted() {
    this.loading = true

    try {
      this.clients = await AdoptionsService.getAdoptionStatus(this.entityId, this.status)
    } finally {
      this.loading = false
    }
  }
};
</script>

<template>
  <div>
    <v-row>
      <v-col cols="12" md="10">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="clients"
              :loading="loading"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>

</style>