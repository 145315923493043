<template>
  <v-container>
    <v-row align="center">
      <v-col cols="auto">
        <v-btn icon @click="exitCreating">
          <v-icon color="black">mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col class="d-flex align-center">
        <h3>Create New Entity</h3>
      </v-col>
    </v-row>
    <v-card outlined rounded elevation="2" width="600px" class="pa-7 mt-5">
      <v-form v-model="isValid">
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Firm Name:</v-col>
          <v-col cols="12" md="9">
            <v-text-field :rules="[rules.required]" outlined v-model="addUserForm.entityName" />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">EIN:</v-col>
          <v-col cols="12" md="9">
            <v-text-field counter="10" :rules="[rules.required, rules.ein]" v-model="addUserForm.ein" outlined />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Firm Type:</v-col>
          <v-col cols="12" md="9">
            <v-select
              v-model="addUserForm.firm_type"
              :items="firm_types"
              :rules="[rules.required]"
              item-text="type"
              item-value="id"
              outlined
            />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">First Name:</v-col>
          <v-col cols="12" md="9">
            <v-text-field v-model="addUserForm.first_name" outlined />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Last Name:</v-col>
          <v-col cols="12" md="9">
            <v-text-field :rules="[rules.required]" v-model="addUserForm.last_name" outlined />
          </v-col>
        </v-row>
        <v-row dense align="center">
          <v-col cols="12" md="3" class="font-weight-bold">Email:</v-col>
          <v-col cols="12" md="9">
            <v-text-field :rules="[rules.required, rules.email]" v-model="addUserForm.email" outlined />
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions class="mt-5">
        <v-row justify="space-between">
          <v-col cols="4">
            <v-btn @click="exitCreating" block color="error">Cancel</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn @click="createEntity" :loading="loading" :disabled="!isValid" block color="primary">Create</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "CreateEntityCard",
  data: () => ({
    firm_types: [
      { id: 1, type: "Processor" },
      { id: 2, type: "Managing Firm" },
      { id: 3, type: "Tax Firm" },
      { id: 4, type: "Referral Partner" },
    ],
    addUserForm: {
      entityName: "",
      ein: "",
      firm_type: "",
      first_name: "",
      last_name: "",
      email: "",
    },
    loading: false,
    isValid: false,
    rules: {
      ein: (value) => {
        const pattern = /^[0-9]{2}-[0-9]{7}$/;
        return pattern.test(value) || "Invalid ein";
      },
      required: (value) => !!value || "This field is required.",
      email: (value) => {
        const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    }
  }),
  methods: {
    exitCreating(refresh = false) {
      this.$emit("exitCreatingEntity", refresh);
    },
    createEntity() {
      this.loading = true;
      const entityModel = {
        name: this.addUserForm.entityName,
        ein: this.addUserForm.ein,
        firm_type: this.addUserForm.firm_type,
        user_first: this.addUserForm.first_name,
        user_last: this.addUserForm.last_name,
        user_email: this.addUserForm.email,
      };

      ClientsService.postEntities(entityModel)
        .then((res) => {
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: `Entity created.`,
            duration: 4000,
            mode: "success",
          });
          this.exitCreating(true);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
