<template>
  <v-row class="d-flex justify-start align-start">
    <v-col
        v-show="!tourStarted"
        id="pppRound1"
        :cols="12"
        lg="6"
        md="12"
        sm="12"
        xs="12"
    >
      <v-hover v-slot="{ hover }">
        <v-card
            class="mx-auto"
            :elevation="hover ? 10 : 2"
            :class="{ 'on-hover': hover }"
            link
            outlined
            min-height="210"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-6 font-weight-bold d-flex justify-center">
                2020 Round of PPP
              </v-list-item-title>

              <div v-if="roundOne" class="d-flex flex-column">
                <v-list-item-subtitle class="mb-4 mx-auto"
                                      v-if="roundOne.did_not_receive === 1">
                  Client did not receive this round of PPP
                </v-list-item-subtitle>

                <v-list-item-subtitle class="mb-4 mx-auto" v-if="!roundOne.did_not_receive">
                  <b>Loan Amount: </b>{{ roundOne.amount }}
                </v-list-item-subtitle>

                <v-list-item-subtitle class="mb-4 mx-auto" v-if="!roundOne.did_not_receive">
                  <b>Loan Date: </b>{{ roundOne.start_formatted.split(' ')[0] }}
                </v-list-item-subtitle>
              </div>

              <v-list-item-subtitle class="mb-4 text-center" v-if="!roundOne">
                NO ROUNDS DECLARED!
              </v-list-item-subtitle>
              <v-spacer></v-spacer>

              <!--											<v-card-actions v-if="roundOne.did_not_receive">-->
              <!--											</v-card-actions>-->

              <!--											<v-card-actions v-if="!roundOne.did_not_receive">-->

              <!--											</v-card-actions>-->

              <v-card-actions class="d-flex justify-center">
                <v-btn
                    v-if="roundOne"
                    text
                    color="#1867C0"
                    @click.stop="$emit('popUp',roundOne.id,1)"
                >
                  <u>Clear </u>
                </v-btn>

                <v-btn
                    v-if="!roundOne"
                    text
                    color="#1867C0"
                    @click.stop="$emit('openRoundInfo','1')"
                >
                  Add Information
                </v-btn>
              </v-card-actions>

            </v-list-item-content>

          </v-list-item>
        </v-card>
      </v-hover>

    </v-col>

    <v-col
        v-show="tourStarted"
        id="pppRound1Tour"
        :cols="12"
        lg="6"
        md="12"
        sm="12"
        xs="12"
    >
      <v-card
          class="mx-auto"
          link
          outlined
          min-height="210"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-6 font-weight-bold text-center">
              2020 Round of PPP
            </v-list-item-title>

            <div v-if="roundOne">
              <v-list-item-subtitle class="mb-4 text-center"
                                    v-if="roundOne.did_not_receive === 1">
                Client did not receive this round of PPP
              </v-list-item-subtitle>

              <v-list-item-subtitle class="mb-4 text-center" v-if="!roundOne.did_not_receive">
                <b>Loan Amount: </b>{{ roundOne.amount }}
              </v-list-item-subtitle>

              <v-list-item-subtitle class="mb-4 text-center" v-if="!roundOne.did_not_receive">
                <b>Loan Date: </b>{{ roundOne.start_formatted.split(' ')[0] }}
              </v-list-item-subtitle>
            </div>

            <v-list-item-subtitle class="mb-4 text-center" v-if="!roundOne">
              NO ROUNDS DECLARED!
            </v-list-item-subtitle>
            <v-spacer></v-spacer>

            <!--											<v-card-actions v-if="roundOne.did_not_receive">-->
            <!--											</v-card-actions>-->

            <!--											<v-card-actions v-if="!roundOne.did_not_receive">-->

            <!--											</v-card-actions>-->

            <v-card-actions class="d-flex justify-center">
              <v-btn
                  v-if="roundOne"
                  text
                  color="#1867C0"
                  @click.stop="$emit('popUp',roundOne.id,1)"
              >
                <u>Clear </u>
              </v-btn>

              <v-btn
                  v-if="!roundOne"
                  text
                  color="#1867C0"
                  @click.stop="$emit('openRoundInfo','1')"
              >
                Add Information
              </v-btn>
            </v-card-actions>

          </v-list-item-content>

        </v-list-item>
      </v-card>

    </v-col>

     <v-col
        v-show="!tourStarted"
        id="pppRound2"
        :cols="12"
        lg="6"
        md="12"
        sm="12"
        xs="12"
    >
      <v-hover v-slot="{ hover }">
        <v-card
            class="mx-auto"
            :elevation="hover ? 10 : 2"
            :class="{ 'on-hover': hover }"
            link
            outlined
            min-height="210"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-6 font-weight-bold text-center">
                2021 Round of PPP
              </v-list-item-title>

              <div v-if="roundTwo">
                <v-list-item-subtitle class="mb-4 text-center"
                                      v-if="roundTwo.did_not_receive === 1">
                  Client did not receive this round of PPP
                </v-list-item-subtitle>

                <v-list-item-subtitle class="mb-4 text-center" v-if="!roundTwo.did_not_receive">
                  <b>Loan Amount: </b>{{ roundTwo.amount }}
                </v-list-item-subtitle>

                <v-list-item-subtitle class="mb-4 text-center" v-if="!roundTwo.did_not_receive">
                  <b>Loan Date: </b>{{ roundTwo.start_formatted.split(' ')[0] }}
                </v-list-item-subtitle>
              </div>

              <v-list-item-subtitle class="mb-4 text-center" v-if="!roundTwo">
                NO ROUNDS DECLARED!
              </v-list-item-subtitle>
              <v-spacer></v-spacer>

              <v-card-actions class="d-flex justify-center">
                <v-btn
                    v-if="roundTwo"
                    text
                    color="#1867C0"
                    @click.stop="$emit('popUp',roundTwo.id,2)"
                >
                  <u>Clear </u>
                </v-btn>

                <v-btn
                    v-else
                    text
                    color="#1867C0"
                    @click.stop="$emit('openRoundInfo','2')"
                >
                  Add Information
                </v-btn>
              </v-card-actions>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-hover>

    </v-col>

    <!--

    <v-col
        v-show="tourStarted"
        id="pppRound2Tour"
        :cols="12"
        lg="6"
        md="12"
        sm="12"
        xs="12"
    >
      <v-card
          class="mx-auto"
          link
          outlined
          min-height="210"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title class="text-h5 mb-6 font-weight-bold">
              2021 Round of PPP
            </v-list-item-title>

            <div v-if="roundTwo">
              <v-list-item-subtitle class="mb-4"
                                    v-if="roundTwo.did_not_receive === 1">
                Client did not receive this round of PPP
              </v-list-item-subtitle>

              <v-list-item-subtitle class="mb-4" v-if="!roundTwo.did_not_receive">
                {{ roundTwo.amount }}
              </v-list-item-subtitle>

              <v-list-item-subtitle class="mb-4" v-if="!roundTwo.did_not_receive">
                {{ roundTwo[0].start.split(' ')[0] }}
              </v-list-item-subtitle>
            </div>

            <v-list-item-subtitle class="mb-4" v-if="!roundTwo">
              NO ROUNDS DECLARED!
            </v-list-item-subtitle>
            <v-spacer></v-spacer>

            <v-card-actions>
              <v-btn
                  v-if="roundTwo"
                  text
                  color="#1867C0"
                  @click.stop="$emit('popUp',roundTwo.id,2)"
              >
                <u>Clear </u>
              </v-btn>

              <v-btn
                  v-else
                  text
                  color="#1867C0"
                  @click.stop="$emit('openRoundInfo','2')"
              >
                Add Information
              </v-btn>
            </v-card-actions>
          </v-list-item-content>
        </v-list-item>
      </v-card>

    </v-col> -->
  </v-row>
</template>
<script>
export default {
  name: 'ProfilePPPCards',
  props: {
    roundOne: {},
    roundTwo: {},
    tourStarted: {}
  }
}
</script>
<style lang="scss">

::v-deep .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
  color: rgba(0, 0, 0, 0.87) !important;
}

::v-deep .v-text-field__slot input::-webkit-outer-spin-button,
::v-deep .v-text-field__slot input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
::v-deep .v-text-field__slot input[type=number] {
  -moz-appearance: textfield !important;
}


</style>
