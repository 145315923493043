<template>
  <div>
    <ProfileClientDialog
      v-if="showRightDialogChosenClient"
      :key="dialogKeyChosenClient"
      :showRightDialogChosenClient.sync="showRightDialogChosenClient"
      :clientProp="clientProp"
    />

    <v-system-bar
      app
      color="#263B4F"
      elevation="4"
      height="72"
      class="pl-7 pr-7"
      style="box-shadow: 0px -2px 15px 0px rgba(0, 0, 0, 0.8); z-index: 99"
    >
      <v-app-bar-nav-icon
        class="d-md-block d-lg-none"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <router-link to="/">
        <v-img
          max-height="64px"
          max-width="252px"
          id="home-logo-button"
          src="@/assets/img/logo-top-menu.png"
        ></v-img>
      </router-link>
      <v-spacer></v-spacer>
      <div v-if="notifsEnabled" @click="openNotifications" style="cursor: pointer">
        <v-badge
          :class="{ 'notifBg': notificationCount > 0 }"
          class="d-none d-md-flex mr-5"
          color="red"
          overlap
          bottom
          :value="notificationCount > 0"
          offset-x="10"
        >
          <template #badge>
            {{notificationCount}}
          </template>
          <v-icon color="black" style="font-size: 26px; margin-left: 2px">mdi-bell</v-icon>
        </v-badge>
      </div>
      <h3 style="color: #fff" id="userFullName">{{ userName }}</h3>
      <div class="mx-4">
        <v-btn
          color="red"
          class="white--text"
          target="_blank"
          href="https://patriotdataprocessing.zendesk.com/hc/en-us/requests/new?ticket_form_id=%5b14354878139931"
          >SUPPORT
        </v-btn>
      </div>
      <v-menu offset-y offset-x transition="slide-y-transition" bottom ref="parentMenuRef">
        <template v-slot:activator="{ on, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn id="profile-button" v-bind="attrs" v-on="on" icon>
                <v-icon size="48" color="white"> mdi-account-circle</v-icon>
              </v-btn>
            </template>
            <span>My account</span>
          </v-tooltip>
        </template>

        <v-list class="main-list">
          <v-list-item class="permissions-dropdown-item" @click="goToPermissionsPage">
            <v-list-item-title class="permissions-dropdown">
              <v-icon color="black">mdi-checkbox-marked</v-icon>
              Permissions
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="permissions-dropdown-item" @click.stop="logout">
            <v-list-item-title class="permissions-dropdown">
              <v-icon color="black">mdi-logout</v-icon>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-system-bar>

    <v-main>
      <v-layout fill-height>
        <v-navigation-drawer v-model="drawer" app :width="show ? '300' : '80'">
          <TheLayoutWrapMenuList
            :drawer="drawer"
            @toggleDrawer="toggleDrawer"
            @toggleShow="toggleShow"
          />
        </v-navigation-drawer>

        <transition name="scale" mode="out-in">
          <v-container :key="key">
            <router-view></router-view>
          </v-container>
        </transition>
      </v-layout>
    </v-main>
    <Notifications
      :unread-notifications="unreadNotifications"
      :show-notif="showNotificationPage"
      :key="notificationKey"
      v-if="showNotificationPage"
      @markedAsRead="notificationCount--"
      @markedAsUnRead="notificationCount++"
    />
  </div>
</template>

<script>
import TheLayoutWrapMenuList from "./TheLayoutWrapMenuList";
import ProfileClientDialog from "../components/Client/ProfileClientDialog";
import AuthService from "../services/auth.service";
import ClientsService from "@/services/clients.service";
import {mapGetters} from "vuex";
import Notifications from "@/components/Client/Notifications";
import {Stomp} from "@stomp/stompjs";
import NotificationService from "@/services/notification.service";

export default {
  name: "TheLayoutWrap",

  components: {
    Notifications,
    TheLayoutWrapMenuList,
    ProfileClientDialog,
  },

  data: () => ({
    show: false,
    mainNavDrawer: true,
    subNavDrawer: false,
    tasks: [],
    loadingTasks: false,
    dialogKeyChosenClient: 101,
    showRightDialogChosenClient: false,
    showRightDialog: false,
    dialogKey: 0,
    showNotificationPage: false,
    notificationKey: 44,
    notificationCount: 0,
    showPermissionsMenu: false,
    userName: null,
    userRole: null,
    connection: null,
    received_messages: [],
    send_message: null,
    connected: false,
    unreadNotifications: "",
    showSubmenu: false,
    drawer: null,
    resizeTimer: null,
    notifsEnabled: false,
    notifsAllowedRoles: [1, 7, 11, 13,4]
  }),

  methods: {
    handleTabClick(item) {
      this.$router.push(item.link);
    },
    toggleShow(show) {
      this.show = show;
    },
    toggleDrawer(cond) {
      if (this.$vuetify.breakpoint.name === "xs" || this.$vuetify.breakpoint.name === "sm")
        this.drawer = cond;
    },
    async getTasks() {
      this.loadingTasks = true;
      await ClientsService.getTasks().then((res) => {
        this.loadingTasks = false;
        this.tasks = res.tasks;
      });
    },
    async viewNote(client_id) {
      this.$store.dispatch("tasks/notesOpen", {
        notes: true,
      });
      this.getCurrentClient(client_id);
    },
    async closeTask(task_id, user_id) {
      const model = {
        user_id: user_id,
      };

      await ClientsService.patchTask(task_id, model).then((res) => {
        this.$func.popMessage("Task closed successfully.");
        this.getTasks();
      });
    },
    addHashToLocation(params) {
      history.pushState({}, null, this.$route.path + "#" + encodeURIComponent(params));
    },
    getCurrentClient(id) {
      return ClientsService.getOneClient(id)
        .then((data) => {
          this.clientProp = data;
          this.select = null;
          this.openClientProfileDialog();
          this.addHashToLocation(id);
          // this.showResults = false;
          // this.inputSearch = null;
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: `${error.response.data.error}. Please, try again.`,
            duration: 4000,
            mode: "fail",
          });
        });
    },

    openClientProfileDialog() {
      this.showRightDialogChosenClient = true;
      this.dialogKeyChosenClient++;
      this.showRightDialog = false;
    },

    hashAsync(algo, str) {
      return crypto.subtle.digest(algo, new TextEncoder("utf-8").encode(str)).then((buf) => {
        return Array.prototype.map
          .call(new Uint8Array(buf), (x) => ("00" + x.toString(16)).slice(-2))
          .join("");
      });
    },

    openNotifications() {
      this.notificationKey++;
      this.showNotificationPage = true;
    },

    goToPermissionsPage() {
      this.$router.replace("/permissions");
    },
    handleWindowResize() {
      if (this.resizeTimer !== null) {
        clearTimeout(this.resizeTimer);
      }
      this.resizeTimer = setTimeout(() => {
        // Call hideNavTwo after a brief delay (e.g., 300 milliseconds) to stabilize the resizing action
        if (this.drawer.length === 0) {
          this.hideNavTwo();
        }
        this.resizeTimer = null;
      }, 300); // Adjust the delay time as needed
    },

    logout() {
      this.$refs.parentMenuRef.isActive = false;
      this.$store.dispatch("auth/signOut").then((res) => {
        if (this.currentUser == null) {
          this.$router.replace("/login");
        }
      });
    },

    getAllNotifs(){
      NotificationService.getAllNotfications().then(res => {
        this.notificationCount = res.total_new
        this.unreadNotifications = res
      })
    },
    connectNotifSocket() {
      let queueName = "/queue/patriot.users.messages";
      this.hashAsync("SHA-256", this.currentUser.current_user_id)
          .then(hash => queueName = `/queue/patriot.user.${hash}`);
      // let url = new WebSocket(process.env.VUE_APP_MESSAGING_BASE_URL);
      // let client = Stomp.over(url);
      let client = Stomp.over(function () {
        return new WebSocket(process.env.VUE_APP_MESSAGING_BASE_URL);
      });
      client.debug = () => {};
      const localSelf = this
      let on_connect = function (x) {
        client.subscribe(queueName, () => {
          localSelf.getAllNotifs()
          // NotificationService.getAllNotfications().then(res => {
          //   this.notificationCount = res.total_new
          //   this.unreadNotifications = res
          // })
        });
      };

      client.connect(
        process.env.VUE_APP_MESSAGING_USER,
        process.env.VUE_APP_MESSAGING_PASS,
        on_connect
      );
    },
    processNotifications() {
      if (!this.notifsEnabled) return
        this.getAllNotifs()
        this.connectNotifSocket()

    }
  },

  computed: {
    showAlert() {
      switch (this.userRole) {
        case 1:
          return true;
        case 4:
          return true;
        case 7:
          return true;
        default:
          return false;
      }
    },
    key() {
      return this.$route.path;
    },

    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },

  mounted() {
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/stomp.js/2.3.3/stomp.js')
    recaptchaScript.setAttribute('integrity', 'sha512-tL4PIUsPy+Rks1go4kQG8M8/ItpRMvKnbBjQm4d2DQnFwgcBYRRN00QdyQnWSCwNMsoY/MfJY8nHp2CzlNdtZA==')
    recaptchaScript.setAttribute('crossOrigin', 'anonymous')
    recaptchaScript.setAttribute('referrerpolicy', 'no-referrer')

     document.head.appendChild(recaptchaScript)
    // this.connectNotifSocket()
  },
  async created() {
    let res = await AuthService.getCurrentUserFromCookie();
    this.userName = res.data.first + " " + res.data.last;
    this.userRole = res.data.role_id;
    this.notifsEnabled = this.notifsAllowedRoles.includes(res.data.role_id)
    this.processNotifications()
  },
  watch: {
    subNavDrawer(val) {
      if (!val) {
        setTimeout(() => {
          this.hideNavTwo();
        }, 1);
      }
    },
    async currentUser() {
      let res = await AuthService.getCurrentUserFromCookie();
      this.userName = res.data.first + " " + res.data.last;
      this.notifsEnabled = this.notifsAllowedRoles.includes(res.data.role_id)
      this.processNotifications()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "https://cdn.jsdelivr.net/npm/animate.css@3.5.1";

.removeWidth {
  width: 0 !important;
}

.notifGenericBg {
  position: absolute;
  top: 20px;
  right: 381px;
}

.notifBg {
  height: 30px;
  width: 30px;
  color: #ffffff;
}

.notifBg:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #ff3232;
  border-radius: 50%;
  z-index: -1;
  opacity: 0.7;
}

.notifBg:before {
  animation: pulse 1s ease-out infinite;
}

.notifBg:after {
  animation: pulse 1s 1s ease-out infinite;
}

@keyframes pulse {
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.scale-enter-active,
.scale-leave-active {
  transition: all 0.4s ease;
}

.theme--light.v-system-bar .v-icon {
  color: white !important;
}

// .v-menu__content--fixed {
//     top: 75px !important;
// }

.permissions-dropdown {
  cursor: pointer;
}

// .main-list {
//     padding-top: 0px !important;
//     padding-bottom: 0px !important;
// }

.permissions-dropdown-item {
  transition: color 0.2s;

  &:hover {
    background-color: rgba(38, 59, 79, 1);
    color: white !important;

    .v-icon.v-icon {
      color: white !important;
    }
  }
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

#home-logo-button {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #home-logo-button {
    width: 110px;
    margin-left: 10px;
  }
}

#userFullName {
  font-size: 1.2rem;
}

@media only screen and (max-width: 600px) {
  #userFullName {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  #userFullName {
    font-size: 0.8rem;
  }
  #userName {
    margin-left: 15px;
    font-size: 11px;
  }
}

.hide {
  display: none;
}
</style>
