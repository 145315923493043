<template>
	<DialogRightAlign :showDialog.sync="showRightDialog" :titleProp="titleRoundProp">
		<v-card-text>
			<v-container>
				<v-row class="d-flex justify-center align-center">
					<v-col
						md="1"
						sm="1"
						xs="1"
					>
						<v-icon>mdi-account-search</v-icon>
					</v-col>

					<v-col
						md="11"
						sm="11"
						xs="11"
					>
						<v-autocomplete
              id="owners-select-droplist"
							v-model="values"
							:items="items"
							@change="changeOwnersSelect"
              :disabled="switchNoOwners"
							item-text="name"
							item-value="name"
							chips
							small-chips
							label="Search..."
							multiple
						></v-autocomplete>
					</v-col>

					<v-col
						md="12"
						sm="12"
						xs="12">
						<v-switch
							v-model="switchNoOwners"
              @click="switchNoOwnersClick"
							label="No owners"
						></v-switch>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>

		<v-spacer></v-spacer>

		<v-card-actions>
			<v-spacer></v-spacer>

			<v-btn
				color="#263B4F"
				text
				@click.native="closeDialog"
			>
				close
			</v-btn>

			<v-btn
				color="#263B4F"
				:disabled="values.length == 0 && switchNoOwners == false"
				text
        :loading="saveLoading"
				@click.native="save"
			>
				save
			</v-btn>
		</v-card-actions>
	</DialogRightAlign>
</template>

<script>

	import DialogRightAlign from "@/components/Base/DialogRightAlign";

	export default {
		name: "OwnerSelectDialog",
		components: {DialogRightAlign},

		props: {
			showRightDialog: {
				type: Boolean,
				default: false
			},

			titleRoundProp: {
				type: String,
				default: "Select Owners"
			},

			ownersProp: {
				type: Array,
				default: []
			},

			hasOwnersProp: {
				type: Boolean,
				default: false
			},

      saveLoading: {
        type: Boolean,
        default: false
      }
		},

		data: () => ({
			items: [],
			values: [],
			value: null,
			switchNoOwners: false,
		}),

		methods: {
			closeDialog() {
				this.$emit('update:showRightDialog', false);
			},

			changeOwnersSelect() {

				let enabledItems = this.items.filter(obj => {
					return !obj.disabled
				});

				// console.log('enabledItems');
				// console.log(enabledItems);

				let disabledItems = this.items.filter(obj => {
					return obj.disabled
				});

				this.items = enabledItems.concat(disabledItems);
			},

			save(value) {
				this.$emit('saveOwners', this.values);
			},

      switchNoOwnersClick(){
        if (this.switchNoOwners) {
          this.values = [];
          this.save()
        }
      }
		},

		mounted() {
			this.items = this.ownersProp;
      if (this.ownersProp){
        this.values = this.ownersProp.filter(employee => {
              return employee.owner === 1
            }
        );
      }

			this.switchNoOwners = !this.hasOwnersProp;
			this.values = this.values.map(employee => employee.name);
		},

    // watch:{
    //
    //   switchNoOwners(value){
    //     if(value){
    //       this.values = [];
    //     }
    //   }
    // }

	}
</script>

<style scoped>

</style>
