import store from '@/store/index.js';

export const func = {
    popMessage: (message, mode) => {
        return store.dispatch('snackbar/showSnackbarMessage', {
            message: message,
            duration: 4000,
            mode: mode
        });
    }
}

export const formatAmount = (amount) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return formatter.format(amount)
}
