<template>
  <div>
    <v-container fluid>
      <v-row><h2 class="ml-15">RECONCILIATION</h2></v-row>
      <v-row class="mx-10 my-6" no-gutters>
        <v-col sm="12" md="6" class="mb-md-0 mb-4">
          <v-form v-model="valid">
            <v-container fluid class="pa-0">
              <v-card class="px-5 py-5">
                <v-row class="d-flex justify-space-between px-2 pt-1">
                  <span class="create">Create Deposit</span>
                  <span class="mr-0 mr-lg-5" @click="editDeposit()"
                    ><v-icon color="gray darken-2"> mdi-pencil </v-icon></span
                  >
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field
                      type="date"
                      v-model="date"
                      :rules="dateRules"
                      label="Date"
                      required
                      :disabled="disableDepositFields"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="amount"
                      v-restrict.number.decimal
                      :rules="amountRules"
                      :counter="12"
                      label="Amount"
                      type="number"
                      required
                      :disabled="disableDepositFields"
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-center align-content-center flex-wrap"
                  >
                    <v-btn
                      id="create"
                      class="px-6"
                      @click="createDeposit()"
                      :disabled="this.$v.$invalid"
                      >Create</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
          </v-form>
        </v-col>
        <v-col
          sm="12"
          md="6"
          class="d-flex justify-center justify-space-around align-content-center flex-wrap px-5"
        >
          <v-card
            class="d-flex justify-center align-content-center flex-wrap px-2"
            min-width="250"
          >
            <v-card-title
              primary-title
              v-model="depositedAmount"
              id="depositedAmount"
              >{{ $formatAmount(depositedAmount) }}</v-card-title
            >
          </v-card>
          <v-btn
            class="mx-20 my-10"
            :disabled="depositedAmount !== totalAmountSelected"
            @click="saveDeposit"
            >Save Reconciled deposit</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
    <template>
      <v-card class="mx-auto" max-width="600" tile>
        <v-list-item three-line v-if="depositedAmount">
          <v-list-item-content>
            <v-list-item-title
              class="selectChecks"
              v-if="depositedAmount !== totalAmountSelected"
              >Select checks to be deposited with a total amount of
              {{ $formatAmount(depositedAmount) }}</v-list-item-title
            >
            <v-list-item-subtitle
              class="selectChecks"
              v-if="depositedAmount !== totalAmountSelected"
              >Total amount from checks selected:
              {{ $formatAmount(totalAmountSelected) }}</v-list-item-subtitle
            >
            <v-list-item-subtitle
              class="selectSuccess"
              v-if="depositedAmount === totalAmountSelected"
              >Deposited amount equals selected checks:
              {{ $formatAmount(totalAmountSelected) }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </template>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :single-select="singleSelect"
      item-key="id"
      class="elevation-5 mt-6 mx-12"
      disable-pagination
      show-select
      hide-default-footer
      id="dataTable"
    >
    </v-data-table>
    <v-overlay :value="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import ClientService from "@/services/clients.service";
import { required, between } from "vuelidate/lib/validators";

export default {
  name: "Reconciliation",
  components: {
    ClientService,
  },

  data() {
    return {
      clientData: {},
      loading: false,
      headers: [
        { text: "Check Number", value: "check_number" },
        { text: "Date", value: "date" },
        { text: "Check Amount", value: "amount_formatted" },
      ],
      items: [],
      valid: false,
      date: null,
      dateRules: [v => !!v || "Please, enter a valid date."],
      amount: 0,
      amountRules: [
        v => !!v || "Please, enter a valid amount.",
        v => v.length <= 12 || "Amount must be between 1 and 12 in length.",
      ],
      singleSelect: false,
      selected: [],
      disableDepositFields: false,
      depositedAmount: 0,
      checksObj: {},
    };
  },
  validations: {
    date: {
      required,
      //Limiting the date to work from today only
      // minValue(val) {
      //   let yesterday = new Date();
      //   return new Date(val) > (yesterday.setDate(yesterday.getDate()-1));
      // },
    },
    amount: {
      required,
    },
  },
  methods: {
    createDeposit() {
      if (this.$v.$invalid) {
      } else {
        this.disableDepositFields = true;
        this.depositedAmount = this.amount;
        this.checksObj.amount = this.amount;
        this.checksObj.date = this.date;
      }
    },
    saveDeposit() {
      this.getSelectedChecks();
      this.postDeposit(this.checksObj);
      this.amount = 0;
      this.date = null;
    },
    getSelectedChecks() {
      let arr = [];
      this.selected.forEach(element => arr.push(element.id));
      this.checksObj.checks = arr;
    },
    getAll() {
      this.loading = true;
      ClientService.getReconciliationDeposits("reconciliation").then(res => {
        this.items = res;
        this.loading = false;
      });
    },
    postDeposit(data) {
      this.loading = true;
      ClientService.postReconciliation(data).then(() => {
        this.getAll();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
      this.disableDepositFields = false;
      this.selected = [];
      this.depositedAmount = 0;
      this.checksObj = {};
    },
    editDeposit() {
      this.disableDepositFields = false;
    },
  },
  created() {
    this.getAll();
  },
  computed: {
    totalAmountSelected() {
      let sum = 0;
      this.selected.forEach(element => {
        if (element.check_number === "WRITEOFF") {
          sum -= Number(element.amount)
          return;
        }
        sum += Number(element.amount)
      });
      return sum.toFixed(2);
    },
  },
};
</script>

<style scoped>
button#create {
  background: #263b4f;
  color: white;
}
#depositedAmount {
  color: #2e5377;
}
#dataTable {
  font-weight: bold;
}
.v-card .v-list-item__content > .selectChecks {
  color: red;
  text-align: center;
  font-size: 1.1rem;
}
.v-card .v-list-item__content > .selectSuccess {
  color: green;
  text-align: center;
  font-size: 1.2rem;
}
.create {
  font-size: 1.4rem;
}

.v-icon {
  cursor: pointer;
}

.v-icon:hover {
  color: #263b4f;
}
</style>
