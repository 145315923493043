import $axios from '../plugins/axios'

class SanitizerService {

    getRTSClients() {

        return $axios.get('/queues/sanitization')
            .then((res) => {

                return res.data;
            });
    }

    patchJobStatus(id,data) {

        return $axios.patch(`/sanitization/jobs/${id}`,data)
            .then((res) => {

                return res.data;
            });
    }

}

export default new SanitizerService()
