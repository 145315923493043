import $axios from '../plugins/axios'

class OwnersService {

	getOwners(data) {

		return $axios.get(`clients/${data.clientId}/owners`)
			.then((res) => {

				return res.data;
			});
	}

	getAllEmployees(data) {
		return $axios.get(`clients/${data.clientId}/employees`)
			.then((res) => {

				return res.data;
			});
	}

	getOneOwner(data) {

		return $axios.get(`clients/${data.clientId}/owners/${data.ownerId}`)
			.then((res) => {

				return res.data;
			})
			.catch((error) => {
				this.$func.popMessage(`${error.response.data.error}`, 'fail')
			})
	}

	createOwner(clientId, data) {
		return $axios.post(`clients/${clientId}/owners`, data)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {
				this.$func.popMessage(`${error.response.data.error}`, 'fail')
			})
	}

	editOwner(data) {

		return $axios.put(`clients/${data.clientId}/owners`, data)
			.then((res) => {
				return res.data;
			})
			.catch((error) => {
				this.$func.popMessage(`${error.response.data.error}`, 'fail')
			})
	}

	deleteOwner(data) {

		return $axios.delete(`clients/${data.clientId}/owners`)
			.then(res => {
				return res.data;
			})
			.catch((error) => {
				this.$func.popMessage(`${error.response.data.error}`, 'fail')
			})
	}
}

export default new OwnersService();