import router from '../../router'

export default function auth({ to, next, store, nextMiddleware }) {
    let isAuthenticated = store.getters['auth/isAuthenticated']();

    if (!isAuthenticated) {
        // console.log('router name is ', router.history._startLocation)
        store.dispatch("auth/changeCurrentPath", {
            path: router.history._startLocation,
        });
        return next({
            path: '/login'
        });
    }

    return nextMiddleware();
}
