<template>
  <div class="pa-8 text-center">
    <h4 class="text-start">
      6. Owners & Family Members
    </h4>
    <h6 class="ml-6 mt-6 text-start">
      Calculation of the ERC requires that business owners and family members be taken into consideration.
    </h6>

    <v-row class="my-6 px-8">
      <v-col class="text-center" cols="6">
        <h4>Owners</h4>
        <h5 >Please list the names of all the owners<br>
          of the business:</h5>
        <v-textarea outlined v-model="owners" style="margin-top: 125px"></v-textarea>
      </v-col>

      <v-divider
          vertical
      ></v-divider>
      <v-col class="text-center" cols="6">
        <h4 class="mb-2">Family Members</h4>
        <h5>Are any W2 employees of the business related to any<br>
          of the business owners?</h5>
        <div class="d-flex flex-row justify-space-around">
          <div class="d-flex align-center justify-center"><v-radio-group v-model="hasFamily"><v-radio :value="1"></v-radio></v-radio-group> <h3 style="font-weight: 350">Yes</h3></div>
          <div class="d-flex align-center justify-center"><v-radio-group v-model="hasFamily"><v-radio :value="0"></v-radio></v-radio-group> <h3 style="font-weight: 350">No</h3></div>
        </div>
        <h5 class="mt-4">If “Yes” please list the W2 employee’s names:</h5>
        <v-textarea :disabled="hasFamily==0" v-model="family" outlined class="mt-4"></v-textarea>
      </v-col>
    </v-row>
    <v-btn color="#263B4F" class="mt-4">
      <span style="color: #FFFFFF" @click="postOwners">Save and Continue</span>
    </v-btn>
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "Step6",
  props:{
    clientId:{
      type: Number
    }
  },
  data () {
    return {
      hasFamily:0,
      owners:'',
      family:'',
      hasOwners:false
    }
  },
  methods:{
    postOwners(){
      const model = this.owners + '\n' + this.family ;
      if (this.hasOwners){
        ClientsService.putRawClients({owner_family_list: model},this.clientId).then(()=>this.$emit('goToNextStep', 7))
      }else {
        ClientsService.postRawClients({owner_family_list: model},this.clientId).then(()=>this.$emit('goToNextStep', 7))
      }
    },
    test(){
      ClientsService.getRawOwners(this.clientId)
          .then(res=>{
            if (res.owner_family_list.length){
              this.hasOwners = true;
              this.owners = res.owner_family_list
            }

          })
    }
  },
  created() {
    this.test()
  }
}
</script>

<style scoped>

</style>