<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-data-table
        style="cursor:pointer;"
        :loading="loading"
        :headers="headers"
        :items="items"
        :hide-default-footer="true"
        disable-pagination
        class="elevation-1 mt-5"
        @click:row="goToClient"
    ></v-data-table>
    <ProfileClientDialog
        v-if="showRightDialogChosenClient"
        :key="dialogKeyChosenClient"
        :showRightDialogChosenClient.sync="showRightDialogChosenClient"
        :clientProp="clientProp"
        @openClientProfileDialog="openClientProfileDialog"

    />
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import ProfileClientDialog from "@/components/Client/ProfileClientDialog.vue";
export default {
  name: "OpenBalance",
  components: {ProfileClientDialog},
  data:()=>({
    showRightDialogChosenClient:false,
    dialogKeyChosenClient:33,
    clientProp: {},
    loading:false,
    selectedClient:{},
    headers: [
      { text: 'Total fees expected', value: 'total_fees_expected_formatted' },
      { text: 'Total remitted', value: 'total_remitted_formatted' },
      { text: 'Balance', value: 'balance_formatted' },
      { text: 'Legal name', value: 'legal_name' },
      { text: 'Trade name', value: 'trade_name' },
      { text: 'Age', value: 'age' },

    ],
    items:[],
  }),
  methods:{
    goToClient(item){
      this.loading = true
      ClientsService.getOneClient(item.client_id).then(res=>{
        //this.clientProp = res
        this.clientProp = res;
        this.select = null;
        this.clientId = Number(this.clientProp.id);
        this.$router.push(`/clients/${this.clientId}/profile`)
      }).finally(()=>{
        this.openClientProfileDialog();
        this.loading=false
      })
    },
    openClientProfileDialog() {
      this.showRightDialogChosenClient = true;
      this.dialogKeyChosenClient++;
      this.loading = false
      this.showRightDialog = false;
    },
    async getData(){
      this.loading=true
      try {
        const res = await ClientsService.getClientReportsStatus("open-balance");
        if(res){
          this.loading=false
          this.items = res
        }
      } catch (error) {
        this.loading=false
        console.error(error)
      }
    },
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
