<template>
 <div>
   <v-overlay :value="loading">
     <v-progress-circular
         :size="70"
         :width="7"
         color="white"
         indeterminate
     ></v-progress-circular>
   </v-overlay>
   <v-container fluid>
     <v-row>
       <v-col v-if="usersRole === 1" class="text-left">
         <v-btn @click="markAsShipped(selected)" v-if="radioSelected === '1'" class="primary">Mark as Shipped</v-btn>
       </v-col>
       <v-col class="text-right d-flex justify-end">
         <v-radio-group v-model="radioSelected" row style="margin: 0 !important;">
           <v-radio @click="getData(5)" label="Ready for Shipping" value="1"></v-radio>
           <v-radio @click="getData(3)" label="Shipped" value="2"></v-radio>
<!--           <v-radio @click="getData(4)" label="Received" value="3"></v-radio>-->
         </v-radio-group>
       </v-col>
     </v-row>
   </v-container>
   <v-data-table
       :single-expand="false"
       :headers="headers"
       :items="items"
       class="elevation-5"
       disable-pagination
       hide-default-footer
       item-key="client_id"
       :show-select="radioSelected === '1' && usersRole === 1"
       v-model="selected"
   >


     <template v-slot:item.legal_name="{ item }">
        <div style="cursor: pointer;font-weight: 430" @click="$router.push(`/clients/${item.client_id}/profile`)">
          {{item.legal_name}}
        </div>
     </template>
     <template v-if="radioSelected ==='3'" v-slot:item.download="{ item }">
       <v-btn :disabled="!item.transcript" @click="downloadFile(item.transcript)" class="primary">Transcript</v-btn>
     </template>
     <template v-else v-slot:item.download="{ item }">
       <v-btn @click="downloadFile(item.signed_4506)" class="primary mr-2">Signed 4506-T</v-btn>

       <v-btn @click="downloadFile(item.label)" class="primary">Label</v-btn>
     </template>
   </v-data-table>

 </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import {mapGetters} from "vuex";
import AuthService from "@/services/auth.service";
import router from "@/router";
export default {
  name: "Report4506T",
  data() {
    return {
      usersRole:0,
      radioSelected: '1',
      selected: [],
      items:[],
      loading:false,
      headers:[
        { text: 'Client', value: 'legal_name' },
        { text: 'Download', value: 'download' },
      ],
    }
  },
  methods: {
    router() {
      return router
    },
    getData(filter) {
      this.loading = true
      ClientsService.get4506Report(filter).then(res => {
        this.items = res
        this.loading = false
      }).catch(() => this.loading = false)
    },
    markAsShipped(id){
      this.loading = true

      const dataModel = {
        status: 3
      }

      this.selected.forEach((element) =>
        ClientsService.patch4506Report(element.id, dataModel).then(res => {
            this.loading = false
            this.getData(5)
          this.$func.popMessage(`Shipped successfully !`, "success");
        }).catch((err) => {
          this.loading = false
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: `${err}`,
            duration: 4000,
            mode: "fail",
          });
        })
      );
    },
    downloadFile(item){
      try {
        if(item === null) {
          this.$store.dispatch("snackbar/showSnackbarMessage", {
            message: `Problem with downloading file`,
            duration: 4000,
            mode: "fail",
          });
        }
        else window.open(item)
      } catch (e) {
        console.log('no such a file')
      }

    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  async created() {
    await AuthService.getCurrentUser(this.currentUser.current_user_id).then(
        (res) => {
          this.usersRole = res.data.role_id;
        }
    );
    this.getData(5)
  }
}
</script>
