<template>
  <div>
    <v-radio-group row v-model="showInProgress">
      <v-radio label="In progress" :value="true" />
      <v-radio label="Completed" :value="false" />
    </v-radio-group>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :loading="itemsLoading"
          :headers="headers"
          :items="items"
          hide-default-footer
          class="elevation-2"
          style="cursor: pointer"
          :search="searchValue"
          @click:row="(item) => this.$router.push(`/queues/transcript/${item.queue_id}`)"
        >
          <template #top>
            <div class="pa-3">
              <v-text-field
                v-model="searchValue"
                :disabled="!items.length"
                hide-details
                append-icon="mdi-magnify"
                label="Search client"
              />
            </div>
          </template>
          <template v-slot:item.completion="{ item }">
            <span class="font-weight-bold" v-if="!+item.completed_tasks && !+item.uncompleted_tasks">NO TASKS YET!</span>
            <v-progress-linear
              v-else
              :style="{width: isMobile ? '150px' : null}"
              :value="item.completion"
              :height="25"
            >
              <strong style="color: white"
                >{{item.completion}}%</strong
              >
            </v-progress-linear>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TranscriptService from "@/services/transcript.service";

export default {
  name: "TranscriptQueueMTA",
  data: () => ({
    searchValue: "",
    itemsLoading: false,
    items: [],
    headers: [
      { text: "Client", sortable: false, value: "trade_name", width: '20%' },
      { text: "Managing Firm", sortable: false, value: "managing_firm_name", width: '20%' },
      { text: "Attorney of Record", sortable: false, value: "aor", width: '20%' },
      { text: "Legal Name", sortable: false, value: "legal_name", width: '20%' },
      { text: "Progress", sortable: true, value: "completion", width: '20%'}
    ],
    showInProgress: true,
  }),
  methods: {
    getItems() {
      this.itemsLoading = true;
      TranscriptService.getTranscriptQueueByFilter(this.showInProgress ? 0 : 1)
        .then((res) => {
          res.forEach(e => {
            let progress = this.calculatePercentage(e.completed_tasks, +e.completed_tasks + +e.uncompleted_tasks)
            e.completion = isNaN(progress) ? -1 : progress
          })
          this.items = res;
        })
        .finally(() => (this.itemsLoading = false));
    },
    calculatePercentage(part, whole) {
      return Math.ceil((part / whole) * 100);
    },
  },
  created() {
    this.getItems();
  },
  watch: {
    showInProgress() {
      this.getItems();
    },
  },
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  }
};
</script>
