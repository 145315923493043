<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog"
              persistent
              max-width="600px">
      <v-card>
        <h3 style="background-color:#263b4f;color:white;padding:12px;font-size:0.9rem">
          Save before leaving?
        </h3>
        <p class="mt-4" style="font-weight:bold;font-size:1rem;margin-left:11px"> You haven't saved your uploads. Are you sure you want to continue </p>

        <v-card-actions>
          <v-spacer></v-spacer>
          <p></p>

          <v-btn
              color="blue darken-1"
              text
              @click="acceptedDelete"

          >
            LEAVE
          </v-btn>

          <v-btn
              color="blue darken-1"
              text
              @click="closeDialog"

          >
            STAY
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-row>
</template>


<script>
export default {
  name: "LeaveUploadsConfirm",
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  methods: {
    acceptedDelete(event) {
      this.$emit('clicked', {
        returnToUploads: true,
        closeWindow: false,
      });

    },
    closeDialog(event) {
      this.$emit('clicked', {
        returnToUploads: false,
        closeWindow: false,
      });

    }
  }
}
</script>

<style scoped>
.dialog-message {
  margin-bottom: 1.6rem;
}
</style>