<template>
<div>
  <h2 class="mb-3">Unassigned Clients</h2>
  <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      item-key="client_id"
      show-select
      class="elevation-1"
      mobile-breakpoint="0"
      disable-pagination
      hide-default-footer
  >
  </v-data-table>
  <v-row >
    <v-col class="d-flex align-center justify-center">
      <h4>Assign to: </h4>
    </v-col>
    <v-col cols="12" md="6"  align-self="end" class="d-flex  ma-5 align-center" >
      <v-select
          class="mr-6"
          v-model="selectedAssignee"
          :items="selectItems"
          :item-text="item => `${item.first} ${item.last}`"
          item-value="id"
          label="Select"
          return-object
      ></v-select>
      <v-btn color="success" @click="postData" :disabled="!selectedAssignee">Assign</v-btn>
    </v-col>
  </v-row>
  <v-overlay :value="loading">
    <v-progress-circular
        :size="70"
        :width="7"
        color="white"
        indeterminate
    ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import AuthService from "@/services/auth.service";

export default {
  name: "UnassignedClients",
  data: () => ({
    items: [],
    selectItems: [],
    selected:[],
    selectedAssignee:'',
    headers: [
      {text: 'Client', value: 'trade_name'},
      {text: 'Age', value: 'age'},
    ],
    loading:false
  }),
  methods:{
    getEntities(){
      AuthService.getMyEntities().then(res=>{
        this.selectItems = res.users.filter(e=>e.can_be_aor == 1)
      })
    },
    postData(){
      this.loading=true
      this.selected.forEach(e=>{
        ClientsService.editClient(e.client_id,{'attorney_of_record':this.selectedAssignee.id})
      })
     setTimeout(()=>{
       this.getUnassigned()
     },1000)
    },
    getUnassigned(){
      ClientsService.getUnassignedClients().then(res=>{
        this.items = res
        this.loading=false
      })
    },
  },
  created() {
    this.getUnassigned()
    this.getEntities()
  }
}
</script>

<style scoped>

</style>
