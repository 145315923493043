<template>
  <section :id="item.id" :class="[{ editable: editing }]" class="text-center" data-fields="icon=icon&amp;title=txt&amp;subtitle=txt">
    <div class="container">
<!--      <i :class="item.icon+' fa-big'"></i>-->
      <h1>{{item.title}}</h1>
      <p>{{item.subtitle}}</p>
<!--      <v-btn  color="#263B4F"-->
<!--              class="text-white">-->
<!--        <a :href="item.button_link"-->
<!--           style="all: unset !important;"-->
<!--           v-if="item.button_text">{{item.button_text}}</a>-->
<!--      </v-btn>-->
    </div>
  </section>
</template>

<script>
export default {
  name: "Header",
  props: ['item','editing'],
}
</script>

<style scoped>
@import "~@/components/PageBuilderComponents/editor/editor.css";

</style>
