<template>
<div>

  <v-stepper v-model="el">
    <v-stepper-items>
      <v-stepper-content step="1">
      <Step1
          @returnClientProp="getClient"
          @returnClientId="getClientId"
          @returnStep1Data="getStepsData"
           />
      </v-stepper-content>

      <v-stepper-content step="2">
      <div v-if="el==2">
        <Step2
            :client-prop="client"
            :clientId="clientId"
            @returnStep2Data="getStepsData"
            @goToNextStep="nextStepClicked" />
      </div>
      </v-stepper-content>

      <v-stepper-content step="3">
       <Step3
           :clientId="clientId"
           @goToNextStep="nextStepClicked"/>
      </v-stepper-content>
      <v-stepper-content step="4">
       <Step4
           :clientProp="client"
           :clientId="clientId"
           @goToNextStep="nextStepClicked"/>
      </v-stepper-content>
      <v-stepper-content step="5">
       <Step5 :clientId="clientId"
              @goToNextStep="nextStepClicked"/>
      </v-stepper-content>
      <v-stepper-content step="6">
       <div v-if="el == 6">
         <Step6 :clientId="clientId"
                @goToNextStep="nextStepClicked"/>
       </div>
      </v-stepper-content>
      <v-stepper-content step="7">
        <div v-if="el==7">
          <Step7 :clientId="clientId"
                 @goToNextStep="nextStepClicked"/>
        </div>
      </v-stepper-content>
      <v-stepper-content step="8">
        <Step8 @goToNextStep="nextStepClicked"/>
      </v-stepper-content>
      <v-stepper-content step="9">
        <Step9 :clientId="clientId" />
      </v-stepper-content>
    </v-stepper-items>

    <v-stepper-header style="overflow: auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: left;">
      <v-stepper-step
          style="width: 210px"
          :complete="el > 1"
          step="1"
      >
        Company information
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
          :complete="el > 2"
          step="2"
          style="width: 210px"
      >
        Company Representative
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
          :complete="el > 3"
          step="3"
          style="width: 210px"
      >
        Employee Count
      </v-stepper-step>

      <v-divider></v-divider>
      <v-stepper-step
          :complete="el > 4"
          step="4"
          style="width: 210px"
      >
        Qualifying For the Erc
      </v-stepper-step>

      <v-divider></v-divider>
      <v-stepper-step
          :complete="el > 5"
          step="5"
          style="width: 210px"
      >
        PPP Loans
      </v-stepper-step>

      <v-divider></v-divider>
      <v-stepper-step
          :complete="el > 6"
          step="6"
          style="width: 210px"
      >
        Owners and Family Members
      </v-stepper-step>

      <v-stepper-step
          :complete="el > 7"
          step="7"
          style="width: 210px"
      >
        Taxable Social Security Wages
      </v-stepper-step>

      <v-stepper-step
          :complete="el > 8"
          step="8"
          style="width: 210px"
      >
        Payroll Spreadsheet
      </v-stepper-step>




      <v-divider></v-divider>

      <v-stepper-step step="9">
        Required Attachments
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
  <v-overlay :value="loading">
    <v-progress-circular
        :size="70"
        :width="7"
        color="white"
        indeterminate
    ></v-progress-circular>
  </v-overlay>
</div>
</template>

<script>
import Step1 from "@/views/EmployeeRetention/Step1";
import Step2 from "@/views/EmployeeRetention/Step2";
import Step3 from "@/views/EmployeeRetention/Step3";
import Step4 from "@/views/EmployeeRetention/Step4";
import Step5 from "@/views/EmployeeRetention/Step5";
import Step6 from "@/views/EmployeeRetention/Step6";
import Step7 from "@/views/EmployeeRetention/Step7";
import Step8 from "@/views/EmployeeRetention/Step8";
import Step9 from "@/views/EmployeeRetention/Step9";
import ClientsService from "@/services/clients.service";
export default {
  name: "EmployeeRetention",
  components: {
    Step8,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Step9
  },
  data () {
    return {
      loading: true,
      clientId:null,
      client:null,
      el: 1,
      step1Data:{},
      step2Data:{},
      step3Data:{},
      step4Data:{},
      step5Data:{},
      step6Data:{},
      step7Data:{},
      step8Data:{},
      step9Data:{},
    }
  },
  methods:{
    getClientId(id){
      this.clientId = id;
    },
    nextStepClicked (value) {
      this.el = value;
    },
    getClient(val){
      this.loading=false;
      this.client=val
      // ClientsService.getOneClient(this.clientId)
      //     .then(res=>{
      //       this.client = res;
      //     })
    },
     getStepsData(val, step){
       this.loading = true;
       if (step==1 || step==2){
        ClientsService.editClient(this.clientId, val).then(res=>{
          this.loading = false;
          this.nextStepClicked(step+1);
          this.$func.popMessage("Client data saved successfully!")
        })
      }else{
        this.nextStepClicked(step+1);
        this.loading=false;
      }
    },
   patchClientData(client){
      ClientsService.editClient(this.clientId, client)
   },
  },


}
</script>

<style scoped>

</style>