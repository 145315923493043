<template>
    <v-container>
        <v-row class="mt-5">
          <v-hover  v-for="(options, index) in paymentOptions" v-slot="{ hover }">
        <v-card
            class="mr-2 mb-2 pa-2"
            outlined
            :elevation="hover ? 16 : 2"
            :style="`width: ${$vuetify.breakpoint.lgAndUp ? '30' : '100'}%`"
            @click="openPaymentOption(index)"
        >
            <v-list-item three-line>
            <v-list-item-content>
  
                <v-list-item-title class="text-h5 mb-1" style="font-weight: bold">
                {{ options.title }}
                </v-list-item-title>
            </v-list-item-content>
            </v-list-item>

            <v-card-actions style="position: relative;padding-bottom: 25px">
            <v-btn
                style="position: absolute;right: 1px"
                text
            >
                Open
            </v-btn>
            </v-card-actions>
        </v-card>
          </v-hover>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data () {
        return {
            paymentOptions: [
                {
                    title: "Overpayments"
                },
                {
                    title: "Reconciliation"
                },
                {
                    title: "Deposits"
                }
            ]
        }
    },
    methods: {
        openPaymentOption(index){
            switch(index) {
                case 0:
                this.$router.push('overpayments')
                break;
                case 1:
                this.$router.push('reconciliation')
                break;
                case 2:
                this.$router.push('deposits')
                break;
            }
        }
    }
}
</script>
