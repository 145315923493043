<template>
<div>
  <input type="file" id="fileInput" class="fileInput" accept="image/png, image/jpeg, image/gif" style="display: none;" />
  <button class="btn btn-outline-secondary" @click="choose">Upload</button>

<!--  <v-card-->
<!--      @drop.prevent="drop($event)"-->
<!--      height="20vh"-->
<!--      class="d-flex  align-center justify-center"-->
<!--      style="border: 5px dashed lightgray; box-shadow: none">-->
<!--    <v-card-text>-->
<!--      <v-row class="d-flex justify-center align-center">-->
<!--        <div-->
<!--            class="p-12"-->
<!--        >-->
<!--          <input type="file" multiple name="fields[assetsFieldHandleUploads][]"-->
<!--                 id="assetsFieldHandleUploads"-->
<!--                 class="d-none absolute" @input="onChange" ref="file"-->
<!--          />-->

<!--          <label for="assetsFieldHandleUploads" class="block cursor-pointer">-->
<!--            <span class="underline"> CHOOSE  A FILE </span> OR DRAG IT <span style="color: blue;"> HERE </span>-->
<!--          </label>-->
<!--          <div v-if="$refs.file" cols="12" class="displayFileName">-->
<!--          <span v-for="(file,index) in $refs.file.files"-->
<!--                style="font-size: 12px"-->
<!--                :key="index + 999">{{ file.name }}-->
<!--            <span v-if="index + 1 != $refs.file.files.length"> ,</span>-->
<!--          </span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </v-row>-->
<!--    </v-card-text>-->
<!--  </v-card>-->
</div>
</template>

<script>
import AuthService from "@/services/auth.service";
export default {
  name: "ImageResize",
  props: ['mykey'],
  data() {
    return {
      forDate: new Date(),
      currentUser: null,
      upload:''
    }
  },
  methods: {
    onChange() {
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        if (this.$refs.file.files[i].size < 25000000) {
          this.isActiveButton = true;
          let uploadedFile = this.$refs.file.files[i]
          const newFile = {
            original_file_name: uploadedFile.name,
            url: uploadedFile,
            created_on: this.forDate,
            mime_type: uploadedFile.type,
            uploaded_by: this.currentUser.id,
            uploadedFile: uploadedFile,
            uploadingFiles: false,
          }
          if (uploadedFile) {
            this.upload=newFile
          }
        } else {
          const removeMessageAfter = 2500
          this.errorMessage = true;
          this.startBuffer()
          setTimeout(() => {
            this.errorMessage = false
            clearInterval(this.interval);
            this.errProgressBarValue = 0;
            this.bufferValue = 0;
          }, removeMessageAfter)
        }
      }
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
    },
    choose: function() {
      document.getElementById('fileInput').click();
    }
  },
  async mounted() {
    const data = await AuthService.getCurrentUserFromCookie()
    this.currentUser = data.data

    var app = this;

    document.getElementById('fileInput').addEventListener('change', function(e) {

      var img_width = 500;

      var img = new Image();
      img.onload = function() {
        var canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d"),
            oc = document.createElement('canvas'),
            octx = oc.getContext('2d');

        canvas.width = img_width; // destination canvas size
        canvas.height = canvas.width * img.height / img.width;

        var cur = {
          width: Math.floor(img.width * 0.5),
          height: Math.floor(img.height * 0.5)
        }
        oc.width = cur.width;
        oc.height = cur.height;
        octx.drawImage(img, 0, 0, cur.width, cur.height);
        while (cur.width * 0.5 > img_width) {
          cur = {
            width: Math.floor(cur.width * 0.5),
            height: Math.floor(cur.height * 0.5)
          };
          octx.drawImage(oc, 0, 0, cur.width * 2, cur.height * 2, 0, 0, cur.width, cur.height);
        }
        ctx.drawImage(oc, 0, 0, cur.width, cur.height, 0, 0, canvas.width, canvas.height);
        var base64Image = canvas.toDataURL('image/jpeg')

        //console.log(base64Image);
        //window.cur_img.src = base64Image;
        //app.item[app.mykey] = base64Image;

        app.$emit("image", {
          key: app.mykey,
          value: base64Image,
          uploaded_file:e.target.files[0]
        });

      }
      img.src = URL.createObjectURL(e.target.files[0]);

    });

  }
}
</script>

<style scoped>

</style>
