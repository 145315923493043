<template>
  <v-row class="ma-10">
    <v-col class="pr-6" cols="6">
      <v-select
          v-model="selectedTaxFirm"
          :items="taxOptions"
          item-text="name"
          item-value="id"
          label="Tax Firm"></v-select>
      <v-text-field type="number"  v-model="taxFirmPoints" :rules="rules" placeholder="Point Spread(0-20)">
      </v-text-field>
    </v-col>
    <v-col class="pl-6" cols="6">
      <v-select v-model="selectedLegalFirm"
                :items="legalOptions"
                item-text="name"
                item-value="id"
                label="Legal Firm"></v-select>
      <v-text-field type="number" v-model="legalFirmPoints" :rules="rules" placeholder="Point Spread(0-20)">
      </v-text-field>
      <v-btn color="#263B4F" @click="savePods"  class="rounded-pill mt-7 px-8"><span style="color: #FFFFFF">Create</span></v-btn>
    </v-col>
    <v-col cols="6" class="mt-3" v-for="(pod,index) in pods" :key="index">

      <v-card v-if="pod.edit">
        <v-card-title class="d-flex justify-space-between ">
          <div style="font-size: 14px">{{pod.tax_firm_name}}</div>
          <div style="font-size: 14px">{{pod.managing_law_firm_name}}</div>
        </v-card-title>
        <v-card-text class="d-flex justify-space-between ">
          <div>
            <v-text-field label="Point spread: " v-model="pod.new_points_processor" class="pr-3"></v-text-field>
          </div>
          <div>
            <v-text-field label="Point spread: " v-model="pod.new_points_managing_firm" class="pl-3"></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <span class="mr-6" style="color: #D21919;cursor: pointer" @click="removeEdit(index)">CANCEL</span>
          <span class="mr-2" style="color: #1976D2;cursor: pointer" @click="patchPod(pod,index)">SAVE</span>
        </v-card-actions>
      </v-card>

      <v-card v-else>
        <v-card-title class="d-flex justify-space-between mb-4">
          <div style="font-size: 14px">{{pod.tax_firm_name}}</div>
          <div style="font-size: 14px">{{pod.managing_law_firm_name}}</div>
        </v-card-title>
        <v-card-text class="d-flex justify-space-between mb-6">
          <span>Point spread: {{pod.points_processor}}</span>
          <span>Point spread: {{pod.points_managing_firm}}</span>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <span class="mr-6" style="color: #D21919;cursor: pointer" @click="opendialog(pod.id)">DELETE</span>
          <span class="mr-2" style="color: #1976D2;cursor: pointer" @click="editPods(index)">EDIT</span>
        </v-card-actions>

      </v-card>
    </v-col>
    <v-dialog
        v-model="dialog"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5 " style="background-color: #263B4F">
          <span style="color: #FFFFFF">POD Delete Confirmation</span>
        </v-card-title>

        <v-card-text class="mt-6">
   Are you sure you want to delete that POD?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog=false"
          >
            Cancel
          </v-btn> <v-btn
            color="primary"
            text
            @click="deletePod(lastDeleteIndex)"
        >
          I accept
        </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
import ClientsService from "@/services/clients.service";
export default {
  name: "Pod",
  data: () => ({
    taxOptions:[],
    legalOptions:[],
    selectedLegalFirm:null,
    selectedTaxFirm:null,
    taxFirmPoints:0,
    legalFirmPoints:0,
    dialog: false,
    loading:false,
    lastDeleteIndex:null,
    pods:[],
    rules: [v => v <= 20 || 'Spread should be up to 20'],
  }),
 methods:{
    opendialog(id){
      this.dialog = true
      this.lastDeleteIndex = id
    },
   removeEdit(index){
     this.pods[index].edit = false
     this.pods = [...this.pods]
   },
   patchPod(pod,index){
      if (pod.new_points_processor == '' || pod.new_points_managing_firm == ''){
        this.$func.popMessage('Please insert a value in point spread!','danger')
      }else {
        const model = {
          "points_tax_firm": pod.new_points_processor,
          "points_managing_firm": pod.new_points_managing_firm
        }
        ClientsService.patchPod(pod.id, model)
            .then(() => {
              this.removeEdit(index)
              // this.pods[index].points_processor = this.pods[index].new_points_processor
              // this.pods[index].points_managing_firm = this.pods[index].new_points_managing_firm
              this.getPods()
              this.$func.popMessage('POD edited successfully!', 'success')
            })
      }
   },
   deletePod(id){
      this.loading=true
     this.dialog=false;
     ClientsService.deletePods(id)
         .then(async ()=>{
           this.$func.popMessage('POD deleted successfully!','success')
           await this.getPods()
           this.loading=false
         }).catch(()=>this.loading=false)
   },
   editPods(index){
     this.pods[index].edit = true
     this.pods[index].new_points_processor = this.pods[index].points_processor
     this.pods[index].new_points_managing_firm = this.pods[index].points_managing_firm
     this.pods = [...this.pods]
   },
    savePods(){
      const model = {
        "managing_lawfirm": this.selectedLegalFirm,
        "tax_firm": this.selectedTaxFirm,
        "points_tax_firm": this.taxFirmPoints,
        "points_managing_firm": this.legalFirmPoints
      }
      ClientsService.postPods(model)
          .then(res=>  this.$func.popMessage("POD Created Successfully!","success"))
    },
   getPods(){
     ClientsService.getTaxFirms()
         .then(res=> {
           this.pods = res;
         })
   },
   getTaxFirms(){
      ClientsService.getEntitiesFiltered('tax').then(res=>this.taxOptions = res)
   }
 },
  created() {
    ClientsService.getEntitiesFiltered('law')
        .then(res => {
          this.legalOptions = res;
        })
    this.getTaxFirms()
    this.getPods()
  }
}
</script>

<style scoped>

</style>
