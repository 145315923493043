<template>
  <v-card elevation="3">
    <v-card-text>
      <div>
        <h4>Description</h4>
        <br />
        <TranscriptQueueTaskNote
          :is-description="true"
          author="M T A"
          :body="task.task_decription"
          :created_on="task.created_on"
        />
        <br />
        <slot name="file-upload" />
        <v-row v-if="task.documents" justify="center"  v-for="(file,index) in task.documents">
          <v-col
              style="border: 1px solid #11AF22; border-radius: 8px"
              class="mt-1 py-2 d-flex flex-row align-center justify-space-between"
              cols="7"
          >
            <div
              style="font-size: 12px"
            >
              <span>
                <v-icon>mdi-file-document-check</v-icon>&nbsp;{{ file.original_file_name }}
              </span>
            </div>
            <v-btn small icon @click="">
              <v-icon @click="downloadFile(file)">mdi-download</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>
      </div>
      <br />
      <h4>Notes</h4>
      <br />
      <div v-for="note in task.notes" :key="note.id">
        <TranscriptQueueTaskNote
          :author="note.author_name"
          :body="note.body"
          :created_on="note.created_on"
        />
      </div>
      <slot name="new-note" />
    </v-card-text>
  </v-card>
</template>

<script>
import TranscriptQueueTaskNote from "@/components/TranscriptQueue/TranscriptQueueTaskNote.vue";

export default {
  name: "TranscriptQueueTaskDetails",
  components: { TranscriptQueueTaskNote },
  props: {
    task: {
      type: Object,
      required: true,
    }
  },
  methods: {
    async downloadFile(file) {
      try {
        const response = await fetch(file.url);
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl
        link.download = file.original_file_name;

        // Simulate click
        link.click();

        // Clean up
        window.URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    }
  }
};
</script>