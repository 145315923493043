<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    
    <v-data-table
        style="cursor:pointer;"
        :loading="loading"
        :headers="headers"
        :items="items"
        :hide-default-footer="true"
        disable-pagination
        class="elevation-1 mt-5"
        :custom-sort="customSort"
        @click:row="goToClient"
    >
    
    <template v-slot:item.active="{ item }">
      <span v-if="item.active === 0" style="color: #D21919;font-weight: 500">{{ item.active_formatted }}</span>
      <span v-else style="color: #228920;font-weight: 550">{{ item.active_formatted }}</span>
    </template>
    </v-data-table>
    <ProfileClientDialog
        v-if="showRightDialogChosenClient"
        :key="dialogKeyChosenClient"
        :showRightDialogChosenClient.sync="showRightDialogChosenClient"
        :clientProp="clientProp"
        @openClientProfileDialog="openClientProfileDialog"

    />
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import ProfileClientDialog from "@/components/Client/ProfileClientDialog.vue";
export default {
  name: "RefileFollowup",
  components: {ProfileClientDialog},
  data:()=>({
    showRightDialogChosenClient:false,
    dialogKeyChosenClient:33,
    clientProp:{},
    loading:false,
    selectedClient:{},
    headers: [
      { text: 'Trade name', value: 'trade_name' },
      { text: 'Active', value: 'active' },
      { text: 'Created', value: 'client_created' },    
      { text: 'Filed date', value: 'filed_date' },  
      { text: 'Age', value: 'days_since_filed' },
      { text: 'Balance Due', value: 'balance_due_formatted' },
      { text: 'Quarter', value: 'quarter' },
      { text: 'Current status', value: 'current_status' },
      { text: 'FedEx tracking', value: 'fedex_tracking' },

    ],
    items:[],
  }),
  methods:{
    customSort(items, index, isDesc) {
      // console.log(items)
      isDesc = isDesc[0]
      const header = index[0]
      // if (!index[0] == "balance_due_formatted") return items;
      if (header === "balance_due_formatted") {
        items.sort((a, b) => {
          if (!isDesc) {
            return a.balance_due - b.balance_due;
          } else {
            return b.balance_due - a.balance_due;
          }
        });
        return items;
      }
      items.sort((a, b) => {
        if (!isDesc) {
          return a[header] - b[header];
        } else {
          return b[header] - a[header];
        }
      });
      return items;
    },
    goToClient(item){
      this.loading = true
      ClientsService.getOneClient(item.client_id).then(res=>{
        //this.clientProp = res
        this.clientProp = res;
        this.select = null;
        this.clientId = Number(this.clientProp.id);
        this.$router.push(`/clients/${this.clientId}/profile`)
      }).finally(()=>{
        this.openClientProfileDialog();
        this.loading=false
      })
    },
    openClientProfileDialog() {
      this.showRightDialogChosenClient = true;
      this.dialogKeyChosenClient++;
      this.loading = false
      this.showRightDialog = false;
    },
    async getData(){
      this.loading=true
      
      try {
        const res = await ClientsService.getClientReportsStatus("refile");
        if(res){
          this.items = res
          this.loading=false
        }
      } catch (error) {
        this.loading=false
        console.error(error)
      }
    },
  },
  created() {
    this.getData()
  }
}
</script>

<style scoped>

</style>
