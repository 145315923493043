<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="queue"
          hide-default-footer
          style="cursor:pointer;"
          class="elevation-1"
          :loading="loading"
          @click:row="handleRowClick"
          disable-pagination
          no-data-text="The queue is empty"
          :search="search"
          loading-text="Loading the queue"
        >
          <template v-slot:top="item" >
            <v-text-field
                class="mx-6"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ClientsService from "@/services/clients.service";

export default {
  name: "QualificationReviewQueue",
  data: () => ({
    headers: [
      { text: "ID", value: "client_id" },
      { text: "Age", value: "age" },
      { text: "Client", value: "client" },
      { text: "Managing Firm", value: "managing_firm" },
      { text: "Attorney of Record", value: "aor" },
      { text: "Legal Name", value: "legal_name" },
      { text: "EIN", value: "ein" },
    ],
    search:'',
    queue: [],
    loading: false,
  }),
  methods: {
    getQualificationReview() {
      this.loading = true;
      ClientsService.getQualificationQueue()
        .then((res) => {
          this.queue = res;
        })
        .finally(() => (this.loading = false));
    },
    handleRowClick(row) {
      this.$router.push(`/clients/${row.client_id}/qualification`)
    },
  },
  created() {
    this.getQualificationReview();
  },
};
</script>
