<template>
  <div class="pt-2">
    <v-overlay :value="loading">
      <v-progress-circular :size="70" :width="7" color="white" indeterminate></v-progress-circular>
    </v-overlay>
    <DeleteConfirmation
        :showDialog="showDeleteMandateDialog"
        :noteTitle="deleteMandateTitle"
        @clicked="deleteMandate"
    ></DeleteConfirmation>

    <v-dialog v-model="reasonDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-body-1 text-md-h6">{{ reasonTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="reason" solo label="Add reason" clearable></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="reasonSubmitAction"
              :loading="reasonDialogLoading"
          >
            {{ reasonSubmitButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row v-if="alert.msg">
      <v-col cols="12" md="4">
        <v-alert :color="alert.color" :type="alert.type"
        >{{ alert.msg }}
          <v-btn
              @click.prevent="checkMandate(5)"
              v-if="reviewStatus.review_status_id === 4"
              text
              color="primary"
          >
            CHECK OUT
          </v-btn>
          <v-btn
              @click.prevent="checkMandate(4)"
              :loading="checkInMandateLoading"
              v-else-if="
              userIsMasterTaxAttorney &&
              reviewStatus.review_status_id === 5 &&
              reviewStatus.checked_out_to !== currentUser.current_user_id
            "
              class="font-weight-bold"
              color="primary"
              text
          >CHECK IN
          </v-btn>
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="substantiationClients.clients.length && isMobile">
      <v-col cols="12">
        <v-data-table
            :headers="substantiationClients.headers"
            :items="substantiationClients.clients"
            hide-default-footer
            :items-per-page="5"
            class="elevation-1"
            mobile-breakpoint="0"
        />
      </v-col>
    </v-row>
    <v-row style="height: 100vh; display: flex">
      <v-col id="firstColumn" lg="4" md="12">
        <v-col class="text-center d-flex flex-wrap px-0" style="gap: 7px" v-if="isMobile">
          <v-btn
              color="red darken-1 white--text"
              @click="deleteMandateDialog"
              style="font-size: 10px"
              :disabled="currRole !== 13 && currRole !== 1"
              :loading="deleteMandateLoading"
              x-small
          >Delete
          </v-btn>
          <v-btn
              color="primary"
              @click="reviewedMandate()"
              style="font-size: 10px"
              :disabled="notAllowed"
              :loading="markAsReviewedLoading"
              x-small
          >Mark As Reviewed
          </v-btn>
          <v-btn
              color="primary"
              @click="blockMandateDialog"
              style="font-size: 10px"
              :disabled="notAllowed"
              :loading="markAsBlockedLoading"
              x-small
          >Mark As Blocked
          </v-btn>
          <v-btn
              @click="escalateMandateDialog"
              :disabled="currRole !== 13 || mandate.is_escalated"
              color="primary"
              style="font-size: 10px"
              x-small
          >Escalate
          </v-btn>
        </v-col>

        <v-col class="d-flex flex-wrap px-0" style="gap: 7px" v-if="isMobile">
          <v-btn
              v-if="reviewStatus.checked_out_to === currentUser.current_user_id"
              @click="checkMandate(4)"
              color="primary"
              x-small
          >check in
          </v-btn>
          <v-btn :disabled="!edited" @click="revertChanges" color="warning" x-small
          >Undo Changes
          </v-btn>

          <v-btn :disabled="!edited" @click="updateMandate" color="primary" x-small
          >Save Changes
          </v-btn>

          <v-btn @click="downloadMandate" color="primary" x-small :disabled="notAllowed"
          >Download
            <v-icon right dark> mdi-download</v-icon>
          </v-btn>
          <v-btn @click="downloadMandate" color="primary" x-small :disabled="notAllowed"
          >Download
            <v-icon right dark> mdi-download</v-icon>
          </v-btn>
        </v-col>
        <v-row class="mb-2" v-if="substantiationClients.clients.length && !isMobile">
          <v-col cols="12">
            <v-data-table
                :headers="substantiationClients.headers"
                :items="substantiationClients.clients"
                hide-default-footer
                :items-per-page="5"
                class="elevation-1"
            />
          </v-col>
        </v-row>
        <v-col class="d-flex justify-end flex-wrap" style="gap: 10px" v-if="!isMobile">
          <v-btn
              v-if="reviewStatus.checked_out_to === currentUser.current_user_id"
              class="me-auto"
              @click="checkMandate(4)"
              color="primary"
          >check in
          </v-btn>
          <v-btn :disabled="!edited" @click="revertChanges" color="warning">Undo Changes</v-btn>
          <v-btn :disabled="!edited" @click="updateMandate" color="primary">Save Changes</v-btn>
        </v-col>

        <!--    States -->
        <v-row class="mt-4" align="center">
          <v-col cols="4">
            <h2>State:</h2>
          </v-col>
          <v-col cols="8">
            <v-autocomplete
                :items="states"
                :item-text="'state_name'"
                return-object
                :search-input.sync="stateSearch"
                @change="onStateSelect"
                :loading="stateLoading"
                :disabled="notAllowed"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="selectedState.state_name !== null">
            <v-chip class="ma-1">{{ selectedState.state_name }}</v-chip>
          </v-col>
        </v-row>
        <!--     Counties -->
        <v-row class="mt-4" align="center">
          <v-col cols="4">
            <h2>Counties:</h2>
          </v-col>
          <v-col cols="8">
            <v-autocomplete
                :items="counties"
                :item-text="'county_name'"
                return-object
                :search-input.sync="countySearch"
                @change="onCountySelect"
                :loading="countyLoading"
                :disabled="notAllowed"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="selectedCounties.length">
            <v-chip class="ma-1" v-for="(state, i) in selectedCounties" :key="`S${i}}`"
            >{{ state.county_name }}
            </v-chip>
          </v-col>
        </v-row>
        <!--    Cities-->
        <v-row class="mt-4" align="center">
          <v-col cols="4">
            <h2>Cities:</h2>
          </v-col>
          <v-col cols="8">
            <v-autocomplete
                :items="cities"
                :item-text="'name'"
                return-object
                :search-input.sync="citySearch"
                @change="onCitySelect"
                :loading="cityLoading"
                :disabled="notAllowed"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="selectedCities.length">
            <v-chip class="ma-1" v-for="(state, i) in selectedCities" :key="`S${i}}`"
            >{{ state.name }}
            </v-chip>
          </v-col>
        </v-row>
        <!--    NAICS-->
        <v-row class="mt-4" align="center">
          <v-col cols="4">
            <h2>Industries:</h2>
          </v-col>
          <v-col cols="8">
            <v-autocomplete
                :items="naics"
                :item-text="'code'"
                return-object
                :search-input.sync="naicsSearch"
                @change="onNaicSelect"
                :loading="naicsLoading"
                :disabled="notAllowed"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="selectedNaics.length">
            <v-chip class="ma-1" v-for="(state, i) in selectedNaics" :key="`S${i}}`"
            >{{ state.code }}
            </v-chip>
          </v-col>
        </v-row>

        <!--    Impact Choices-->
        <div class="mt-16">
          <p class="text-h4">Business Impact</p>
          <p class="text-subtitle-1">
            Check the business impact items, which are related to this mandate.
          </p>
          <v-row>
            <v-col v-for="choice in businessImpactChoices" :key="choice.id" cols="12">
              <v-switch
                  style="padding: 0; margin: 0"
                  v-model="selectedImpacts"
                  :label="choice.choice"
                  :value="choice"
                  color="primary"
                  inset
                  dense
                  hide-details
                  :disabled="notAllowed"
              >
              </v-switch>
            </v-col>
          </v-row>

          <div class="mt-5">
            <v-btn @click="" color="primary" :disabled="notAllowed">Save Mandate</v-btn>
          </div>
        </div>

        <!--Notes-->
        <DeleteConfirmation
            :showDialog.sync="showDeleteDialog"
            :noteTitle="deleteNoteTitle"
            @clicked="onClickDeleteNoteDialog"
        ></DeleteConfirmation>

        <v-col cols="12" class="mt-10">
          <v-textarea
              filled
              name="input-7-1"
              label="Add generic note"
              v-model="noteMessage"
          ></v-textarea>
          <v-btn :loading="addingNoteLoading" @click="addNote" color="primary">Add Note</v-btn>

          <v-skeleton-loader class="mt-10" v-if="notesLoading" type="text" />
          <div class="mt-5" v-else-if="!notes.length && !notesLoading"><p>NO NOTES YET</p></div>
          <div class="mt-5" v-else>
            <v-card class="mx-auto" v-for="(note, index) in notes" :key="index">
              <v-card-actions>
                <v-list-item>
                  <v-list-item-avatar color="grey darken-3">
                    <v-img class="elevation-6" alt="" src="../assets/img/Asset-1.png"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <div class="d-flex align-center justify-space-between">
                      <span>{{ note.author_name }}</span>
                      <span style="font-size: 0.8rem; text-align: end">{{ note.created_on }}3</span>
                    </div>
                  </v-list-item-content>

                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <!--                      <v-list-item link @click="isEditingNote = !isEditingNote">-->
                      <!--                        <v-list-item-title>Edit</v-list-item-title>-->
                      <!--                      </v-list-item>-->
                      <v-list-item
                          link
                          @click="deleteNoteDialog(note)"
                          v-if="currentUser.current_user_id == note.author || currRole == 13"
                      >
                        <v-list-item-title>Delete</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item>
              </v-card-actions>

              <v-card-text class="text-subtitle-1 mb-3">
                <span>{{ note.body }}</span>
                <!--                <span v-if="!isEditingNote">{{ note.body }}</span>-->
                <!--                <v-text-field-->
                <!--                  v-else-->
                <!--                  v-model="note.body"-->
                <!--                  v-on:keyup.enter="EditNote(note)"-->
                <!--                ></v-text-field>-->
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-col>
      <v-col cols="8" id="secondColumn" class="text-center" v-if="!isMobile && mandate !== {}">
        <v-col class="text-center d-flex justify-center flex-wrap" style="gap: 5px">
          <v-btn
              color="red darken-1 white--text"
              @click="deleteMandateDialog"
              :disabled="currRole !== 13 && currRole !== 1"
              :loading="deleteMandateLoading"
          >Delete
          </v-btn>
          <v-btn
              color="primary"
              @click="reviewedMandate()"
              :disabled="notAllowed"
              :loading="markAsReviewedLoading"
          >Mark As Reviewed
          </v-btn>
          <v-btn
              color="primary"
              @click="blockMandateDialog"
              :disabled="notAllowed"
              :loading="markAsBlockedLoading"
          >Mark As Blocked
          </v-btn>
          <v-btn
              @click="escalateMandateDialog"
              :disabled="currRole !== 13 || mandate.is_escalated"
              color="primary"
          >Escalate
          </v-btn>
          <v-btn @click="downloadMandate" color="primary"
          >Download
            <v-icon right dark> mdi-download</v-icon>
          </v-btn>
        </v-col>

        <div class="d-flex justify-lg-space-around">
          <v-btn class="mr-3" color="primary" @click="onPrevPage" id="prev">Previous</v-btn>
          <div>
            <v-select
                style="width: 100px"
                dense
                label="Zoom in/out"
                v-model="scale"
                @change="queueRenderPage(pageNum)"
                :items="canvasSize"
            >
            </v-select>
            <span class="mr-16"
            ><strong>Page</strong>: <span id="page_num"></span> / <span id="page_count"></span
            ></span>
          </div>
          <v-btn class="mr-3" color="primary" @click.prevent="onNextPage" id="next">Next</v-btn>
        </div>
        <canvas @click="handleClick" class="text-center mt-2" id="the-canvas"></canvas>
        <!--          <iframe :src="mandate.upload_url" width="100%" height="100%"></iframe>-->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MandateService from "@/services/mandate.service";
import ClientsService from "@/services/clients.service";
import {mapGetters} from "vuex";
import DeleteConfirmation from "@/components/Client/DeleteConfirmation.vue";

export default {
  name: "SingleMandate",
  components: {DeleteConfirmation},
  data() {
    return {
      canvasSize: [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
      mandate: null,
      originalMandate: null,
      loading: false,
      businessImpactChoices: [],
      stateSearch: "",
      stateLoading: false,
      countySearch: "",
      countyLoading: false,
      citySearch: "",
      cityLoading: false,
      naicsSearch: "",
      naicsLoading: false,
      selectedState: {},
      selectedCities: [],
      selectedCounties: [],
      selectedImpacts: [],
      selectedNaics: [],
      states: [],
      cities: [],
      counties: [],
      naics: [],
      impacts: [],
      pageNum: 1,
      pdfDoc: null,
      pageRendering: false,
      pageNumPending: null,
      scale: 1,
      canvasEl: null,
      ctx: null,
      clickTimeout: null,
      reasonDialog: false,
      reasonTitle: "",
      reason: "",
      reasonSubmitAction: null,
      reasonSubmitButtonText: "",
      reasonDialogLoading: false,
      isEditingNote: false,
      showDeleteDialog: false,
      showDeleteMandateDialog: false,
      notes: [],
      noteID: null,
      noteMessage: "",
      deleteNoteTitle: "Are you sure you want to delete this note?",
      deleteMandateTitle: "Are you sure you want to delete this mandate?",
      currRole: null,
      markAsReviewedLoading: false,
      markAsBlockedLoading: false,
      deleteMandateLoading: false,
      alert: {
        msg: "",
        type: "",
        color: "",
      },
      userIsMasterTaxAttorney: false,
      checkInMandateLoading: false,
      reviewStatus: {},
      substantiationClients: {
        headers: [],
        clients: [],
      },
      addingNoteLoading: false,
      notesLoading: false,
    };
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
        "src",
        "//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.min.js"
    );
    // recaptchaScript.setAttribute("src", "//mozilla.github.io/pdf.js/build/pdf.js");
    document.head.appendChild(recaptchaScript);
  },
  methods: {
    async escalateMandate() {
      this.reasonDialogLoading = true;
      let payload = {
        escalated: true,
        priority_reason: this.reason,
      };
      await this.patchMandate(this.$route.params.id, payload);
      this.$func.popMessage("Mandate has been escalated!", "success");
      await this.getReviewStatus();
      this.reason = "";
      this.reasonDialog = false;
      this.reasonDialogLoading = false;
    },
    async patchMandateReview(id, paylod) {
      try {
        await MandateService.updateIndividualMandate(id, paylod);
      } catch (error) {
        this.$func.popMessage(error.response.data.error, "error");
      }
    },
    async patchMandate(id, payload) {
      try {
        await MandateService.patchMandate(id, payload);
      } catch (e) {
        this.$func.popMessage(error.response.data.error, "error");
      }
    },
    async reviewedMandate() {
      const reviewModel = {
        review_status: 6,
      };
      this.markAsReviewedLoading = true;
      await this.patchMandateReview(this.mandate.mandate_queue_id, reviewModel);
      await this.getReviewStatus();
      this.markAsReviewedLoading = false;
    },
    blockMandateDialog() {
      this.reasonDialog = true;
      this.reasonTitle = "Add a reason for blockage";
      this.reasonSubmitAction = this.submitBlockMandate;
      this.reasonSubmitButtonText = "Mark as blocked";
    },
    escalateMandateDialog() {
      this.reasonDialog = true;
      this.reasonTitle = "Add a reason for escalation (optional)";
      this.reasonSubmitAction = this.escalateMandate;
      this.reasonSubmitButtonText = "Mark as escalated";
    },
    async submitBlockMandate() {
      this.reasonDialogLoading = true;
      const blockedModel = {
        review_status: 7,
        review_status_reason: this.reason,
      };

      await this.patchMandateReview(this.mandate.mandate_queue_id, blockedModel);
      await this.getReviewStatus();
      this.reason = "";
      this.reasonDialog = false;
      this.reasonDialogLoading = false;
    },
    async checkMandate(statusId) {
      this.checkInMandateLoading = true;
      const id = this.mandate.mandate_queue_id;
      const checkInModel = {
        review_status: statusId,
      };
      await this.patchMandateReview(id, checkInModel);
      await this.getReviewStatus();
      this.checkInMandateLoading = false;
    },
    async deleteMandate({closeWindow, acceptedDelete}) {
      this.showDeleteMandateDialog = closeWindow;
      if (acceptedDelete) {
        const id = this.mandate.mandate_queue_id;
        try {
          await MandateService.deleteMandateInReviewQueueById(id);
          this.$router.push("/queues/mandates");
        } catch (e) {
          const {error, related_clients} = e.response.data;
          if (related_clients) {
            this.substantiationClients.headers = [
              {text: "Client ID", value: "client_id", class: "orange lighten-2 font-weight-bold"},
              {
                text: "Client Trade Name",
                value: "trade_name",
                class: "orange lighten-2 font-weight-bold",
              },
              {text: "EIN", value: "ein", class: "orange lighten-2 font-weight-bold"},
            ];
            this.substantiationClients.clients = related_clients;
            this.setAlertData(error, "warning");
          } else {
            this.$func.popMessage(error, "error");
          }
        } finally {
          this.deleteMandateLoading = false;
        }
      }
      this.deleteMandateLoading = false;
    },
    deleteMandateDialog() {
      this.deleteMandateLoading = true;
      this.showDeleteMandateDialog = true;
    },
    deleteNoteDialog(note) {
      this.showDeleteDialog = true;
      this.noteID = note.id;
    },
    async onClickDeleteNoteDialog({closeWindow, acceptedDelete}) {
      this.showDeleteDialog = closeWindow;
      if (acceptedDelete) {
        try {
          await MandateService.deleteNote(this.noteID);
          await this.getNotes();
        } catch (e) {
          this.$func.popMessage(e.response.data.error, "error");
        }
      }
    },
    // async EditNote(noteID) {
    //   const id = this.$route.params.id;
    //
    //   const noteModel = {
    //     body: this.noteMessage,
    //   };
    //
    //   try {
    //     await MandateService.editingNote(id, noteID.id, noteModel);
    //     await this.GetNotes();
    //     this.isEditingNote = false;
    //   } catch (e) {
    //     this.$func.popMessage(e.response.data.error, "error");
    //   }
    // },
    async addNote() {
      const id = this.$route.params.id;

      const noteModel = {
        body: this.noteMessage,
      };

      try {
        this.addingNoteLoading = true;
        await MandateService.postingNote(id, noteModel);
        await this.getNotes();
      } catch (e) {
        this.$func.popMessage(e.response.data.error, "error");
      } finally {
        this.noteMessage = "";
        this.addingNoteLoading = false;
      }
    },
    async getNotes() {
      const id = this.$route.params.id;
      this.notesLoading = true;
      try {
        const res = await MandateService.getNote(id);
        this.notes = res;
      } catch (e) {
        this.$func.popMessage(e.response.data.error, "error");
      } finally {
        this.notesLoading = false;
      }
    },
    handleClick() {
      if (!this.clickTimeout) {
        this.clickTimeout = setTimeout(() => {
          clearTimeout(this.clickTimeout);
          this.clickTimeout = null;
        }, 230);
      } else {
        clearTimeout(this.clickTimeout);
        this.clickTimeout = null;
        if (this.scale >= 2) return;
        this.scale += 0.25;
        this.queueRenderPage(this.pageNum);
      }
    },
    updateMandate() {
      const model = {
        state: this.selectedState.id,
        counties: this.selectedCounties.map((e) => e.id),
        cities: this.selectedCities.map((e) => e.id),
        naics: this.selectedNaics.map((e) => e.id),
        impact: this.selectedImpacts.map((e) => e.id),
      };
      this.loading = true;
      MandateService.updateMandate(this.$route.params.id, model)
          .then((res) => {
            this.getMandate();
          })
          .finally(() => (this.loading = false));
    },
    revertChanges() {
      this.selectedState = {
        id: this.originalMandate.state,
        state_name: this.originalMandate.state_name,
      };
      this.selectedCities = [...this.originalMandate.cities];
      this.selectedCounties = [...this.originalMandate.counties];
      this.selectedImpacts = [...this.originalMandate.impact];
      this.selectedNaics = [...this.originalMandate.naics];
      this.naicsSearch = "";
      this.citySearch = "";
      this.countySearch = "";
      this.stateSearch = "";
    },
    downloadMandate() {
      window.open(this.mandate.upload_url, "_blank");
    },
    onStateSelect(state) {
      this.selectedState = state;
      this.selectedCities = [];
      this.selectedCounties = [];
      // this.selectedNaics = [];
      setTimeout(() => {
        this.stateSearch = "";
      }, 10);
    },
    onCountySelect(item) {
      if (this.selectedCounties.filter((e) => e.id === item.id).length > 0) {
        setTimeout(() => {
          this.countySearch = "";
        }, 10);
        return;
      }
      this.selectedCounties.push(item);
      setTimeout(() => {
        this.countySearch = "";
      }, 10);
    },
    onCitySelect(item) {
      if (this.selectedCities.filter((e) => e.id === item.id).length > 0) {
        setTimeout(() => {
          this.citySearch = "";
        }, 10);
        return;
      }
      this.selectedCities.push(item);
      setTimeout(() => {
        this.citySearch = "";
      }, 10);
    },
    onNaicSelect(item) {
      if (this.selectedNaics.filter((e) => e.id === item.id).length > 0) {
        setTimeout(() => {
          this.naicsSearch = "";
        }, 10);
        return;
      }
      this.selectedNaics.push(item);
      setTimeout(() => {
        this.naicsSearch = "";
      }, 10);
    },
    renderPage(num, scale, canvasEl, ctx) {
      this.pageRendering = true;
      // Using promise to fetch the page
      this.pdfDoc.getPage(num).then(function (page) {
        let viewport = page.getViewport({scale: scale});
        canvasEl.height = viewport.height;
        canvasEl.width = viewport.width;

        // Render PDF page into canvas context
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        page.render(renderContext);
      });
      setTimeout(() => {
        this.pageRendering = false;
        if (this.pageNumPending !== null) {
          this.pageNumPending = null;
          this.renderPage(this.pageNumPending, scale, canvasEl, ctx);
        }
        document.getElementById("page_num").textContent = num;
      }, 50);
      // Update page counters
    },
    queueRenderPage(num) {
      if (this.pageRendering) {
        this.pageNumPending = num;
      } else {
        this.renderPage(num, this.scale, this.canvasEl, this.ctx);
      }
    },
    onPrevPage() {
      if (this.pageNum <= 1) {
        return;
      }
      this.pageNum--;
      this.queueRenderPage(this.pageNum);
    },
    onNextPage() {
      if (this.pageNum >= this.pdfDoc.numPages) {
        return;
      }
      this.pageNum++;
      this.queueRenderPage(this.pageNum);
    },
    getMandate() {
      //this.loading = true;
      return MandateService.getMandate(this.$route.params.id)
          .then((response) => {
            this.mandate = response;
            this.originalMandate = response;
            this.selectedState = {id: this.mandate.state, state_name: this.mandate.state_name};
            this.selectedCities = [...this.mandate.cities];
            this.selectedCounties = [...this.mandate.counties];
            this.selectedImpacts = [...this.mandate.impact];
            this.selectedNaics = [...this.mandate.naics];
            if (this.isMobile) {
              this.loading = false;
              return;
            }
            let pdfjsLib = window["pdfjs-dist/build/pdf"];
            // pdfjsLib.GlobalWorkerOptions.workerSrc = "//mozilla.github.io/pdf.js/build/pdf.worker.js";
            // pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.1.266/pdf.worker.js`;
            pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js`;
            this.canvasEl = document.getElementById("the-canvas");
            this.ctx = this.canvasEl.getContext("2d");
            setTimeout(() => {
              pdfjsLib.getDocument(this.mandate.upload_url).promise.then((pdfDoc_) => {
                this.pdfDoc = pdfDoc_;
                document.getElementById("page_count").textContent = this.pdfDoc.numPages;

                // Initial/first page rendering
                this.renderPage(this.pageNum, 1, this.canvasEl, this.ctx);
              });
              this.loading = false;
            }, 1500);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    setAlertData(msg, type, color) {
      this.alert = {
        msg: msg,
        type: type,
        color: color,
      };
    },
    async getReviewStatus() {
      const data = await MandateService.getMandateInReviewQueueById(this.mandate.mandate_queue_id);
      this.reviewStatus = data;
      this.mandate.is_escalated = data.is_escalated === "true";

      if (!data.checked_out_to) {
        if ([1, 2, 3].includes(data.review_status_id)) {
          this.setAlertData(
              "Mandate is still pre-processing, and cannot be checked out yet. Check back in a few minutes.",
              "error"
          );
          return;
        }

        if (data.review_status_id === 7) {
          this.setAlertData("This mandate is marked as blocked", "error");
          return;
        }

        if (data.review_status_id === 6) {
          this.setAlertData(`This mandate is marked as reviewed`, "success");
          return;
        }

        this.setAlertData(
            'In order to edit this mandate please press the "CHECK OUT" button.',
            "error"
        );
      } else if (data.checked_out_to !== this.currentUser.current_user_id) {
        this.setAlertData(
            `This mandate has been checked out by user ${data.checked_out_to_name}`,
            this.userIsMasterTaxAttorney ? "info" : "error",
            this.userIsMasterTaxAttorney ? "blue-grey" : null
        );
      } else {
        this.setAlertData("");
      }
    },
  },
  watch: {
    stateSearch(val) {
      if (!val) return;
      this.stateLoading = true;
      MandateService.getStates({q: val}).then((res) => {
        this.states = res;
        this.stateLoading = false;
      });
    },
    citySearch(val) {
      if (!val) return;
      this.cityLoading = true;
      MandateService.getCities(this.selectedState.id, {q: val}).then((res) => {
        this.cities = res;
        this.cityLoading = false;
      });
    },
    naicsSearch(val) {
      if (!val) return;
      this.naicsLoading = true;
      MandateService.getIndustries({q: val}).then((res) => {
        this.naics = res;
        this.naicsLoading = false;
      });
    },
    countySearch(val) {
      if (!val) return;
      this.countyLoading = true;
      MandateService.getCounties(this.selectedState.id, {q: val}).then((res) => {
        this.counties = res;
        this.countyLoading = false;
      });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return true;
        default:
          return false;
      }
    },
    edited() {
      if (this.originalMandate) {
        let stateEdited = this.selectedState.id !== this.originalMandate.state;
        let countiesEdited =
            JSON.stringify(this.selectedCounties) !== JSON.stringify(this.originalMandate.counties);
        let citiesEdited =
            JSON.stringify(this.selectedCities) !== JSON.stringify(this.originalMandate.cities);
        let industriesEdited =
            JSON.stringify(this.selectedNaics) !== JSON.stringify(this.originalMandate.naics);
        let impactsEdited =
            JSON.stringify(this.selectedImpacts) !== JSON.stringify(this.originalMandate.impact);

        if (stateEdited || countiesEdited || citiesEdited || industriesEdited || impactsEdited)
          return true;

        return false;
      }
    },
    notAllowed() {
      return this.alert.type === "error";
    },
  },
  async created() {
    this.loading = true;
    await MandateService.getBusinessImpact().then((res) => {
      this.businessImpactChoices = res;
    });
    await this.getMandate();

    this.currRole = (await ClientsService.getUsersRole(this.currentUser.current_user_id)).role_id;
    this.userIsMasterTaxAttorney = this.currRole === 13;

    await this.getReviewStatus();
    this.loading = false;

    this.getNotes();
  },
};
</script>

<style scoped>
#firstColumn {
  height: 100%;
  width: 45%;
//border:1px solid red; float: left; overflow-y: scroll;
}

#secondColumn {
  height: 100%;
  width: 45%;
//margin-left:50%; //border:1px solid blue; overflow-y: scroll;
}

@media only screen and (min-width: 960px) {
  #firstColumn::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  #firstColumn::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }

  #firstColumn::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #979797;
  }

  #secondColumn::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
}
</style>
