const state = () => ({
    note: false
});

const getters = {
    getClientNotes(state) {
        return state.note
    }
}

const mutations = {
    setNotePanel(state, payload) {
        state.note = payload.notes;
    },
}

const actions = {
    notesOpen({ commit }, payload) {
        commit("setNotePanel", payload);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
