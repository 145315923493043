<template>
  <div>
    <v-overlay :value="loadinOverlay">
      <v-progress-circular
          :size="70"
          :width="7"
          color="white"
          indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="true"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            v-model="date"
            label="Payment period"
            style="width: 83%"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
          v-model="date"
          type="month"
          no-title
          scrollable
      >
        <v-spacer></v-spacer>
      </v-date-picker>
    </v-menu>
    <div v-if="!loadinOverlay" class="d-flex justify-space-around">

      <v-card elevation="7" class="pa-10 d-flex justify-center align-center flex-column">
        <h3>Total Checks Received</h3>
        <h1 style="font-size: 2rem">{{ totalFees }}</h1>
      </v-card>

      <v-card elevation="7" class="pa-10 d-flex justify-center align-center flex-column">
        <h3>Average Cases per Week</h3>
        <h1 style="font-size: 2rem">{{ avgCases }}</h1>
      </v-card>

    </div>
    <v-data-table
        v-if="feeItems.length"
        :headers="headers"
        :items="feeItems"
        class="elevation-5 mt-6"
        disable-pagination
        hide-default-footer

    >
      <template v-slot:item="row">


        <tr>
          <td class="text-xs-right">{{ row.item.adjusted_fees_payable_formatted }} </td>
          <td class="text-xs-right  text-end">{{ row.item.pay_to_the_order_of}} </td>
        </tr>


      </template>
    </v-data-table>
    <div v-if="!feeItems.length" class="mt-10 px-10 text-center">
      <h3 style="color: #555555">
        No checks have been received for the current month. Once checks are entered for this month, amounts collected for those entities will display here.
      </h3>
    </div>
  </div>
</template>

<script>

import ClientsService from "@/services/clients.service";

export default {
  name: "MasterTaxAttorneyFees",
  data () {
    return {
      loadinOverlay:false,
      menu:false,
      date:'',
      feeItems:[],
      totalFees:0,
      avgCases:0,
      headers: [
        { text: 'Fees Payable', value: 'adjusted_fees_payable_formatted' },
        { text: 'Pay to the order of', value: 'pay_to_the_order_of',align: 'end' },
      ],
    }

  },
  watch: {
    date(val){
      this.getReport()
    },
  },
  methods:{
    getReport(){
      this.loadinOverlay = true
      ClientsService.getReports(this.date)
          .then(data => {
            this.feeItems = data.register
            this.totalFees = data.adjusted_fees_payable_formatted != null ? data.adjusted_fees_payable_formatted : 0;
            this.avgCases = data.avg_cases_per_week;
            this.loadinOverlay = false
          }).catch(()=> {this.loadinOverlay = false})

    },
    getCurrMonth(){
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1; //months from 1-12
      const year = dateObj.getUTCFullYear();
      if (month < 10){
        month = '0' + month
      }

      return year + "-" + month;
    },
  },
  async created() {
    this.date = await this.getCurrMonth()
  }
}
</script>


<style scoped>

</style>