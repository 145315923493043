import $axios from '../plugins/axios'

class SearchService {
	getSearchResults(searchTerm) {
		return $axios.get('/search', {
			params: {
				q: searchTerm,
			}
		})
			.then((res) => {
				return res.data;
			});
	}

}

export default new SearchService();