<template>
<div>
  <v-row>
    <v-col cols="12" class="text-center">
      <h1>Financial performance</h1>
      <h2>Final Clients</h2>
    </v-col>
    <v-col class="d-flex justify-space-between align-center pa-5" cols="12" >
      <v-row class="d-flex justify-space-between align-center">
        <v-col cols="6"  md="3"><v-card  class="cardHeight px-xl-16 py-6 d-flex flex-column ml-lg-7 text-center">
          <h2>Total Reports Filed</h2>
          <h1>{{ reportsFiled }}</h1>
        </v-card></v-col>
        <v-col cols="6"  md="3"> <v-card class="cardHeight px-xl-16 py-6 d-flex flex-column text-center">
          <h2>Total Credits Filed</h2>
          <h1>{{creditsFiled}}</h1>
        </v-card></v-col>
        <v-col cols="6"  md="3"> <v-card class="cardHeight px-xl-16 py-6 d-flex flex-column text-center">
          <h2>Fees Payable</h2>
          <h1>{{ payableFees }}</h1>
        </v-card></v-col>
      </v-row>
    </v-col>
    <v-col cols="12" style="height: 400px !important;">
      <canvas id="dailyChart"></canvas>
    </v-col>
    <v-col cols="12" style="height: 400px !important;">
      <canvas id="feesChart"></canvas>
    </v-col>
    <v-col cols="12" style="height: 400px !important;">
      <canvas id="w2Chart"></canvas>
    </v-col>
  </v-row>

</div>
</template>

<script>
import Chart from "chart.js/auto";
import ClientsService from "@/services/clients.service";
export default {
  name: "FinancialPerformance",
  data:()=>({
    reportsFiled:'',
    creditsFiled:'',
    payableFees:'',
    daily_dataset:[{
      label: 'Final Reports Generated',
      data: [],
      fill:true,
      backgroundColor: ['rgba(250,108,108,0.47)'],
      borderColor: ['#263B4F'],
      borderWidth: 1
    }],
    w2_dataset:[{
      label: 'W2 Trend',
      data: [],
      fill:true,
      backgroundColor: ['rgba(128,48,128,0.47)'],
      borderColor: ['#263B4F'],
      borderWidth: 1
    }],
    fees_dataset:[{
      label: 'Fees Payable Trend',
      data: [],
      fill:true,
      backgroundColor: ['rgba(58,194,58,0.47)'],
      borderColor: ['#263B4F'],
      borderWidth: 1
    }],
    items:[],
    maxChartValue:0,
    maxChartValue2:0,
    maxChartValue3:0,
    chartLabels:[],
    chartLabelsFees:[],
    chartLabelsW2:[],
  }),
  methods:{
    formatData(){
      // this.items={
      //   "filed": {
      //     "total_reports": 177,
      //     "credits_filed": 37475628.89,
      //     "credits_filed_formatted": "37,475,628.89",
      //     "fees_payable": 2852916.33,
      //     "fees_payable_formatted": "2,852,916.33",
      //     "w2_count": 13230,
      //     "charts": {
      //       "daily_trend": {
      //         "labels": [
      //           "Feb 08",
      //           "Feb 10",
      //           "Feb 13",
      //           "Feb 15",
      //           "Feb 16",
      //           "Feb 17",
      //           "Feb 20",
      //           "Feb 21",
      //           "Feb 22",
      //           "Feb 24",
      //           "Feb 26",
      //           "Feb 27",
      //           "Feb 28",
      //           "Mar 01",
      //           "Mar 02",
      //           "Mar 03",
      //           "Mar 06",
      //           "Mar 08",
      //           "Mar 09",
      //           "Mar 10",
      //           "Mar 13",
      //           "Mar 14",
      //           "Mar 15",
      //           "Mar 16",
      //           "Mar 17",
      //           "Mar 20",
      //           "Mar 21",
      //           "Mar 22",
      //           "Mar 23",
      //           "Mar 24"
      //         ],
      //         "data": [
      //           2,
      //           14,
      //           19,
      //           6,
      //           5,
      //           16,
      //           3,
      //           8,
      //           7,
      //           3,
      //           2,
      //           12,
      //           1,
      //           16,
      //           1,
      //           5,
      //           2,
      //           8,
      //           3,
      //           3,
      //           2,
      //           4,
      //           1,
      //           4,
      //           2,
      //           7,
      //           12,
      //           8,
      //           6,
      //           5
      //         ]
      //       },
      //       "w2_trend": {
      //         "labels": [
      //           "02-24",
      //           "02-26",
      //           "02-27",
      //           "02-28",
      //           "03-01",
      //           "03-02",
      //           "03-03",
      //           "03-06",
      //           "03-08",
      //           "03-09",
      //           "03-10",
      //           "03-13",
      //           "03-14",
      //           "03-15",
      //           "03-16",
      //           "03-17",
      //           "03-20",
      //           "03-21",
      //           "03-22",
      //           "03-23",
      //           "03-24"
      //         ],
      //         "data": [
      //           310,
      //           64,
      //           501,
      //           49,
      //           1301,
      //           46,
      //           170,
      //           29,
      //           553,
      //           489,
      //           182,
      //           188,
      //           187,
      //           13,
      //           85,
      //           174,
      //           257,
      //           791,
      //           144,
      //           204,
      //           88
      //         ]
      //       },
      //       "fees_payable_trend": {
      //         "labels": [
      //           "02-24",
      //           "02-26",
      //           "02-27",
      //           "02-28",
      //           "03-01",
      //           "03-02",
      //           "03-03",
      //           "03-06",
      //           "03-08",
      //           "03-10",
      //           "03-13",
      //           "03-14",
      //           "03-16",
      //           "03-22"
      //         ],
      //         "data": [
      //           87713.97,
      //           4540,
      //           159134.8,
      //           15011.46,
      //           286924.73,
      //           25246.48,
      //           28628.78,
      //           1088.57,
      //           4678.43,
      //           2587.09,
      //           44959.49,
      //           48728.24,
      //           33352.15,
      //           5075.44
      //         ]
      //       }
      //     }
      //   }
      // }
      this.payableFees = this.items.filed.fees_payable_formatted
      this.reportsFiled = this.items.filed.total_reports
      this.creditsFiled = this.items.filed.credits_filed_formatted
      this.createFirstChart();
      this.createSecondChart();
      this.createThirdChart();
    },
    createFirstChart(){
      const ctx = document.getElementById('dailyChart');
      this.daily_dataset[0].data = this.items.filed.charts.daily_trend.data
      this.chartLabels = this.items.filed.charts.daily_trend.labels
      this.maxChartValue = Math.max( ...this.daily_dataset[0].data );
      const myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: this.chartLabels,
          datasets: this.daily_dataset
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          // scales: {
          //   y: {
          //     beginAtZero: true
          //   }
          // }
          scales: {
            x: {
              display: true,
              title: {
                display: true
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Value'
              },
              suggestedMin: 0,
              suggestedMax: this.maxChartValue
            }
          }
        }
      });

    },
    createSecondChart(){
      const fees = document.getElementById('feesChart');
      this.fees_dataset[0].data = this.items.filed.charts.fees_payable_trend.data

      this.chartLabels2 = this.items.filed.charts.fees_payable_trend.labels
      this.maxChartValue2 = Math.max( ...this.fees_dataset[0].data );

      const feesChart = new Chart(fees, {
        type: 'line',
        data: {
          labels: this.chartLabels2,
          datasets: this.fees_dataset
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          // scales: {
          //   y: {
          //     beginAtZero: true
          //   }
          // }
          scales: {
            x: {
              display: true,
              title: {
                display: true
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Value'
              },
              suggestedMin: 0,
              suggestedMax: this.maxChartValue2
            }
          }
        }
      });

    },
    createThirdChart(){
      this.w2_dataset[0].data = this.items.filed.charts.w2_trend.data
      const w2 = document.getElementById('w2Chart');
      this.chartLabels3 = this.items.filed.charts.w2_trend.labels
      this.maxChartValue3 = Math.max( ...this.w2_dataset[0].data );
      const w2Chart = new Chart(w2, {
        type: 'line',
        data: {
          labels: this.chartLabels3,
          datasets: this.w2_dataset
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          // scales: {
          //   y: {
          //     beginAtZero: true
          //   }
          // }
          scales: {
            x: {
              display: true,
              title: {
                display: true
              }
            },
            y: {
              display: true,
              title: {
                display: true,
                text: 'Value'
              },
              suggestedMin: 0,
              suggestedMax: this.maxChartValue3
            }
          }
        }
      });
    },
    getData(){
      ClientsService.getFinancialPerformance().then(res=>{
        this.items = res
        setTimeout(()=>{
          this.formatData()
        },50)
        //Dummy data
      })
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style scoped>

</style>
